import { type QuestionAnswers_userStudySessionQuestion$key } from '@app/__generated__/QuestionAnswers_userStudySessionQuestion.graphql'
import { AnswerButton, MarkdownBox, QuestionResult } from '@app/components'
import { createStyles, Group, Stack } from '@mantine/core'
import { map } from 'lodash'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const userStudySessionQuestionFragment = graphql`
  fragment QuestionAnswers_userStudySessionQuestion on UserStudySessionQuestion {
    question {
      questionAnswers(condition: { isActive: true }) {
        nodes {
          body
          isCorrect
          rowId
          sequence
        }
      }
      ...QuestionResult_question
    }
    questionAnswerId
  }
`

export interface QuestionAnswersProps {
  disabled?: boolean
  userStudySessionQuestion: QuestionAnswers_userStudySessionQuestion$key
  onSelectAnswer?: (answer: any) => void
  selectedAnswerId?: string
  showResult?: boolean
}

const useStyles = createStyles(() => ({
  answerText: {
    fontSize: 16,
    marginTop: 10
  }
}))

const letterOptions = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]

export const QuestionAnswers: FC<QuestionAnswersProps> = ({
  userStudySessionQuestion,
  showResult = false,
  disabled = false,
  selectedAnswerId,
  onSelectAnswer
}) => {
  const { classes } = useStyles()
  const userStudySessionQuestionData = useFragment(userStudySessionQuestionFragment, userStudySessionQuestion)
  const correctAnswer = userStudySessionQuestionData?.question?.questionAnswers?.nodes?.find(
    (answer) => answer.isCorrect
  )
  const actualSelectedAnswerId = userStudySessionQuestionData?.questionAnswerId || selectedAnswerId
  const isSelectedAnswerCorrect = correctAnswer?.rowId === actualSelectedAnswerId

  return (
    <Stack>
      {map(userStudySessionQuestionData?.question?.questionAnswers?.nodes, ({ rowId, isCorrect, body }, idx) => (
        <Group
          key={`answer-${rowId}`}
          noWrap
          align='flex-start'
        >
          <AnswerButton
            onClick={disabled || !onSelectAnswer ? undefined : () => onSelectAnswer(rowId)}
            isSelected={actualSelectedAnswerId === rowId || (showResult && !isSelectedAnswerCorrect && isCorrect)}
            variant={
              actualSelectedAnswerId === rowId && showResult && !isSelectedAnswerCorrect ? 'incorrect' : 'default'
            }
          >
            {letterOptions[idx]}
          </AnswerButton>
          <Stack>
            <MarkdownBox className={classes.answerText}>{body}</MarkdownBox>
            {userStudySessionQuestionData?.question && showResult && isCorrect && (
              <QuestionResult
                isCorrect={isSelectedAnswerCorrect}
                question={userStudySessionQuestionData?.question}
              />
            )}
          </Stack>
        </Group>
      ))}
    </Stack>
  )
}
