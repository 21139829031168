import { type StudySessionReviewWizard_userStudySession$key } from '@app/__generated__/StudySessionReviewWizard_userStudySession.graphql'
import { BorderButton, StudySessionReviewQuestion, WizardWrapper } from '@app/components'
import { useMantineTheme } from '@mantine/core'
import { IconChevronLeft } from '@tabler/icons-react'
import { map } from 'lodash'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const userStudySessionFragment = graphql`
  fragment StudySessionReviewWizard_userStudySession on UserStudySession {
    totalQuestionsCount
    userStudySessionQuestions(orderBy: [SEQUENCE_ASC]) {
      nodes {
        rowId
        ...StudySessionReviewQuestion_userStudySessionQuestion
      }
    }
  }
`
export interface StudySessionReviewWizardProps {
  userStudySession: StudySessionReviewWizard_userStudySession$key
  resetSession: () => void
  showStudySessionResults: () => void
}
export const StudySessionReviewWizard: FC<StudySessionReviewWizardProps> = ({
  userStudySession,
  resetSession,
  showStudySessionResults
}) => {
  const userStudySessionData = useFragment(userStudySessionFragment, userStudySession)
  const theme = useMantineTheme()

  return (
    <WizardWrapper stepCount={userStudySessionData?.totalQuestionsCount || 0}>
      {({ active, onNext, onPrev }) =>
        map(userStudySessionData?.userStudySessionQuestions.nodes, (userStudySessionQuestion, index: number) => (
          <div
            hidden={active !== index}
            key={`step${index}`}
          >
            {active === index && (
              <StudySessionReviewQuestion
                questionsCount={userStudySessionData.totalQuestionsCount || 0}
                userStudySessionQuestion={userStudySessionQuestion}
                onNext={onNext}
                onPrev={index > 0 ? onPrev : undefined}
                finalOptions={
                  index === (userStudySessionData?.totalQuestionsCount || 0) - 1 ? (
                    <>
                      <BorderButton
                        onClick={onPrev}
                        color={theme.colors.studiousGray[0]}
                        leftIcon={<IconChevronLeft />}
                        disabled={!onPrev}
                      >
                        Previous
                      </BorderButton>
                      <BorderButton
                        onClick={resetSession}
                        color={theme.colors.studiousGray[3]}
                      >
                        New Session
                      </BorderButton>
                      <BorderButton
                        onClick={showStudySessionResults}
                        color={theme.colors.studiousGray[0]}
                      >
                        Session Results
                      </BorderButton>
                    </>
                  ) : undefined
                }
              />
            )}
          </div>
        ))
      }
    </WizardWrapper>
  )
}
