/**
 * @generated SignedSource<<e3603a6cef6c78f3d4646b6154f73a6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateStudentForm_user$data = {
  readonly image: string | null | undefined;
  readonly name: string | null | undefined;
  readonly " $fragmentType": "UpdateStudentForm_user";
};
export type UpdateStudentForm_user$key = {
  readonly " $data"?: UpdateStudentForm_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateStudentForm_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateStudentForm_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "435b443d1f5a9b2f6c13e016c23d0006";

export default node;
