/**
 * @generated SignedSource<<294deb87bc7011704e935d46304126ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteExamTypesUserByIdInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type ExamTypesUserDeleteModalDeleteExamTypesUserMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteExamTypesUserByIdInput;
};
export type ExamTypesUserDeleteModalDeleteExamTypesUserMutation$data = {
  readonly deleteExamTypesUserById: {
    readonly deletedExamTypesUserId: string | null | undefined;
    readonly examType: {
      readonly " $fragmentSpreads": FragmentRefs<"ExamTypeUserForm_examType">;
    } | null | undefined;
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"UserExamTypeForm_user">;
    } | null | undefined;
  } | null | undefined;
};
export type ExamTypesUserDeleteModalDeleteExamTypesUserMutation = {
  response: ExamTypesUserDeleteModalDeleteExamTypesUserMutation$data;
  variables: ExamTypesUserDeleteModalDeleteExamTypesUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedExamTypesUserId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExamTypesUserDeleteModalDeleteExamTypesUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteExamTypesUserPayload",
        "kind": "LinkedField",
        "name": "deleteExamTypesUserById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamType",
            "kind": "LinkedField",
            "name": "examType",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ExamTypeUserForm_examType"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserExamTypeForm_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExamTypesUserDeleteModalDeleteExamTypesUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteExamTypesUserPayload",
        "kind": "LinkedField",
        "name": "deleteExamTypesUserById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedExamTypesUserId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamType",
            "kind": "LinkedField",
            "name": "examType",
            "plural": false,
            "selections": [
              {
                "alias": "existingExamTypesUsers",
                "args": null,
                "concreteType": "ExamTypesUsersConnection",
                "kind": "LinkedField",
                "name": "examTypesUsers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamTypesUser",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": "existingExamTypesUsers",
                "args": null,
                "concreteType": "ExamTypesUsersConnection",
                "kind": "LinkedField",
                "name": "examTypesUsers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamTypesUser",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "examTypeId",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee5b6dc16c4eeb4fa561aeea00767dad",
    "id": null,
    "metadata": {},
    "name": "ExamTypesUserDeleteModalDeleteExamTypesUserMutation",
    "operationKind": "mutation",
    "text": "mutation ExamTypesUserDeleteModalDeleteExamTypesUserMutation(\n  $input: DeleteExamTypesUserByIdInput!\n) {\n  deleteExamTypesUserById(input: $input) {\n    deletedExamTypesUserId\n    examType {\n      ...ExamTypeUserForm_examType\n      id\n    }\n    user {\n      ...UserExamTypeForm_user\n      id\n    }\n  }\n}\n\nfragment ExamTypeUserForm_examType on ExamType {\n  existingExamTypesUsers: examTypesUsers {\n    nodes {\n      userId\n      id\n    }\n  }\n}\n\nfragment UserExamTypeForm_user on User {\n  existingExamTypesUsers: examTypesUsers {\n    nodes {\n      examTypeId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5bd322fe53bfdccc859a3572eba5a973";

export default node;
