import { type UserExamTypeUpdateModal_examTypesUser$key } from '@app/__generated__/UserExamTypeUpdateModal_examTypesUser.graphql'
import { type UserExamTypeUpdateModal_query$key } from '@app/__generated__/UserExamTypeUpdateModal_query.graphql'
import { type UserExamTypeUpdateModal_user$key } from '@app/__generated__/UserExamTypeUpdateModal_user.graphql'
import { type UserExamTypeUpdateModalUpdateExamTypesUserMutation } from '@app/__generated__/UserExamTypeUpdateModalUpdateExamTypesUserMutation.graphql'
import { UnsavedChangesModal, type UnsavedChangesModalProps, UserExamTypeForm } from '@app/components'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const examTypesUserFragment = graphql`
  fragment UserExamTypeUpdateModal_examTypesUser on ExamTypesUser {
    examType {
      title
    }
    id
    ...UserExamTypeForm_examTypesUser
  }
`
const queryFragment = graphql`
  fragment UserExamTypeUpdateModal_query on Query {
    ...UserExamTypeForm_query
  }
`
const updateMutation = graphql`
  mutation UserExamTypeUpdateModalUpdateExamTypesUserMutation($input: UpdateExamTypesUserByIdInput!) {
    updateExamTypesUserById(input: $input) {
      examTypesUser {
        ...UserExamTypeUpdateModal_examTypesUser
      }
    }
  }
`
const userFragment = graphql`
  fragment UserExamTypeUpdateModal_user on User {
    ...UserExamTypeForm_user
  }
`

export interface UserExamTypeUpdateModalProps extends UnsavedChangesModalProps {
  examTypesUser: UserExamTypeUpdateModal_examTypesUser$key | null
  query: UserExamTypeUpdateModal_query$key | null
  user: UserExamTypeUpdateModal_user$key | null
}

export const UserExamTypeUpdateModal: FC<UserExamTypeUpdateModalProps> = ({
  examTypesUser,
  onClose,
  query,
  user,
  ...props
}) => {
  const examTypesUserData = useFragment(examTypesUserFragment, examTypesUser)
  const queryData = useFragment(queryFragment, query)
  const userData = useFragment(userFragment, user)
  const [updateExamTypesUser, isUpdatingExamTypesUser] =
    useMutation<UserExamTypeUpdateModalUpdateExamTypesUserMutation>(updateMutation)

  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      title={`Modify User Exam for ${examTypesUserData?.examType?.title}`}
      {...props}
    >
      <UserExamTypeForm
        examTypesUser={examTypesUserData}
        isCreating={false}
        isSaving={isUpdatingExamTypesUser}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (patch) =>
            examTypesUserData?.id &&
            updateExamTypesUser({
              onCompleted: onClose,
              variables: {
                input: {
                  id: examTypesUserData.id,
                  patch
                }
              }
            }),
          [examTypesUserData?.id, onClose, updateExamTypesUser]
        )}
        query={queryData}
        user={userData}
      />
    </UnsavedChangesModal>
  )
}
