/**
 * @generated SignedSource<<531c319a6c28f56ef112290bf32b3f6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminTopicObjectiveLayout_topicObjective$data = {
  readonly slug: string | null | undefined;
  readonly title: string;
  readonly topic: {
    readonly parentTopic: {
      readonly examType: {
        readonly slug: string | null | undefined;
      } | null | undefined;
      readonly slug: string | null | undefined;
    } | null | undefined;
    readonly slug: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "AdminTopicObjectiveLayout_topicObjective";
};
export type AdminTopicObjectiveLayout_topicObjective$key = {
  readonly " $data"?: AdminTopicObjectiveLayout_topicObjective$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminTopicObjectiveLayout_topicObjective">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminTopicObjectiveLayout_topicObjective",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "parentTopic",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamType",
              "kind": "LinkedField",
              "name": "examType",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "TopicObjective",
  "abstractKey": null
};
})();

(node as any).hash = "4dfa2ee67e3cd5b0fedf600612c9e30b";

export default node;
