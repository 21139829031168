import { createStyles, Select, type SelectProps } from '@mantine/core'
import { IconSelect } from '@tabler/icons-react'
import { type FC } from 'react'

const useStyles = createStyles(() => ({
  input: {
    label: {
      fontSize: 16,
      fontWeight: 'bold'
    },
    input: {
      fontWeight: 'bold',
      borderRadius: 5,
      padding: '30px 15px',
      fontSize: 18
    }
  }
}))
export interface StudentSelectInputProps extends SelectProps {}

export const StudentSelectInput: FC<StudentSelectInputProps> = (props) => {
  const { classes } = useStyles()

  return (
    <Select
      className={classes.input}
      mb='xs'
      icon={<IconSelect size={18} />}
      {...props}
    />
  )
}
