import { PerformanceScoreBox_examTypesUser$key } from '@app/__generated__/PerformanceScoreBox_examTypesUser.graphql'
import { BorderBox, type BorderBoxProps, CustomIcon } from '@app/components'
import { createStyles, List, Stack, Text, Title } from '@mantine/core'
import dayjs from 'dayjs'
import durationPlugin from 'dayjs/plugin/duration'
import { round } from 'lodash'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

dayjs.extend(durationPlugin)

const useStyles = createStyles(() => ({
  iconListRoot: {
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center'
  },
  iconListItem: {
    padding: 0,
    margin: 0,
    '&:not(:first-of-type)': {
      margin: 0,
      marginLeft: 15
    }
  }
}))

const examTypesUserFragment = graphql`
  fragment PerformanceScoreBox_examTypesUser on ExamTypesUser {
    score
    totalTimeSpent {
      days
      hours
      minutes
      seconds
    }
  }
`

export interface PerformanceScoreBoxProps extends BorderBoxProps {
  examTypesUser: PerformanceScoreBox_examTypesUser$key | null
}

export const PerformanceScoreBox: FC<PerformanceScoreBoxProps> = ({ examTypesUser }) => {
  const examTypesUserData = useFragment(examTypesUserFragment, examTypesUser)

  const { classes, theme } = useStyles()

  return (
    <BorderBox
      backgroundColor={theme.colors.studiousGray[0]}
      mb='lg'
    >
      <Stack>
        <Title order={4}>Performance Score</Title>
        <List
          spacing='xs'
          size='sm'
          center
          classNames={{
            root: classes.iconListRoot,
            item: classes.iconListItem
          }}
        >
          <List.Item icon={<CustomIcon type='bolt' />}>
            <strong>{Math.round((examTypesUserData?.score || 0) * 100)}%</strong> Score
          </List.Item>
          <List.Item icon={<CustomIcon type='clock' />}>
            <strong>
              {dayjs
                .duration({
                  days: examTypesUserData?.totalTimeSpent?.days || 0,
                  hours: examTypesUserData?.totalTimeSpent?.hours || 0,
                  minutes: examTypesUserData?.totalTimeSpent?.minutes || 0,
                  seconds: examTypesUserData?.totalTimeSpent?.seconds
                    ? round(examTypesUserData?.totalTimeSpent.seconds)
                    : 0
                })
                .format(examTypesUserData?.totalTimeSpent?.hours ? 'HH:mm:ss' : 'mm:ss')}
            </strong>{' '}
            Studied
          </List.Item>
        </List>
        <Text
          mb='lg'
          color={theme.colors.gray[6]}
        >
          Your Performance Score is a measure of your progress and improvement!
        </Text>
      </Stack>
    </BorderBox>
  )
}
