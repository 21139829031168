/**
 * @generated SignedSource<<f667b3373a5f5c69979ed096a21cda73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamTypeUserCreateModal_examType$data = {
  readonly rowId: any;
  readonly " $fragmentSpreads": FragmentRefs<"ExamTypeUserForm_examType">;
  readonly " $fragmentType": "ExamTypeUserCreateModal_examType";
};
export type ExamTypeUserCreateModal_examType$key = {
  readonly " $data"?: ExamTypeUserCreateModal_examType$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamTypeUserCreateModal_examType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamTypeUserCreateModal_examType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamTypeUserForm_examType"
    }
  ],
  "type": "ExamType",
  "abstractKey": null
};

(node as any).hash = "48352c67fa7abb724c8ce965e59cc831";

export default node;
