/**
 * @generated SignedSource<<9d840d15902d7832c09157f95de8dd24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserLessonQuestionAnswers_userLessonQuestion$data = {
  readonly attempts: number;
  readonly question: {
    readonly correctAnswer: {
      readonly nodes: ReadonlyArray<{
        readonly rowId: any;
      }>;
    };
    readonly questionAnswers: {
      readonly nodes: ReadonlyArray<{
        readonly body: string;
        readonly isCorrect: boolean | null | undefined;
        readonly rowId: any;
        readonly sequence: number;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"QuestionResult_question">;
  } | null | undefined;
  readonly " $fragmentType": "UserLessonQuestionAnswers_userLessonQuestion";
};
export type UserLessonQuestionAnswers_userLessonQuestion$key = {
  readonly " $data"?: UserLessonQuestionAnswers_userLessonQuestion$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserLessonQuestionAnswers_userLessonQuestion">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserLessonQuestionAnswers_userLessonQuestion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attempts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Question",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": [
        {
          "alias": "correctAnswer",
          "args": [
            {
              "kind": "Literal",
              "name": "condition",
              "value": {
                "isActive": true,
                "isCorrect": true
              }
            }
          ],
          "concreteType": "QuestionAnswersConnection",
          "kind": "LinkedField",
          "name": "questionAnswers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuestionAnswer",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "questionAnswers(condition:{\"isActive\":true,\"isCorrect\":true})"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "condition",
              "value": {
                "isActive": true
              }
            },
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": "SEQUENCE_ASC"
            }
          ],
          "concreteType": "QuestionAnswersConnection",
          "kind": "LinkedField",
          "name": "questionAnswers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuestionAnswer",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "body",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isCorrect",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sequence",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "questionAnswers(condition:{\"isActive\":true},orderBy:\"SEQUENCE_ASC\")"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuestionResult_question"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserLessonQuestion",
  "abstractKey": null
};
})();

(node as any).hash = "092bccff72d677f8a2024600f530eb00";

export default node;
