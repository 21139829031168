import {
  type ExamTypeTopicsTable_examType$data,
  type ExamTypeTopicsTable_examType$key
} from '@app/__generated__/ExamTypeTopicsTable_examType.graphql'
import { type ExamTypeTopicsTablePaginationQuery } from '@app/__generated__/ExamTypeTopicsTablePaginationQuery.graphql'
import { type ExamTypeTopicsTableReorderTopicMutation } from '@app/__generated__/ExamTypeTopicsTableReorderTopicMutation.graphql'
import { ActionColumnIcon, IconBoolean, PaginationTable } from '@app/components'
import { type TopicFilter, type TopicsOrderBy } from '@app/lib'
import { Avatar } from '@mantine/core'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, useMutation, usePaginationFragment } from 'react-relay'
import { Get, IterableElement } from 'type-fest'

type TopicEdge = IterableElement<Get<Get<ExamTypeTopicsTable_examType$data, 'topics'>, 'edges'>>

// noinspection GraphQLUnresolvedReference
const examTypeFragment = graphql`
  fragment ExamTypeTopicsTable_examType on ExamType
  @argumentDefinitions(
    filter: { type: "TopicFilter", defaultValue: null }
    first: { type: "Int", defaultValue: 100 }
    after: { type: "Cursor" }
    orderBy: { type: "[TopicsOrderBy!]", defaultValue: [SEQUENCE_ASC] }
  )
  @refetchable(queryName: "ExamTypeTopicsTablePaginationQuery") {
    slug
    topics(condition: { parentTopicId: null }, filter: $filter, first: $first, after: $after, orderBy: $orderBy)
      @connection(key: "ExamTypeTopicsTable_examType_topics", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          id
          image
          isActive
          sequence
          slug
          title
        }
      }
      totalCount
    }
  }
`

const reorderMutation = graphql`
  mutation ExamTypeTopicsTableReorderTopicMutation($input: UpdateTopicByIdInput!) {
    updateTopicById(input: $input) {
      topic {
        sequence
      }
      examType {
        ...ExamTypeTopicsTable_examType
      }
    }
  }
`

export interface ExamTypeTopicsTableProps {
  examType: ExamTypeTopicsTable_examType$key | null
}

export const ExamTypeTopicsTable: FC<ExamTypeTopicsTableProps> = ({ examType }) => {
  const {
    data: examTypeData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<ExamTypeTopicsTablePaginationQuery, ExamTypeTopicsTable_examType$key>(
    examTypeFragment,
    examType
  )
  const [reorderTopic, isReorderingTopic] = useMutation<ExamTypeTopicsTableReorderTopicMutation>(reorderMutation)

  return (
    <PaginationTable<TopicEdge, TopicsOrderBy, ExamTypeTopicsTablePaginationQuery, TopicFilter>
      columns={useMemo<ColumnDef<TopicEdge>[]>(
        () => [
          {
            id: 'actions',
            size: 50,
            enableMultiSort: false,
            enableSorting: false,
            cell: (props) => {
              const node = props.row.original.node

              return <ActionColumnIcon href={`/admin/examTypes/${examTypeData?.slug}/topics/${node.slug}`} />
            }
          },
          {
            accessorKey: 'node.isActive',
            header: 'Active',
            size: 50,
            enableMultiSort: false,
            enableSorting: false,
            cell: (props) => {
              const node = props.row.original.node

              return (
                <IconBoolean
                  size={18}
                  value={node.isActive}
                />
              )
            }
          },
          {
            id: 'image',
            header: 'Image',
            size: 50,
            enableMultiSort: false,
            enableSorting: false,
            cell: (props) => {
              const node = props.row.original.node

              return node.image ? (
                <Avatar
                  alt='image'
                  size='sm'
                  src={node.image}
                />
              ) : null
            }
          },
          {
            accessorKey: 'node.title',
            header: 'Title',
            enableMultiSort: false,
            enableSorting: false
          }
        ],
        [examTypeData?.slug]
      )}
      data={examTypeData?.topics?.edges || []}
      filterPlaceholder='Search Topics'
      getFilterFromSearch={useCallback(
        (search: string) =>
          !isEmpty(search)
            ? {
                or: [
                  {
                    title: {
                      includesInsensitive: search
                    }
                  },
                  {
                    summary: {
                      includesInsensitive: search
                    }
                  }
                ]
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={[
        {
          id: 'sequence',
          desc: false
        }
      ]}
      isFilterable
      isLoadingNext={isLoadingNext}
      isReorderable
      loadNext={loadNext}
      refetch={refetch}
      onReorder={useCallback<(sourceIndex: number, destinationIndex: number) => void>(
        (sourceIndex, destinationIndex) => {
          if (!isReorderingTopic) {
            const topic = examTypeData?.topics?.edges?.[sourceIndex]?.node
            const nextSequence = destinationIndex + 1

            if (topic && topic.sequence !== nextSequence) {
              reorderTopic({
                variables: {
                  input: {
                    id: topic.id,
                    patch: {
                      sequence: nextSequence
                    }
                  }
                }
              })
            }
          }
        },
        [examTypeData?.topics?.edges, isReorderingTopic, reorderTopic]
      )}
      totalCount={examTypeData?.topics?.totalCount || 0}
    />
  )
}
