/**
 * @generated SignedSource<<7d513eed33913c12efb2a34c923f8c8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySessionQuestionWizard_userStudySession$data = {
  readonly userStudySessionQuestions: {
    readonly nodes: ReadonlyArray<{
      readonly rowId: any;
      readonly " $fragmentSpreads": FragmentRefs<"StudySessionQuestion_userStudySessionQuestion">;
    }>;
  };
  readonly " $fragmentType": "StudySessionQuestionWizard_userStudySession";
};
export type StudySessionQuestionWizard_userStudySession$key = {
  readonly " $data"?: StudySessionQuestionWizard_userStudySession$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySessionQuestionWizard_userStudySession">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudySessionQuestionWizard_userStudySession",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "SEQUENCE_ASC"
          ]
        }
      ],
      "concreteType": "UserStudySessionQuestionsConnection",
      "kind": "LinkedField",
      "name": "userStudySessionQuestions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserStudySessionQuestion",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rowId",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StudySessionQuestion_userStudySessionQuestion"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "userStudySessionQuestions(orderBy:[\"SEQUENCE_ASC\"])"
    }
  ],
  "type": "UserStudySession",
  "abstractKey": null
};

(node as any).hash = "1258bd2265779e6a57dd23628d8ccfae";

export default node;
