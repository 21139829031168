import { type UserLessonQuestionAnswers_userLessonQuestion$key } from '@app/__generated__/UserLessonQuestionAnswers_userLessonQuestion.graphql'
import { MarkdownBox, UserLessonAnswerButton } from '@app/components'
import { Group, Stack } from '@mantine/core'
import { includes, map } from 'lodash'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const userLessonQuestionFragment = graphql`
  fragment UserLessonQuestionAnswers_userLessonQuestion on UserLessonQuestion {
    attempts
    question {
      correctAnswer: questionAnswers(condition: { isActive: true, isCorrect: true }) {
        nodes {
          rowId
        }
      }
      questionAnswers(condition: { isActive: true }, orderBy: SEQUENCE_ASC) {
        nodes {
          body
          isCorrect
          rowId
          sequence
        }
      }
      ...QuestionResult_question
    }
  }
`

export interface UserLessonQuestionAnswersProps {
  disabled?: boolean
  onSelectAnswer: (answerId: string, isCorrect: boolean) => void
  selectedAnswerId?: string
  previouslySubmittedAnswerIds: string[]
  showResult?: boolean
  userLessonQuestion: UserLessonQuestionAnswers_userLessonQuestion$key
}

export const UserLessonQuestionAnswers: FC<UserLessonQuestionAnswersProps> = ({
  userLessonQuestion,
  onSelectAnswer,
  previouslySubmittedAnswerIds,
  selectedAnswerId
}) => {
  const userLessonQuestionData = useFragment(userLessonQuestionFragment, userLessonQuestion)
  const correctAnswer = userLessonQuestionData?.question?.correctAnswer?.nodes?.[0]
  const isPreviousAnswerCorrect = correctAnswer?.rowId === previouslySubmittedAnswerIds[0]

  return (
    <Stack>
      {map(userLessonQuestionData?.question?.questionAnswers?.nodes, ({ rowId, body }) => (
        <Group
          key={`answer-${rowId}`}
          noWrap
          align='flex-start'
        >
          <UserLessonAnswerButton
            onClick={() => {
              if (!(isPreviousAnswerCorrect && previouslySubmittedAnswerIds[0] !== rowId)) {
                onSelectAnswer(rowId, correctAnswer?.rowId === rowId)
              }
            }}
            disabled={includes(previouslySubmittedAnswerIds, rowId)}
            isSelected={selectedAnswerId === rowId}
            variant={
              isPreviousAnswerCorrect &&
              selectedAnswerId === rowId &&
              previouslySubmittedAnswerIds[0] === selectedAnswerId
                ? 'correct'
                : includes(previouslySubmittedAnswerIds, rowId)
                ? 'incorrect'
                : 'default'
            }
          >
            <MarkdownBox>{body}</MarkdownBox>
          </UserLessonAnswerButton>
        </Group>
      ))}
    </Stack>
  )
}
