import { BorderBox } from '@app/components'
import { type ButtonProps, useMantineTheme } from '@mantine/core'
import { type FC } from 'react'

export interface UserLessonAnswerButtonProps extends ButtonProps {
  disabled?: boolean
  isSelected?: boolean | null
  variant?: 'default' | 'correct' | 'incorrect'
  onClick?: () => void
}

export const UserLessonAnswerButton: FC<UserLessonAnswerButtonProps> = ({
  disabled = false,
  isSelected = false,
  variant = 'default',
  children,
  ...props
}) => {
  const theme = useMantineTheme()

  return (
    <BorderBox
      size='md'
      sx={{
        boxShadow: `3px 3px ${
          variant === 'correct'
            ? theme.colors.jelloGreen[4]
            : variant === 'incorrect'
            ? theme.colors.softRed[4]
            : isSelected && variant === 'default'
            ? theme.colors.stickyNoteBlue[4]
            : theme.colors.yellow[4]
        }`,
        cursor: disabled ? 'default' : 'pointer'
      }}
      {...props}
    >
      {children}
    </BorderBox>
  )
}
