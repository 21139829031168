import { BorderBox, BorderButton, CustomIcon } from '@app/components'
import { Box, createStyles, Group, Text, Title } from '@mantine/core'
import Image from 'next/image'
import { type FC } from 'react'

import cursorImage from './cursor.svg'
import upArrowImage from './upArrow.svg'

export interface StudySessionCompleteNegativeProps {}

const useStyles = createStyles((theme) => ({
  boxContainer: {
    position: 'relative',
    paddingLeft: 70,
    paddingRight: 70,
    [theme.fn.smallerThan('sm')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  },
  cursorImage: {
    position: 'absolute',
    right: -55,
    bottom: 0,
    [theme.fn.smallerThan('sm')]: {
      right: -55,
      bottom: -90
    }
  },
  upArrowImage: {
    position: 'absolute',
    left: -75,
    top: 0,
    [theme.fn.smallerThan('sm')]: {
      left: -30,
      top: -40
    }
  }
}))

export const StudySessionCompleteNegative: FC<StudySessionCompleteNegativeProps> = () => {
  const { classes, theme } = useStyles()

  return (
    <>
      <BorderBox
        withShadow
        backgroundColor={theme.colors.studiousGray[2]}
        variant='roundedBottom'
        mb='lg'
        className={classes.boxContainer}
      >
        <Group
          noWrap
          position='center'
          pb='lg'
          pt='lg'
          align='flex-start'
        >
          <Box className={classes.upArrowImage}>
            <Image
              width={150}
              height={150}
              src={upArrowImage}
              alt='Arrow'
            />
          </Box>
          <Box>
            <Text
              mb='lg'
              align='center'
              size='xl'
              weight={800}
            >
              You’ve completed your daily quesion session. Keep at it!
            </Text>
            <Title
              mb='lg'
              align='center'
              px='xl'
              order={2}
            >
              You answered <CustomIcon type='bolt' />
              <strong style={{ color: theme.colors.red[4] }}>25%</strong> of the questions correctly at
              <CustomIcon
                type='clock'
                ml='xs'
              />
              1:23 per question.
            </Title>
            <Text
              align='center'
              pb='lg'
              size='md'
            >
              Studious is here to help with flashcards, videos and more. Reach out!
            </Text>
            <Text
              align='center'
              size='md'
            >
              Complete the next section to hone your learning skills further, or finish now.
            </Text>
          </Box>
          <Box className={classes.cursorImage}>
            <Image
              width={150}
              height={150}
              src={cursorImage}
              alt='Cursor'
            />
          </Box>
        </Group>
      </BorderBox>
      <Group position='center'>
        <BorderButton color={theme.colors.studiousGray[3]}>New Session</BorderButton>
        <BorderButton>Review</BorderButton>
      </Group>
    </>
  )
}
