/**
 * @generated SignedSource<<48c5cc057ab82667c7abff92fd9ec9b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LessonsCarousel_examTypesUser$data = {
  readonly examType: {
    readonly ongoingTopics: {
      readonly nodes: ReadonlyArray<{
        readonly rowId: any;
        readonly userLessons: {
          readonly nodes: ReadonlyArray<{
            readonly completedAt: any | null | undefined;
          }>;
        };
      }>;
    };
    readonly topics: {
      readonly nodes: ReadonlyArray<{
        readonly rowId: any;
        readonly subtopics: {
          readonly nodes: ReadonlyArray<{
            readonly rowId: any;
            readonly sequence: number;
            readonly title: string;
            readonly topicObjectives: {
              readonly nodes: ReadonlyArray<{
                readonly rowId: any;
                readonly sequence: number;
                readonly title: string;
                readonly userLessons: {
                  readonly totalCount: number;
                };
                readonly " $fragmentSpreads": FragmentRefs<"ObjectiveCard_topicObjective">;
              }>;
            };
            readonly userTopicObjectives: {
              readonly nodes: ReadonlyArray<{
                readonly score: number;
                readonly topicObjectiveId: any;
              }>;
            };
          }>;
        };
        readonly title: string;
        readonly userSubtopics: {
          readonly nodes: ReadonlyArray<{
            readonly score: number;
            readonly subtopicId: any;
          }>;
        };
      }>;
    };
    readonly userTopics: {
      readonly nodes: ReadonlyArray<{
        readonly score: number;
        readonly topicId: any;
      }>;
    };
  } | null | undefined;
  readonly score: number;
  readonly " $fragmentType": "LessonsCarousel_examTypesUser";
};
export type LessonsCarousel_examTypesUser$key = {
  readonly " $data"?: LessonsCarousel_examTypesUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"LessonsCarousel_examTypesUser">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      "SCORE_ASC",
      "TOPIC_BY_TOPIC_ID__SEQUENCE_ASC"
    ]
  }
],
v2 = {
  "kind": "Literal",
  "name": "condition",
  "value": {
    "meetsTopicViabilityCriteria": true
  }
},
v3 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": [
    "SEQUENCE_ASC"
  ]
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sequence",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonsCarousel_examTypesUser",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamType",
      "kind": "LinkedField",
      "name": "examType",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "UserTopicsConnection",
          "kind": "LinkedField",
          "name": "userTopics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserTopic",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "topicId",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "userTopics(orderBy:[\"SCORE_ASC\",\"TOPIC_BY_TOPIC_ID__SEQUENCE_ASC\"])"
        },
        {
          "alias": null,
          "args": [
            (v2/*: any*/),
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "parentTopicExists": false
              }
            },
            (v3/*: any*/)
          ],
          "concreteType": "TopicsConnection",
          "kind": "LinkedField",
          "name": "topics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": "subtopics",
                  "args": [
                    (v2/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "filter",
                      "value": {
                        "parentTopicExists": true
                      }
                    },
                    (v3/*: any*/)
                  ],
                  "concreteType": "TopicsConnection",
                  "kind": "LinkedField",
                  "name": "topicsByParentTopicId",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Topic",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": [
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "condition",
                              "value": {
                                "meetsLessonViabilityCriteria": true
                              }
                            },
                            (v3/*: any*/)
                          ],
                          "concreteType": "TopicObjectivesConnection",
                          "kind": "LinkedField",
                          "name": "topicObjectives",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TopicObjective",
                              "kind": "LinkedField",
                              "name": "nodes",
                              "plural": true,
                              "selections": [
                                (v4/*: any*/),
                                (v5/*: any*/),
                                (v6/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "UserLessonsConnection",
                                  "kind": "LinkedField",
                                  "name": "userLessons",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "totalCount",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "ObjectiveCard_topicObjective"
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": "topicObjectives(condition:{\"meetsLessonViabilityCriteria\":true},orderBy:[\"SEQUENCE_ASC\"])"
                        },
                        {
                          "alias": "userTopicObjectives",
                          "args": (v1/*: any*/),
                          "concreteType": "UserTopicObjectivesConnection",
                          "kind": "LinkedField",
                          "name": "userTopicObjectivesBySubtopicId",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "UserTopicObjective",
                              "kind": "LinkedField",
                              "name": "nodes",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "topicObjectiveId",
                                  "storageKey": null
                                },
                                (v0/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": "userTopicObjectivesBySubtopicId(orderBy:[\"SCORE_ASC\",\"TOPIC_BY_TOPIC_ID__SEQUENCE_ASC\"])"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "topicsByParentTopicId(condition:{\"meetsTopicViabilityCriteria\":true},filter:{\"parentTopicExists\":true},orderBy:[\"SEQUENCE_ASC\"])"
                },
                {
                  "alias": null,
                  "args": (v1/*: any*/),
                  "concreteType": "UserSubtopicsConnection",
                  "kind": "LinkedField",
                  "name": "userSubtopics",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserSubtopic",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "subtopicId",
                          "storageKey": null
                        },
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "userSubtopics(orderBy:[\"SCORE_ASC\",\"TOPIC_BY_TOPIC_ID__SEQUENCE_ASC\"])"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "topics(condition:{\"meetsTopicViabilityCriteria\":true},filter:{\"parentTopicExists\":false},orderBy:[\"SEQUENCE_ASC\"])"
        },
        {
          "alias": "ongoingTopics",
          "args": [
            (v2/*: any*/),
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "userLessonsExist": true,
                "userTopics": {
                  "some": {
                    "score": {
                      "lessThan": 0.7
                    }
                  }
                }
              }
            }
          ],
          "concreteType": "TopicsConnection",
          "kind": "LinkedField",
          "name": "topics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 1
                    },
                    {
                      "kind": "Literal",
                      "name": "orderBy",
                      "value": [
                        "COMPLETED_AT_ASC"
                      ]
                    }
                  ],
                  "concreteType": "UserLessonsConnection",
                  "kind": "LinkedField",
                  "name": "userLessons",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserLesson",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "completedAt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "userLessons(first:1,orderBy:[\"COMPLETED_AT_ASC\"])"
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "topics(condition:{\"meetsTopicViabilityCriteria\":true},filter:{\"userLessonsExist\":true,\"userTopics\":{\"some\":{\"score\":{\"lessThan\":0.7}}}})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExamTypesUser",
  "abstractKey": null
};
})();

(node as any).hash = "31ec90cf025916f0a97b96015d97ce85";

export default node;
