import { type MarkdownPreviewProps } from '@uiw/react-markdown-preview'
import { compact, isString, join, map } from 'lodash'
import dynamic from 'next/dynamic'
import { isValidElement, ReactNode } from 'react'

import { KatexCode } from './KatexCode'
import { MermaidCode } from './MermaidCode'

const getMarkdownContent = (arr: ReactNode & ReactNode[] = []): string | false =>
  join(
    compact(
      map(arr, (dt) => {
        if (isString(dt)) {
          return dt
        }

        if (isValidElement(dt)) {
          return getMarkdownContent(dt.props.children)
        }

        return false
      })
    ),
    ''
  )

export const markdownPreviewProps: MarkdownPreviewProps = {
  components: {
    code: ({ inline, children, className }) => {
      const txt = getMarkdownContent(children)

      if (!txt) {
        return null
      }

      if (inline) {
        return <code>{txt}</code>
      }

      if (typeof className === 'string') {
        if (/^language-katex/i.test(className)) {
          return <KatexCode className={className}>{txt}</KatexCode>
        }

        if (/^language-mermaid/i.test(className)) {
          return <MermaidCode className={className}>{txt}</MermaidCode>
        }
      }

      return <code className={className}>{txt}</code>
    }
  }
}

export const MarkdownPreview = dynamic(() => import('@uiw/react-markdown-preview'), { ssr: false })
