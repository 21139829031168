import { StudySessionModal, type UnsavedChangesModalProps } from '@app/components'
import { LoadingOverlay, type LoadingOverlayProps } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import { FC, ReactNode, useCallback, useState } from 'react'

export interface WizardModalChildrenProps {
  active: number
  onNext: () => void
  onPrev: () => void
  onReset: () => void
}

export interface WizardModalProps extends Omit<UnsavedChangesModalProps, 'children'> {
  children: (props: WizardModalChildrenProps) => ReactNode
  isLoading?: boolean
  loadingOverlayProps?: Omit<LoadingOverlayProps, 'visible'>
  onStepChange?: (step: number) => void
  stepCount: number
}

export const WizardModal: FC<WizardModalProps> = ({
  children,
  isLoading,
  loadingOverlayProps,
  onClose,
  onStepChange,
  stepCount,
  ...props
}) => {
  const [active, setActive] = useState(0)

  const onNext = useCallback(() => setActive((value) => Math.min(stepCount, value + 1)), [stepCount])
  const onPrev = useCallback(() => setActive((value) => Math.max(0, value - 1)), [])
  const onReset = useCallback(() => setActive(0), [])

  useDidUpdate(() => {
    onStepChange?.(active)
  }, [active, onStepChange])

  return (
    <StudySessionModal
      onClose={() => {
        onClose?.()
        // onReset()
      }}
      {...props}
    >
      <LoadingOverlay
        visible={!!isLoading}
        {...loadingOverlayProps}
      />
      {children({ active, onNext, onPrev, onReset })}
    </StudySessionModal>
  )
}
