import { BorderButton, CustomIcon } from '@app/components'
import { Box, createStyles, Group, Text, Title } from '@mantine/core'
import Image from 'next/image'
import { type FC } from 'react'

import megaphoneImage from './megaphone.svg'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative'
  },
  megaphone: {
    position: 'absolute',
    display: 'inline-block',
    top: 20,
    left: -20
  },
  borderBox: {
    position: 'relative',
    [theme.fn.smallerThan('sm')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  },
  title: {
    [theme.fn.smallerThan('sm')]: {
      textAlign: 'center',
      marginBottom: '2rem'
    }
  }
}))

export interface StudySessionStartProps {
  disabled?: boolean
  onStart: () => void
  onNo: () => void
}

export const StudySessionStart: FC<StudySessionStartProps> = ({ disabled, onNo, onStart }) => {
  const { classes, theme } = useStyles()

  return (
    <Box className={classes.container}>
      <span className={classes.megaphone}>
        <Image
          height={50}
          src={megaphoneImage}
          alt='Megaphone'
        />
      </span>
      <Group position='center'>
        <Box>
          <Title
            mb='lg'
            size={32}
            className={classes.title}
          >
            Take a deep breath. You&apos;ve got this!
          </Title>
          <Text size='lg'>Before you begin:</Text>
          <ul>
            <li>
              <Group noWrap>
                <Text size='lg'>You&apos;ll want to make sure that you&apos;ve removed all distractions.</Text>
                <CustomIcon
                  type='excite'
                  size={50}
                />
              </Group>
            </li>
            <li>
              <Group noWrap>
                <Text size='lg'>Turn the ringer on your phone to “silent”</Text>
                <CustomIcon
                  type='phone'
                  size={40}
                />
              </Group>
            </li>
            <li>
              <Group noWrap>
                <Text size='lg'>Let friends and family know that you need this time to study!</Text>
                <CustomIcon
                  type='negative'
                  size={50}
                />
              </Group>
            </li>
          </ul>
        </Box>
      </Group>
      <Group position='center'>
        <BorderButton
          onClick={onNo}
          color={theme.colors.softRed[4]}
        >
          No
        </BorderButton>
        <BorderButton
          disabled={disabled}
          onClick={onStart}
          color={theme.colors.jelloGreen[4]}
        >
          Yes
        </BorderButton>
      </Group>
    </Box>
  )
}
