import { type QuestionAnswerForm_questionAnswer$key } from '@app/__generated__/QuestionAnswerForm_questionAnswer.graphql'
import { CheckboxInput, Form, MarkdownEditorInput } from '@app/components'
import { useForm, zodResolver } from '@mantine/form'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const formSchema = z.object({
  body: z.string(),
  isActive: z.boolean(),
  isCorrect: z.boolean()
})

const questionAnswerFragment = graphql`
  fragment QuestionAnswerForm_questionAnswer on QuestionAnswer {
    body
    isActive
    isCorrect
  }
`

export interface QuestionAnswerFormData {
  body: string
  isActive: boolean
  isCorrect: boolean
}

export type QuestionAnswerFormProps = {
  questionAnswer: QuestionAnswerForm_questionAnswer$key | null
  isSaving?: boolean
  onSubmit: (data: QuestionAnswerFormData) => void
}

export const QuestionAnswerForm: FC<QuestionAnswerFormProps> = ({ isSaving, onSubmit, questionAnswer }) => {
  const answerData = useFragment(questionAnswerFragment, questionAnswer)
  const form = useForm({
    initialValues: {
      body: answerData?.body || '',
      isActive: answerData?.isActive ?? true,
      isCorrect: answerData?.isCorrect ?? false
    },
    validate: zodResolver(formSchema),
    validateInputOnChange: true
  })

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <MarkdownEditorInput
        autoFocus
        label='Body'
        required
        {...form.getInputProps('body')}
      />
      <CheckboxInput
        disabled={isSaving}
        label='Active'
        mt='xl'
        mb='xl'
        {...form.getInputProps('isActive', { type: 'checkbox' })}
      />
      <CheckboxInput
        disabled={isSaving}
        label='Correct'
        mt='xl'
        mb='xl'
        {...form.getInputProps('isCorrect', { type: 'checkbox' })}
      />
    </Form>
  )
}
