import { Button, Group, Text } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { type FC, useCallback } from 'react'

import { Modal, type ModalProps } from './Modal'

export interface ConfirmModalProps extends ModalProps {
  cancelLabel?: string
  closeOnConfirm?: boolean
  confirmLabel?: string
  onConfirm?(): void
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  cancelLabel = 'Cancel',
  children,
  closeOnConfirm = false,
  confirmLabel = 'Confirm',
  isLoading,
  onClose,
  onConfirm,
  title = 'Confirm',
  ...props
}) => (
  <Modal
    isLoading={isLoading}
    onClose={onClose}
    title={title}
    {...props}
  >
    <Text size='sm'>{children}</Text>
    <Group
      mt='xl'
      position='right'
    >
      <Button
        disabled={isLoading}
        onClick={onClose}
        variant='default'
      >
        {cancelLabel}
      </Button>
      <Button
        disabled={isLoading}
        leftIcon={<IconCheck size={18} />}
        onClick={useCallback(() => {
          onConfirm?.()
          if (closeOnConfirm) {
            onClose()
          }
        }, [onConfirm, closeOnConfirm, onClose])}
      >
        {confirmLabel}
      </Button>
    </Group>
  </Modal>
)
