import { type UsersTable_query$data, type UsersTable_query$key } from '@app/__generated__/UsersTable_query.graphql'
import { type UsersTablePaginationQuery } from '@app/__generated__/UsersTablePaginationQuery.graphql'
import { ActionColumnIcon, IconBoolean, PaginationTable } from '@app/components'
import { initials, type UserFilter, UsersOrderBy } from '@app/lib'
import { Avatar } from '@mantine/core'
import { type ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'
import { Get, IterableElement } from 'type-fest'

type UserEdge = IterableElement<Get<Get<UsersTable_query$data, 'users'>, 'edges'>>

// noinspection GraphQLUnresolvedReference
const queryFragment = graphql`
  fragment UsersTable_query on Query
  @argumentDefinitions(
    filter: { type: "UserFilter", defaultValue: null }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "Cursor" }
    orderBy: { type: "[UsersOrderBy!]", defaultValue: [NAME_ASC] }
  )
  @refetchable(queryName: "UsersTablePaginationQuery") {
    users(filter: $filter, first: $first, after: $after, orderBy: $orderBy)
      @connection(key: "UsersTable_query_users", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          createdAt
          email
          image
          isAdmin
          isActive
          lastActivityAt
          name
          slug
        }
      }
      totalCount
    }
  }
`

export interface UsersTableProps {
  query: UsersTable_query$key
}

export const UsersTable: FC<UsersTableProps> = ({ query }) => {
  const { data, hasNext, loadNext, isLoadingNext, refetch } = usePaginationFragment<
    UsersTablePaginationQuery,
    UsersTable_query$key
  >(queryFragment, query)

  return (
    <PaginationTable<UserEdge, UsersOrderBy, UsersTablePaginationQuery, UserFilter>
      columns={useMemo<ColumnDef<UserEdge>[]>(
        () => [
          {
            id: 'actions',
            size: 50,
            enableMultiSort: false,
            enableSorting: false,
            cell: (props) => {
              const node = props.row.original.node

              return <ActionColumnIcon href={`/admin/users/${node.slug}`} />
            }
          },
          {
            accessorKey: 'node.isAdmin',
            header: 'Admin',
            id: 'isAdmin',
            size: 50,
            enableMultiSort: false,
            enableSorting: true,
            cell: (props) => {
              const node = props.row.original.node
              return (
                <IconBoolean
                  size={18}
                  value={node.isAdmin}
                />
              )
            }
          },
          {
            accessorKey: 'node.image',
            header: 'Image',
            size: 50,
            enableMultiSort: false,
            enableSorting: false,
            cell: (props) => {
              const node = props.row.original.node
              return (
                <Avatar
                  alt='image'
                  size='sm'
                  src={node.image}
                >
                  {node.name && initials(node.name)}
                </Avatar>
              )
            }
          },
          {
            accessorKey: 'node.name',
            header: 'Name',
            id: 'name',
            enableMultiSort: false,
            enableSorting: true
          },
          {
            accessorKey: 'node.email',
            header: 'Email',
            id: 'email',
            enableMultiSort: false,
            enableSorting: true
          },
          {
            accessorKey: 'node.createdAt',
            header: 'Created On',
            id: 'createdAt',
            enableMultiSort: false,
            enableSorting: true,
            cell: (props) => {
              const node = props.row.original.node
              return dayjs(node.createdAt).format('MMM D, YYYY')
            }
          },
          {
            accessorKey: 'node.lastActivity',
            header: 'Last Activity',
            id: 'lastActivity',
            enableMultiSort: false,
            enableSorting: true,
            cell: (props) => {
              const node = props.row.original.node
              return dayjs(node.lastActivityAt).format('MMM D, YYYY h:mm A')
            }
          }
        ],
        []
      )}
      data={data?.users?.edges || []}
      filterPlaceholder='Search Users'
      getFilterFromSearch={useCallback(
        (search: string) =>
          !isEmpty(search)
            ? {
                or: [
                  {
                    name: {
                      includesInsensitive: search
                    }
                  },
                  {
                    email: {
                      includesInsensitive: search
                    }
                  }
                ]
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={[
        {
          id: 'name',
          desc: false
        }
      ]}
      isFilterable
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      refetch={refetch}
      sortOptions={{
        email: {
          asc: UsersOrderBy.EmailAsc,
          desc: UsersOrderBy.EmailDesc
        },
        isAdmin: {
          asc: UsersOrderBy.IsAdminAsc,
          desc: UsersOrderBy.IsAdminDesc
        },
        name: {
          asc: UsersOrderBy.NameAsc,
          desc: UsersOrderBy.NameDesc
        },
        createdAt: {
          asc: UsersOrderBy.CreatedAtAsc,
          desc: UsersOrderBy.CreatedAtDesc
        },
        lastActivity: {
          asc: UsersOrderBy.LastActivityAtAsc,
          desc: UsersOrderBy.LastActivityAtDesc
        }
      }}
      totalCount={data?.users?.totalCount || 0}
    />
  )
}
