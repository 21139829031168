import { poppinsFont } from '@app/lib'
import {
  MantineProvider as BaseMantineProvider,
  type MantineProviderProps as BaseMantineProviderProps
} from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { type FC } from 'react'

export interface MantineProviderProps extends BaseMantineProviderProps {}

export const MantineProvider: FC<MantineProviderProps> = ({ children }) => (
  <BaseMantineProvider
    withGlobalStyles
    withNormalizeCSS
    theme={{
      globalStyles: (theme) => ({
        body: {
          ...theme.fn.fontStyles(),
          backgroundColor: theme.colors.studiousGray[0]
        }
      }),
      black: '#392F5A',
      headings: { fontFamily: poppinsFont.style.fontFamily },
      colors: {
        jelloGreen: [
          '#E4F3EA',
          '#BEE6D0',
          '#99DEB8',
          '#74DBA2',
          '#4EDD8E', // primary
          '#3BCC7C',
          '#38AF6E',
          '#389361',
          '#346A4C',
          '#315A43'
        ],
        paperYellow: [
          '#F6F3E6',
          '#E8DDAF',
          '#E4D077',
          '#ECCB3D',
          '#FECE00', // primary
          '#C09F13',
          '#927C1D',
          '#716221',
          '#584D21',
          '#453E1F'
        ],
        studiousGray: [
          '#F9F9F9', // super light (also primary bg)
          '#F1F1F1', // light gray
          '#FFFEF8', // yellowish gray
          '#E5E5E5', // body bg
          '#F3EFE0', // darkish
          '#8E8C86',
          '#7F7E79',
          '#717170',
          '#666565'
        ],
        stickyNoteBlue: [
          '#E1EEF2',
          '#B5DAE6',
          '#87CBE1',
          '#57C2E5',
          '#23BFF2', // primary
          '#20A3CE',
          '#2985A4',
          '#2D6E83',
          '#2D5B6A',
          '#2C4C57'
        ],
        spaceCadetPurple: [
          '#918CA0',
          '#7C7692',
          '#5C5477',
          '#4F466C',
          '#392F5A', // primary
          '#332C4A',
          '#2D283D',
          '#282433',
          '#1E1C24',
          '#1A191E'
        ],
        softRed: [
          '#FBF5F5',
          '#F2D9D9',
          '#EDBBBB',
          '#EE9B9B',
          '#F47878', // primary
          '#E56A6A',
          '#D55F5F',
          '#C55757',
          '#B55050',
          '#A05050'
        ],
        grayGradient: ['linear-gradient(180deg, rgba(253,254,254,1) 35%, rgba(219,227,231,1) 100%)']
      },
      primaryColor: 'stickyNoteBlue',
      primaryShade: {
        light: 4,
        dark: 8
      }
    }}
  >
    <ModalsProvider>{children}</ModalsProvider>
  </BaseMantineProvider>
)
