import {
  createStyles,
  TextInput as MantineTextInput,
  type TextInputProps as MantineTextInputProps
} from '@mantine/core'
import { type FC } from 'react'

const useStyles = createStyles(() => ({
  input: {
    label: {
      fontSize: 16,
      fontWeight: 'bold'
    },
    input: {
      fontWeight: 'bold',
      borderRadius: 5,
      padding: '30px 15px',
      fontSize: 18
    }
  }
}))

export interface StudentTextInputProps extends MantineTextInputProps {}

export const StudentTextInput: FC<StudentTextInputProps> = (mantineTextInputProps) => {
  const { classes } = useStyles()
  return (
    <MantineTextInput
      className={classes.input}
      {...mantineTextInputProps}
    />
  )
}
