import {
  type QuestionAnswersTable_question$data,
  type QuestionAnswersTable_question$key
} from '@app/__generated__/QuestionAnswersTable_question.graphql'
import { type QuestionAnswersTableDeleteQuestionAnswerMutation } from '@app/__generated__/QuestionAnswersTableDeleteQuestionAnswerMutation.graphql'
import { type QuestionAnswersTablePaginationQuery } from '@app/__generated__/QuestionAnswersTablePaginationQuery.graphql'
import { type QuestionAnswersTableReorderQuestionAnswersMutation } from '@app/__generated__/QuestionAnswersTableReorderQuestionAnswersMutation.graphql'
import { type QuestionAnswersTableUpdateQuestionAnswerMutation } from '@app/__generated__/QuestionAnswersTableUpdateQuestionAnswerMutation.graphql'
import { ActionColumnMenu, IconBoolean, PaginationTable, QuestionAnswerForm } from '@app/components'
import { type QuestionAnswerFilter, QuestionAnswersOrderBy, stripMarkdown } from '@app/lib'
import { Menu, Text } from '@mantine/core'
import { useModals } from '@mantine/modals'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, useMutation, usePaginationFragment } from 'react-relay'
import { Get, IterableElement } from 'type-fest'

type QuestionAnswerEdge = IterableElement<Get<Get<QuestionAnswersTable_question$data, 'questionAnswers'>, 'edges'>>

const deleteQuestionAnswerMutation = graphql`
  mutation QuestionAnswersTableDeleteQuestionAnswerMutation($input: DeleteQuestionAnswerByIdInput!) {
    deleteQuestionAnswerById(input: $input) {
      deletedQuestionAnswerId
      question {
        ...QuestionAnswersTable_question
      }
    }
  }
`

const reorderQuestionAnswersMutation = graphql`
  mutation QuestionAnswersTableReorderQuestionAnswersMutation($input: UpdateQuestionAnswerInput!) {
    updateQuestionAnswer(input: $input) {
      question {
        ...QuestionAnswersTable_question
      }
      questionAnswer {
        sequence
      }
    }
  }
`

// noinspection GraphQLUnresolvedReference
const questionFragment = graphql`
  fragment QuestionAnswersTable_question on Question
  @argumentDefinitions(
    filter: { type: "QuestionAnswerFilter", defaultValue: null }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "Cursor" }
    orderBy: { type: "[QuestionAnswersOrderBy!]", defaultValue: [SEQUENCE_ASC] }
  )
  @refetchable(queryName: "QuestionAnswersTablePaginationQuery") {
    questionAnswers(filter: $filter, first: $first, after: $after, orderBy: $orderBy)
      @connection(key: "QuestionAnswersTable_questionAnswers", filters: ["filter", "orderBy"]) {
      edges {
        node {
          body
          id
          isActive
          isCorrect
          rowId
          sequence
          ...QuestionAnswerForm_questionAnswer
        }
      }
      totalCount
    }
  }
`

const updateQuestionAnswerMutation = graphql`
  mutation QuestionAnswersTableUpdateQuestionAnswerMutation($input: UpdateQuestionAnswerInput!) {
    updateQuestionAnswer(input: $input) {
      question {
        ...QuestionAnswersTable_question
      }
    }
  }
`

export interface QuestionAnswersTableProps {
  question: QuestionAnswersTable_question$key | null
}

export const QuestionAnswersTable: FC<QuestionAnswersTableProps> = ({ question }) => {
  const modals = useModals()
  const { data, hasNext, loadNext, isLoadingNext, refetch } = usePaginationFragment<
    QuestionAnswersTablePaginationQuery,
    QuestionAnswersTable_question$key
  >(questionFragment, question)
  const [deleteQuestionAnswer, isDeletingQuestionAnswer] =
    useMutation<QuestionAnswersTableDeleteQuestionAnswerMutation>(deleteQuestionAnswerMutation)
  const [updateQuestionAnswer, isUpdatingAnswer] =
    useMutation<QuestionAnswersTableUpdateQuestionAnswerMutation>(updateQuestionAnswerMutation)
  const [reorderQuestionAnswers, isReordering] =
    useMutation<QuestionAnswersTableReorderQuestionAnswersMutation>(reorderQuestionAnswersMutation)

  return (
    <PaginationTable<
      QuestionAnswerEdge,
      QuestionAnswersOrderBy,
      QuestionAnswersTablePaginationQuery,
      QuestionAnswerFilter
    >
      columns={useMemo<ColumnDef<QuestionAnswerEdge>[]>(
        () => [
          {
            id: 'actions',
            enableMultiSort: false,
            enableSorting: false,
            size: 50,
            cell: (props) => {
              const node = props.row.original.node

              return (
                <ActionColumnMenu>
                  <Menu.Item
                    icon={<IconEdit size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        centered: true,
                        title: 'Edit Answer',
                        size: 'xl',
                        children: (
                          <QuestionAnswerForm
                            isSaving={isUpdatingAnswer}
                            onSubmit={(patch) =>
                              updateQuestionAnswer({
                                variables: {
                                  input: {
                                    rowId: node.rowId,
                                    patch
                                  }
                                },
                                onCompleted: () => {
                                  modals.closeModal(modalId)
                                }
                              })
                            }
                            questionAnswer={node}
                          />
                        )
                      })
                    }}
                  >
                    Edit Answer
                  </Menu.Item>
                  <Menu.Item
                    color='red'
                    icon={<IconTrash size={14} />}
                    onClick={() => {
                      const modalId = modals.openConfirmModal({
                        centered: true,
                        size: 'xs',
                        title: `Delete Answer?`,
                        children: (
                          <Text
                            size='sm'
                            pb='xs'
                          >
                            Are you sure you want to delete this answer?
                          </Text>
                        ),
                        labels: {
                          confirm: 'Delete',
                          cancel: 'Cancel'
                        },
                        cancelProps: {
                          variant: 'subtle'
                        },
                        confirmProps: {
                          color: 'red',
                          leftIcon: <IconTrash size={16} />,
                          loading: isDeletingQuestionAnswer
                        },
                        onConfirm: () => {
                          deleteQuestionAnswer({
                            variables: {
                              input: {
                                id: node.id
                              }
                            },
                            onCompleted: () => {
                              modals.closeModal(modalId)
                            }
                          })
                        }
                      })
                    }}
                  >
                    Delete Answer
                  </Menu.Item>
                </ActionColumnMenu>
              )
            }
          },
          {
            accessorKey: 'node.isActive',
            header: 'Active',
            enableMultiSort: false,
            enableSorting: false,
            size: 50,
            cell: (props) => {
              const node = props.row.original.node
              return (
                <IconBoolean
                  value={node.isActive}
                  size={18}
                />
              )
            }
          },
          {
            accessorKey: 'node.isCorrect',
            header: 'Correct',
            enableMultiSort: false,
            enableSorting: false,
            size: 50,
            cell: (props) => {
              const node = props.row.original.node
              return (
                <IconBoolean
                  value={node.isCorrect}
                  size={18}
                />
              )
            }
          },
          {
            accessorKey: 'node.body',
            header: 'Body',
            enableMultiSort: false,
            enableSorting: false,
            cell: (props) => {
              const node = props.row.original.node
              return stripMarkdown(node.body)
            }
          }
        ],
        [deleteQuestionAnswer, isDeletingQuestionAnswer, isUpdatingAnswer, modals, updateQuestionAnswer]
      )}
      data={data?.questionAnswers?.edges || []}
      filterPlaceholder='Search Answers'
      getFilterFromSearch={useCallback(
        (search: string) =>
          !isEmpty(search)
            ? {
                body: {
                  includesInsensitive: search
                }
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={[
        {
          id: 'sequence',
          desc: false
        }
      ]}
      isLoadingNext={isLoadingNext}
      isReorderable
      loadNext={loadNext}
      refetch={refetch}
      onReorder={useCallback(
        (sourceIndex: number, destinationIndex: number) => {
          if (!isReordering) {
            const questionAnswer = data?.questionAnswers?.edges?.[sourceIndex]?.node
            const nextSequence = destinationIndex + 1

            if (questionAnswer && questionAnswer.sequence !== nextSequence) {
              reorderQuestionAnswers({
                variables: {
                  input: {
                    rowId: questionAnswer.rowId,
                    patch: {
                      sequence: nextSequence
                    }
                  }
                }
              })
            }
          }
        },
        [data?.questionAnswers?.edges, isReordering, reorderQuestionAnswers]
      )}
      totalCount={data?.questionAnswers?.totalCount || 0}
    />
  )
}
