/**
 * @generated SignedSource<<1eca4e9737a0b661e9015e2708e91933>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamTypeUserUpdateModal_examType$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExamTypeUserForm_examType">;
  readonly " $fragmentType": "ExamTypeUserUpdateModal_examType";
};
export type ExamTypeUserUpdateModal_examType$key = {
  readonly " $data"?: ExamTypeUserUpdateModal_examType$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamTypeUserUpdateModal_examType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamTypeUserUpdateModal_examType",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamTypeUserForm_examType"
    }
  ],
  "type": "ExamType",
  "abstractKey": null
};

(node as any).hash = "78d4d1ceec41fa776091c851e4b84840";

export default node;
