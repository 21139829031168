/**
 * @generated SignedSource<<7954e2e04fb8e4c7d13575018ac34886>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicForm_examType$data = {
  readonly allTopics: {
    readonly nodes: ReadonlyArray<{
      readonly rowId: any;
      readonly title: string;
    }>;
  };
  readonly " $fragmentType": "TopicForm_examType";
};
export type TopicForm_examType$key = {
  readonly " $data"?: TopicForm_examType$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicForm_examType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicForm_examType",
  "selections": [
    {
      "alias": "allTopics",
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "parentTopicId": null
          }
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "SEQUENCE_ASC"
          ]
        }
      ],
      "concreteType": "TopicsConnection",
      "kind": "LinkedField",
      "name": "topics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rowId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "topics(condition:{\"parentTopicId\":null},orderBy:[\"SEQUENCE_ASC\"])"
    }
  ],
  "type": "ExamType",
  "abstractKey": null
};

(node as any).hash = "05c75619de21ce414b361f793da6004e";

export default node;
