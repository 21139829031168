/**
 * @generated SignedSource<<9c0d0666ecb2a45227f30b005ea639a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentWeekStudySessionInfo_examType$data = {
  readonly userStudySessionsData: {
    readonly nodes: ReadonlyArray<{
      readonly completedAt: any | null | undefined;
      readonly startedAt: any | null | undefined;
    }>;
  };
  readonly " $fragmentType": "CurrentWeekStudySessionInfo_examType";
};
export type CurrentWeekStudySessionInfo_examType$key = {
  readonly " $data"?: CurrentWeekStudySessionInfo_examType$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentWeekStudySessionInfo_examType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentWeekStudySessionInfo_examType",
  "selections": [
    {
      "alias": "userStudySessionsData",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "completedAt": {
              "isNull": false
            }
          }
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "COMPLETED_AT_DESC"
          ]
        }
      ],
      "concreteType": "UserStudySessionsConnection",
      "kind": "LinkedField",
      "name": "userStudySessions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserStudySession",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "userStudySessions(filter:{\"completedAt\":{\"isNull\":false}},orderBy:[\"COMPLETED_AT_DESC\"])"
    }
  ],
  "type": "ExamType",
  "abstractKey": null
};

(node as any).hash = "10a6433fb721166dd26aba38f0a2f88e";

export default node;
