/**
 * @generated SignedSource<<76fb83584e5e0772f116324caf2d684a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySessionQuestion_userStudySessionQuestion$data = {
  readonly question: {
    readonly body: string;
    readonly subtopic: {
      readonly title: string;
      readonly topic: {
        readonly title: string;
        readonly userTopics: {
          readonly nodes: ReadonlyArray<{
            readonly score: number;
          }>;
        };
      } | null | undefined;
      readonly userSubtopicsBySubtopicId: {
        readonly nodes: ReadonlyArray<{
          readonly score: number;
        }>;
      };
    } | null | undefined;
    readonly topicObjective: {
      readonly title: string;
      readonly userTopicObjectives: {
        readonly nodes: ReadonlyArray<{
          readonly score: number;
        }>;
      };
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"QuestionTitle_question">;
  } | null | undefined;
  readonly rowId: any;
  readonly sequence: number;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionAnswers_userStudySessionQuestion">;
  readonly " $fragmentType": "StudySessionQuestion_userStudySessionQuestion";
};
export type StudySessionQuestion_userStudySessionQuestion$key = {
  readonly " $data"?: StudySessionQuestion_userStudySessionQuestion$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySessionQuestion_userStudySessionQuestion">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "score",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudySessionQuestion_userStudySessionQuestion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Question",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TopicObjective",
          "kind": "LinkedField",
          "name": "topicObjective",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UserTopicObjectivesConnection",
              "kind": "LinkedField",
              "name": "userTopicObjectives",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserTopicObjective",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "subtopic",
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "topic",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UserSubtopicsConnection",
              "kind": "LinkedField",
              "name": "userSubtopicsBySubtopicId",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserSubtopic",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": "topic",
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "parentTopic",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserTopicsConnection",
                  "kind": "LinkedField",
                  "name": "userTopics",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserTopic",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuestionTitle_question"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuestionAnswers_userStudySessionQuestion"
    }
  ],
  "type": "UserStudySessionQuestion",
  "abstractKey": null
};
})();

(node as any).hash = "05c02a046a56332f0d5cebbd5d81860d";

export default node;
