/**
 * @generated SignedSource<<961de3d0a8ea56727b77bc7be43d5404>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminTopicLayout_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AdminLayout_query">;
  readonly " $fragmentType": "AdminTopicLayout_query";
};
export type AdminTopicLayout_query$key = {
  readonly " $data"?: AdminTopicLayout_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminTopicLayout_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminTopicLayout_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminLayout_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "1a741f6388fa686e3bfbeeb6a16c4b18";

export default node;
