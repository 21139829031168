import { type StudySessionWizardModal_examTypesUser$key } from '@app/__generated__/StudySessionWizardModal_examTypesUser.graphql'
import { type StudySessionWizardModal_query$key } from '@app/__generated__/StudySessionWizardModal_query.graphql'
import { type StudySessionWizardModalCreateUserStudySessionMutation } from '@app/__generated__/StudySessionWizardModalCreateUserStudySessionMutation.graphql'
import { type StudySessionWizardModalDeleteUserStudySessionMutation } from '@app/__generated__/StudySessionWizardModalDeleteUserStudySessionMutation.graphql'
import { type StudySessionWizardModalUpdateUserStudySessionMutation } from '@app/__generated__/StudySessionWizardModalUpdateUserStudySessionMutation.graphql'
import { type StudySessionWizardModalUpdateUserStudySessionQuestionMutation } from '@app/__generated__/StudySessionWizardModalUpdateUserStudySessionQuestionMutation.graphql'
import {
  ConfirmModal,
  Modal,
  StudySessionCompletePositive,
  type StudySessionQuestionOnSubmitData,
  StudySessionQuestionWizard,
  StudySessionReviewWizard,
  StudySessionStart
} from '@app/components'
import { Question, Topic, UserSubtopic, UserTopic, zeroIfEmpty } from '@app/lib'
import { createStyles, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { filter, find, flatMap, isEmpty, map, sampleSize, shuffle } from 'lodash'
import { event as trackEvent } from 'nextjs-google-analytics'
import { type FC, useCallback, useEffect, useMemo, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const SESSION_TYPE = 'STUDY_SESSION'
// min * seconds
const SESSION_TIME_LIMIT = 20 * 60
const PASSING_SCORE = 0.7

const createUserStudySessionMutation = graphql`
  mutation StudySessionWizardModalCreateUserStudySessionMutation($input: CreateUserStudySessionInput!) {
    createUserStudySession(input: $input) {
      userStudySession {
        id
        userStudySessionQuestions(orderBy: [SEQUENCE_ASC]) {
          nodes {
            ...StudySessionQuestion_userStudySessionQuestion
          }
        }
        rowId
        ...StudySessionCompletePositive_userStudySession
        ...StudySessionQuestionWizard_userStudySession
        ...StudySessionReviewWizard_userStudySession
      }
      user {
        currentExamTypesUser {
          ...DaysUntilExam_examTypesUser
          ...QuestionsPerformanceBox_examTypesUser
          ...PerformanceScoreBox_examTypesUser
          ...StreakBox_examTypesUser
          ...StudySessionWizardModal_examTypesUser
        }
      }
    }
  }
`

const deleteUserStudySessionMutation = graphql`
  mutation StudySessionWizardModalDeleteUserStudySessionMutation($input: DeleteUserStudySessionByIdInput!) {
    deleteUserStudySessionById(input: $input) {
      user {
        currentExamTypesUser {
          ...DaysUntilExam_examTypesUser
          ...QuestionsPerformanceBox_examTypesUser
          ...PerformanceScoreBox_examTypesUser
          ...StreakBox_examTypesUser
          ...StudySessionWizardModal_examTypesUser
        }
      }
    }
  }
`

const examTypesUserFragment = graphql`
  fragment StudySessionWizardModal_examTypesUser on ExamTypesUser {
    examTypeId
    score
    examType {
      subtopics: topics(
        condition: { meetsTopicViabilityCriteria: true }
        filter: { parentTopicExists: true }
        orderBy: [SEQUENCE_ASC]
      ) {
        nodes {
          sequence
          id
          parentTopicId
          topicObjectives(condition: { meetsLessonViabilityCriteria: true }) {
            nodes {
              id
              topicId
              questions(condition: { isActive: true, isStudySession: true }) {
                nodes {
                  rowId
                  topicId
                  topicObjectiveId
                }
              }
            }
          }
        }
      }
      title
      topics(
        condition: { meetsTopicViabilityCriteria: true }
        filter: { parentTopicExists: false }
        orderBy: [SEQUENCE_ASC]
      ) {
        nodes {
          rowId
        }
      }
      userStudySessions {
        totalCount
      }
      userSubtopics(orderBy: [SCORE_ASC, TOPIC_BY_TOPIC_ID__SEQUENCE_ASC]) {
        nodes {
          score
          subtopicId
          topicId
          subtopic {
            topicObjectives(condition: { meetsLessonViabilityCriteria: true }) {
              nodes {
                questions(condition: { isActive: true, isStudySession: true }) {
                  nodes {
                    rowId
                    topicId
                    topicObjectiveId
                  }
                }
              }
            }
          }
        }
      }
      userTopics(orderBy: [SCORE_ASC, TOPIC_BY_TOPIC_ID__SEQUENCE_ASC]) {
        nodes {
          score
          topicId
        }
      }
    }
    ...QuestionsPerformanceBox_examTypesUser
    ...StreakBox_examTypesUser
  }
`

const updateUserStudySessionMutation = graphql`
  mutation StudySessionWizardModalUpdateUserStudySessionMutation($input: UpdateUserStudySessionInput!) {
    updateUserStudySession(input: $input) {
      userStudySession {
        ...StudySessionCompletePositive_userStudySession
        ...StudySessionQuestionWizard_userStudySession
        ...StudySessionReviewWizard_userStudySession
      }
      user {
        currentExamTypesUser {
          ...DaysUntilExam_examTypesUser
          ...QuestionsPerformanceBox_examTypesUser
          ...PerformanceScoreBox_examTypesUser
          ...StreakBox_examTypesUser
          ...StudySessionWizardModal_examTypesUser
        }
      }
    }
  }
`

const updateUserStudySessionQuestionMutation = graphql`
  mutation StudySessionWizardModalUpdateUserStudySessionQuestionMutation($input: UpdateUserStudySessionQuestionInput!) {
    updateUserStudySessionQuestion(input: $input) {
      userStudySession {
        rowId
        user {
          currentExamTypesUser {
            ...DaysUntilExam_examTypesUser
            ...QuestionsPerformanceBox_examTypesUser
            ...PerformanceScoreBox_examTypesUser
            ...StreakBox_examTypesUser
            ...StudySessionWizardModal_examTypesUser
          }
        }
        ...StudySessionCompletePositive_userStudySession
        ...StudySessionQuestionWizard_userStudySession
        ...StudySessionReviewWizard_userStudySession
      }
      userStudySessionQuestion {
        ...StudySessionQuestion_userStudySessionQuestion
      }
    }
  }
`

const queryFragment = graphql`
  fragment StudySessionWizardModal_query on Query {
    currentUser {
      rowId
    }
  }
`

const useStyles = createStyles((theme) => ({
  modalBody: {
    backgroundColor: theme.colors.studiousGray[2],
    flexGrow: 1,
    padding: '2rem'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  modalHeader: {
    backgroundColor: theme.colors.gray[3],
    padding: 10,
    boxSizing: 'border-box',
    height: 70,
    marginBottom: 20
  },
  modalTitle: {
    width: '100%',
    textAlign: 'center'
  }
}))

export interface StudySessionWizardModalProps {
  examTypesUser: StudySessionWizardModal_examTypesUser$key
  query: StudySessionWizardModal_query$key
  onClose(): void
}
export const StudySessionWizardModal: FC<StudySessionWizardModalProps> = ({ examTypesUser, onClose, query }) => {
  const examTypesUserData = useFragment(examTypesUserFragment, examTypesUser)
  const queryData = useFragment(queryFragment, query)

  const onCloseHandler = useCallback(() => {
    trackEvent('close_study_session_modal', {
      exam_type: examTypesUserData?.examType?.title,
      type: SESSION_TYPE
    })
    onClose()
  }, [onClose, examTypesUserData?.examType?.title])

  const [createUserStudySession] =
    useMutation<StudySessionWizardModalCreateUserStudySessionMutation>(createUserStudySessionMutation)
  const [deleteUserStudySession] =
    useMutation<StudySessionWizardModalDeleteUserStudySessionMutation>(deleteUserStudySessionMutation)
  const [updateUserStudySession] =
    useMutation<StudySessionWizardModalUpdateUserStudySessionMutation>(updateUserStudySessionMutation)
  const [updateUserStudySessionQuestion] = useMutation<StudySessionWizardModalUpdateUserStudySessionQuestionMutation>(
    updateUserStudySessionQuestionMutation
  )

  const [deleting, confirmDeleteSessionModalHandler] = useDisclosure(false)
  const [currentUserStudySession, setCurrentUserStudySession] = useState<any>(null)
  const [currentUserStudySessionQuestions, setCurrentUserStudySessionQuestions] = useState<[]>([])
  const [currentModalView, setCurrentModalView] = useState<string>('start')
  const [sessionTimer, setSessionTimer] = useState(SESSION_TIME_LIMIT)
  const [studySessionStarted, setStudySessionStarted] = useState(false)
  const resetSession = useCallback(() => {
    setCurrentUserStudySession(null)
    setCurrentUserStudySessionQuestions([])
    setCurrentModalView('start')
    setSessionTimer(SESSION_TIME_LIMIT)
    setStudySessionStarted(false)
    trackEvent('reset_study_session_modal', {
      exam_type: examTypesUserData?.examType?.title,
      type: SESSION_TYPE
    })
  }, [examTypesUserData?.examType?.title])

  const { classes } = useStyles()

  const generatedStudySessionQuestions = useMemo(() => {
    if (zeroIfEmpty(examTypesUserData?.score) < PASSING_SCORE) {
      // find all topics that are not passing or haven't been started
      const failingTopics = filter(
        examTypesUserData?.examType?.topics?.nodes,
        (examTopic) =>
          zeroIfEmpty(
            find(examTypesUserData?.examType?.userTopics?.nodes as UserTopic[], ['topicId', examTopic?.rowId])?.score
          ) < PASSING_SCORE
      ) as Topic[]
      // pick three random topics
      const randomFailingTopics = sampleSize(failingTopics, 3)
      // find all subtopics that are not passing for each topic ordered by sequence and return the first one
      const selectedSubtopics = map(randomFailingTopics, (randomFailingTopic) => {
        const subtopics = filter(
          // first filter the list of subtopics to only include subtopics that belong to the topic
          filter(
            examTypesUserData?.examType?.subtopics?.nodes,
            (subtopic) => subtopic?.parentTopicId === randomFailingTopic.rowId
          ),
          (subtopic) =>
            // find within the userSubtopics (which is a list of subtopics that the user has started) the subtopic that matches the current subtopic
            zeroIfEmpty(find(examTypesUserData?.examType?.userSubtopics?.nodes, ['subtopicId', subtopic.id])?.score) <
            PASSING_SCORE
        )
        return sampleSize(subtopics, 1)[0]
      }) as Topic[]

      return flatMap(selectedSubtopics, (subtopic) => {
        // select 4 random topicObjectives
        const randomObjectives = sampleSize(subtopic?.topicObjectives.nodes, 4)
        // select 1 random question from each topicObjective
        const randomQuestions = map(
          randomObjectives,
          (objective) => sampleSize(objective?.questions.nodes, 1)[0]
        ) as Question[]
        return shuffle(
          map(randomQuestions, (question) => ({
            questionId: question?.rowId,
            topicId: subtopic?.parentTopicId,
            subtopicId: question?.topicId,
            topicObjectiveId: question?.topicObjectiveId
          }))
        )
      })
    }
    const lowestScoreTopic = examTypesUserData?.examType?.userTopics?.nodes?.[0]
    const lowestScoreSubtopic = find(examTypesUserData?.examType?.userSubtopics?.nodes, [
      'topicId',
      lowestScoreTopic?.topicId
    ]) as UserSubtopic
    const randomObjectives = sampleSize(lowestScoreSubtopic?.subtopic?.topicObjectives?.nodes, 12)
    const randomQuestions = map(
      randomObjectives,
      (objective) => sampleSize(objective?.questions.nodes, 1)[0]
    ) as Question[]
    return shuffle(
      map(randomQuestions, (question) => ({
        questionId: question?.rowId,
        topicId: lowestScoreTopic?.topicId,
        subtopicId: lowestScoreSubtopic?.subtopicId,
        topicObjectiveId: question?.topicObjectiveId
      }))
    )
  }, [examTypesUserData])

  const startSession = useCallback(() => {
    createUserStudySession({
      variables: {
        input: {
          userStudySession: {
            examTypeId: examTypesUserData?.examTypeId as string,
            startedAt: new Date(),
            userId: queryData?.currentUser?.rowId as string,
            totalQuestionsCount: generatedStudySessionQuestions.length,
            userStudySessionQuestions: {
              create: map(generatedStudySessionQuestions, (question, index) => ({
                ...question,
                sequence: index + 1
              }))
            }
          }
        }
      },
      onCompleted: ({ createUserStudySession }) => {
        setCurrentUserStudySessionQuestions(
          createUserStudySession?.userStudySession?.userStudySessionQuestions?.nodes as []
        )
        setCurrentUserStudySession(createUserStudySession?.userStudySession)
      }
    })
    setStudySessionStarted(true)
    setCurrentModalView('session')
    trackEvent('start_study_session_modal', {
      exam_type: examTypesUserData?.examType?.title,
      type: SESSION_TYPE
    })
  }, [
    createUserStudySession,
    examTypesUserData?.examTypeId,
    examTypesUserData?.examType?.title,
    queryData?.currentUser?.rowId,
    generatedStudySessionQuestions
  ])

  const submitQuestionAnswer = useCallback(
    (answerData: StudySessionQuestionOnSubmitData) => {
      updateUserStudySessionQuestion({
        variables: {
          input: answerData
        },
        onCompleted: ({ updateUserStudySessionQuestion }) => {
          if (answerData.patch.sequence === currentUserStudySessionQuestions?.length) {
            updateUserStudySession({
              variables: {
                input: {
                  rowId: updateUserStudySessionQuestion?.userStudySession?.rowId,
                  patch: {
                    completedAt: answerData.patch.completedAt
                  }
                }
              },
              onCompleted: () => {
                trackEvent('complete_study_session_modal', {
                  exam_type: examTypesUserData?.examType?.title,
                  type: SESSION_TYPE
                })
                setCurrentModalView('results')
              }
            })
          }
        }
      })
      trackEvent('submit_answer_study_session_modal', {
        exam_type: examTypesUserData?.examType?.title,
        type: SESSION_TYPE
      })
    },
    [
      currentUserStudySessionQuestions?.length,
      updateUserStudySession,
      updateUserStudySessionQuestion,
      examTypesUserData?.examType?.title
    ]
  )

  useEffect(() => {
    trackEvent('open_study_session_modal', {
      exam_type: examTypesUserData?.examType?.title,
      type: SESSION_TYPE
    })
  }, [examTypesUserData?.examType?.title])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    studySessionStarted && sessionTimer > 0 && setTimeout(() => setSessionTimer(sessionTimer - 1), 1000)
    if (sessionTimer === 0) {
      trackEvent('expired_study_session_modal', {
        exam_type: examTypesUserData?.examType?.title,
        type: SESSION_TYPE
      })
    }
  }, [sessionTimer, studySessionStarted, examTypesUserData?.examType?.title])

  return (
    <>
      <Modal
        classNames={{
          header: classes.modalHeader,
          title: classes.modalTitle,
          content: classes.modalContent,
          body: classes.modalBody
        }}
        onClose={currentModalView === 'session' ? confirmDeleteSessionModalHandler.open : onCloseHandler}
        opened
        radius={16}
        size='xl'
        title={<Title order={2}>Daily Questions</Title>}
      >
        {currentModalView === 'start' && (
          <StudySessionStart
            disabled={!queryData?.currentUser?.rowId || !examTypesUserData?.examTypeId}
            onNo={onCloseHandler}
            onStart={startSession}
          />
        )}
        {currentUserStudySession && !isEmpty(currentUserStudySessionQuestions) && currentModalView === 'session' && (
          <StudySessionQuestionWizard
            userStudySession={currentUserStudySession}
            onClose={confirmDeleteSessionModalHandler.open}
            questionOnSubmit={submitQuestionAnswer}
            sessionTimer={sessionTimer}
          />
        )}
        {currentModalView === 'results' && (
          <StudySessionCompletePositive
            userStudySession={currentUserStudySession}
            onNewSession={resetSession}
            onStartReview={() => {
              trackEvent('review_study_session_modal', {
                exam_type: examTypesUserData?.examType?.title,
                type: SESSION_TYPE
              })
              setCurrentModalView('review')
            }}
          />
        )}
        {currentModalView === 'review' && (
          <StudySessionReviewWizard
            userStudySession={currentUserStudySession}
            resetSession={resetSession}
            showStudySessionResults={() => setCurrentModalView('results')}
          />
        )}
      </Modal>
      <ConfirmModal
        onClose={confirmDeleteSessionModalHandler.close}
        onConfirm={useCallback(
          () =>
            deleteUserStudySession({
              variables: {
                input: {
                  id: currentUserStudySession?.id
                }
              },
              onCompleted: onCloseHandler
            }),
          [currentUserStudySession?.id, deleteUserStudySession, onCloseHandler]
        )}
        opened={deleting}
        title='Are you sure you want to cancel this session? Canceling will delete all progress.'
        zIndex={100001}
      />
    </>
  )
}
