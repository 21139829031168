import { type TopicPropertyList_topic$key } from '@app/__generated__/TopicPropertyList_topic.graphql'
import { IconBoolean, MarkdownBox, PropertyList, type PropertyListProps } from '@app/components'
import { longDate } from '@app/lib'
import { Avatar } from '@mantine/core'
import { compact } from 'lodash'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const topicFragment = graphql`
  fragment TopicPropertyList_topic on Topic {
    createdAt
    guidebook
    image
    isActive
    summary
    title
    updatedAt
    parentTopicId
  }
`

export interface TopicPropertyListProps extends Omit<PropertyListProps, 'items'> {
  topic: TopicPropertyList_topic$key | null
}

export const TopicPropertyList: FC<TopicPropertyListProps> = ({ topic }) => {
  const topicData = useFragment(topicFragment, topic)
  const isSubtopic = !!topicData?.parentTopicId

  return (
    <PropertyList
      p='xs'
      items={compact([
        {
          label: 'Title',
          value: topicData?.title
        },
        {
          label: 'Summary',
          value: topicData?.summary
        },
        isSubtopic
          ? null
          : {
              label: 'Image',
              value: topicData?.image ? (
                <Avatar
                  size='sm'
                  src={topicData?.image}
                />
              ) : null
            },
        {
          label: 'Guidebook',
          value: (
            <MarkdownBox
              mb='xs'
              w='100%'
            >
              {topicData?.guidebook}
            </MarkdownBox>
          )
        },
        {
          label: 'Active',
          value: (
            <IconBoolean
              size={18}
              value={topicData?.isActive ?? false}
            />
          )
        },
        {
          label: 'Created',
          value: longDate(topicData?.createdAt)
        },
        {
          label: 'Last Updated',
          value: longDate(topicData?.updatedAt)
        }
      ])}
    />
  )
}
