/**
 * @generated SignedSource<<72fa566edbed87b2477d149835a90e77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserExamTypesTable_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserExamTypeCreateModal_query" | "UserExamTypeUpdateModal_query">;
  readonly " $fragmentType": "UserExamTypesTable_query";
};
export type UserExamTypesTable_query$key = {
  readonly " $data"?: UserExamTypesTable_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserExamTypesTable_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserExamTypesTable_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserExamTypeCreateModal_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserExamTypeUpdateModal_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "99056fb8d1370d3368e9df77add0873e";

export default node;
