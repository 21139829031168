import { type StudySessionReviewQuestion_userStudySessionQuestion$key } from '@app/__generated__/StudySessionReviewQuestion_userStudySessionQuestion.graphql'
import { BorderButton, QuestionAnswers, QuestionTitle } from '@app/components'
import { Box, createStyles, Group, RingProgress, Text } from '@mantine/core'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { type FC, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

const userStudySessionQuestionFragment = graphql`
  fragment StudySessionReviewQuestion_userStudySessionQuestion on UserStudySessionQuestion {
    questionAnswerId
    startedAt
    completedAt
    sequence
    rowId
    question {
      correctAnswer: questionAnswers(condition: { isActive: true, isCorrect: true }) {
        nodes {
          rowId
        }
      }
      ...QuestionTitle_question
    }
    ...QuestionAnswers_userStudySessionQuestion
  }
`

export interface StudySessionReviewQuestionProps {
  finalOptions?: any
  userStudySessionQuestion: StudySessionReviewQuestion_userStudySessionQuestion$key

  onNext?: () => void

  onPrev?: () => void
  questionsCount?: number
}

const useStyles = createStyles((theme) => ({
  groupContainer: {
    position: 'relative',
    flexWrap: 'nowrap',
    [theme.fn.smallerThan('sm')]: {
      flexWrap: 'wrap'
    }
  }
}))

export const StudySessionReviewQuestion: FC<StudySessionReviewQuestionProps> = ({
  userStudySessionQuestion,
  onNext,
  onPrev,
  finalOptions,
  questionsCount
}) => {
  const { classes, theme } = useStyles()
  const userStudySessionQuestionData = useFragment(userStudySessionQuestionFragment, userStudySessionQuestion)

  const questionTimeRemaining = useMemo(
    () =>
      100 -
      dayjs(userStudySessionQuestionData.completedAt).diff(dayjs(userStudySessionQuestionData.startedAt), 'second'),
    [userStudySessionQuestionData.completedAt, userStudySessionQuestionData.startedAt]
  )

  return (
    <>
      <Group
        align='flex-start'
        mb='xl'
        className={classes.groupContainer}
      >
        <RingProgress
          size={60}
          thickness={4}
          roundCaps
          {...(questionTimeRemaining === 0 && { rootColor: theme.colors.red[4] })}
          sections={[
            {
              value: questionTimeRemaining,
              color:
                questionTimeRemaining < 16
                  ? theme.colors.red[4]
                  : questionTimeRemaining < 31
                  ? theme.colors.yellow[4]
                  : theme.colors.jelloGreen[4]
            }
          ]}
          label={
            <Text
              align='center'
              size='sm'
            >
              {questionTimeRemaining}
            </Text>
          }
        />
        <Group
          position='left'
          mb='xl'
        >
          <Box>
            <QuestionTitle
              number={userStudySessionQuestionData.sequence}
              questionsCount={questionsCount}
              question={userStudySessionQuestionData.question as any}
            />
            <QuestionAnswers
              showResult={true}
              disabled={true}
              selectedAnswerId={userStudySessionQuestionData?.questionAnswerId}
              userStudySessionQuestion={userStudySessionQuestionData}
            />
          </Box>
        </Group>
      </Group>
      <Group
        position='center'
        p='xl'
      >
        {finalOptions || (
          <>
            <BorderButton
              onClick={onPrev}
              color={theme.colors.studiousGray[0]}
              leftIcon={<IconChevronLeft />}
              disabled={!onPrev}
            >
              Previous
            </BorderButton>
            <BorderButton
              onClick={onNext}
              rightIcon={<IconChevronRight />}
              disabled={!onNext}
            >
              Next
            </BorderButton>
          </>
        )}
      </Group>
    </>
  )
}
