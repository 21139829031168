/**
 * @generated SignedSource<<17c24ab094acd85d70b2f4aa06c4cbf9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ObjectiveCard_topicObjective$data = {
  readonly currentUserHasCompletedLesson: boolean | null | undefined;
  readonly examTypeId: any;
  readonly rowId: any;
  readonly sequence: number;
  readonly subtopicId: any;
  readonly summary: string | null | undefined;
  readonly title: string;
  readonly topic: {
    readonly topicId: any | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ObjectiveCard_topicObjective";
};
export type ObjectiveCard_topicObjective$key = {
  readonly " $data"?: ObjectiveCard_topicObjective$data;
  readonly " $fragmentSpreads": FragmentRefs<"ObjectiveCard_topicObjective">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ObjectiveCard_topicObjective",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examTypeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    },
    {
      "alias": "subtopicId",
      "args": null,
      "kind": "ScalarField",
      "name": "topicId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "alias": "topicId",
          "args": null,
          "kind": "ScalarField",
          "name": "parentTopicId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserHasCompletedLesson",
      "storageKey": null
    }
  ],
  "type": "TopicObjective",
  "abstractKey": null
};

(node as any).hash = "d12117bd1da550d7659250435342d269";

export default node;
