/**
 * @generated SignedSource<<7bf26db95d08daf5c81679c8b8622510>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicForm_topic$data = {
  readonly guidebook: string | null | undefined;
  readonly image: string | null | undefined;
  readonly isActive: boolean | null | undefined;
  readonly parentTopicId: any | null | undefined;
  readonly rowId: any;
  readonly summary: string | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "TopicForm_topic";
};
export type TopicForm_topic$key = {
  readonly " $data"?: TopicForm_topic$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicForm_topic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicForm_topic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "guidebook",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentTopicId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "6ac348dfc1580edac9726bfe9c2a92aa";

export default node;
