import { Button, Group, LoadingOverlay } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { type FC, type FormEvent, type PropsWithChildren, type ReactNode } from 'react'

export interface FormProps extends PropsWithChildren {
  buttons?: ReactNode | boolean
  isSaving?: boolean
  isValid?: boolean
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
}

export const Form: FC<FormProps> = ({
  buttons = true,
  children,
  isSaving = false,
  isValid = true,
  onSubmit,
  ...props
}) => (
  <form
    {...props}
    onSubmit={onSubmit}
    style={{ position: 'relative' }}
  >
    <LoadingOverlay visible={isSaving} />
    {children}
    {buttons ? (
      <Group position='right'>
        {buttons === true ? (
          <Button
            color='green'
            disabled={isSaving || !isValid}
            leftIcon={<IconCheck size={18} />}
            loading={isSaving}
            type='submit'
          >
            Save
          </Button>
        ) : (
          buttons
        )}
      </Group>
    ) : null}
  </form>
)
