import { Text } from '@mantine/core'
import { useModals } from '@mantine/modals'
import { IconCheck } from '@tabler/icons-react'
import { type FC } from 'react'

import { Modal, type ModalProps } from './Modal'

export interface UnsavedChangesModalProps extends ModalProps {
  changed?: boolean
}

export const UnsavedChangesModal: FC<UnsavedChangesModalProps> = ({ changed, children, onClose, ...props }) => {
  const modals = useModals()

  return (
    <Modal
      onClose={() => {
        if (changed) {
          modals.openConfirmModal({
            centered: true,
            title: 'Unsaved Changes',
            children: <Text size='sm'>You have unsaved changes, are you sure you want to cancel?</Text>,
            labels: {
              confirm: "Yes, I'm Sure",
              cancel: 'Oops, Nevermind'
            },
            confirmProps: {
              leftIcon: <IconCheck size={16} />
            },
            onConfirm: onClose,
            zIndex: 1002
          })
        } else {
          onClose()
        }
      }}
      {...props}
    >
      {children}
    </Modal>
  )
}
