import { DraggableRow, TextInput } from '@app/components'
import {
  ActionIcon,
  Box,
  CloseButton,
  createStyles,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  SegmentedControl,
  Stack,
  Table as MantineTable,
  TableProps as MantineTableProps,
  Text
} from '@mantine/core'
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks'
import {
  IconArrowsSort,
  IconChevronsLeft,
  IconChevronsRight,
  IconSearch,
  IconSortAscending,
  IconSortDescending,
  IconZoomExclamation
} from '@tabler/icons-react'
import {
  ColumnDef,
  ColumnSort,
  flexRender,
  getCoreRowModel,
  PaginationState,
  SortingState,
  TableOptions,
  useReactTable
} from '@tanstack/react-table'
import { isArray, isEmpty, isNaN, map, reduce, toString } from 'lodash'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { LoadMoreFn, RefetchOptions, Variables } from 'react-relay'
import { OperationType } from 'relay-runtime'

const useStyles = createStyles({
  root: {
    position: 'relative'
  },
  cell: {
    verticalAlign: 'top'
  }
})

export interface PaginationTableProps<TEdge, TOrderBy, TQuery extends OperationType, TFilter>
  extends MantineTableProps {
  columns: ColumnDef<TEdge>[]
  data: TEdge[] | ReadonlyArray<TEdge>
  filterPlaceholder?: string
  getFilterFromSearch?: (search: string) => TFilter | null
  hasNext: boolean
  initialPagination?: PaginationState
  initialSorting?: SortingState
  isFilterable?: boolean
  isLoadingNext?: boolean
  isReorderable?: boolean
  loadNext: LoadMoreFn<TQuery>
  onReorder?: (sourceIndex: number, destinationIndex: number) => void
  pageSizeOptions?: number[]
  refetch: (variables: object, options?: RefetchOptions) => void
  sortOptions?: Record<
    string,
    {
      asc: TOrderBy
      desc: TOrderBy
    }
  >
  tableOptions?: TableOptions<TEdge>
  totalCount: number
}

export const PaginationTable = <TEdge, TOrderBy, TQuery extends OperationType, TFilter>({
  className,
  columns,
  data: passedData,
  filterPlaceholder = 'Search',
  getFilterFromSearch,
  hasNext,
  initialPagination = {
    pageIndex: 0,
    pageSize: 10
  },
  initialSorting = [],
  isFilterable,
  isLoadingNext,
  isReorderable,
  loadNext,
  onReorder,
  pageSizeOptions = [10, 20, 50, 100],
  refetch,
  sortOptions,
  tableOptions,
  totalCount = 0,
  ...props
}: PaginationTableProps<TEdge, TOrderBy, TQuery, TFilter>) => {
  const [data, setData] = useState(passedData)
  const [loading, setLoading] = useState(false)
  const [rawSearch, setRawSearch] = useState<string | null>(null)
  const [sorting, setSorting] = useState(initialSorting)
  const [{ pageIndex, pageSize }, setPagination] = useState(initialPagination)
  const [search] = useDebouncedValue(rawSearch, 300)
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  )
  const table = useReactTable<TEdge>({
    ...tableOptions,
    columns,
    data: useMemo(() => {
      if (isReorderable) {
        return data.slice()
      }

      if (isArray(data)) {
        // since relay appends new pages to the connection in the store, we need to slice just the edges that we want for
        // this page. we'll determine the starting and (non-inclusive) ending index, and return a shallow copy of overall
        // data for this page.
        const pageIndexStart = pageIndex * pageSize

        return data.slice(pageIndexStart, pageIndexStart + pageSize)
      }

      return []
    }, [data, isReorderable, pageIndex, pageSize]),
    defaultColumn: {
      // @ts-ignore this is actually acceptable, but react-table's types don't think it is.  don't change!
      size: 'auto'
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    pageCount: useMemo(() => (totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize)), [pageSize, totalCount]),
    sortDescFirst: false,
    state: {
      pagination,
      sorting
    }
  })
  const { classes, cx, theme } = useStyles()
  const handleReorder = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      setData((prev) => {
        const next = prev.slice()

        next.splice(
          destinationIndex < 0 ? next.length + destinationIndex : destinationIndex,
          0,
          next.splice(sourceIndex, 1)[0]
        )

        return next
      })
      onReorder?.(sourceIndex, destinationIndex)
    },
    [onReorder]
  )
  // this gives the parent table a chance to re-render before dispatching another re-render call (which react complains about)
  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setRawSearch(e.currentTarget.value), [])
  const onSearchClear = useCallback(() => setRawSearch(''), [])

  useDidUpdate(() => {
    setData(passedData)
  }, [passedData])

  useDidUpdate(() => {
    setLoading(true)

    const variables = {
      after: null,
      first: pageSize
    } as Variables

    if (isFilterable && search && getFilterFromSearch) {
      variables.filter = getFilterFromSearch(search)
    }

    if (!isReorderable && sortOptions) {
      const orderBy = reduce<ColumnSort, TOrderBy[]>(
        sorting,
        (acc, { id, desc }) => [...acc, sortOptions[id][desc ? 'desc' : 'asc']],
        []
      )

      variables.orderBy = !isEmpty(orderBy) ? orderBy : null
    }

    refetch(variables, {
      onComplete: () =>
        requestAnimationFrame(() => {
          table.setPageIndex(0)
          setLoading(false)
        })
    })
  }, [search, pageSize, sorting])

  return (
    <DndProvider backend={HTML5Backend}>
      <Box className={cx(classes.root, className)}>
        <LoadingOverlay visible={isLoadingNext || loading} />
        {useMemo(
          () =>
            isFilterable ? (
              <TextInput
                icon={<IconSearch size={18} />}
                mb='xs'
                onChange={onSearchChange}
                placeholder={filterPlaceholder}
                rightSection={
                  search && (
                    <CloseButton
                      onClick={onSearchClear}
                      variant='transparent'
                    />
                  )
                }
                value={rawSearch ?? ''}
              />
            ) : null,
          [filterPlaceholder, isFilterable, onSearchChange, onSearchClear, rawSearch, search]
        )}
        <Paper
          shadow='sm'
          radius='sm'
          withBorder
        >
          <MantineTable {...props}>
            <thead>
              {map(
                table.getHeaderGroups(),
                useCallback(
                  (headerGroup) => (
                    <tr key={headerGroup.id}>
                      {map(headerGroup.headers, (header) => {
                        const isSorted = header.column.getIsSorted()
                        const label = !header.isPlaceholder
                          ? flexRender(header.column.columnDef.header, header.getContext())
                          : null
                        const width = header.column.getSize()

                        // noinspection TypeScriptValidateJSTypes
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{
                              width: isNaN(width) ? 'auto' : width
                            }}
                          >
                            {!isReorderable && header.column.getCanSort() ? (
                              <Group
                                position='left'
                                spacing={0}
                                noWrap
                              >
                                {label}
                                <ActionIcon
                                  onClick={() =>
                                    header.column.toggleSorting(undefined, header.column.getCanMultiSort())
                                  }
                                  variant='transparent'
                                >
                                  {isSorted === 'asc' ? (
                                    <IconSortAscending size={14} />
                                  ) : isSorted === 'desc' ? (
                                    <IconSortDescending size={14} />
                                  ) : (
                                    <IconArrowsSort size={14} />
                                  )}
                                </ActionIcon>
                              </Group>
                            ) : (
                              label
                            )}
                          </th>
                        )
                      })}
                      {isReorderable && (
                        <th
                          style={{
                            width: 35
                          }}
                        >
                          &nbsp;
                        </th>
                      )}
                    </tr>
                  ),
                  [isReorderable]
                )
              )}
            </thead>
            <tbody>
              {map(table.getRowModel().rows, (row) =>
                isReorderable && onReorder ? (
                  <DraggableRow
                    key={row.id}
                    onReorder={handleReorder}
                    row={row}
                  />
                ) : (
                  <tr key={row.id}>
                    {map(row.getVisibleCells(), (cell) => {
                      const width = cell.column.getSize()

                      return (
                        <td
                          className={classes.cell}
                          key={cell.id}
                          style={{
                            width: isNaN(width) ? 'auto' : width
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      )
                    })}
                  </tr>
                )
              )}
            </tbody>
          </MantineTable>
          {useMemo(
            () =>
              data?.length === 0 ? (
                <Stack
                  align='center'
                  justify='center'
                  spacing='xs'
                  p='xl'
                  sx={{ minHeight: '10vh' }}
                >
                  <IconZoomExclamation
                    color={theme.colors.gray[4]}
                    size={64}
                  />
                  <Text
                    size='xl'
                    color='dimmed'
                  >
                    {search ? `No results found for ${search}` : 'No results found'}
                  </Text>
                </Stack>
              ) : null,
            [data?.length, search, theme.colors.gray]
          )}
          {!isReorderable && (
            <>
              <Divider color={theme.colors.gray[3]} />
              <Group
                align='center'
                p='xs'
                position='apart'
              >
                <ActionIcon
                  color='blue'
                  disabled={!table.getCanPreviousPage()}
                  onClick={() => table.previousPage()}
                  variant='transparent'
                >
                  <IconChevronsLeft size={18} />
                </ActionIcon>
                <Group spacing='sm'>
                  <Text size='xs'>
                    Page {pageIndex + 1} of {table.getPageCount()}
                  </Text>
                  <Text size='xs'>&middot;</Text>
                  <SegmentedControl
                    color='blue'
                    data={map(pageSizeOptions, toString)}
                    onChange={(nextPageSize: string) => table.setPageSize(Number(nextPageSize))}
                    size='xs'
                    value={toString(pageSize)}
                  />
                  <Text size='xs'>Per Page</Text>
                  <Text size='xs'>&middot;</Text>
                  <Text size='xs'>
                    {totalCount === 0 ? 'No results' : totalCount === 1 ? '1 result' : `${totalCount} results`}
                  </Text>
                </Group>
                <ActionIcon
                  color='blue'
                  disabled={!(hasNext || table.getCanNextPage())}
                  onClick={() => {
                    if (data[(pageIndex + 1) * pageSize]) {
                      // the data is already fetched, so just change page index
                      table.nextPage()
                    } else if (hasNext) {
                      // we don't have the data already, and relay reports that there's a next page, so we need to load it
                      setLoading(true)

                      loadNext(pageSize, {
                        onComplete: () =>
                          requestAnimationFrame(() => {
                            // now that the data is loaded, we can let the table change to that page index
                            table.nextPage()

                            setLoading(false)
                          })
                      })
                    }
                  }}
                  variant='transparent'
                >
                  <IconChevronsRight size={18} />
                </ActionIcon>
              </Group>
            </>
          )}
        </Paper>
      </Box>
    </DndProvider>
  )
}
