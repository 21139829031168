/**
 * @generated SignedSource<<be10f3c73f365d9f4d830f21516f4fce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserExamTypeForm_user$data = {
  readonly existingExamTypesUsers: {
    readonly nodes: ReadonlyArray<{
      readonly examTypeId: any;
    }>;
  };
  readonly " $fragmentType": "UserExamTypeForm_user";
};
export type UserExamTypeForm_user$key = {
  readonly " $data"?: UserExamTypeForm_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserExamTypeForm_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserExamTypeForm_user",
  "selections": [
    {
      "alias": "existingExamTypesUsers",
      "args": null,
      "concreteType": "ExamTypesUsersConnection",
      "kind": "LinkedField",
      "name": "examTypesUsers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamTypesUser",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "examTypeId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "dfef8bcbfe651255476bc089d846a656";

export default node;
