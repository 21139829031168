import { ActionIcon, type ActionIconProps } from '@mantine/core'
import { IconSettings } from '@tabler/icons-react'
import Link from 'next/link'
import { forwardRef } from 'react'

export interface DataTableActionIconProps extends ActionIconProps {
  href?: string
  title?: string
}

export const ActionColumnIcon = forwardRef<any, DataTableActionIconProps>(({ href, title, ...props }, ref) =>
  href ? (
    <ActionIcon
      color='blue'
      component={Link}
      href={href}
      ref={ref}
      title={title}
      variant='transparent'
      {...props}
    >
      <IconSettings size={16} />
    </ActionIcon>
  ) : (
    <ActionIcon
      color='blue'
      ref={ref}
      title={title}
      variant='transparent'
      {...props}
    >
      <IconSettings size={16} />
    </ActionIcon>
  )
)

ActionColumnIcon.displayName = 'DataTableActionIcon'
