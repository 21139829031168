/**
 * @generated SignedSource<<8e08ca19ed4bb721ac0f89410ab287a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySessionWizardModal_query$data = {
  readonly currentUser: {
    readonly rowId: any;
  } | null | undefined;
  readonly " $fragmentType": "StudySessionWizardModal_query";
};
export type StudySessionWizardModal_query$key = {
  readonly " $data"?: StudySessionWizardModal_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySessionWizardModal_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudySessionWizardModal_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "6ce63c1379562925ed09f2decefd95a2";

export default node;
