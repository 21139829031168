/**
 * @generated SignedSource<<afc60306ea4d3a0eba09c5b9d0a85a19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestionForm_examType$data = {
  readonly allTopics: {
    readonly nodes: ReadonlyArray<{
      readonly allSubtopics: {
        readonly nodes: ReadonlyArray<{
          readonly allTopicObjectives: {
            readonly nodes: ReadonlyArray<{
              readonly rowId: any;
              readonly title: string;
            }>;
          };
          readonly rowId: any;
          readonly title: string;
        }>;
      };
      readonly parentTopicId: any | null | undefined;
      readonly rowId: any;
      readonly title: string;
    }>;
  };
  readonly " $fragmentType": "QuestionForm_examType";
};
export type QuestionForm_examType$key = {
  readonly " $data"?: QuestionForm_examType$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionForm_examType">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": [
    "SEQUENCE_ASC"
  ]
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionForm_examType",
  "selections": [
    {
      "alias": "allTopics",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "parentTopicId": {
              "isNull": true
            }
          }
        },
        (v0/*: any*/)
      ],
      "concreteType": "TopicsConnection",
      "kind": "LinkedField",
      "name": "topics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "parentTopicId",
              "storageKey": null
            },
            {
              "alias": "allSubtopics",
              "args": (v3/*: any*/),
              "concreteType": "TopicsConnection",
              "kind": "LinkedField",
              "name": "topicsByParentTopicId",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Topic",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": "allTopicObjectives",
                      "args": (v3/*: any*/),
                      "concreteType": "TopicObjectivesConnection",
                      "kind": "LinkedField",
                      "name": "topicObjectives",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TopicObjective",
                          "kind": "LinkedField",
                          "name": "nodes",
                          "plural": true,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "topicObjectives(orderBy:[\"SEQUENCE_ASC\"])"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "topicsByParentTopicId(orderBy:[\"SEQUENCE_ASC\"])"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "topics(filter:{\"parentTopicId\":{\"isNull\":true}},orderBy:[\"SEQUENCE_ASC\"])"
    }
  ],
  "type": "ExamType",
  "abstractKey": null
};
})();

(node as any).hash = "9196f327d068ac49ba73e3c09a258d19";

export default node;
