/**
 * @generated SignedSource<<937b88d703a2f17746e2b7c1db07c00a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicObjectiveForm_examType$data = {
  readonly allTopics: {
    readonly nodes: ReadonlyArray<{
      readonly parentTopicId: any | null | undefined;
      readonly rowId: any;
      readonly title: string;
    }>;
  };
  readonly " $fragmentType": "TopicObjectiveForm_examType";
};
export type TopicObjectiveForm_examType$key = {
  readonly " $data"?: TopicObjectiveForm_examType$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicObjectiveForm_examType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicObjectiveForm_examType",
  "selections": [
    {
      "alias": "allTopics",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "SEQUENCE_ASC"
          ]
        }
      ],
      "concreteType": "TopicsConnection",
      "kind": "LinkedField",
      "name": "topics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "parentTopicId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rowId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "topics(orderBy:[\"SEQUENCE_ASC\"])"
    }
  ],
  "type": "ExamType",
  "abstractKey": null
};

(node as any).hash = "effb25c14173f99dbc904f29f8e52e5e";

export default node;
