import { BackgroundImage, Container, createStyles, Modal, type ModalProps, Stack } from '@mantine/core'
import { type FC } from 'react'

export interface StudySessionModalProps extends ModalProps {
  backgroundImage?: string
}

const useStyles = createStyles((theme) => ({
  modalTitle: {
    fontWeight: 'bold'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    [theme.fn.smallerThan('md')]: {
      padding: 0
    }
  },
  modalBody: {
    flexGrow: 1,
    [theme.fn.smallerThan('md')]: {
      padding: 0
    }
  },
  fullHeight: {
    height: '100%',
    minHeight: '70vh'
  },
  container: {
    [theme.fn.smallerThan('md')]: {
      padding: 10
    }
  }
}))

export const StudySessionModal: FC<StudySessionModalProps> = ({ backgroundImage, children, classNames, ...props }) => {
  const { classes, cx } = useStyles()

  return (
    <Modal
      title="Welcome to Today's Study Session"
      transitionProps={{
        transition: 'fade',
        duration: 200
      }}
      classNames={{
        title: classes.modalTitle,
        content: classes.modalContent,
        body: classes.modalBody,
        ...classNames
      }}
      zIndex={10000}
      {...props}
    >
      <BackgroundImage
        src={backgroundImage || ''}
        radius='sm'
        className={classes.fullHeight}
      >
        <Container
          size='md'
          className={cx(classes.fullHeight, classes.container)}
        >
          <Stack
            justify='center'
            className={classes.fullHeight}
          >
            {children}
          </Stack>
        </Container>
      </BackgroundImage>
    </Modal>
  )
}
