/**
 * @generated SignedSource<<54f0d9e09c04a00649ff444649806380>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneratedContentReviewStatus = "APPROVED" | "READY_FOR_REVIEW" | "REJECTED" | "%future added value";
export type UpdateQuestionAnswerInput = {
  clientMutationId?: string | null | undefined;
  patch: QuestionAnswerPatch;
  rowId: any;
};
export type QuestionAnswerPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionAnswersCreatedByFkeyInput | null | undefined;
  examType?: QuestionAnswersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  isActive?: boolean | null | undefined;
  isCorrect?: boolean | null | undefined;
  question?: QuestionAnswersQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionAnswersUpdatedByFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionAnswerIdFkeyInverseInput | null | undefined;
};
export type QuestionAnswersCreatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: QuestionAnswersCreatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: QuestionAnswerOnQuestionAnswerForQuestionAnswersCreatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnQuestionAnswerForQuestionAnswersCreatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnQuestionAnswerForQuestionAnswersCreatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserNodeIdConnect = {
  id: string;
};
export type UserUsersPkeyConnect = {
  rowId: any;
};
export type UserUniqueSlugConnect = {
  slug: string;
};
export type QuestionAnswersCreatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UsersCreatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UsersCreatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserOnUserForUsersCreatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserForUsersCreatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserForUsersCreatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UsersCreatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type ExamTypesCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<ExamTypeNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<ExamTypeExamTypesPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<ExamTypeExamTypesSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<ExamTypesCreatedByFkeyExamTypesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<ExamTypeNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<ExamTypeExamTypesPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<ExamTypeExamTypesSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnExamTypeForExamTypesCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<ExamTypeOnExamTypeForExamTypesCreatedByFkeyUsingExamTypesPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<ExamTypeOnExamTypeForExamTypesCreatedByFkeyUsingExamTypesSlugUkeyUpdate> | null | undefined;
};
export type ExamTypeNodeIdConnect = {
  id: string;
};
export type ExamTypeExamTypesPkeyConnect = {
  rowId: any;
};
export type ExamTypeExamTypesSlugUkeyConnect = {
  slug: string;
};
export type ExamTypesCreatedByFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypesCreatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: ExamTypesCreatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: ExamTypeOnExamTypeForExamTypesCreatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnExamTypeForExamTypesCreatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnExamTypeForExamTypesCreatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type ExamTypesCreatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type ExamTypesUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<ExamTypeNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<ExamTypeExamTypesPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<ExamTypeExamTypesSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<ExamTypesUpdatedByFkeyExamTypesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<ExamTypeNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<ExamTypeExamTypesPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<ExamTypeExamTypesSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnExamTypeForExamTypesUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<ExamTypeOnExamTypeForExamTypesUpdatedByFkeyUsingExamTypesPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<ExamTypeOnExamTypeForExamTypesUpdatedByFkeyUsingExamTypesSlugUkeyUpdate> | null | undefined;
};
export type ExamTypesUpdatedByFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypesUsersExamTypeIdFkeyInverseInput = {
  connectById?: ReadonlyArray<ExamTypesUserNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<ExamTypesUserExamTypesUsersPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<ExamTypesUserExamTypesUsersSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<ExamTypesUsersExamTypeIdFkeyExamTypesUsersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<ExamTypesUserNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<ExamTypesUserExamTypesUsersPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<ExamTypesUserExamTypesUsersSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnExamTypesUserForExamTypesUsersExamTypeIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<ExamTypesUserOnExamTypesUserForExamTypesUsersExamTypeIdFkeyUsingExamTypesUsersPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<ExamTypesUserOnExamTypesUserForExamTypesUsersExamTypeIdFkeyUsingExamTypesUsersSlugUkeyUpdate> | null | undefined;
};
export type ExamTypesUserNodeIdConnect = {
  id: string;
};
export type ExamTypesUserExamTypesUsersPkeyConnect = {
  rowId: any;
};
export type ExamTypesUserExamTypesUsersSlugUkeyConnect = {
  slug: string;
};
export type ExamTypesUsersExamTypeIdFkeyExamTypesUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesUsersCreatedByFkeyInput | null | undefined;
  examDateAt?: any | null | undefined;
  examType?: ExamTypesUsersExamTypeIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUsersUpdatedByFkeyInput | null | undefined;
  user?: ExamTypesUsersUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type ExamTypesUsersCreatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: ExamTypesUsersCreatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: ExamTypesUserOnExamTypesUserForExamTypesUsersCreatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnExamTypesUserForExamTypesUsersCreatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnExamTypesUserForExamTypesUsersCreatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type ExamTypesUsersCreatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type ExamTypesUsersCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<ExamTypesUserNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<ExamTypesUserExamTypesUsersPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<ExamTypesUserExamTypesUsersSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<ExamTypesUsersCreatedByFkeyExamTypesUsersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<ExamTypesUserNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<ExamTypesUserExamTypesUsersPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<ExamTypesUserExamTypesUsersSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnExamTypesUserForExamTypesUsersCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<ExamTypesUserOnExamTypesUserForExamTypesUsersCreatedByFkeyUsingExamTypesUsersPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<ExamTypesUserOnExamTypesUserForExamTypesUsersCreatedByFkeyUsingExamTypesUsersSlugUkeyUpdate> | null | undefined;
};
export type ExamTypesUsersCreatedByFkeyExamTypesUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: ExamTypesUsersCreatedByFkeyInput | null | undefined;
  examDateAt?: any | null | undefined;
  examType?: ExamTypesUsersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUsersUpdatedByFkeyInput | null | undefined;
  user?: ExamTypesUsersUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type ExamTypesUsersExamTypeIdFkeyInput = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: ExamTypesUsersExamTypeIdFkeyExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: ExamTypesUserOnExamTypesUserForExamTypesUsersExamTypeIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnExamTypesUserForExamTypesUsersExamTypeIdFkeyUsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnExamTypesUserForExamTypesUsersExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type ExamTypesUsersExamTypeIdFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type GeneratedExplanationsExamTypeIdFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedExplanationNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedExplanationsExamTypeIdFkeyGeneratedExplanationsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedExplanationNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyUsingGeneratedExplanationsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyUsingGeneratedExplanationsSlugUkeyUpdate> | null | undefined;
};
export type GeneratedExplanationNodeIdConnect = {
  id: string;
};
export type GeneratedExplanationGeneratedExplanationsPkeyConnect = {
  rowId: any;
};
export type GeneratedExplanationGeneratedExplanationsSlugUkeyConnect = {
  slug: string;
};
export type GeneratedExplanationsExamTypeIdFkeyGeneratedExplanationsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedExplanationsExamTypeIdFkeyInput | null | undefined;
  generativeAiResponse?: GeneratedExplanationsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  question?: GeneratedExplanationsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  slug: string;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedExplanationsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedExplanationsExamTypeIdFkeyInput = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: GeneratedExplanationsExamTypeIdFkeyExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyUsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type GeneratedExplanationsExamTypeIdFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type GeneratedQuestionsExamTypeIdFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedQuestionsExamTypeIdFkeyGeneratedQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyUsingGeneratedQuestionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyUsingGeneratedQuestionsSlugUkeyUpdate> | null | undefined;
};
export type GeneratedQuestionNodeIdConnect = {
  id: string;
};
export type GeneratedQuestionGeneratedQuestionsPkeyConnect = {
  rowId: any;
};
export type GeneratedQuestionGeneratedQuestionsSlugUkeyConnect = {
  slug: string;
};
export type GeneratedQuestionsExamTypeIdFkeyGeneratedQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  explanation: string;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug: string;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionsExamTypeIdFkeyInput = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: GeneratedQuestionsExamTypeIdFkeyExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyUsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type GeneratedQuestionsExamTypeIdFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type QuestionAnswersExamTypeIdFkeyInverseInput = {
  connectById?: ReadonlyArray<QuestionAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<QuestionAnswerQuestionAnswersPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<QuestionAnswerQuestionAnswersSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<QuestionAnswersExamTypeIdFkeyQuestionAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<QuestionAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<QuestionAnswerQuestionAnswersPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<QuestionAnswerQuestionAnswersSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<QuestionAnswerOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyUsingQuestionAnswersPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<QuestionAnswerOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyUsingQuestionAnswersSlugUkeyUpdate> | null | undefined;
};
export type QuestionAnswerNodeIdConnect = {
  id: string;
};
export type QuestionAnswerQuestionAnswersPkeyConnect = {
  rowId: any;
};
export type QuestionAnswerQuestionAnswersSlugUkeyConnect = {
  slug: string;
};
export type QuestionAnswersExamTypeIdFkeyQuestionAnswersCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionAnswersCreatedByFkeyInput | null | undefined;
  examType?: QuestionAnswersExamTypeIdFkeyInput | null | undefined;
  isActive?: boolean | null | undefined;
  isCorrect?: boolean | null | undefined;
  question?: QuestionAnswersQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionAnswersUpdatedByFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionAnswerIdFkeyInverseInput | null | undefined;
};
export type QuestionAnswersExamTypeIdFkeyInput = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: QuestionAnswersExamTypeIdFkeyExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: QuestionAnswerOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyUsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type QuestionAnswersExamTypeIdFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type QuestionsExamTypeIdFkeyInverseInput = {
  connectById?: ReadonlyArray<QuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<QuestionQuestionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<QuestionQuestionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<QuestionsExamTypeIdFkeyQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<QuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<QuestionQuestionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<QuestionQuestionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnQuestionForQuestionsExamTypeIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<QuestionOnQuestionForQuestionsExamTypeIdFkeyUsingQuestionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<QuestionOnQuestionForQuestionsExamTypeIdFkeyUsingQuestionsSlugUkeyUpdate> | null | undefined;
};
export type QuestionNodeIdConnect = {
  id: string;
};
export type QuestionQuestionsPkeyConnect = {
  rowId: any;
};
export type QuestionQuestionsSlugUkeyConnect = {
  slug: string;
};
export type QuestionsExamTypeIdFkeyQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  explanation: string;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionsCreatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: QuestionsCreatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: QuestionOnQuestionForQuestionsCreatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnQuestionForQuestionsCreatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnQuestionForQuestionsCreatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type QuestionsCreatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type ExamTypesUsersUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<ExamTypesUserNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<ExamTypesUserExamTypesUsersPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<ExamTypesUserExamTypesUsersSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<ExamTypesUsersUpdatedByFkeyExamTypesUsersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<ExamTypesUserNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<ExamTypesUserExamTypesUsersPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<ExamTypesUserExamTypesUsersSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnExamTypesUserForExamTypesUsersUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<ExamTypesUserOnExamTypesUserForExamTypesUsersUpdatedByFkeyUsingExamTypesUsersPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<ExamTypesUserOnExamTypesUserForExamTypesUsersUpdatedByFkeyUsingExamTypesUsersSlugUkeyUpdate> | null | undefined;
};
export type ExamTypesUsersUpdatedByFkeyExamTypesUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesUsersCreatedByFkeyInput | null | undefined;
  examDateAt?: any | null | undefined;
  examType?: ExamTypesUsersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: ExamTypesUsersUpdatedByFkeyInput | null | undefined;
  user?: ExamTypesUsersUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type IntervalInput = {
  days?: number | null | undefined;
  hours?: number | null | undefined;
  minutes?: number | null | undefined;
  months?: number | null | undefined;
  seconds?: number | null | undefined;
  years?: number | null | undefined;
};
export type ExamTypesUsersUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: ExamTypesUsersUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: ExamTypesUserOnExamTypesUserForExamTypesUsersUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnExamTypesUserForExamTypesUsersUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnExamTypesUserForExamTypesUsersUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type ExamTypesUsersUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type ExamTypesUsersUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<ExamTypesUserNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<ExamTypesUserExamTypesUsersPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<ExamTypesUserExamTypesUsersSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<ExamTypesUsersUserIdFkeyExamTypesUsersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<ExamTypesUserNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<ExamTypesUserExamTypesUsersPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<ExamTypesUserExamTypesUsersSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnExamTypesUserForExamTypesUsersUserIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<ExamTypesUserOnExamTypesUserForExamTypesUsersUserIdFkeyUsingExamTypesUsersPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<ExamTypesUserOnExamTypesUserForExamTypesUsersUserIdFkeyUsingExamTypesUsersSlugUkeyUpdate> | null | undefined;
};
export type ExamTypesUsersUserIdFkeyExamTypesUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesUsersCreatedByFkeyInput | null | undefined;
  examDateAt?: any | null | undefined;
  examType?: ExamTypesUsersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUsersUpdatedByFkeyInput | null | undefined;
  user?: ExamTypesUsersUserIdFkeyInput | null | undefined;
};
export type ExamTypesUsersUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: ExamTypesUsersUserIdFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: ExamTypesUserOnExamTypesUserForExamTypesUsersUserIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnExamTypesUserForExamTypesUsersUserIdFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnExamTypesUserForExamTypesUsersUserIdFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type ExamTypesUsersUserIdFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type GeneratedAnswersCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedAnswerGeneratedAnswersPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedAnswerGeneratedAnswersSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedAnswersCreatedByFkeyGeneratedAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedAnswerGeneratedAnswersPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedAnswerGeneratedAnswersSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnGeneratedAnswerForGeneratedAnswersCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersCreatedByFkeyUsingGeneratedAnswersPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersCreatedByFkeyUsingGeneratedAnswersSlugUkeyUpdate> | null | undefined;
};
export type GeneratedAnswerNodeIdConnect = {
  id: string;
};
export type GeneratedAnswerGeneratedAnswersPkeyConnect = {
  rowId: any;
};
export type GeneratedAnswerGeneratedAnswersSlugUkeyConnect = {
  slug: string;
};
export type GeneratedAnswersCreatedByFkeyGeneratedAnswersCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  generatedQuestion?: GeneratedAnswersGeneratedQuestionIdFkeyInput | null | undefined;
  generatedQuestionId?: any | null | undefined;
  isCorrect: boolean;
  rowId?: any | null | undefined;
  slug: string;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedAnswersUpdatedByFkeyInput | null | undefined;
};
export type GeneratedAnswersGeneratedQuestionIdFkeyInput = {
  connectById?: GeneratedQuestionNodeIdConnect | null | undefined;
  connectByRowId?: GeneratedQuestionGeneratedQuestionsPkeyConnect | null | undefined;
  connectBySlug?: GeneratedQuestionGeneratedQuestionsSlugUkeyConnect | null | undefined;
  create?: GeneratedAnswersGeneratedQuestionIdFkeyGeneratedQuestionsCreateInput | null | undefined;
  deleteById?: GeneratedQuestionNodeIdDelete | null | undefined;
  deleteByRowId?: GeneratedQuestionGeneratedQuestionsPkeyDelete | null | undefined;
  deleteBySlug?: GeneratedQuestionGeneratedQuestionsSlugUkeyDelete | null | undefined;
  updateById?: GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: GeneratedQuestionOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyUsingGeneratedQuestionsPkeyUpdate | null | undefined;
  updateBySlug?: GeneratedQuestionOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyUsingGeneratedQuestionsSlugUkeyUpdate | null | undefined;
};
export type GeneratedAnswersGeneratedQuestionIdFkeyGeneratedQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug: string;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedAnswersGeneratedQuestionIdFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedAnswerGeneratedAnswersPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedAnswerGeneratedAnswersSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedAnswersGeneratedQuestionIdFkeyGeneratedAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedAnswerGeneratedAnswersPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedAnswerGeneratedAnswersSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<GeneratedQuestionOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyUsingGeneratedAnswersPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyUsingGeneratedAnswersSlugUkeyUpdate> | null | undefined;
};
export type GeneratedAnswersGeneratedQuestionIdFkeyGeneratedAnswersCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  generatedQuestion?: GeneratedAnswersGeneratedQuestionIdFkeyInput | null | undefined;
  isCorrect: boolean;
  rowId?: any | null | undefined;
  slug: string;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedAnswersUpdatedByFkeyInput | null | undefined;
};
export type GeneratedAnswersUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: GeneratedAnswersUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type GeneratedAnswersUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type GeneratedAnswersUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedAnswerGeneratedAnswersPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedAnswerGeneratedAnswersSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedAnswersUpdatedByFkeyGeneratedAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedAnswerGeneratedAnswersPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedAnswerGeneratedAnswersSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyUsingGeneratedAnswersPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyUsingGeneratedAnswersSlugUkeyUpdate> | null | undefined;
};
export type GeneratedAnswersUpdatedByFkeyGeneratedAnswersCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  generatedQuestion?: GeneratedAnswersGeneratedQuestionIdFkeyInput | null | undefined;
  generatedQuestionId?: any | null | undefined;
  isCorrect: boolean;
  rowId?: any | null | undefined;
  slug: string;
  updatedAt?: any | null | undefined;
  user?: GeneratedAnswersUpdatedByFkeyInput | null | undefined;
};
export type GeneratedAnswerNodeIdDelete = {
  id: string;
};
export type GeneratedAnswerGeneratedAnswersPkeyDelete = {
  rowId: any;
};
export type GeneratedAnswerGeneratedAnswersSlugUkeyDelete = {
  slug: string;
};
export type UserOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedAnswerPatch;
};
export type GeneratedAnswerPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  generatedQuestion?: GeneratedAnswersGeneratedQuestionIdFkeyInput | null | undefined;
  generatedQuestionId?: any | null | undefined;
  isCorrect?: boolean | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedAnswersUpdatedByFkeyInput | null | undefined;
};
export type GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyUsingGeneratedAnswersPkeyUpdate = {
  patch: updateGeneratedAnswerOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyPatch;
  rowId: any;
};
export type updateGeneratedAnswerOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  generatedQuestion?: GeneratedAnswersGeneratedQuestionIdFkeyInput | null | undefined;
  generatedQuestionId?: any | null | undefined;
  isCorrect?: boolean | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  user?: GeneratedAnswersUpdatedByFkeyInput | null | undefined;
};
export type GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyUsingGeneratedAnswersSlugUkeyUpdate = {
  patch: updateGeneratedAnswerOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyPatch;
  slug: string;
};
export type GeneratedExplanationsCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedExplanationNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedExplanationsCreatedByFkeyGeneratedExplanationsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedExplanationNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnGeneratedExplanationForGeneratedExplanationsCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsCreatedByFkeyUsingGeneratedExplanationsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsCreatedByFkeyUsingGeneratedExplanationsSlugUkeyUpdate> | null | undefined;
};
export type GeneratedExplanationsCreatedByFkeyGeneratedExplanationsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  examType?: GeneratedExplanationsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generativeAiResponse?: GeneratedExplanationsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  question?: GeneratedExplanationsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  slug: string;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedExplanationsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedExplanationsGenerativeAiResponseIdFkeyInput = {
  connectById?: GenerativeAiResponseNodeIdConnect | null | undefined;
  connectByRowId?: GenerativeAiResponseGenerativeAiResponsesPkeyConnect | null | undefined;
  connectBySlug?: GenerativeAiResponseGenerativeAiResponsesSlugUkeyConnect | null | undefined;
  create?: GeneratedExplanationsGenerativeAiResponseIdFkeyGenerativeAiResponsesCreateInput | null | undefined;
  deleteById?: GenerativeAiResponseNodeIdDelete | null | undefined;
  deleteByRowId?: GenerativeAiResponseGenerativeAiResponsesPkeyDelete | null | undefined;
  deleteBySlug?: GenerativeAiResponseGenerativeAiResponsesSlugUkeyDelete | null | undefined;
  updateById?: GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: GenerativeAiResponseOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyUsingGenerativeAiResponsesPkeyUpdate | null | undefined;
  updateBySlug?: GenerativeAiResponseOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyUsingGenerativeAiResponsesSlugUkeyUpdate | null | undefined;
};
export type GenerativeAiResponseNodeIdConnect = {
  id: string;
};
export type GenerativeAiResponseGenerativeAiResponsesPkeyConnect = {
  rowId: any;
};
export type GenerativeAiResponseGenerativeAiResponsesSlugUkeyConnect = {
  slug: string;
};
export type GeneratedExplanationsGenerativeAiResponseIdFkeyGenerativeAiResponsesCreateInput = {
  context?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  genType: string;
  generatedExplanations?: GeneratedExplanationsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  model: string;
  prompt: string;
  result?: string | null | undefined;
  rowId?: any | null | undefined;
  slug: string;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GenerativeAiResponsesUpdatedByFkeyInput | null | undefined;
};
export type GeneratedExplanationsGenerativeAiResponseIdFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedExplanationNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedExplanationsGenerativeAiResponseIdFkeyGeneratedExplanationsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedExplanationNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<GenerativeAiResponseOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyUsingGeneratedExplanationsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyUsingGeneratedExplanationsSlugUkeyUpdate> | null | undefined;
};
export type GeneratedExplanationsGenerativeAiResponseIdFkeyGeneratedExplanationsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedExplanationsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generativeAiResponse?: GeneratedExplanationsGenerativeAiResponseIdFkeyInput | null | undefined;
  question?: GeneratedExplanationsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  slug: string;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedExplanationsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedExplanationsQuestionIdFkeyInput = {
  connectById?: QuestionNodeIdConnect | null | undefined;
  connectByRowId?: QuestionQuestionsPkeyConnect | null | undefined;
  connectBySlug?: QuestionQuestionsSlugUkeyConnect | null | undefined;
  create?: GeneratedExplanationsQuestionIdFkeyQuestionsCreateInput | null | undefined;
  deleteById?: QuestionNodeIdDelete | null | undefined;
  deleteByRowId?: QuestionQuestionsPkeyDelete | null | undefined;
  deleteBySlug?: QuestionQuestionsSlugUkeyDelete | null | undefined;
  updateById?: GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: QuestionOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyUsingQuestionsPkeyUpdate | null | undefined;
  updateBySlug?: QuestionOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyUsingQuestionsSlugUkeyUpdate | null | undefined;
};
export type GeneratedExplanationsQuestionIdFkeyQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionsExamTypeIdFkeyInput = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: QuestionsExamTypeIdFkeyExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: QuestionOnQuestionForQuestionsExamTypeIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnQuestionForQuestionsExamTypeIdFkeyUsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnQuestionForQuestionsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type QuestionsExamTypeIdFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type TopicObjectivesExamTypeIdFkeyInverseInput = {
  connectById?: ReadonlyArray<TopicObjectiveNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<TopicObjectiveTopicObjectivesPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<TopicObjectiveTopicObjectivesSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<TopicObjectivesExamTypeIdFkeyTopicObjectivesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<TopicObjectiveNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<TopicObjectiveTopicObjectivesPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<TopicObjectiveTopicObjectivesSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<TopicObjectiveOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyUsingTopicObjectivesPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<TopicObjectiveOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyUsingTopicObjectivesSlugUkeyUpdate> | null | undefined;
};
export type TopicObjectiveNodeIdConnect = {
  id: string;
};
export type TopicObjectiveTopicObjectivesPkeyConnect = {
  rowId: any;
};
export type TopicObjectiveTopicObjectivesSlugUkeyConnect = {
  slug: string;
};
export type TopicObjectivesExamTypeIdFkeyTopicObjectivesCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectivesCreatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: TopicObjectivesCreatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: TopicObjectiveOnTopicObjectiveForTopicObjectivesCreatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnTopicObjectiveForTopicObjectivesCreatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnTopicObjectiveForTopicObjectivesCreatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type TopicObjectivesCreatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type GeneratedExplanationsUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedExplanationNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedExplanationsUpdatedByFkeyGeneratedExplanationsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedExplanationNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyUsingGeneratedExplanationsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyUsingGeneratedExplanationsSlugUkeyUpdate> | null | undefined;
};
export type GeneratedExplanationsUpdatedByFkeyGeneratedExplanationsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedExplanationsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generativeAiResponse?: GeneratedExplanationsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  question?: GeneratedExplanationsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  slug: string;
  updatedAt?: any | null | undefined;
  user?: GeneratedExplanationsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedExplanationsUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: GeneratedExplanationsUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type GeneratedExplanationsUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type GeneratedQuestionsCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedQuestionsCreatedByFkeyGeneratedQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnGeneratedQuestionForGeneratedQuestionsCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsCreatedByFkeyUsingGeneratedQuestionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsCreatedByFkeyUsingGeneratedQuestionsSlugUkeyUpdate> | null | undefined;
};
export type GeneratedQuestionsCreatedByFkeyGeneratedQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug: string;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionsGenerativeAiResponseIdFkeyInput = {
  connectById?: GenerativeAiResponseNodeIdConnect | null | undefined;
  connectByRowId?: GenerativeAiResponseGenerativeAiResponsesPkeyConnect | null | undefined;
  connectBySlug?: GenerativeAiResponseGenerativeAiResponsesSlugUkeyConnect | null | undefined;
  create?: GeneratedQuestionsGenerativeAiResponseIdFkeyGenerativeAiResponsesCreateInput | null | undefined;
  deleteById?: GenerativeAiResponseNodeIdDelete | null | undefined;
  deleteByRowId?: GenerativeAiResponseGenerativeAiResponsesPkeyDelete | null | undefined;
  deleteBySlug?: GenerativeAiResponseGenerativeAiResponsesSlugUkeyDelete | null | undefined;
  updateById?: GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: GenerativeAiResponseOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyUsingGenerativeAiResponsesPkeyUpdate | null | undefined;
  updateBySlug?: GenerativeAiResponseOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyUsingGenerativeAiResponsesSlugUkeyUpdate | null | undefined;
};
export type GeneratedQuestionsGenerativeAiResponseIdFkeyGenerativeAiResponsesCreateInput = {
  context?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  genType: string;
  generatedExplanations?: GeneratedExplanationsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  model: string;
  prompt: string;
  result?: string | null | undefined;
  rowId?: any | null | undefined;
  slug: string;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GenerativeAiResponsesUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionsGenerativeAiResponseIdFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedQuestionsGenerativeAiResponseIdFkeyGeneratedQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<GenerativeAiResponseOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyUsingGeneratedQuestionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyUsingGeneratedQuestionsSlugUkeyUpdate> | null | undefined;
};
export type GeneratedQuestionsGenerativeAiResponseIdFkeyGeneratedQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug: string;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionsQuestionIdFkeyInput = {
  connectById?: QuestionNodeIdConnect | null | undefined;
  connectByRowId?: QuestionQuestionsPkeyConnect | null | undefined;
  connectBySlug?: QuestionQuestionsSlugUkeyConnect | null | undefined;
  create?: GeneratedQuestionsQuestionIdFkeyQuestionsCreateInput | null | undefined;
  deleteById?: QuestionNodeIdDelete | null | undefined;
  deleteByRowId?: QuestionQuestionsPkeyDelete | null | undefined;
  deleteBySlug?: QuestionQuestionsSlugUkeyDelete | null | undefined;
  updateById?: GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: QuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyUsingQuestionsPkeyUpdate | null | undefined;
  updateBySlug?: QuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyUsingQuestionsSlugUkeyUpdate | null | undefined;
};
export type GeneratedQuestionsQuestionIdFkeyQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type GeneratedExplanationsQuestionIdFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedExplanationNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedExplanationsQuestionIdFkeyGeneratedExplanationsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedExplanationNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedExplanationGeneratedExplanationsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<QuestionOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyUsingGeneratedExplanationsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyUsingGeneratedExplanationsSlugUkeyUpdate> | null | undefined;
};
export type GeneratedExplanationsQuestionIdFkeyGeneratedExplanationsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedExplanationsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generativeAiResponse?: GeneratedExplanationsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  question?: GeneratedExplanationsQuestionIdFkeyInput | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  slug: string;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedExplanationsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedExplanationNodeIdDelete = {
  id: string;
};
export type GeneratedExplanationGeneratedExplanationsPkeyDelete = {
  rowId: any;
};
export type GeneratedExplanationGeneratedExplanationsSlugUkeyDelete = {
  slug: string;
};
export type QuestionOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedExplanationPatch;
};
export type GeneratedExplanationPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedExplanationsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generativeAiResponse?: GeneratedExplanationsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  question?: GeneratedExplanationsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedExplanationsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyUsingGeneratedExplanationsPkeyUpdate = {
  patch: updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyPatch;
  rowId: any;
};
export type updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedExplanationsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generativeAiResponse?: GeneratedExplanationsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  question?: GeneratedExplanationsQuestionIdFkeyInput | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedExplanationsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyUsingGeneratedExplanationsSlugUkeyUpdate = {
  patch: updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyPatch;
  slug: string;
};
export type GeneratedQuestionsQuestionIdFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedQuestionsQuestionIdFkeyGeneratedQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<QuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyUsingGeneratedQuestionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyUsingGeneratedQuestionsSlugUkeyUpdate> | null | undefined;
};
export type GeneratedQuestionsQuestionIdFkeyGeneratedQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug: string;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionsTopicIdFkeyInput = {
  connectById?: TopicNodeIdConnect | null | undefined;
  connectByRowId?: TopicTopicsPkeyConnect | null | undefined;
  connectBySlug?: TopicTopicsSlugUkeyConnect | null | undefined;
  create?: GeneratedQuestionsTopicIdFkeyTopicsCreateInput | null | undefined;
  deleteById?: TopicNodeIdDelete | null | undefined;
  deleteByRowId?: TopicTopicsPkeyDelete | null | undefined;
  deleteBySlug?: TopicTopicsSlugUkeyDelete | null | undefined;
  updateById?: GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyUsingTopicsPkeyUpdate | null | undefined;
  updateBySlug?: TopicOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyUsingTopicsSlugUkeyUpdate | null | undefined;
};
export type TopicNodeIdConnect = {
  id: string;
};
export type TopicTopicsPkeyConnect = {
  rowId: any;
};
export type TopicTopicsSlugUkeyConnect = {
  slug: string;
};
export type GeneratedQuestionsTopicIdFkeyTopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicsCreatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: TopicsCreatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: TopicOnTopicForTopicsCreatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnTopicForTopicsCreatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnTopicForTopicsCreatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type TopicsCreatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type GeneratedQuestionsUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedQuestionsUpdatedByFkeyGeneratedQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyUsingGeneratedQuestionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyUsingGeneratedQuestionsSlugUkeyUpdate> | null | undefined;
};
export type GeneratedQuestionsUpdatedByFkeyGeneratedQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug: string;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionsTopicObjectiveIdFkeyInput = {
  connectById?: TopicObjectiveNodeIdConnect | null | undefined;
  connectByRowId?: TopicObjectiveTopicObjectivesPkeyConnect | null | undefined;
  connectBySlug?: TopicObjectiveTopicObjectivesSlugUkeyConnect | null | undefined;
  create?: GeneratedQuestionsTopicObjectiveIdFkeyTopicObjectivesCreateInput | null | undefined;
  deleteById?: TopicObjectiveNodeIdDelete | null | undefined;
  deleteByRowId?: TopicObjectiveTopicObjectivesPkeyDelete | null | undefined;
  deleteBySlug?: TopicObjectiveTopicObjectivesSlugUkeyDelete | null | undefined;
  updateById?: GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicObjectiveOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyUsingTopicObjectivesPkeyUpdate | null | undefined;
  updateBySlug?: TopicObjectiveOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyUsingTopicObjectivesSlugUkeyUpdate | null | undefined;
};
export type GeneratedQuestionsTopicObjectiveIdFkeyTopicObjectivesCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectivesExamTypeIdFkeyInput = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: TopicObjectivesExamTypeIdFkeyExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: TopicObjectiveOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyUsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type TopicObjectivesExamTypeIdFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type TopicsExamTypeIdFkeyInverseInput = {
  connectById?: ReadonlyArray<TopicNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<TopicTopicsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<TopicTopicsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<TopicsExamTypeIdFkeyTopicsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<TopicNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<TopicTopicsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<TopicTopicsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnTopicForTopicsExamTypeIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<TopicOnTopicForTopicsExamTypeIdFkeyUsingTopicsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<TopicOnTopicForTopicsExamTypeIdFkeyUsingTopicsSlugUkeyUpdate> | null | undefined;
};
export type TopicsExamTypeIdFkeyTopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicsExamTypeIdFkeyInput = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: TopicsExamTypeIdFkeyExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: TopicOnTopicForTopicsExamTypeIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnTopicForTopicsExamTypeIdFkeyUsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnTopicForTopicsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type TopicsExamTypeIdFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypesUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: ExamTypesUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: ExamTypeOnExamTypeForExamTypesUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnExamTypeForExamTypesUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnExamTypeForExamTypesUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type ExamTypesUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type GenerativeAiResponsesCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<GenerativeAiResponseNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GenerativeAiResponseGenerativeAiResponsesPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GenerativeAiResponseGenerativeAiResponsesSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GenerativeAiResponsesCreatedByFkeyGenerativeAiResponsesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GenerativeAiResponseNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GenerativeAiResponseGenerativeAiResponsesPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GenerativeAiResponseGenerativeAiResponsesSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnGenerativeAiResponseForGenerativeAiResponsesCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesCreatedByFkeyUsingGenerativeAiResponsesPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesCreatedByFkeyUsingGenerativeAiResponsesSlugUkeyUpdate> | null | undefined;
};
export type GenerativeAiResponsesCreatedByFkeyGenerativeAiResponsesCreateInput = {
  context?: any | null | undefined;
  createdAt?: any | null | undefined;
  genType: string;
  generatedExplanations?: GeneratedExplanationsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  model: string;
  prompt: string;
  result?: string | null | undefined;
  rowId?: any | null | undefined;
  slug: string;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GenerativeAiResponsesUpdatedByFkeyInput | null | undefined;
};
export type GenerativeAiResponsesUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: GenerativeAiResponsesUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: GenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type GenerativeAiResponsesUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type GenerativeAiResponsesUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<GenerativeAiResponseNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GenerativeAiResponseGenerativeAiResponsesPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GenerativeAiResponseGenerativeAiResponsesSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GenerativeAiResponsesUpdatedByFkeyGenerativeAiResponsesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GenerativeAiResponseNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GenerativeAiResponseGenerativeAiResponsesPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GenerativeAiResponseGenerativeAiResponsesSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyUsingGenerativeAiResponsesPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyUsingGenerativeAiResponsesSlugUkeyUpdate> | null | undefined;
};
export type GenerativeAiResponsesUpdatedByFkeyGenerativeAiResponsesCreateInput = {
  context?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  genType: string;
  generatedExplanations?: GeneratedExplanationsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  model: string;
  prompt: string;
  result?: string | null | undefined;
  rowId?: any | null | undefined;
  slug: string;
  updatedAt?: any | null | undefined;
  user?: GenerativeAiResponsesUpdatedByFkeyInput | null | undefined;
};
export type GenerativeAiResponseNodeIdDelete = {
  id: string;
};
export type GenerativeAiResponseGenerativeAiResponsesPkeyDelete = {
  rowId: any;
};
export type GenerativeAiResponseGenerativeAiResponsesSlugUkeyDelete = {
  slug: string;
};
export type UserOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: GenerativeAiResponsePatch;
};
export type GenerativeAiResponsePatch = {
  context?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  genType?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  model?: string | null | undefined;
  prompt?: string | null | undefined;
  result?: string | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GenerativeAiResponsesUpdatedByFkeyInput | null | undefined;
};
export type GenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyUsingGenerativeAiResponsesPkeyUpdate = {
  patch: updateGenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyPatch;
  rowId: any;
};
export type updateGenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyPatch = {
  context?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  genType?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  model?: string | null | undefined;
  prompt?: string | null | undefined;
  result?: string | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  user?: GenerativeAiResponsesUpdatedByFkeyInput | null | undefined;
};
export type GenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyUsingGenerativeAiResponsesSlugUkeyUpdate = {
  patch: updateGenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyPatch;
  slug: string;
};
export type QuestionAnswersCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<QuestionAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<QuestionAnswerQuestionAnswersPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<QuestionAnswerQuestionAnswersSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<QuestionAnswersCreatedByFkeyQuestionAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<QuestionAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<QuestionAnswerQuestionAnswersPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<QuestionAnswerQuestionAnswersSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnQuestionAnswerForQuestionAnswersCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<QuestionAnswerOnQuestionAnswerForQuestionAnswersCreatedByFkeyUsingQuestionAnswersPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<QuestionAnswerOnQuestionAnswerForQuestionAnswersCreatedByFkeyUsingQuestionAnswersSlugUkeyUpdate> | null | undefined;
};
export type QuestionAnswersCreatedByFkeyQuestionAnswersCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdByUser?: QuestionAnswersCreatedByFkeyInput | null | undefined;
  examType?: QuestionAnswersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  isActive?: boolean | null | undefined;
  isCorrect?: boolean | null | undefined;
  question?: QuestionAnswersQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionAnswersUpdatedByFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionAnswerIdFkeyInverseInput | null | undefined;
};
export type QuestionAnswersQuestionIdFkeyInput = {
  connectById?: QuestionNodeIdConnect | null | undefined;
  connectByRowId?: QuestionQuestionsPkeyConnect | null | undefined;
  connectBySlug?: QuestionQuestionsSlugUkeyConnect | null | undefined;
  create?: QuestionAnswersQuestionIdFkeyQuestionsCreateInput | null | undefined;
  deleteById?: QuestionNodeIdDelete | null | undefined;
  deleteByRowId?: QuestionQuestionsPkeyDelete | null | undefined;
  deleteBySlug?: QuestionQuestionsSlugUkeyDelete | null | undefined;
  updateById?: QuestionAnswerOnQuestionAnswerForQuestionAnswersQuestionIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: QuestionOnQuestionAnswerForQuestionAnswersQuestionIdFkeyUsingQuestionsPkeyUpdate | null | undefined;
  updateBySlug?: QuestionOnQuestionAnswerForQuestionAnswersQuestionIdFkeyUsingQuestionsSlugUkeyUpdate | null | undefined;
};
export type QuestionAnswersQuestionIdFkeyQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionAnswersQuestionIdFkeyInverseInput = {
  connectById?: ReadonlyArray<QuestionAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<QuestionAnswerQuestionAnswersPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<QuestionAnswerQuestionAnswersSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<QuestionAnswersQuestionIdFkeyQuestionAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<QuestionAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<QuestionAnswerQuestionAnswersPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<QuestionAnswerQuestionAnswersSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<QuestionOnQuestionAnswerForQuestionAnswersQuestionIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<QuestionAnswerOnQuestionAnswerForQuestionAnswersQuestionIdFkeyUsingQuestionAnswersPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<QuestionAnswerOnQuestionAnswerForQuestionAnswersQuestionIdFkeyUsingQuestionAnswersSlugUkeyUpdate> | null | undefined;
};
export type QuestionAnswersQuestionIdFkeyQuestionAnswersCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionAnswersCreatedByFkeyInput | null | undefined;
  examType?: QuestionAnswersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  isActive?: boolean | null | undefined;
  isCorrect?: boolean | null | undefined;
  question?: QuestionAnswersQuestionIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionAnswersUpdatedByFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionAnswerIdFkeyInverseInput | null | undefined;
};
export type QuestionAnswersUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: QuestionAnswersUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: QuestionAnswerOnQuestionAnswerForQuestionAnswersUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnQuestionAnswerForQuestionAnswersUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnQuestionAnswerForQuestionAnswersUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type QuestionAnswersUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type QuestionAnswersUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<QuestionAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<QuestionAnswerQuestionAnswersPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<QuestionAnswerQuestionAnswersSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<QuestionAnswersUpdatedByFkeyQuestionAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<QuestionAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<QuestionAnswerQuestionAnswersPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<QuestionAnswerQuestionAnswersSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnQuestionAnswerForQuestionAnswersUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<QuestionAnswerOnQuestionAnswerForQuestionAnswersUpdatedByFkeyUsingQuestionAnswersPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<QuestionAnswerOnQuestionAnswerForQuestionAnswersUpdatedByFkeyUsingQuestionAnswersSlugUkeyUpdate> | null | undefined;
};
export type QuestionAnswersUpdatedByFkeyQuestionAnswersCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionAnswersCreatedByFkeyInput | null | undefined;
  examType?: QuestionAnswersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  isActive?: boolean | null | undefined;
  isCorrect?: boolean | null | undefined;
  question?: QuestionAnswersQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: QuestionAnswersUpdatedByFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionAnswerIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionQuestionsQuestionAnswerIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserStudySessionQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserStudySessionQuestionsQuestionAnswerIdFkeyUserStudySessionQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserStudySessionQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<QuestionAnswerOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyUsingUserStudySessionQuestionsPkeyUpdate> | null | undefined;
};
export type UserStudySessionQuestionNodeIdConnect = {
  id: string;
};
export type UserStudySessionQuestionUserStudySessionQuestionsPkeyConnect = {
  rowId: any;
};
export type UserStudySessionQuestionsQuestionAnswerIdFkeyUserStudySessionQuestionsCreateInput = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserStudySessionQuestionsCreatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserStudySessionQuestionsCreatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserStudySessionQuestionsCreatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type QuestionsCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<QuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<QuestionQuestionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<QuestionQuestionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<QuestionsCreatedByFkeyQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<QuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<QuestionQuestionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<QuestionQuestionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnQuestionForQuestionsCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<QuestionOnQuestionForQuestionsCreatedByFkeyUsingQuestionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<QuestionOnQuestionForQuestionsCreatedByFkeyUsingQuestionsSlugUkeyUpdate> | null | undefined;
};
export type QuestionsCreatedByFkeyQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionsTopicIdFkeyInput = {
  connectById?: TopicNodeIdConnect | null | undefined;
  connectByRowId?: TopicTopicsPkeyConnect | null | undefined;
  connectBySlug?: TopicTopicsSlugUkeyConnect | null | undefined;
  create?: QuestionsTopicIdFkeyTopicsCreateInput | null | undefined;
  deleteById?: TopicNodeIdDelete | null | undefined;
  deleteByRowId?: TopicTopicsPkeyDelete | null | undefined;
  deleteBySlug?: TopicTopicsSlugUkeyDelete | null | undefined;
  updateById?: QuestionOnQuestionForQuestionsTopicIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicOnQuestionForQuestionsTopicIdFkeyUsingTopicsPkeyUpdate | null | undefined;
  updateBySlug?: TopicOnQuestionForQuestionsTopicIdFkeyUsingTopicsSlugUkeyUpdate | null | undefined;
};
export type QuestionsTopicIdFkeyTopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type GeneratedQuestionsTopicIdFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedQuestionsTopicIdFkeyGeneratedQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyUsingGeneratedQuestionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyUsingGeneratedQuestionsSlugUkeyUpdate> | null | undefined;
};
export type GeneratedQuestionsTopicIdFkeyGeneratedQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug: string;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionsUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: GeneratedQuestionsUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type GeneratedQuestionsUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type QuestionsUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<QuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<QuestionQuestionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<QuestionQuestionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<QuestionsUpdatedByFkeyQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<QuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<QuestionQuestionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<QuestionQuestionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnQuestionForQuestionsUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<QuestionOnQuestionForQuestionsUpdatedByFkeyUsingQuestionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<QuestionOnQuestionForQuestionsUpdatedByFkeyUsingQuestionsSlugUkeyUpdate> | null | undefined;
};
export type QuestionsUpdatedByFkeyQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionsTopicObjectiveIdFkeyInput = {
  connectById?: TopicObjectiveNodeIdConnect | null | undefined;
  connectByRowId?: TopicObjectiveTopicObjectivesPkeyConnect | null | undefined;
  connectBySlug?: TopicObjectiveTopicObjectivesSlugUkeyConnect | null | undefined;
  create?: QuestionsTopicObjectiveIdFkeyTopicObjectivesCreateInput | null | undefined;
  deleteById?: TopicObjectiveNodeIdDelete | null | undefined;
  deleteByRowId?: TopicObjectiveTopicObjectivesPkeyDelete | null | undefined;
  deleteBySlug?: TopicObjectiveTopicObjectivesSlugUkeyDelete | null | undefined;
  updateById?: QuestionOnQuestionForQuestionsTopicObjectiveIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicObjectiveOnQuestionForQuestionsTopicObjectiveIdFkeyUsingTopicObjectivesPkeyUpdate | null | undefined;
  updateBySlug?: TopicObjectiveOnQuestionForQuestionsTopicObjectiveIdFkeyUsingTopicObjectivesSlugUkeyUpdate | null | undefined;
};
export type QuestionsTopicObjectiveIdFkeyTopicObjectivesCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type GeneratedQuestionsTopicObjectiveIdFkeyInverseInput = {
  connectById?: ReadonlyArray<GeneratedQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<GeneratedQuestionsTopicObjectiveIdFkeyGeneratedQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<GeneratedQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<GeneratedQuestionGeneratedQuestionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicObjectiveOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyUsingGeneratedQuestionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyUsingGeneratedQuestionsSlugUkeyUpdate> | null | undefined;
};
export type GeneratedQuestionsTopicObjectiveIdFkeyGeneratedQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus: GeneratedContentReviewStatus;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug: string;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionNodeIdDelete = {
  id: string;
};
export type GeneratedQuestionGeneratedQuestionsPkeyDelete = {
  rowId: any;
};
export type GeneratedQuestionGeneratedQuestionsSlugUkeyDelete = {
  slug: string;
};
export type TopicObjectiveOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedQuestionPatch;
};
export type GeneratedQuestionPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyUsingGeneratedQuestionsPkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyPatch;
  rowId: any;
};
export type updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyUsingGeneratedQuestionsSlugUkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyPatch;
  slug: string;
};
export type QuestionsTopicObjectiveIdFkeyInverseInput = {
  connectById?: ReadonlyArray<QuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<QuestionQuestionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<QuestionQuestionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<QuestionsTopicObjectiveIdFkeyQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<QuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<QuestionQuestionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<QuestionQuestionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicObjectiveOnQuestionForQuestionsTopicObjectiveIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<QuestionOnQuestionForQuestionsTopicObjectiveIdFkeyUsingQuestionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<QuestionOnQuestionForQuestionsTopicObjectiveIdFkeyUsingQuestionsSlugUkeyUpdate> | null | undefined;
};
export type QuestionsTopicObjectiveIdFkeyQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionsUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: QuestionsUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: QuestionOnQuestionForQuestionsUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnQuestionForQuestionsUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnQuestionForQuestionsUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type QuestionsUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type TopicObjectivesCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<TopicObjectiveNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<TopicObjectiveTopicObjectivesPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<TopicObjectiveTopicObjectivesSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<TopicObjectivesCreatedByFkeyTopicObjectivesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<TopicObjectiveNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<TopicObjectiveTopicObjectivesPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<TopicObjectiveTopicObjectivesSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnTopicObjectiveForTopicObjectivesCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<TopicObjectiveOnTopicObjectiveForTopicObjectivesCreatedByFkeyUsingTopicObjectivesPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<TopicObjectiveOnTopicObjectiveForTopicObjectivesCreatedByFkeyUsingTopicObjectivesSlugUkeyUpdate> | null | undefined;
};
export type TopicObjectivesCreatedByFkeyTopicObjectivesCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectivesTopicIdFkeyInput = {
  connectById?: TopicNodeIdConnect | null | undefined;
  connectByRowId?: TopicTopicsPkeyConnect | null | undefined;
  connectBySlug?: TopicTopicsSlugUkeyConnect | null | undefined;
  create?: TopicObjectivesTopicIdFkeyTopicsCreateInput | null | undefined;
  deleteById?: TopicNodeIdDelete | null | undefined;
  deleteByRowId?: TopicTopicsPkeyDelete | null | undefined;
  deleteBySlug?: TopicTopicsSlugUkeyDelete | null | undefined;
  updateById?: TopicObjectiveOnTopicObjectiveForTopicObjectivesTopicIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicOnTopicObjectiveForTopicObjectivesTopicIdFkeyUsingTopicsPkeyUpdate | null | undefined;
  updateBySlug?: TopicOnTopicObjectiveForTopicObjectivesTopicIdFkeyUsingTopicsSlugUkeyUpdate | null | undefined;
};
export type TopicObjectivesTopicIdFkeyTopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type QuestionsTopicIdFkeyInverseInput = {
  connectById?: ReadonlyArray<QuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<QuestionQuestionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<QuestionQuestionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<QuestionsTopicIdFkeyQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<QuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<QuestionQuestionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<QuestionQuestionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnQuestionForQuestionsTopicIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<QuestionOnQuestionForQuestionsTopicIdFkeyUsingQuestionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<QuestionOnQuestionForQuestionsTopicIdFkeyUsingQuestionsSlugUkeyUpdate> | null | undefined;
};
export type QuestionsTopicIdFkeyQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type UserLessonQuestionsQuestionIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserLessonQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserLessonQuestionUserLessonQuestionsPkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserLessonQuestionsQuestionIdFkeyUserLessonQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserLessonQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserLessonQuestionUserLessonQuestionsPkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<QuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyUsingUserLessonQuestionsPkeyUpdate> | null | undefined;
};
export type UserLessonQuestionNodeIdConnect = {
  id: string;
};
export type UserLessonQuestionUserLessonQuestionsPkeyConnect = {
  rowId: any;
};
export type UserLessonQuestionsQuestionIdFkeyUserLessonQuestionsCreateInput = {
  attempts?: number | null | undefined;
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserLessonQuestionsQuestionIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonQuestionsUpdatedByFkeyInput | null | undefined;
  userLesson?: UserLessonQuestionsUserLessonIdFkeyInput | null | undefined;
  userLessonId?: any | null | undefined;
};
export type UserLessonQuestionsCreatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserLessonQuestionsCreatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserLessonQuestionsCreatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type TopicObjectivesUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<TopicObjectiveNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<TopicObjectiveTopicObjectivesPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<TopicObjectiveTopicObjectivesSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<TopicObjectivesUpdatedByFkeyTopicObjectivesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<TopicObjectiveNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<TopicObjectiveTopicObjectivesPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<TopicObjectiveTopicObjectivesSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnTopicObjectiveForTopicObjectivesUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<TopicObjectiveOnTopicObjectiveForTopicObjectivesUpdatedByFkeyUsingTopicObjectivesPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<TopicObjectiveOnTopicObjectiveForTopicObjectivesUpdatedByFkeyUsingTopicObjectivesSlugUkeyUpdate> | null | undefined;
};
export type TopicObjectivesUpdatedByFkeyTopicObjectivesCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectivesUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: TopicObjectivesUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: TopicObjectiveOnTopicObjectiveForTopicObjectivesUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnTopicObjectiveForTopicObjectivesUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnTopicObjectiveForTopicObjectivesUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type TopicObjectivesUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type TopicsCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<TopicNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<TopicTopicsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<TopicTopicsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<TopicsCreatedByFkeyTopicsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<TopicNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<TopicTopicsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<TopicTopicsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnTopicForTopicsCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<TopicOnTopicForTopicsCreatedByFkeyUsingTopicsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<TopicOnTopicForTopicsCreatedByFkeyUsingTopicsSlugUkeyUpdate> | null | undefined;
};
export type TopicsCreatedByFkeyTopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicsParentTopicIdFkeyInput = {
  connectById?: TopicNodeIdConnect | null | undefined;
  connectByRowId?: TopicTopicsPkeyConnect | null | undefined;
  connectBySlug?: TopicTopicsSlugUkeyConnect | null | undefined;
  create?: TopicsParentTopicIdFkeyTopicsCreateInput | null | undefined;
  deleteById?: TopicNodeIdDelete | null | undefined;
  deleteByRowId?: TopicTopicsPkeyDelete | null | undefined;
  deleteBySlug?: TopicTopicsSlugUkeyDelete | null | undefined;
  updateById?: TopicOnTopicForTopicsParentTopicIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicOnTopicForTopicsParentTopicIdFkeyUsingTopicsPkeyUpdate | null | undefined;
  updateBySlug?: TopicOnTopicForTopicsParentTopicIdFkeyUsingTopicsSlugUkeyUpdate | null | undefined;
};
export type TopicsParentTopicIdFkeyTopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicObjectivesTopicIdFkeyInverseInput = {
  connectById?: ReadonlyArray<TopicObjectiveNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<TopicObjectiveTopicObjectivesPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<TopicObjectiveTopicObjectivesSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<TopicObjectivesTopicIdFkeyTopicObjectivesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<TopicObjectiveNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<TopicObjectiveTopicObjectivesPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<TopicObjectiveTopicObjectivesSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnTopicObjectiveForTopicObjectivesTopicIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<TopicObjectiveOnTopicObjectiveForTopicObjectivesTopicIdFkeyUsingTopicObjectivesPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<TopicObjectiveOnTopicObjectiveForTopicObjectivesTopicIdFkeyUsingTopicObjectivesSlugUkeyUpdate> | null | undefined;
};
export type TopicObjectivesTopicIdFkeyTopicObjectivesCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type UserLessonsTopicObjectiveIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserLessonNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserLessonsTopicObjectiveIdFkeyUserLessonsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserLessonNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicObjectiveOnUserLessonForUserLessonsTopicObjectiveIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsTopicObjectiveIdFkeyUsingUserLessonsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsTopicObjectiveIdFkeyUsingUserLessonsSlugUkeyUpdate> | null | undefined;
};
export type UserLessonNodeIdConnect = {
  id: string;
};
export type UserLessonUserLessonsPkeyConnect = {
  rowId: any;
};
export type UserLessonUserLessonsSlugUkeyConnect = {
  slug: string;
};
export type UserLessonsTopicObjectiveIdFkeyUserLessonsCreateInput = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonsCreatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserLessonsCreatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserLessonOnUserLessonForUserLessonsCreatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserLessonForUserLessonsCreatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserLessonForUserLessonsCreatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserLessonsCreatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type TopicsUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<TopicNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<TopicTopicsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<TopicTopicsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<TopicsUpdatedByFkeyTopicsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<TopicNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<TopicTopicsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<TopicTopicsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnTopicForTopicsUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<TopicOnTopicForTopicsUpdatedByFkeyUsingTopicsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<TopicOnTopicForTopicsUpdatedByFkeyUsingTopicsSlugUkeyUpdate> | null | undefined;
};
export type TopicsUpdatedByFkeyTopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicsUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: TopicsUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: TopicOnTopicForTopicsUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnTopicForTopicsUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnTopicForTopicsUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type TopicsUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UsersUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UsersUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserOnUserForUsersUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserForUsersUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserForUsersUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UsersUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserLessonQuestionsCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<UserLessonQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserLessonQuestionUserLessonQuestionsPkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserLessonQuestionsCreatedByFkeyUserLessonQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserLessonQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserLessonQuestionUserLessonQuestionsPkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyUsingUserLessonQuestionsPkeyUpdate> | null | undefined;
};
export type UserLessonQuestionsCreatedByFkeyUserLessonQuestionsCreateInput = {
  attempts?: number | null | undefined;
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdByUser?: UserLessonQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserLessonQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonQuestionsUpdatedByFkeyInput | null | undefined;
  userLesson?: UserLessonQuestionsUserLessonIdFkeyInput | null | undefined;
  userLessonId?: any | null | undefined;
};
export type UserLessonQuestionsQuestionIdFkeyInput = {
  connectById?: QuestionNodeIdConnect | null | undefined;
  connectByRowId?: QuestionQuestionsPkeyConnect | null | undefined;
  connectBySlug?: QuestionQuestionsSlugUkeyConnect | null | undefined;
  create?: UserLessonQuestionsQuestionIdFkeyQuestionsCreateInput | null | undefined;
  deleteById?: QuestionNodeIdDelete | null | undefined;
  deleteByRowId?: QuestionQuestionsPkeyDelete | null | undefined;
  deleteBySlug?: QuestionQuestionsSlugUkeyDelete | null | undefined;
  updateById?: UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: QuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyUsingQuestionsPkeyUpdate | null | undefined;
  updateBySlug?: QuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyUsingQuestionsSlugUkeyUpdate | null | undefined;
};
export type UserLessonQuestionsQuestionIdFkeyQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey1InverseInput = {
  connectById?: ReadonlyArray<UserQuestionAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyConnect> | null | undefined;
  create?: ReadonlyArray<FakeAppPublicUserQuestionAnswersForeignKey1UserQuestionAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserQuestionAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<QuestionOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1NodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1UsingFakeAppPublicUserQuestionAnswersPrimaryKeyUpdate> | null | undefined;
};
export type UserQuestionAnswerNodeIdConnect = {
  id: string;
};
export type UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyConnect = {
  rowId: any;
};
export type FakeAppPublicUserQuestionAnswersForeignKey1UserQuestionAnswersCreateInput = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  examTypeId?: any | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  rowId: any;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  topicObjectiveId?: any | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
  userId?: any | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey0Input = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: FakeAppPublicUserQuestionAnswersForeignKey0ExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0NodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0UsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0UsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey0ExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type UserLessonsExamTypeIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserLessonNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserLessonsExamTypeIdFkeyUserLessonsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserLessonNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnUserLessonForUserLessonsExamTypeIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsExamTypeIdFkeyUsingUserLessonsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsExamTypeIdFkeyUsingUserLessonsSlugUkeyUpdate> | null | undefined;
};
export type UserLessonsExamTypeIdFkeyUserLessonsCreateInput = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonsExamTypeIdFkeyInput = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: UserLessonsExamTypeIdFkeyExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: UserLessonOnUserLessonForUserLessonsExamTypeIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnUserLessonForUserLessonsExamTypeIdFkeyUsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnUserLessonForUserLessonsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type UserLessonsExamTypeIdFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey0InverseInput = {
  connectById?: ReadonlyArray<UserQuestionAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyConnect> | null | undefined;
  create?: ReadonlyArray<FakeAppPublicUserQuestionAnswersForeignKey0UserQuestionAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserQuestionAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0NodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0UsingFakeAppPublicUserQuestionAnswersPrimaryKeyUpdate> | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey0UserQuestionAnswersCreateInput = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  questionId?: any | null | undefined;
  rowId: any;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  topicObjectiveId?: any | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
  userId?: any | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey1Input = {
  connectById?: QuestionNodeIdConnect | null | undefined;
  connectByRowId?: QuestionQuestionsPkeyConnect | null | undefined;
  connectBySlug?: QuestionQuestionsSlugUkeyConnect | null | undefined;
  create?: FakeAppPublicUserQuestionAnswersForeignKey1QuestionsCreateInput | null | undefined;
  deleteById?: QuestionNodeIdDelete | null | undefined;
  deleteByRowId?: QuestionQuestionsPkeyDelete | null | undefined;
  deleteBySlug?: QuestionQuestionsSlugUkeyDelete | null | undefined;
  updateById?: UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1NodeIdUpdate | null | undefined;
  updateByRowId?: QuestionOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1UsingQuestionsPkeyUpdate | null | undefined;
  updateBySlug?: QuestionOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1UsingQuestionsSlugUkeyUpdate | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey1QuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionQuestionsQuestionIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserStudySessionQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserStudySessionQuestionsQuestionIdFkeyUserStudySessionQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserStudySessionQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<QuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyUsingUserStudySessionQuestionsPkeyUpdate> | null | undefined;
};
export type UserStudySessionQuestionsQuestionIdFkeyUserStudySessionQuestionsCreateInput = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserStudySessionQuestionsQuestionIdFkeyInput = {
  connectById?: QuestionNodeIdConnect | null | undefined;
  connectByRowId?: QuestionQuestionsPkeyConnect | null | undefined;
  connectBySlug?: QuestionQuestionsSlugUkeyConnect | null | undefined;
  create?: UserStudySessionQuestionsQuestionIdFkeyQuestionsCreateInput | null | undefined;
  deleteById?: QuestionNodeIdDelete | null | undefined;
  deleteByRowId?: QuestionQuestionsPkeyDelete | null | undefined;
  deleteBySlug?: QuestionQuestionsSlugUkeyDelete | null | undefined;
  updateById?: UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: QuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyUsingQuestionsPkeyUpdate | null | undefined;
  updateBySlug?: QuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyUsingQuestionsSlugUkeyUpdate | null | undefined;
};
export type UserStudySessionQuestionsQuestionIdFkeyQuestionsCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation: string;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionNodeIdDelete = {
  id: string;
};
export type QuestionQuestionsPkeyDelete = {
  rowId: any;
};
export type QuestionQuestionsSlugUkeyDelete = {
  slug: string;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionPatch;
};
export type QuestionPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyUsingQuestionsPkeyUpdate = {
  patch: updateQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyPatch;
  rowId: any;
};
export type updateQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyUsingQuestionsSlugUkeyUpdate = {
  patch: updateQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyPatch;
  slug: string;
};
export type UserStudySessionQuestionsQuestionAnswerIdFkeyInput = {
  connectById?: QuestionAnswerNodeIdConnect | null | undefined;
  connectByRowId?: QuestionAnswerQuestionAnswersPkeyConnect | null | undefined;
  connectBySlug?: QuestionAnswerQuestionAnswersSlugUkeyConnect | null | undefined;
  create?: UserStudySessionQuestionsQuestionAnswerIdFkeyQuestionAnswersCreateInput | null | undefined;
  deleteById?: QuestionAnswerNodeIdDelete | null | undefined;
  deleteByRowId?: QuestionAnswerQuestionAnswersPkeyDelete | null | undefined;
  deleteBySlug?: QuestionAnswerQuestionAnswersSlugUkeyDelete | null | undefined;
  updateById?: UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: QuestionAnswerOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyUsingQuestionAnswersPkeyUpdate | null | undefined;
  updateBySlug?: QuestionAnswerOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyUsingQuestionAnswersSlugUkeyUpdate | null | undefined;
};
export type UserStudySessionQuestionsQuestionAnswerIdFkeyQuestionAnswersCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionAnswersCreatedByFkeyInput | null | undefined;
  examType?: QuestionAnswersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  isActive?: boolean | null | undefined;
  isCorrect?: boolean | null | undefined;
  question?: QuestionAnswersQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionAnswersUpdatedByFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionAnswerIdFkeyInverseInput | null | undefined;
};
export type QuestionAnswerNodeIdDelete = {
  id: string;
};
export type QuestionAnswerQuestionAnswersPkeyDelete = {
  rowId: any;
};
export type QuestionAnswerQuestionAnswersSlugUkeyDelete = {
  slug: string;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionAnswerPatch;
};
export type QuestionAnswerOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyUsingQuestionAnswersPkeyUpdate = {
  patch: updateQuestionAnswerOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyPatch;
  rowId: any;
};
export type updateQuestionAnswerOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionAnswersCreatedByFkeyInput | null | undefined;
  examType?: QuestionAnswersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  isActive?: boolean | null | undefined;
  isCorrect?: boolean | null | undefined;
  question?: QuestionAnswersQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionAnswersUpdatedByFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionAnswerIdFkeyInverseInput | null | undefined;
};
export type QuestionAnswerOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyUsingQuestionAnswersSlugUkeyUpdate = {
  patch: updateQuestionAnswerOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyPatch;
  slug: string;
};
export type UserStudySessionQuestionsSubtopicIdFkeyInput = {
  connectById?: TopicNodeIdConnect | null | undefined;
  connectByRowId?: TopicTopicsPkeyConnect | null | undefined;
  connectBySlug?: TopicTopicsSlugUkeyConnect | null | undefined;
  create?: UserStudySessionQuestionsSubtopicIdFkeyTopicsCreateInput | null | undefined;
  deleteById?: TopicNodeIdDelete | null | undefined;
  deleteByRowId?: TopicTopicsPkeyDelete | null | undefined;
  deleteBySlug?: TopicTopicsSlugUkeyDelete | null | undefined;
  updateById?: UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyUsingTopicsPkeyUpdate | null | undefined;
  updateBySlug?: TopicOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyUsingTopicsSlugUkeyUpdate | null | undefined;
};
export type UserStudySessionQuestionsSubtopicIdFkeyTopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type UserLessonsSubtopicIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserLessonNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserLessonsSubtopicIdFkeyUserLessonsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserLessonNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnUserLessonForUserLessonsSubtopicIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsSubtopicIdFkeyUsingUserLessonsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsSubtopicIdFkeyUsingUserLessonsSlugUkeyUpdate> | null | undefined;
};
export type UserLessonsSubtopicIdFkeyUserLessonsCreateInput = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonsSubtopicIdFkeyInput = {
  connectById?: TopicNodeIdConnect | null | undefined;
  connectByRowId?: TopicTopicsPkeyConnect | null | undefined;
  connectBySlug?: TopicTopicsSlugUkeyConnect | null | undefined;
  create?: UserLessonsSubtopicIdFkeyTopicsCreateInput | null | undefined;
  deleteById?: TopicNodeIdDelete | null | undefined;
  deleteByRowId?: TopicTopicsPkeyDelete | null | undefined;
  deleteBySlug?: TopicTopicsSlugUkeyDelete | null | undefined;
  updateById?: UserLessonOnUserLessonForUserLessonsSubtopicIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicOnUserLessonForUserLessonsSubtopicIdFkeyUsingTopicsPkeyUpdate | null | undefined;
  updateBySlug?: TopicOnUserLessonForUserLessonsSubtopicIdFkeyUsingTopicsSlugUkeyUpdate | null | undefined;
};
export type UserLessonsSubtopicIdFkeyTopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type UserLessonsTopicIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserLessonNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserLessonsTopicIdFkeyUserLessonsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserLessonNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnUserLessonForUserLessonsTopicIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsTopicIdFkeyUsingUserLessonsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsTopicIdFkeyUsingUserLessonsSlugUkeyUpdate> | null | undefined;
};
export type UserLessonsTopicIdFkeyUserLessonsCreateInput = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonsTopicObjectiveIdFkeyInput = {
  connectById?: TopicObjectiveNodeIdConnect | null | undefined;
  connectByRowId?: TopicObjectiveTopicObjectivesPkeyConnect | null | undefined;
  connectBySlug?: TopicObjectiveTopicObjectivesSlugUkeyConnect | null | undefined;
  create?: UserLessonsTopicObjectiveIdFkeyTopicObjectivesCreateInput | null | undefined;
  deleteById?: TopicObjectiveNodeIdDelete | null | undefined;
  deleteByRowId?: TopicObjectiveTopicObjectivesPkeyDelete | null | undefined;
  deleteBySlug?: TopicObjectiveTopicObjectivesSlugUkeyDelete | null | undefined;
  updateById?: UserLessonOnUserLessonForUserLessonsTopicObjectiveIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicObjectiveOnUserLessonForUserLessonsTopicObjectiveIdFkeyUsingTopicObjectivesPkeyUpdate | null | undefined;
  updateBySlug?: TopicObjectiveOnUserLessonForUserLessonsTopicObjectiveIdFkeyUsingTopicObjectivesSlugUkeyUpdate | null | undefined;
};
export type UserLessonsTopicObjectiveIdFkeyTopicObjectivesCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey4InverseInput = {
  connectById?: ReadonlyArray<UserQuestionAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyConnect> | null | undefined;
  create?: ReadonlyArray<FakeAppPublicUserQuestionAnswersForeignKey4UserQuestionAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserQuestionAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicObjectiveOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4NodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4UsingFakeAppPublicUserQuestionAnswersPrimaryKeyUpdate> | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey4UserQuestionAnswersCreateInput = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  examTypeId?: any | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  questionId?: any | null | undefined;
  rowId: any;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
  userId?: any | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey3Input = {
  connectById?: TopicNodeIdConnect | null | undefined;
  connectByRowId?: TopicTopicsPkeyConnect | null | undefined;
  connectBySlug?: TopicTopicsSlugUkeyConnect | null | undefined;
  create?: FakeAppPublicUserQuestionAnswersForeignKey3TopicsCreateInput | null | undefined;
  deleteById?: TopicNodeIdDelete | null | undefined;
  deleteByRowId?: TopicTopicsPkeyDelete | null | undefined;
  deleteBySlug?: TopicTopicsSlugUkeyDelete | null | undefined;
  updateById?: UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3NodeIdUpdate | null | undefined;
  updateByRowId?: TopicOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3UsingTopicsPkeyUpdate | null | undefined;
  updateBySlug?: TopicOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3UsingTopicsSlugUkeyUpdate | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey3TopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey2InverseInput = {
  connectById?: ReadonlyArray<UserQuestionAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyConnect> | null | undefined;
  create?: ReadonlyArray<FakeAppPublicUserQuestionAnswersForeignKey2UserQuestionAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserQuestionAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey2NodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey2UsingFakeAppPublicUserQuestionAnswersPrimaryKeyUpdate> | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey2UserQuestionAnswersCreateInput = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  examTypeId?: any | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  questionId?: any | null | undefined;
  rowId: any;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  topicObjectiveId?: any | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
  userId?: any | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey4Input = {
  connectById?: TopicObjectiveNodeIdConnect | null | undefined;
  connectByRowId?: TopicObjectiveTopicObjectivesPkeyConnect | null | undefined;
  connectBySlug?: TopicObjectiveTopicObjectivesSlugUkeyConnect | null | undefined;
  create?: FakeAppPublicUserQuestionAnswersForeignKey4TopicObjectivesCreateInput | null | undefined;
  deleteById?: TopicObjectiveNodeIdDelete | null | undefined;
  deleteByRowId?: TopicObjectiveTopicObjectivesPkeyDelete | null | undefined;
  deleteBySlug?: TopicObjectiveTopicObjectivesSlugUkeyDelete | null | undefined;
  updateById?: UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4NodeIdUpdate | null | undefined;
  updateByRowId?: TopicObjectiveOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4UsingTopicObjectivesPkeyUpdate | null | undefined;
  updateBySlug?: TopicObjectiveOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4UsingTopicObjectivesSlugUkeyUpdate | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey4TopicObjectivesCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserStudySessionQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserStudySessionQuestionsTopicObjectiveIdFkeyUserStudySessionQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserStudySessionQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicObjectiveOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyUsingUserStudySessionQuestionsPkeyUpdate> | null | undefined;
};
export type UserStudySessionQuestionsTopicObjectiveIdFkeyUserStudySessionQuestionsCreateInput = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserStudySessionQuestionsTopicObjectiveIdFkeyInput = {
  connectById?: TopicObjectiveNodeIdConnect | null | undefined;
  connectByRowId?: TopicObjectiveTopicObjectivesPkeyConnect | null | undefined;
  connectBySlug?: TopicObjectiveTopicObjectivesSlugUkeyConnect | null | undefined;
  create?: UserStudySessionQuestionsTopicObjectiveIdFkeyTopicObjectivesCreateInput | null | undefined;
  deleteById?: TopicObjectiveNodeIdDelete | null | undefined;
  deleteByRowId?: TopicObjectiveTopicObjectivesPkeyDelete | null | undefined;
  deleteBySlug?: TopicObjectiveTopicObjectivesSlugUkeyDelete | null | undefined;
  updateById?: UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicObjectiveOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyUsingTopicObjectivesPkeyUpdate | null | undefined;
  updateBySlug?: TopicObjectiveOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyUsingTopicObjectivesSlugUkeyUpdate | null | undefined;
};
export type UserStudySessionQuestionsTopicObjectiveIdFkeyTopicObjectivesCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type UserTopicObjectivesTopicObjectiveIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserTopicObjectiveNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesPkeyConnect> | null | undefined;
  connectByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserTopicObjectivesTopicObjectiveIdFkeyUserTopicObjectivesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserTopicObjectiveNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesPkeyDelete> | null | undefined;
  deleteByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyUsingUserTopicObjectivesPkeyUpdate> | null | undefined;
  updateByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyUsingUserTopicObjectivesUkeyUpdate> | null | undefined;
};
export type UserTopicObjectiveNodeIdConnect = {
  id: string;
};
export type UserTopicObjectiveUserTopicObjectivesPkeyConnect = {
  rowId: any;
};
export type UserTopicObjectiveUserTopicObjectivesUkeyConnect = {
  examTypeId: any;
  subtopicId: any;
  topicId: any;
  topicObjectiveId: any;
  userId: any;
};
export type UserTopicObjectivesTopicObjectiveIdFkeyUserTopicObjectivesCreateInput = {
  examType?: UserTopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserTopicObjectivesSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserTopicObjectivesTopicObjectiveIdFkeyInput | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicObjectivesUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicObjectivesExamTypeIdFkeyInput = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: UserTopicObjectivesExamTypeIdFkeyExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyUsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type UserTopicObjectivesExamTypeIdFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionsExamTypeIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserStudySessionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserStudySessionUserStudySessionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<UserStudySessionUserStudySessionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserStudySessionsExamTypeIdFkeyUserStudySessionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserStudySessionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserStudySessionUserStudySessionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<UserStudySessionUserStudySessionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnUserStudySessionForUserStudySessionsExamTypeIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserStudySessionOnUserStudySessionForUserStudySessionsExamTypeIdFkeyUsingUserStudySessionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<UserStudySessionOnUserStudySessionForUserStudySessionsExamTypeIdFkeyUsingUserStudySessionsSlugUkeyUpdate> | null | undefined;
};
export type UserStudySessionNodeIdConnect = {
  id: string;
};
export type UserStudySessionUserStudySessionsPkeyConnect = {
  rowId: any;
};
export type UserStudySessionUserStudySessionsSlugUkeyConnect = {
  slug: string;
};
export type UserStudySessionsExamTypeIdFkeyUserStudySessionsCreateInput = {
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionsCreatedByFkeyInput | null | undefined;
  examType?: UserStudySessionsExamTypeIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionsUpdatedByFkeyInput | null | undefined;
  user?: UserStudySessionsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsUserStudySessionIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionsCreatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserStudySessionsCreatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserStudySessionOnUserStudySessionForUserStudySessionsCreatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserStudySessionForUserStudySessionsCreatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserStudySessionForUserStudySessionsCreatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserStudySessionsCreatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserLessonQuestionsUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<UserLessonQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserLessonQuestionUserLessonQuestionsPkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserLessonQuestionsUpdatedByFkeyUserLessonQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserLessonQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserLessonQuestionUserLessonQuestionsPkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyUsingUserLessonQuestionsPkeyUpdate> | null | undefined;
};
export type UserLessonQuestionsUpdatedByFkeyUserLessonQuestionsCreateInput = {
  attempts?: number | null | undefined;
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserLessonQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UserLessonQuestionsUpdatedByFkeyInput | null | undefined;
  userLesson?: UserLessonQuestionsUserLessonIdFkeyInput | null | undefined;
  userLessonId?: any | null | undefined;
};
export type UserLessonQuestionsUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserLessonQuestionsUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserLessonQuestionsUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserLessonsCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<UserLessonNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserLessonsCreatedByFkeyUserLessonsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserLessonNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserLessonForUserLessonsCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsCreatedByFkeyUsingUserLessonsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsCreatedByFkeyUsingUserLessonsSlugUkeyUpdate> | null | undefined;
};
export type UserLessonsCreatedByFkeyUserLessonsCreateInput = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonsUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserLessonsUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserLessonOnUserLessonForUserLessonsUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserLessonForUserLessonsUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserLessonForUserLessonsUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserLessonsUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserLessonsUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<UserLessonNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserLessonsUpdatedByFkeyUserLessonsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserLessonNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserLessonForUserLessonsUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsUpdatedByFkeyUsingUserLessonsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsUpdatedByFkeyUsingUserLessonsSlugUkeyUpdate> | null | undefined;
};
export type UserLessonsUpdatedByFkeyUserLessonsCreateInput = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonsUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserLessonsUserIdFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserLessonOnUserLessonForUserLessonsUserIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserLessonForUserLessonsUserIdFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserLessonForUserLessonsUserIdFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserLessonsUserIdFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserLessonsUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserLessonNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserLessonsUserIdFkeyUserLessonsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserLessonNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserLessonUserLessonsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<UserLessonUserLessonsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserLessonForUserLessonsUserIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsUserIdFkeyUsingUserLessonsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<UserLessonOnUserLessonForUserLessonsUserIdFkeyUsingUserLessonsSlugUkeyUpdate> | null | undefined;
};
export type UserLessonsUserIdFkeyUserLessonsCreateInput = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonQuestionsUserLessonIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserLessonQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserLessonQuestionUserLessonQuestionsPkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserLessonQuestionsUserLessonIdFkeyUserLessonQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserLessonQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserLessonQuestionUserLessonQuestionsPkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserLessonOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyUsingUserLessonQuestionsPkeyUpdate> | null | undefined;
};
export type UserLessonQuestionsUserLessonIdFkeyUserLessonQuestionsCreateInput = {
  attempts?: number | null | undefined;
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserLessonQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonQuestionsUpdatedByFkeyInput | null | undefined;
  userLesson?: UserLessonQuestionsUserLessonIdFkeyInput | null | undefined;
};
export type UserLessonQuestionsUserLessonIdFkeyInput = {
  connectById?: UserLessonNodeIdConnect | null | undefined;
  connectByRowId?: UserLessonUserLessonsPkeyConnect | null | undefined;
  connectBySlug?: UserLessonUserLessonsSlugUkeyConnect | null | undefined;
  create?: UserLessonQuestionsUserLessonIdFkeyUserLessonsCreateInput | null | undefined;
  deleteById?: UserLessonNodeIdDelete | null | undefined;
  deleteByRowId?: UserLessonUserLessonsPkeyDelete | null | undefined;
  deleteBySlug?: UserLessonUserLessonsSlugUkeyDelete | null | undefined;
  updateById?: UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserLessonOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyUsingUserLessonsPkeyUpdate | null | undefined;
  updateBySlug?: UserLessonOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyUsingUserLessonsSlugUkeyUpdate | null | undefined;
};
export type UserLessonQuestionsUserLessonIdFkeyUserLessonsCreateInput = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonNodeIdDelete = {
  id: string;
};
export type UserLessonUserLessonsPkeyDelete = {
  rowId: any;
};
export type UserLessonUserLessonsSlugUkeyDelete = {
  slug: string;
};
export type UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserLessonPatch;
};
export type UserLessonPatch = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyUsingUserLessonsPkeyUpdate = {
  patch: updateUserLessonOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyPatch;
  rowId: any;
};
export type updateUserLessonOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyPatch = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyUsingUserLessonsSlugUkeyUpdate = {
  patch: updateUserLessonOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyPatch;
  slug: string;
};
export type UserLessonQuestionNodeIdDelete = {
  id: string;
};
export type UserLessonQuestionUserLessonQuestionsPkeyDelete = {
  rowId: any;
};
export type UserLessonOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserLessonQuestionPatch;
};
export type UserLessonQuestionPatch = {
  attempts?: number | null | undefined;
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserLessonQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonQuestionsUpdatedByFkeyInput | null | undefined;
  userLesson?: UserLessonQuestionsUserLessonIdFkeyInput | null | undefined;
  userLessonId?: any | null | undefined;
};
export type UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyUsingUserLessonQuestionsPkeyUpdate = {
  patch: updateUserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyPatch;
  rowId: any;
};
export type updateUserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsUserLessonIdFkeyPatch = {
  attempts?: number | null | undefined;
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserLessonQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonQuestionsUpdatedByFkeyInput | null | undefined;
  userLesson?: UserLessonQuestionsUserLessonIdFkeyInput | null | undefined;
};
export type UserOnUserLessonForUserLessonsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserLessonPatch;
};
export type UserLessonOnUserLessonForUserLessonsUserIdFkeyUsingUserLessonsPkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserLessonOnUserLessonForUserLessonsUserIdFkeyPatch = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonOnUserLessonForUserLessonsUserIdFkeyUsingUserLessonsSlugUkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsUserIdFkeyPatch;
  slug: string;
};
export type FakeAppPublicUserQuestionAnswersForeignKey5InverseInput = {
  connectById?: ReadonlyArray<UserQuestionAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyConnect> | null | undefined;
  create?: ReadonlyArray<FakeAppPublicUserQuestionAnswersForeignKey5UserQuestionAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserQuestionAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5NodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5UsingFakeAppPublicUserQuestionAnswersPrimaryKeyUpdate> | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey5UserQuestionAnswersCreateInput = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  examTypeId?: any | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  questionId?: any | null | undefined;
  rowId: any;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  topicObjectiveId?: any | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey5Input = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: FakeAppPublicUserQuestionAnswersForeignKey5UsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5NodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5UsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5UsingUniqueSlugUpdate | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey5UsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserStudySessionQuestionsCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<UserStudySessionQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserStudySessionQuestionsCreatedByFkeyUserStudySessionQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserStudySessionQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyUsingUserStudySessionQuestionsPkeyUpdate> | null | undefined;
};
export type UserStudySessionQuestionsCreatedByFkeyUserStudySessionQuestionsCreateInput = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserStudySessionQuestionsUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserStudySessionQuestionsUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserStudySessionQuestionsUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserStudySessionQuestionsUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<UserStudySessionQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserStudySessionQuestionsUpdatedByFkeyUserStudySessionQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserStudySessionQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyUsingUserStudySessionQuestionsPkeyUpdate> | null | undefined;
};
export type UserStudySessionQuestionsUpdatedByFkeyUserStudySessionQuestionsCreateInput = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserStudySessionQuestionsUserStudySessionIdFkeyInput = {
  connectById?: UserStudySessionNodeIdConnect | null | undefined;
  connectByRowId?: UserStudySessionUserStudySessionsPkeyConnect | null | undefined;
  connectBySlug?: UserStudySessionUserStudySessionsSlugUkeyConnect | null | undefined;
  create?: UserStudySessionQuestionsUserStudySessionIdFkeyUserStudySessionsCreateInput | null | undefined;
  deleteById?: UserStudySessionNodeIdDelete | null | undefined;
  deleteByRowId?: UserStudySessionUserStudySessionsPkeyDelete | null | undefined;
  deleteBySlug?: UserStudySessionUserStudySessionsSlugUkeyDelete | null | undefined;
  updateById?: UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserStudySessionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyUsingUserStudySessionsPkeyUpdate | null | undefined;
  updateBySlug?: UserStudySessionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyUsingUserStudySessionsSlugUkeyUpdate | null | undefined;
};
export type UserStudySessionQuestionsUserStudySessionIdFkeyUserStudySessionsCreateInput = {
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionsCreatedByFkeyInput | null | undefined;
  examType?: UserStudySessionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionsUpdatedByFkeyInput | null | undefined;
  user?: UserStudySessionsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsUserStudySessionIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionsExamTypeIdFkeyInput = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: UserStudySessionsExamTypeIdFkeyExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: UserStudySessionOnUserStudySessionForUserStudySessionsExamTypeIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnUserStudySessionForUserStudySessionsExamTypeIdFkeyUsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnUserStudySessionForUserStudySessionsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type UserStudySessionsExamTypeIdFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type UserSubtopicsExamTypeIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserSubtopicNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserSubtopicUserSubtopicsPkeyConnect> | null | undefined;
  connectByUserIdAndExamTypeIdAndTopicIdAndSubtopicId?: ReadonlyArray<UserSubtopicUserSubtopicsUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserSubtopicsExamTypeIdFkeyUserSubtopicsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserSubtopicNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserSubtopicUserSubtopicsPkeyDelete> | null | undefined;
  deleteByUserIdAndExamTypeIdAndTopicIdAndSubtopicId?: ReadonlyArray<UserSubtopicUserSubtopicsUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnUserSubtopicForUserSubtopicsExamTypeIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserSubtopicOnUserSubtopicForUserSubtopicsExamTypeIdFkeyUsingUserSubtopicsPkeyUpdate> | null | undefined;
  updateByUserIdAndExamTypeIdAndTopicIdAndSubtopicId?: ReadonlyArray<UserSubtopicOnUserSubtopicForUserSubtopicsExamTypeIdFkeyUsingUserSubtopicsUkeyUpdate> | null | undefined;
};
export type UserSubtopicNodeIdConnect = {
  id: string;
};
export type UserSubtopicUserSubtopicsPkeyConnect = {
  rowId: any;
};
export type UserSubtopicUserSubtopicsUkeyConnect = {
  examTypeId: any;
  subtopicId: any;
  topicId: any;
  userId: any;
};
export type UserSubtopicsExamTypeIdFkeyUserSubtopicsCreateInput = {
  examType?: UserSubtopicsExamTypeIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserSubtopicsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserSubtopicsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserSubtopicsExamTypeIdFkeyInput = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: UserSubtopicsExamTypeIdFkeyExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: UserSubtopicOnUserSubtopicForUserSubtopicsExamTypeIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnUserSubtopicForUserSubtopicsExamTypeIdFkeyUsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnUserSubtopicForUserSubtopicsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type UserSubtopicsExamTypeIdFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type UserTopicObjectivesExamTypeIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserTopicObjectiveNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesPkeyConnect> | null | undefined;
  connectByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserTopicObjectivesExamTypeIdFkeyUserTopicObjectivesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserTopicObjectiveNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesPkeyDelete> | null | undefined;
  deleteByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyUsingUserTopicObjectivesPkeyUpdate> | null | undefined;
  updateByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyUsingUserTopicObjectivesUkeyUpdate> | null | undefined;
};
export type UserTopicObjectivesExamTypeIdFkeyUserTopicObjectivesCreateInput = {
  examType?: UserTopicObjectivesExamTypeIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserTopicObjectivesSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserTopicObjectivesTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicObjectivesUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicObjectivesSubtopicIdFkeyInput = {
  connectById?: TopicNodeIdConnect | null | undefined;
  connectByRowId?: TopicTopicsPkeyConnect | null | undefined;
  connectBySlug?: TopicTopicsSlugUkeyConnect | null | undefined;
  create?: UserTopicObjectivesSubtopicIdFkeyTopicsCreateInput | null | undefined;
  deleteById?: TopicNodeIdDelete | null | undefined;
  deleteByRowId?: TopicTopicsPkeyDelete | null | undefined;
  deleteBySlug?: TopicTopicsSlugUkeyDelete | null | undefined;
  updateById?: UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyUsingTopicsPkeyUpdate | null | undefined;
  updateBySlug?: TopicOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyUsingTopicsSlugUkeyUpdate | null | undefined;
};
export type UserTopicObjectivesSubtopicIdFkeyTopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey3InverseInput = {
  connectById?: ReadonlyArray<UserQuestionAnswerNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyConnect> | null | undefined;
  create?: ReadonlyArray<FakeAppPublicUserQuestionAnswersForeignKey3UserQuestionAnswersCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserQuestionAnswerNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3NodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3UsingFakeAppPublicUserQuestionAnswersPrimaryKeyUpdate> | null | undefined;
};
export type FakeAppPublicUserQuestionAnswersForeignKey3UserQuestionAnswersCreateInput = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  examTypeId?: any | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  questionId?: any | null | undefined;
  rowId: any;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  topicObjectiveId?: any | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
  userId?: any | null | undefined;
};
export type UserQuestionAnswerNodeIdDelete = {
  id: string;
};
export type UserQuestionAnswerFakeAppPublicUserQuestionAnswersPrimaryKeyDelete = {
  rowId: any;
};
export type TopicOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3NodeIdUpdate = {
  id: string;
  patch: UserQuestionAnswerPatch;
};
export type UserQuestionAnswerPatch = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  examTypeId?: any | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  topicObjectiveId?: any | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
  userId?: any | null | undefined;
};
export type UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3UsingFakeAppPublicUserQuestionAnswersPrimaryKeyUpdate = {
  patch: updateUserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3Patch;
  rowId: any;
};
export type updateUserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3Patch = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  examTypeId?: any | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  topicObjectiveId?: any | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
  userId?: any | null | undefined;
};
export type UserStudySessionQuestionsSubtopicIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserStudySessionQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserStudySessionQuestionsSubtopicIdFkeyUserStudySessionQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserStudySessionQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyUsingUserStudySessionQuestionsPkeyUpdate> | null | undefined;
};
export type UserStudySessionQuestionsSubtopicIdFkeyUserStudySessionQuestionsCreateInput = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserStudySessionQuestionNodeIdDelete = {
  id: string;
};
export type UserStudySessionQuestionUserStudySessionQuestionsPkeyDelete = {
  rowId: any;
};
export type TopicOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionQuestionPatch;
};
export type UserStudySessionQuestionPatch = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyUsingUserStudySessionQuestionsPkeyUpdate = {
  patch: updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyPatch = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserStudySessionQuestionsTopicIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserStudySessionQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserStudySessionQuestionsTopicIdFkeyUserStudySessionQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserStudySessionQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnUserStudySessionQuestionForUserStudySessionQuestionsTopicIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsTopicIdFkeyUsingUserStudySessionQuestionsPkeyUpdate> | null | undefined;
};
export type UserStudySessionQuestionsTopicIdFkeyUserStudySessionQuestionsCreateInput = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type TopicOnUserStudySessionQuestionForUserStudySessionQuestionsTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionQuestionPatch;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsTopicIdFkeyUsingUserStudySessionQuestionsPkeyUpdate = {
  patch: updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsTopicIdFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsTopicIdFkeyPatch = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserSubtopicsSubtopicIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserSubtopicNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserSubtopicUserSubtopicsPkeyConnect> | null | undefined;
  connectByUserIdAndExamTypeIdAndTopicIdAndSubtopicId?: ReadonlyArray<UserSubtopicUserSubtopicsUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserSubtopicsSubtopicIdFkeyUserSubtopicsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserSubtopicNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserSubtopicUserSubtopicsPkeyDelete> | null | undefined;
  deleteByUserIdAndExamTypeIdAndTopicIdAndSubtopicId?: ReadonlyArray<UserSubtopicUserSubtopicsUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserSubtopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyUsingUserSubtopicsPkeyUpdate> | null | undefined;
  updateByUserIdAndExamTypeIdAndTopicIdAndSubtopicId?: ReadonlyArray<UserSubtopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyUsingUserSubtopicsUkeyUpdate> | null | undefined;
};
export type UserSubtopicsSubtopicIdFkeyUserSubtopicsCreateInput = {
  examType?: UserSubtopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  topic?: UserSubtopicsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserSubtopicsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserSubtopicsSubtopicIdFkeyInput = {
  connectById?: TopicNodeIdConnect | null | undefined;
  connectByRowId?: TopicTopicsPkeyConnect | null | undefined;
  connectBySlug?: TopicTopicsSlugUkeyConnect | null | undefined;
  create?: UserSubtopicsSubtopicIdFkeyTopicsCreateInput | null | undefined;
  deleteById?: TopicNodeIdDelete | null | undefined;
  deleteByRowId?: TopicTopicsPkeyDelete | null | undefined;
  deleteBySlug?: TopicTopicsSlugUkeyDelete | null | undefined;
  updateById?: UserSubtopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyUsingTopicsPkeyUpdate | null | undefined;
  updateBySlug?: TopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyUsingTopicsSlugUkeyUpdate | null | undefined;
};
export type UserSubtopicsSubtopicIdFkeyTopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type UserSubtopicsTopicIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserSubtopicNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserSubtopicUserSubtopicsPkeyConnect> | null | undefined;
  connectByUserIdAndExamTypeIdAndTopicIdAndSubtopicId?: ReadonlyArray<UserSubtopicUserSubtopicsUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserSubtopicsTopicIdFkeyUserSubtopicsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserSubtopicNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserSubtopicUserSubtopicsPkeyDelete> | null | undefined;
  deleteByUserIdAndExamTypeIdAndTopicIdAndSubtopicId?: ReadonlyArray<UserSubtopicUserSubtopicsUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnUserSubtopicForUserSubtopicsTopicIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserSubtopicOnUserSubtopicForUserSubtopicsTopicIdFkeyUsingUserSubtopicsPkeyUpdate> | null | undefined;
  updateByUserIdAndExamTypeIdAndTopicIdAndSubtopicId?: ReadonlyArray<UserSubtopicOnUserSubtopicForUserSubtopicsTopicIdFkeyUsingUserSubtopicsUkeyUpdate> | null | undefined;
};
export type UserSubtopicsTopicIdFkeyUserSubtopicsCreateInput = {
  examType?: UserSubtopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserSubtopicsSubtopicIdFkeyInput | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserSubtopicsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserSubtopicsUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserSubtopicsUserIdFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserSubtopicOnUserSubtopicForUserSubtopicsUserIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserSubtopicForUserSubtopicsUserIdFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserSubtopicForUserSubtopicsUserIdFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserSubtopicsUserIdFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserStudySessionsCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<UserStudySessionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserStudySessionUserStudySessionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<UserStudySessionUserStudySessionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserStudySessionsCreatedByFkeyUserStudySessionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserStudySessionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserStudySessionUserStudySessionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<UserStudySessionUserStudySessionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserStudySessionForUserStudySessionsCreatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserStudySessionOnUserStudySessionForUserStudySessionsCreatedByFkeyUsingUserStudySessionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<UserStudySessionOnUserStudySessionForUserStudySessionsCreatedByFkeyUsingUserStudySessionsSlugUkeyUpdate> | null | undefined;
};
export type UserStudySessionsCreatedByFkeyUserStudySessionsCreateInput = {
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdByUser?: UserStudySessionsCreatedByFkeyInput | null | undefined;
  examType?: UserStudySessionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionsUpdatedByFkeyInput | null | undefined;
  user?: UserStudySessionsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsUserStudySessionIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionsUpdatedByFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserStudySessionsUpdatedByFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserStudySessionOnUserStudySessionForUserStudySessionsUpdatedByFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserStudySessionForUserStudySessionsUpdatedByFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserStudySessionForUserStudySessionsUpdatedByFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserStudySessionsUpdatedByFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserStudySessionsUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<UserStudySessionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserStudySessionUserStudySessionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<UserStudySessionUserStudySessionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserStudySessionsUpdatedByFkeyUserStudySessionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserStudySessionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserStudySessionUserStudySessionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<UserStudySessionUserStudySessionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserStudySessionForUserStudySessionsUpdatedByFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserStudySessionOnUserStudySessionForUserStudySessionsUpdatedByFkeyUsingUserStudySessionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<UserStudySessionOnUserStudySessionForUserStudySessionsUpdatedByFkeyUsingUserStudySessionsSlugUkeyUpdate> | null | undefined;
};
export type UserStudySessionsUpdatedByFkeyUserStudySessionsCreateInput = {
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionsCreatedByFkeyInput | null | undefined;
  examType?: UserStudySessionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UserStudySessionsUpdatedByFkeyInput | null | undefined;
  user?: UserStudySessionsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsUserStudySessionIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionsUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserStudySessionsUserIdFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserStudySessionOnUserStudySessionForUserStudySessionsUserIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserStudySessionForUserStudySessionsUserIdFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserStudySessionForUserStudySessionsUserIdFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserStudySessionsUserIdFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserStudySessionsUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserStudySessionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserStudySessionUserStudySessionsPkeyConnect> | null | undefined;
  connectBySlug?: ReadonlyArray<UserStudySessionUserStudySessionsSlugUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserStudySessionsUserIdFkeyUserStudySessionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserStudySessionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserStudySessionUserStudySessionsPkeyDelete> | null | undefined;
  deleteBySlug?: ReadonlyArray<UserStudySessionUserStudySessionsSlugUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserStudySessionForUserStudySessionsUserIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserStudySessionOnUserStudySessionForUserStudySessionsUserIdFkeyUsingUserStudySessionsPkeyUpdate> | null | undefined;
  updateBySlug?: ReadonlyArray<UserStudySessionOnUserStudySessionForUserStudySessionsUserIdFkeyUsingUserStudySessionsSlugUkeyUpdate> | null | undefined;
};
export type UserStudySessionsUserIdFkeyUserStudySessionsCreateInput = {
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionsCreatedByFkeyInput | null | undefined;
  examType?: UserStudySessionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionsUpdatedByFkeyInput | null | undefined;
  user?: UserStudySessionsUserIdFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsUserStudySessionIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionQuestionsUserStudySessionIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserStudySessionQuestionNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserStudySessionQuestionsUserStudySessionIdFkeyUserStudySessionQuestionsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserStudySessionQuestionNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserStudySessionQuestionUserStudySessionQuestionsPkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserStudySessionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyUsingUserStudySessionQuestionsPkeyUpdate> | null | undefined;
};
export type UserStudySessionQuestionsUserStudySessionIdFkeyUserStudySessionQuestionsCreateInput = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
};
export type UserStudySessionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionQuestionPatch;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyUsingUserStudySessionQuestionsPkeyUpdate = {
  patch: updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyPatch = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
};
export type UserStudySessionNodeIdDelete = {
  id: string;
};
export type UserStudySessionUserStudySessionsPkeyDelete = {
  rowId: any;
};
export type UserStudySessionUserStudySessionsSlugUkeyDelete = {
  slug: string;
};
export type UserOnUserStudySessionForUserStudySessionsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionPatch;
};
export type UserStudySessionPatch = {
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionsCreatedByFkeyInput | null | undefined;
  examType?: UserStudySessionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionsUpdatedByFkeyInput | null | undefined;
  user?: UserStudySessionsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsUserStudySessionIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionOnUserStudySessionForUserStudySessionsUserIdFkeyUsingUserStudySessionsPkeyUpdate = {
  patch: updateUserStudySessionOnUserStudySessionForUserStudySessionsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionOnUserStudySessionForUserStudySessionsUserIdFkeyPatch = {
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionsCreatedByFkeyInput | null | undefined;
  examType?: UserStudySessionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionsUpdatedByFkeyInput | null | undefined;
  user?: UserStudySessionsUserIdFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsUserStudySessionIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionOnUserStudySessionForUserStudySessionsUserIdFkeyUsingUserStudySessionsSlugUkeyUpdate = {
  patch: updateUserStudySessionOnUserStudySessionForUserStudySessionsUserIdFkeyPatch;
  slug: string;
};
export type UserSubtopicsUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserSubtopicNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserSubtopicUserSubtopicsPkeyConnect> | null | undefined;
  connectByUserIdAndExamTypeIdAndTopicIdAndSubtopicId?: ReadonlyArray<UserSubtopicUserSubtopicsUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserSubtopicsUserIdFkeyUserSubtopicsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserSubtopicNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserSubtopicUserSubtopicsPkeyDelete> | null | undefined;
  deleteByUserIdAndExamTypeIdAndTopicIdAndSubtopicId?: ReadonlyArray<UserSubtopicUserSubtopicsUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserSubtopicForUserSubtopicsUserIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserSubtopicOnUserSubtopicForUserSubtopicsUserIdFkeyUsingUserSubtopicsPkeyUpdate> | null | undefined;
  updateByUserIdAndExamTypeIdAndTopicIdAndSubtopicId?: ReadonlyArray<UserSubtopicOnUserSubtopicForUserSubtopicsUserIdFkeyUsingUserSubtopicsUkeyUpdate> | null | undefined;
};
export type UserSubtopicsUserIdFkeyUserSubtopicsCreateInput = {
  examType?: UserSubtopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserSubtopicsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserSubtopicsUserIdFkeyInput | null | undefined;
};
export type UserSubtopicNodeIdDelete = {
  id: string;
};
export type UserSubtopicUserSubtopicsPkeyDelete = {
  rowId: any;
};
export type UserSubtopicUserSubtopicsUkeyDelete = {
  examTypeId: any;
  subtopicId: any;
  topicId: any;
  userId: any;
};
export type UserOnUserSubtopicForUserSubtopicsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserSubtopicPatch;
};
export type UserSubtopicPatch = {
  examType?: UserSubtopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserSubtopicsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserSubtopicsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserSubtopicOnUserSubtopicForUserSubtopicsUserIdFkeyUsingUserSubtopicsPkeyUpdate = {
  patch: updateUserSubtopicOnUserSubtopicForUserSubtopicsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserSubtopicOnUserSubtopicForUserSubtopicsUserIdFkeyPatch = {
  examType?: UserSubtopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserSubtopicsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserSubtopicsUserIdFkeyInput | null | undefined;
};
export type UserSubtopicOnUserSubtopicForUserSubtopicsUserIdFkeyUsingUserSubtopicsUkeyUpdate = {
  examTypeId: any;
  patch: updateUserSubtopicOnUserSubtopicForUserSubtopicsUserIdFkeyPatch;
  subtopicId: any;
  topicId: any;
  userId: any;
};
export type UserTopicObjectivesUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserTopicObjectiveNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesPkeyConnect> | null | undefined;
  connectByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserTopicObjectivesUserIdFkeyUserTopicObjectivesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserTopicObjectiveNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesPkeyDelete> | null | undefined;
  deleteByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyUsingUserTopicObjectivesPkeyUpdate> | null | undefined;
  updateByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyUsingUserTopicObjectivesUkeyUpdate> | null | undefined;
};
export type UserTopicObjectivesUserIdFkeyUserTopicObjectivesCreateInput = {
  examType?: UserTopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserTopicObjectivesSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserTopicObjectivesTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicObjectivesUserIdFkeyInput | null | undefined;
};
export type UserTopicObjectivesTopicObjectiveIdFkeyInput = {
  connectById?: TopicObjectiveNodeIdConnect | null | undefined;
  connectByRowId?: TopicObjectiveTopicObjectivesPkeyConnect | null | undefined;
  connectBySlug?: TopicObjectiveTopicObjectivesSlugUkeyConnect | null | undefined;
  create?: UserTopicObjectivesTopicObjectiveIdFkeyTopicObjectivesCreateInput | null | undefined;
  deleteById?: TopicObjectiveNodeIdDelete | null | undefined;
  deleteByRowId?: TopicObjectiveTopicObjectivesPkeyDelete | null | undefined;
  deleteBySlug?: TopicObjectiveTopicObjectivesSlugUkeyDelete | null | undefined;
  updateById?: UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyUsingTopicObjectivesPkeyUpdate | null | undefined;
  updateBySlug?: TopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyUsingTopicObjectivesSlugUkeyUpdate | null | undefined;
};
export type UserTopicObjectivesTopicObjectiveIdFkeyTopicObjectivesCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectiveNodeIdDelete = {
  id: string;
};
export type TopicObjectiveTopicObjectivesPkeyDelete = {
  rowId: any;
};
export type TopicObjectiveTopicObjectivesSlugUkeyDelete = {
  slug: string;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicObjectivePatch;
};
export type TopicObjectivePatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyUsingTopicObjectivesPkeyUpdate = {
  patch: updateTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyPatch;
  rowId: any;
};
export type updateTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyUsingTopicObjectivesSlugUkeyUpdate = {
  patch: updateTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyPatch;
  slug: string;
};
export type UserTopicObjectivesUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserTopicObjectivesUserIdFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserTopicObjectivesUserIdFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserTopicsUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserTopicNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserTopicUserTopicsPkeyConnect> | null | undefined;
  connectByUserIdAndExamTypeIdAndTopicId?: ReadonlyArray<UserTopicUserTopicsUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserTopicsUserIdFkeyUserTopicsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserTopicNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserTopicUserTopicsPkeyDelete> | null | undefined;
  deleteByUserIdAndExamTypeIdAndTopicId?: ReadonlyArray<UserTopicUserTopicsUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<UserOnUserTopicForUserTopicsUserIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserTopicOnUserTopicForUserTopicsUserIdFkeyUsingUserTopicsPkeyUpdate> | null | undefined;
  updateByUserIdAndExamTypeIdAndTopicId?: ReadonlyArray<UserTopicOnUserTopicForUserTopicsUserIdFkeyUsingUserTopicsUkeyUpdate> | null | undefined;
};
export type UserTopicNodeIdConnect = {
  id: string;
};
export type UserTopicUserTopicsPkeyConnect = {
  rowId: any;
};
export type UserTopicUserTopicsUkeyConnect = {
  examTypeId: any;
  topicId: any;
  userId: any;
};
export type UserTopicsUserIdFkeyUserTopicsCreateInput = {
  examType?: UserTopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  topic?: UserTopicsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicsUserIdFkeyInput | null | undefined;
};
export type UserTopicsExamTypeIdFkeyInput = {
  connectById?: ExamTypeNodeIdConnect | null | undefined;
  connectByRowId?: ExamTypeExamTypesPkeyConnect | null | undefined;
  connectBySlug?: ExamTypeExamTypesSlugUkeyConnect | null | undefined;
  create?: UserTopicsExamTypeIdFkeyExamTypesCreateInput | null | undefined;
  deleteById?: ExamTypeNodeIdDelete | null | undefined;
  deleteByRowId?: ExamTypeExamTypesPkeyDelete | null | undefined;
  deleteBySlug?: ExamTypeExamTypesSlugUkeyDelete | null | undefined;
  updateById?: UserTopicOnUserTopicForUserTopicsExamTypeIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: ExamTypeOnUserTopicForUserTopicsExamTypeIdFkeyUsingExamTypesPkeyUpdate | null | undefined;
  updateBySlug?: ExamTypeOnUserTopicForUserTopicsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate | null | undefined;
};
export type UserTopicsExamTypeIdFkeyExamTypesCreateInput = {
  body: string;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title: string;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type UserTopicsExamTypeIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserTopicNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserTopicUserTopicsPkeyConnect> | null | undefined;
  connectByUserIdAndExamTypeIdAndTopicId?: ReadonlyArray<UserTopicUserTopicsUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserTopicsExamTypeIdFkeyUserTopicsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserTopicNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserTopicUserTopicsPkeyDelete> | null | undefined;
  deleteByUserIdAndExamTypeIdAndTopicId?: ReadonlyArray<UserTopicUserTopicsUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<ExamTypeOnUserTopicForUserTopicsExamTypeIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserTopicOnUserTopicForUserTopicsExamTypeIdFkeyUsingUserTopicsPkeyUpdate> | null | undefined;
  updateByUserIdAndExamTypeIdAndTopicId?: ReadonlyArray<UserTopicOnUserTopicForUserTopicsExamTypeIdFkeyUsingUserTopicsUkeyUpdate> | null | undefined;
};
export type UserTopicsExamTypeIdFkeyUserTopicsCreateInput = {
  examType?: UserTopicsExamTypeIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  topic?: UserTopicsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicsTopicIdFkeyInput = {
  connectById?: TopicNodeIdConnect | null | undefined;
  connectByRowId?: TopicTopicsPkeyConnect | null | undefined;
  connectBySlug?: TopicTopicsSlugUkeyConnect | null | undefined;
  create?: UserTopicsTopicIdFkeyTopicsCreateInput | null | undefined;
  deleteById?: TopicNodeIdDelete | null | undefined;
  deleteByRowId?: TopicTopicsPkeyDelete | null | undefined;
  deleteBySlug?: TopicTopicsSlugUkeyDelete | null | undefined;
  updateById?: UserTopicOnUserTopicForUserTopicsTopicIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: TopicOnUserTopicForUserTopicsTopicIdFkeyUsingTopicsPkeyUpdate | null | undefined;
  updateBySlug?: TopicOnUserTopicForUserTopicsTopicIdFkeyUsingTopicsSlugUkeyUpdate | null | undefined;
};
export type UserTopicsTopicIdFkeyTopicsCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title: string;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type UserTopicObjectivesSubtopicIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserTopicObjectiveNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesPkeyConnect> | null | undefined;
  connectByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserTopicObjectivesSubtopicIdFkeyUserTopicObjectivesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserTopicObjectiveNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesPkeyDelete> | null | undefined;
  deleteByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyUsingUserTopicObjectivesPkeyUpdate> | null | undefined;
  updateByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyUsingUserTopicObjectivesUkeyUpdate> | null | undefined;
};
export type UserTopicObjectivesSubtopicIdFkeyUserTopicObjectivesCreateInput = {
  examType?: UserTopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  topic?: UserTopicObjectivesSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserTopicObjectivesTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicObjectivesUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicObjectiveNodeIdDelete = {
  id: string;
};
export type UserTopicObjectiveUserTopicObjectivesPkeyDelete = {
  rowId: any;
};
export type UserTopicObjectiveUserTopicObjectivesUkeyDelete = {
  examTypeId: any;
  subtopicId: any;
  topicId: any;
  topicObjectiveId: any;
  userId: any;
};
export type TopicOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserTopicObjectivePatch;
};
export type UserTopicObjectivePatch = {
  examType?: UserTopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserTopicObjectivesSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserTopicObjectivesTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicObjectivesUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyUsingUserTopicObjectivesPkeyUpdate = {
  patch: updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyPatch;
  rowId: any;
};
export type updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyPatch = {
  examType?: UserTopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  topic?: UserTopicObjectivesSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserTopicObjectivesTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicObjectivesUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyUsingUserTopicObjectivesUkeyUpdate = {
  examTypeId: any;
  patch: updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyPatch;
  subtopicId: any;
  topicId: any;
  topicObjectiveId: any;
  userId: any;
};
export type UserTopicObjectivesTopicIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserTopicObjectiveNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesPkeyConnect> | null | undefined;
  connectByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserTopicObjectivesTopicIdFkeyUserTopicObjectivesCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserTopicObjectiveNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesPkeyDelete> | null | undefined;
  deleteByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveUserTopicObjectivesUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnUserTopicObjectiveForUserTopicObjectivesTopicIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicIdFkeyUsingUserTopicObjectivesPkeyUpdate> | null | undefined;
  updateByUserIdAndExamTypeIdAndTopicIdAndSubtopicIdAndTopicObjectiveId?: ReadonlyArray<UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicIdFkeyUsingUserTopicObjectivesUkeyUpdate> | null | undefined;
};
export type UserTopicObjectivesTopicIdFkeyUserTopicObjectivesCreateInput = {
  examType?: UserTopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserTopicObjectivesSubtopicIdFkeyInput | null | undefined;
  topicObjective?: UserTopicObjectivesTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicObjectivesUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type TopicOnUserTopicObjectiveForUserTopicObjectivesTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserTopicObjectivePatch;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicIdFkeyUsingUserTopicObjectivesPkeyUpdate = {
  patch: updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicIdFkeyPatch;
  rowId: any;
};
export type updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicIdFkeyPatch = {
  examType?: UserTopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserTopicObjectivesSubtopicIdFkeyInput | null | undefined;
  topicObjective?: UserTopicObjectivesTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicObjectivesUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicIdFkeyUsingUserTopicObjectivesUkeyUpdate = {
  examTypeId: any;
  patch: updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicIdFkeyPatch;
  subtopicId: any;
  topicId: any;
  topicObjectiveId: any;
  userId: any;
};
export type UserTopicsTopicIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserTopicNodeIdConnect> | null | undefined;
  connectByRowId?: ReadonlyArray<UserTopicUserTopicsPkeyConnect> | null | undefined;
  connectByUserIdAndExamTypeIdAndTopicId?: ReadonlyArray<UserTopicUserTopicsUkeyConnect> | null | undefined;
  create?: ReadonlyArray<UserTopicsTopicIdFkeyUserTopicsCreateInput> | null | undefined;
  deleteById?: ReadonlyArray<UserTopicNodeIdDelete> | null | undefined;
  deleteByRowId?: ReadonlyArray<UserTopicUserTopicsPkeyDelete> | null | undefined;
  deleteByUserIdAndExamTypeIdAndTopicId?: ReadonlyArray<UserTopicUserTopicsUkeyDelete> | null | undefined;
  deleteOthers?: boolean | null | undefined;
  updateById?: ReadonlyArray<TopicOnUserTopicForUserTopicsTopicIdFkeyNodeIdUpdate> | null | undefined;
  updateByRowId?: ReadonlyArray<UserTopicOnUserTopicForUserTopicsTopicIdFkeyUsingUserTopicsPkeyUpdate> | null | undefined;
  updateByUserIdAndExamTypeIdAndTopicId?: ReadonlyArray<UserTopicOnUserTopicForUserTopicsTopicIdFkeyUsingUserTopicsUkeyUpdate> | null | undefined;
};
export type UserTopicsTopicIdFkeyUserTopicsCreateInput = {
  examType?: UserTopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  topic?: UserTopicsTopicIdFkeyInput | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicsUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null | undefined;
  connectByRowId?: UserUsersPkeyConnect | null | undefined;
  connectBySlug?: UserUniqueSlugConnect | null | undefined;
  create?: UserTopicsUserIdFkeyUsersCreateInput | null | undefined;
  deleteById?: UserNodeIdDelete | null | undefined;
  deleteByRowId?: UserUsersPkeyDelete | null | undefined;
  deleteBySlug?: UserUniqueSlugDelete | null | undefined;
  updateById?: UserTopicOnUserTopicForUserTopicsUserIdFkeyNodeIdUpdate | null | undefined;
  updateByRowId?: UserOnUserTopicForUserTopicsUserIdFkeyUsingUsersPkeyUpdate | null | undefined;
  updateBySlug?: UserOnUserTopicForUserTopicsUserIdFkeyUsingUniqueSlugUpdate | null | undefined;
};
export type UserTopicsUserIdFkeyUsersCreateInput = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email: string;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserNodeIdDelete = {
  id: string;
};
export type UserUsersPkeyDelete = {
  rowId: any;
};
export type UserUniqueSlugDelete = {
  slug: string;
};
export type UserTopicOnUserTopicForUserTopicsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserTopicForUserTopicsUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserTopicForUserTopicsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserTopicForUserTopicsUserIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserTopicForUserTopicsUserIdFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserTopicForUserTopicsUserIdFkeyPatch;
  slug: string;
};
export type UserTopicNodeIdDelete = {
  id: string;
};
export type UserTopicUserTopicsPkeyDelete = {
  rowId: any;
};
export type UserTopicUserTopicsUkeyDelete = {
  examTypeId: any;
  topicId: any;
  userId: any;
};
export type TopicOnUserTopicForUserTopicsTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserTopicPatch;
};
export type UserTopicPatch = {
  examType?: UserTopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  topic?: UserTopicsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicOnUserTopicForUserTopicsTopicIdFkeyUsingUserTopicsPkeyUpdate = {
  patch: updateUserTopicOnUserTopicForUserTopicsTopicIdFkeyPatch;
  rowId: any;
};
export type updateUserTopicOnUserTopicForUserTopicsTopicIdFkeyPatch = {
  examType?: UserTopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  topic?: UserTopicsTopicIdFkeyInput | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicOnUserTopicForUserTopicsTopicIdFkeyUsingUserTopicsUkeyUpdate = {
  examTypeId: any;
  patch: updateUserTopicOnUserTopicForUserTopicsTopicIdFkeyPatch;
  topicId: any;
  userId: any;
};
export type TopicNodeIdDelete = {
  id: string;
};
export type TopicTopicsPkeyDelete = {
  rowId: any;
};
export type TopicTopicsSlugUkeyDelete = {
  slug: string;
};
export type UserTopicOnUserTopicForUserTopicsTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnUserTopicForUserTopicsTopicIdFkeyUsingTopicsPkeyUpdate = {
  patch: updateTopicOnUserTopicForUserTopicsTopicIdFkeyPatch;
  rowId: any;
};
export type updateTopicOnUserTopicForUserTopicsTopicIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnUserTopicForUserTopicsTopicIdFkeyUsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnUserTopicForUserTopicsTopicIdFkeyPatch;
  slug: string;
};
export type ExamTypeOnUserTopicForUserTopicsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserTopicPatch;
};
export type UserTopicOnUserTopicForUserTopicsExamTypeIdFkeyUsingUserTopicsPkeyUpdate = {
  patch: updateUserTopicOnUserTopicForUserTopicsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateUserTopicOnUserTopicForUserTopicsExamTypeIdFkeyPatch = {
  examType?: UserTopicsExamTypeIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  topic?: UserTopicsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicOnUserTopicForUserTopicsExamTypeIdFkeyUsingUserTopicsUkeyUpdate = {
  examTypeId: any;
  patch: updateUserTopicOnUserTopicForUserTopicsExamTypeIdFkeyPatch;
  topicId: any;
  userId: any;
};
export type ExamTypeNodeIdDelete = {
  id: string;
};
export type ExamTypeExamTypesPkeyDelete = {
  rowId: any;
};
export type ExamTypeExamTypesSlugUkeyDelete = {
  slug: string;
};
export type UserTopicOnUserTopicForUserTopicsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypePatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnUserTopicForUserTopicsExamTypeIdFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnUserTopicForUserTopicsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnUserTopicForUserTopicsExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnUserTopicForUserTopicsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnUserTopicForUserTopicsExamTypeIdFkeyPatch;
  slug: string;
};
export type UserOnUserTopicForUserTopicsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserTopicPatch;
};
export type UserTopicOnUserTopicForUserTopicsUserIdFkeyUsingUserTopicsPkeyUpdate = {
  patch: updateUserTopicOnUserTopicForUserTopicsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserTopicOnUserTopicForUserTopicsUserIdFkeyPatch = {
  examType?: UserTopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  topic?: UserTopicsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicsUserIdFkeyInput | null | undefined;
};
export type UserTopicOnUserTopicForUserTopicsUserIdFkeyUsingUserTopicsUkeyUpdate = {
  examTypeId: any;
  patch: updateUserTopicOnUserTopicForUserTopicsUserIdFkeyPatch;
  topicId: any;
  userId: any;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyPatch;
  slug: string;
};
export type UserOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserTopicObjectivePatch;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyUsingUserTopicObjectivesPkeyUpdate = {
  patch: updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyPatch;
  rowId: any;
};
export type updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyPatch = {
  examType?: UserTopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserTopicObjectivesSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserTopicObjectivesTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicObjectivesUserIdFkeyInput | null | undefined;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyUsingUserTopicObjectivesUkeyUpdate = {
  examTypeId: any;
  patch: updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesUserIdFkeyPatch;
  subtopicId: any;
  topicId: any;
  topicObjectiveId: any;
  userId: any;
};
export type UserStudySessionOnUserStudySessionForUserStudySessionsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserStudySessionForUserStudySessionsUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserStudySessionForUserStudySessionsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserStudySessionForUserStudySessionsUserIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserStudySessionForUserStudySessionsUserIdFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserStudySessionForUserStudySessionsUserIdFkeyPatch;
  slug: string;
};
export type UserOnUserStudySessionForUserStudySessionsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionPatch;
};
export type UserStudySessionOnUserStudySessionForUserStudySessionsUpdatedByFkeyUsingUserStudySessionsPkeyUpdate = {
  patch: updateUserStudySessionOnUserStudySessionForUserStudySessionsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionOnUserStudySessionForUserStudySessionsUpdatedByFkeyPatch = {
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionsCreatedByFkeyInput | null | undefined;
  examType?: UserStudySessionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UserStudySessionsUpdatedByFkeyInput | null | undefined;
  user?: UserStudySessionsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsUserStudySessionIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionOnUserStudySessionForUserStudySessionsUpdatedByFkeyUsingUserStudySessionsSlugUkeyUpdate = {
  patch: updateUserStudySessionOnUserStudySessionForUserStudySessionsUpdatedByFkeyPatch;
  slug: string;
};
export type UserStudySessionOnUserStudySessionForUserStudySessionsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserStudySessionForUserStudySessionsUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserStudySessionForUserStudySessionsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnUserStudySessionForUserStudySessionsUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserStudySessionForUserStudySessionsUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserStudySessionForUserStudySessionsUpdatedByFkeyPatch;
  slug: string;
};
export type UserOnUserStudySessionForUserStudySessionsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionPatch;
};
export type UserStudySessionOnUserStudySessionForUserStudySessionsCreatedByFkeyUsingUserStudySessionsPkeyUpdate = {
  patch: updateUserStudySessionOnUserStudySessionForUserStudySessionsCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionOnUserStudySessionForUserStudySessionsCreatedByFkeyPatch = {
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdByUser?: UserStudySessionsCreatedByFkeyInput | null | undefined;
  examType?: UserStudySessionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionsUpdatedByFkeyInput | null | undefined;
  user?: UserStudySessionsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsUserStudySessionIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionOnUserStudySessionForUserStudySessionsCreatedByFkeyUsingUserStudySessionsSlugUkeyUpdate = {
  patch: updateUserStudySessionOnUserStudySessionForUserStudySessionsCreatedByFkeyPatch;
  slug: string;
};
export type UserSubtopicOnUserSubtopicForUserSubtopicsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserSubtopicForUserSubtopicsUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserSubtopicForUserSubtopicsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserSubtopicForUserSubtopicsUserIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserSubtopicForUserSubtopicsUserIdFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserSubtopicForUserSubtopicsUserIdFkeyPatch;
  slug: string;
};
export type TopicOnUserSubtopicForUserSubtopicsTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserSubtopicPatch;
};
export type UserSubtopicOnUserSubtopicForUserSubtopicsTopicIdFkeyUsingUserSubtopicsPkeyUpdate = {
  patch: updateUserSubtopicOnUserSubtopicForUserSubtopicsTopicIdFkeyPatch;
  rowId: any;
};
export type updateUserSubtopicOnUserSubtopicForUserSubtopicsTopicIdFkeyPatch = {
  examType?: UserSubtopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserSubtopicsSubtopicIdFkeyInput | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserSubtopicsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserSubtopicOnUserSubtopicForUserSubtopicsTopicIdFkeyUsingUserSubtopicsUkeyUpdate = {
  examTypeId: any;
  patch: updateUserSubtopicOnUserSubtopicForUserSubtopicsTopicIdFkeyPatch;
  subtopicId: any;
  topicId: any;
  userId: any;
};
export type UserSubtopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyUsingTopicsPkeyUpdate = {
  patch: updateTopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyPatch;
  rowId: any;
};
export type updateTopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyUsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyPatch;
  slug: string;
};
export type TopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserSubtopicPatch;
};
export type UserSubtopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyUsingUserSubtopicsPkeyUpdate = {
  patch: updateUserSubtopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyPatch;
  rowId: any;
};
export type updateUserSubtopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyPatch = {
  examType?: UserSubtopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  topic?: UserSubtopicsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserSubtopicsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserSubtopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyUsingUserSubtopicsUkeyUpdate = {
  examTypeId: any;
  patch: updateUserSubtopicOnUserSubtopicForUserSubtopicsSubtopicIdFkeyPatch;
  subtopicId: any;
  topicId: any;
  userId: any;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyUsingTopicsPkeyUpdate = {
  patch: updateTopicOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyPatch;
  rowId: any;
};
export type updateTopicOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyUsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnUserTopicObjectiveForUserTopicObjectivesSubtopicIdFkeyPatch;
  slug: string;
};
export type ExamTypeOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserTopicObjectivePatch;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyUsingUserTopicObjectivesPkeyUpdate = {
  patch: updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyPatch = {
  examType?: UserTopicObjectivesExamTypeIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserTopicObjectivesSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserTopicObjectivesTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicObjectivesUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyUsingUserTopicObjectivesUkeyUpdate = {
  examTypeId: any;
  patch: updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyPatch;
  subtopicId: any;
  topicId: any;
  topicObjectiveId: any;
  userId: any;
};
export type UserSubtopicOnUserSubtopicForUserSubtopicsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnUserSubtopicForUserSubtopicsExamTypeIdFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnUserSubtopicForUserSubtopicsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnUserSubtopicForUserSubtopicsExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnUserSubtopicForUserSubtopicsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnUserSubtopicForUserSubtopicsExamTypeIdFkeyPatch;
  slug: string;
};
export type ExamTypeOnUserSubtopicForUserSubtopicsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserSubtopicPatch;
};
export type UserSubtopicOnUserSubtopicForUserSubtopicsExamTypeIdFkeyUsingUserSubtopicsPkeyUpdate = {
  patch: updateUserSubtopicOnUserSubtopicForUserSubtopicsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateUserSubtopicOnUserSubtopicForUserSubtopicsExamTypeIdFkeyPatch = {
  examType?: UserSubtopicsExamTypeIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserSubtopicsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserSubtopicsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserSubtopicOnUserSubtopicForUserSubtopicsExamTypeIdFkeyUsingUserSubtopicsUkeyUpdate = {
  examTypeId: any;
  patch: updateUserSubtopicOnUserSubtopicForUserSubtopicsExamTypeIdFkeyPatch;
  subtopicId: any;
  topicId: any;
  userId: any;
};
export type UserStudySessionOnUserStudySessionForUserStudySessionsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnUserStudySessionForUserStudySessionsExamTypeIdFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnUserStudySessionForUserStudySessionsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnUserStudySessionForUserStudySessionsExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnUserStudySessionForUserStudySessionsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnUserStudySessionForUserStudySessionsExamTypeIdFkeyPatch;
  slug: string;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionPatch;
};
export type UserStudySessionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyUsingUserStudySessionsPkeyUpdate = {
  patch: updateUserStudySessionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyPatch = {
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionsCreatedByFkeyInput | null | undefined;
  examType?: UserStudySessionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionsUpdatedByFkeyInput | null | undefined;
  user?: UserStudySessionsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsUserStudySessionIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyUsingUserStudySessionsSlugUkeyUpdate = {
  patch: updateUserStudySessionOnUserStudySessionQuestionForUserStudySessionQuestionsUserStudySessionIdFkeyPatch;
  slug: string;
};
export type UserOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionQuestionPatch;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyUsingUserStudySessionQuestionsPkeyUpdate = {
  patch: updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyPatch = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserStudySessionQuestionForUserStudySessionQuestionsUpdatedByFkeyPatch;
  slug: string;
};
export type UserOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionQuestionPatch;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyUsingUserStudySessionQuestionsPkeyUpdate = {
  patch: updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyPatch = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5NodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5UsingUsersPkeyUpdate = {
  patch: updateUserOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5Patch;
  rowId: any;
};
export type updateUserOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5Patch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5UsingUniqueSlugUpdate = {
  patch: updateUserOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5Patch;
  slug: string;
};
export type UserOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5NodeIdUpdate = {
  id: string;
  patch: UserQuestionAnswerPatch;
};
export type UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5UsingFakeAppPublicUserQuestionAnswersPrimaryKeyUpdate = {
  patch: updateUserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5Patch;
  rowId: any;
};
export type updateUserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey5Patch = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  examTypeId?: any | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  topicObjectiveId?: any | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
};
export type UserLessonOnUserLessonForUserLessonsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserLessonForUserLessonsUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserLessonForUserLessonsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserLessonForUserLessonsUserIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserLessonForUserLessonsUserIdFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserLessonForUserLessonsUserIdFkeyPatch;
  slug: string;
};
export type UserOnUserLessonForUserLessonsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserLessonPatch;
};
export type UserLessonOnUserLessonForUserLessonsUpdatedByFkeyUsingUserLessonsPkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserLessonOnUserLessonForUserLessonsUpdatedByFkeyPatch = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonOnUserLessonForUserLessonsUpdatedByFkeyUsingUserLessonsSlugUkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsUpdatedByFkeyPatch;
  slug: string;
};
export type UserLessonOnUserLessonForUserLessonsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserLessonForUserLessonsUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserLessonForUserLessonsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnUserLessonForUserLessonsUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserLessonForUserLessonsUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserLessonForUserLessonsUpdatedByFkeyPatch;
  slug: string;
};
export type UserOnUserLessonForUserLessonsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserLessonPatch;
};
export type UserLessonOnUserLessonForUserLessonsCreatedByFkeyUsingUserLessonsPkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserLessonOnUserLessonForUserLessonsCreatedByFkeyPatch = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonOnUserLessonForUserLessonsCreatedByFkeyUsingUserLessonsSlugUkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsCreatedByFkeyPatch;
  slug: string;
};
export type UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyPatch;
  slug: string;
};
export type UserOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserLessonQuestionPatch;
};
export type UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyUsingUserLessonQuestionsPkeyUpdate = {
  patch: updateUserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsUpdatedByFkeyPatch = {
  attempts?: number | null | undefined;
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserLessonQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UserLessonQuestionsUpdatedByFkeyInput | null | undefined;
  userLesson?: UserLessonQuestionsUserLessonIdFkeyInput | null | undefined;
  userLessonId?: any | null | undefined;
};
export type UserStudySessionOnUserStudySessionForUserStudySessionsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserStudySessionForUserStudySessionsCreatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserStudySessionForUserStudySessionsCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnUserStudySessionForUserStudySessionsCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserStudySessionForUserStudySessionsCreatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserStudySessionForUserStudySessionsCreatedByFkeyPatch;
  slug: string;
};
export type ExamTypeOnUserStudySessionForUserStudySessionsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionPatch;
};
export type UserStudySessionOnUserStudySessionForUserStudySessionsExamTypeIdFkeyUsingUserStudySessionsPkeyUpdate = {
  patch: updateUserStudySessionOnUserStudySessionForUserStudySessionsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionOnUserStudySessionForUserStudySessionsExamTypeIdFkeyPatch = {
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionsCreatedByFkeyInput | null | undefined;
  examType?: UserStudySessionsExamTypeIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionsUpdatedByFkeyInput | null | undefined;
  user?: UserStudySessionsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsUserStudySessionIdFkeyInverseInput | null | undefined;
};
export type UserStudySessionOnUserStudySessionForUserStudySessionsExamTypeIdFkeyUsingUserStudySessionsSlugUkeyUpdate = {
  patch: updateUserStudySessionOnUserStudySessionForUserStudySessionsExamTypeIdFkeyPatch;
  slug: string;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnUserTopicObjectiveForUserTopicObjectivesExamTypeIdFkeyPatch;
  slug: string;
};
export type TopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserTopicObjectivePatch;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyUsingUserTopicObjectivesPkeyUpdate = {
  patch: updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyPatch;
  rowId: any;
};
export type updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyPatch = {
  examType?: UserTopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserTopicObjectivesSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserTopicObjectivesTopicObjectiveIdFkeyInput | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  user?: UserTopicObjectivesUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type UserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyUsingUserTopicObjectivesUkeyUpdate = {
  examTypeId: any;
  patch: updateUserTopicObjectiveOnUserTopicObjectiveForUserTopicObjectivesTopicObjectiveIdFkeyPatch;
  subtopicId: any;
  topicId: any;
  topicObjectiveId: any;
  userId: any;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicObjectivePatch;
};
export type TopicObjectiveOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyUsingTopicObjectivesPkeyUpdate = {
  patch: updateTopicObjectiveOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyPatch;
  rowId: any;
};
export type updateTopicObjectiveOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectiveOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyUsingTopicObjectivesSlugUkeyUpdate = {
  patch: updateTopicObjectiveOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyPatch;
  slug: string;
};
export type TopicObjectiveOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionQuestionPatch;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyUsingUserStudySessionQuestionsPkeyUpdate = {
  patch: updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsTopicObjectiveIdFkeyPatch = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4NodeIdUpdate = {
  id: string;
  patch: TopicObjectivePatch;
};
export type TopicObjectiveOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4UsingTopicObjectivesPkeyUpdate = {
  patch: updateTopicObjectiveOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4Patch;
  rowId: any;
};
export type updateTopicObjectiveOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4Patch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectiveOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4UsingTopicObjectivesSlugUkeyUpdate = {
  patch: updateTopicObjectiveOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4Patch;
  slug: string;
};
export type TopicOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey2NodeIdUpdate = {
  id: string;
  patch: UserQuestionAnswerPatch;
};
export type UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey2UsingFakeAppPublicUserQuestionAnswersPrimaryKeyUpdate = {
  patch: updateUserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey2Patch;
  rowId: any;
};
export type updateUserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey2Patch = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  examTypeId?: any | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  topicObjectiveId?: any | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
  userId?: any | null | undefined;
};
export type UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3NodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3UsingTopicsPkeyUpdate = {
  patch: updateTopicOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3Patch;
  rowId: any;
};
export type updateTopicOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3Patch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3UsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey3Patch;
  slug: string;
};
export type TopicObjectiveOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4NodeIdUpdate = {
  id: string;
  patch: UserQuestionAnswerPatch;
};
export type UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4UsingFakeAppPublicUserQuestionAnswersPrimaryKeyUpdate = {
  patch: updateUserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4Patch;
  rowId: any;
};
export type updateUserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey4Patch = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  examTypeId?: any | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
  userId?: any | null | undefined;
};
export type UserLessonOnUserLessonForUserLessonsTopicObjectiveIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicObjectivePatch;
};
export type TopicObjectiveOnUserLessonForUserLessonsTopicObjectiveIdFkeyUsingTopicObjectivesPkeyUpdate = {
  patch: updateTopicObjectiveOnUserLessonForUserLessonsTopicObjectiveIdFkeyPatch;
  rowId: any;
};
export type updateTopicObjectiveOnUserLessonForUserLessonsTopicObjectiveIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectiveOnUserLessonForUserLessonsTopicObjectiveIdFkeyUsingTopicObjectivesSlugUkeyUpdate = {
  patch: updateTopicObjectiveOnUserLessonForUserLessonsTopicObjectiveIdFkeyPatch;
  slug: string;
};
export type TopicOnUserLessonForUserLessonsTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserLessonPatch;
};
export type UserLessonOnUserLessonForUserLessonsTopicIdFkeyUsingUserLessonsPkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsTopicIdFkeyPatch;
  rowId: any;
};
export type updateUserLessonOnUserLessonForUserLessonsTopicIdFkeyPatch = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonOnUserLessonForUserLessonsTopicIdFkeyUsingUserLessonsSlugUkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsTopicIdFkeyPatch;
  slug: string;
};
export type UserLessonOnUserLessonForUserLessonsSubtopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicOnUserLessonForUserLessonsSubtopicIdFkeyUsingTopicsPkeyUpdate = {
  patch: updateTopicOnUserLessonForUserLessonsSubtopicIdFkeyPatch;
  rowId: any;
};
export type updateTopicOnUserLessonForUserLessonsSubtopicIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnUserLessonForUserLessonsSubtopicIdFkeyUsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnUserLessonForUserLessonsSubtopicIdFkeyPatch;
  slug: string;
};
export type TopicOnUserLessonForUserLessonsSubtopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserLessonPatch;
};
export type UserLessonOnUserLessonForUserLessonsSubtopicIdFkeyUsingUserLessonsPkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsSubtopicIdFkeyPatch;
  rowId: any;
};
export type updateUserLessonOnUserLessonForUserLessonsSubtopicIdFkeyPatch = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonOnUserLessonForUserLessonsSubtopicIdFkeyUsingUserLessonsSlugUkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsSubtopicIdFkeyPatch;
  slug: string;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyUsingTopicsPkeyUpdate = {
  patch: updateTopicOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyPatch;
  rowId: any;
};
export type updateTopicOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyUsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnUserStudySessionQuestionForUserStudySessionQuestionsSubtopicIdFkeyPatch;
  slug: string;
};
export type QuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionQuestionPatch;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyUsingUserStudySessionQuestionsPkeyUpdate = {
  patch: updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionIdFkeyPatch = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionAnswerId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1NodeIdUpdate = {
  id: string;
  patch: QuestionPatch;
};
export type QuestionOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1UsingQuestionsPkeyUpdate = {
  patch: updateQuestionOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1Patch;
  rowId: any;
};
export type updateQuestionOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1Patch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1UsingQuestionsSlugUkeyUpdate = {
  patch: updateQuestionOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1Patch;
  slug: string;
};
export type ExamTypeOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0NodeIdUpdate = {
  id: string;
  patch: UserQuestionAnswerPatch;
};
export type UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0UsingFakeAppPublicUserQuestionAnswersPrimaryKeyUpdate = {
  patch: updateUserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0Patch;
  rowId: any;
};
export type updateUserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0Patch = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  topicObjectiveId?: any | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
  userId?: any | null | undefined;
};
export type UserLessonOnUserLessonForUserLessonsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnUserLessonForUserLessonsExamTypeIdFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnUserLessonForUserLessonsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnUserLessonForUserLessonsExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnUserLessonForUserLessonsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnUserLessonForUserLessonsExamTypeIdFkeyPatch;
  slug: string;
};
export type ExamTypeOnUserLessonForUserLessonsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserLessonPatch;
};
export type UserLessonOnUserLessonForUserLessonsExamTypeIdFkeyUsingUserLessonsPkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateUserLessonOnUserLessonForUserLessonsExamTypeIdFkeyPatch = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonOnUserLessonForUserLessonsExamTypeIdFkeyUsingUserLessonsSlugUkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsExamTypeIdFkeyPatch;
  slug: string;
};
export type UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0NodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0UsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0Patch;
  rowId: any;
};
export type updateExamTypeOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0Patch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0UsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey0Patch;
  slug: string;
};
export type QuestionOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1NodeIdUpdate = {
  id: string;
  patch: UserQuestionAnswerPatch;
};
export type UserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1UsingFakeAppPublicUserQuestionAnswersPrimaryKeyUpdate = {
  patch: updateUserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1Patch;
  rowId: any;
};
export type updateUserQuestionAnswerOnUserQuestionAnswerForFakeAppPublicUserQuestionAnswersForeignKey1Patch = {
  completedAt?: any | null | undefined;
  duration?: IntervalInput | null | undefined;
  examType?: FakeAppPublicUserQuestionAnswersForeignKey0Input | null | undefined;
  examTypeId?: any | null | undefined;
  question?: FakeAppPublicUserQuestionAnswersForeignKey1Input | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: FakeAppPublicUserQuestionAnswersForeignKey3Input | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: FakeAppPublicUserQuestionAnswersForeignKey4Input | null | undefined;
  topicObjectiveId?: any | null | undefined;
  type?: string | null | undefined;
  user?: FakeAppPublicUserQuestionAnswersForeignKey5Input | null | undefined;
  userId?: any | null | undefined;
};
export type UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionPatch;
};
export type QuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyUsingQuestionsPkeyUpdate = {
  patch: updateQuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyPatch;
  rowId: any;
};
export type updateQuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyUsingQuestionsSlugUkeyUpdate = {
  patch: updateQuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyPatch;
  slug: string;
};
export type UserOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserLessonQuestionPatch;
};
export type UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyUsingUserLessonQuestionsPkeyUpdate = {
  patch: updateUserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyPatch = {
  attempts?: number | null | undefined;
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdByUser?: UserLessonQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserLessonQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonQuestionsUpdatedByFkeyInput | null | undefined;
  userLesson?: UserLessonQuestionsUserLessonIdFkeyInput | null | undefined;
  userLessonId?: any | null | undefined;
};
export type UserOnUserForUsersUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserForUsersUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserForUsersUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnUserForUsersUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserForUsersUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserForUsersUpdatedByFkeyPatch;
  slug: string;
};
export type TopicOnTopicForTopicsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnTopicForTopicsUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnTopicForTopicsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnTopicForTopicsUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnTopicForTopicsUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnTopicForTopicsUpdatedByFkeyPatch;
  slug: string;
};
export type UserOnTopicForTopicsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicOnTopicForTopicsUpdatedByFkeyUsingTopicsPkeyUpdate = {
  patch: updateTopicOnTopicForTopicsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateTopicOnTopicForTopicsUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnTopicForTopicsUpdatedByFkeyUsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnTopicForTopicsUpdatedByFkeyPatch;
  slug: string;
};
export type UserLessonOnUserLessonForUserLessonsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserLessonForUserLessonsCreatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserLessonForUserLessonsCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnUserLessonForUserLessonsCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserLessonForUserLessonsCreatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserLessonForUserLessonsCreatedByFkeyPatch;
  slug: string;
};
export type TopicObjectiveOnUserLessonForUserLessonsTopicObjectiveIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserLessonPatch;
};
export type UserLessonOnUserLessonForUserLessonsTopicObjectiveIdFkeyUsingUserLessonsPkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsTopicObjectiveIdFkeyPatch;
  rowId: any;
};
export type updateUserLessonOnUserLessonForUserLessonsTopicObjectiveIdFkeyPatch = {
  averageAttemptsPerQuestion?: number | null | undefined;
  averageTimePerCorrectQuestion?: IntervalInput | null | undefined;
  averageTimePerQuestion?: IntervalInput | null | undefined;
  completedAt?: any | null | undefined;
  correctQuestionsCount?: number | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonsCreatedByFkeyInput | null | undefined;
  examType?: UserLessonsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserLessonsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserLessonsTopicObjectiveIdFkeyInput | null | undefined;
  totalQuestionsCount?: number | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonsUpdatedByFkeyInput | null | undefined;
  user?: UserLessonsUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
  userLessonQuestions?: UserLessonQuestionsUserLessonIdFkeyInverseInput | null | undefined;
};
export type UserLessonOnUserLessonForUserLessonsTopicObjectiveIdFkeyUsingUserLessonsSlugUkeyUpdate = {
  patch: updateUserLessonOnUserLessonForUserLessonsTopicObjectiveIdFkeyPatch;
  slug: string;
};
export type TopicOnTopicObjectiveForTopicObjectivesTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicObjectivePatch;
};
export type TopicObjectiveOnTopicObjectiveForTopicObjectivesTopicIdFkeyUsingTopicObjectivesPkeyUpdate = {
  patch: updateTopicObjectiveOnTopicObjectiveForTopicObjectivesTopicIdFkeyPatch;
  rowId: any;
};
export type updateTopicObjectiveOnTopicObjectiveForTopicObjectivesTopicIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectiveOnTopicObjectiveForTopicObjectivesTopicIdFkeyUsingTopicObjectivesSlugUkeyUpdate = {
  patch: updateTopicObjectiveOnTopicObjectiveForTopicObjectivesTopicIdFkeyPatch;
  slug: string;
};
export type TopicOnTopicForTopicsParentTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicOnTopicForTopicsParentTopicIdFkeyUsingTopicsPkeyUpdate = {
  patch: updateTopicOnTopicForTopicsParentTopicIdFkeyPatch;
  rowId: any;
};
export type updateTopicOnTopicForTopicsParentTopicIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnTopicForTopicsParentTopicIdFkeyUsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnTopicForTopicsParentTopicIdFkeyPatch;
  slug: string;
};
export type UserOnTopicForTopicsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicOnTopicForTopicsCreatedByFkeyUsingTopicsPkeyUpdate = {
  patch: updateTopicOnTopicForTopicsCreatedByFkeyPatch;
  rowId: any;
};
export type updateTopicOnTopicForTopicsCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnTopicForTopicsCreatedByFkeyUsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnTopicForTopicsCreatedByFkeyPatch;
  slug: string;
};
export type TopicObjectiveOnTopicObjectiveForTopicObjectivesUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnTopicObjectiveForTopicObjectivesUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnTopicObjectiveForTopicObjectivesUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnTopicObjectiveForTopicObjectivesUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnTopicObjectiveForTopicObjectivesUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnTopicObjectiveForTopicObjectivesUpdatedByFkeyPatch;
  slug: string;
};
export type UserOnTopicObjectiveForTopicObjectivesUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: TopicObjectivePatch;
};
export type TopicObjectiveOnTopicObjectiveForTopicObjectivesUpdatedByFkeyUsingTopicObjectivesPkeyUpdate = {
  patch: updateTopicObjectiveOnTopicObjectiveForTopicObjectivesUpdatedByFkeyPatch;
  rowId: any;
};
export type updateTopicObjectiveOnTopicObjectiveForTopicObjectivesUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectiveOnTopicObjectiveForTopicObjectivesUpdatedByFkeyUsingTopicObjectivesSlugUkeyUpdate = {
  patch: updateTopicObjectiveOnTopicObjectiveForTopicObjectivesUpdatedByFkeyPatch;
  slug: string;
};
export type UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserLessonQuestionForUserLessonQuestionsCreatedByFkeyPatch;
  slug: string;
};
export type QuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserLessonQuestionPatch;
};
export type UserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyUsingUserLessonQuestionsPkeyUpdate = {
  patch: updateUserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyPatch;
  rowId: any;
};
export type updateUserLessonQuestionOnUserLessonQuestionForUserLessonQuestionsQuestionIdFkeyPatch = {
  attempts?: number | null | undefined;
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserLessonQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserLessonQuestionsQuestionIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserLessonQuestionsUpdatedByFkeyInput | null | undefined;
  userLesson?: UserLessonQuestionsUserLessonIdFkeyInput | null | undefined;
  userLessonId?: any | null | undefined;
};
export type TopicOnQuestionForQuestionsTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionPatch;
};
export type QuestionOnQuestionForQuestionsTopicIdFkeyUsingQuestionsPkeyUpdate = {
  patch: updateQuestionOnQuestionForQuestionsTopicIdFkeyPatch;
  rowId: any;
};
export type updateQuestionOnQuestionForQuestionsTopicIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionOnQuestionForQuestionsTopicIdFkeyUsingQuestionsSlugUkeyUpdate = {
  patch: updateQuestionOnQuestionForQuestionsTopicIdFkeyPatch;
  slug: string;
};
export type TopicObjectiveOnTopicObjectiveForTopicObjectivesTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicOnTopicObjectiveForTopicObjectivesTopicIdFkeyUsingTopicsPkeyUpdate = {
  patch: updateTopicOnTopicObjectiveForTopicObjectivesTopicIdFkeyPatch;
  rowId: any;
};
export type updateTopicOnTopicObjectiveForTopicObjectivesTopicIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnTopicObjectiveForTopicObjectivesTopicIdFkeyUsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnTopicObjectiveForTopicObjectivesTopicIdFkeyPatch;
  slug: string;
};
export type UserOnTopicObjectiveForTopicObjectivesCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: TopicObjectivePatch;
};
export type TopicObjectiveOnTopicObjectiveForTopicObjectivesCreatedByFkeyUsingTopicObjectivesPkeyUpdate = {
  patch: updateTopicObjectiveOnTopicObjectiveForTopicObjectivesCreatedByFkeyPatch;
  rowId: any;
};
export type updateTopicObjectiveOnTopicObjectiveForTopicObjectivesCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectiveOnTopicObjectiveForTopicObjectivesCreatedByFkeyUsingTopicObjectivesSlugUkeyUpdate = {
  patch: updateTopicObjectiveOnTopicObjectiveForTopicObjectivesCreatedByFkeyPatch;
  slug: string;
};
export type QuestionOnQuestionForQuestionsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnQuestionForQuestionsUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnQuestionForQuestionsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnQuestionForQuestionsUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnQuestionForQuestionsUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnQuestionForQuestionsUpdatedByFkeyPatch;
  slug: string;
};
export type TopicObjectiveOnQuestionForQuestionsTopicObjectiveIdFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionPatch;
};
export type QuestionOnQuestionForQuestionsTopicObjectiveIdFkeyUsingQuestionsPkeyUpdate = {
  patch: updateQuestionOnQuestionForQuestionsTopicObjectiveIdFkeyPatch;
  rowId: any;
};
export type updateQuestionOnQuestionForQuestionsTopicObjectiveIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionOnQuestionForQuestionsTopicObjectiveIdFkeyUsingQuestionsSlugUkeyUpdate = {
  patch: updateQuestionOnQuestionForQuestionsTopicObjectiveIdFkeyPatch;
  slug: string;
};
export type QuestionOnQuestionForQuestionsTopicObjectiveIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicObjectivePatch;
};
export type TopicObjectiveOnQuestionForQuestionsTopicObjectiveIdFkeyUsingTopicObjectivesPkeyUpdate = {
  patch: updateTopicObjectiveOnQuestionForQuestionsTopicObjectiveIdFkeyPatch;
  rowId: any;
};
export type updateTopicObjectiveOnQuestionForQuestionsTopicObjectiveIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectiveOnQuestionForQuestionsTopicObjectiveIdFkeyUsingTopicObjectivesSlugUkeyUpdate = {
  patch: updateTopicObjectiveOnQuestionForQuestionsTopicObjectiveIdFkeyPatch;
  slug: string;
};
export type UserOnQuestionForQuestionsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionPatch;
};
export type QuestionOnQuestionForQuestionsUpdatedByFkeyUsingQuestionsPkeyUpdate = {
  patch: updateQuestionOnQuestionForQuestionsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateQuestionOnQuestionForQuestionsUpdatedByFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionOnQuestionForQuestionsUpdatedByFkeyUsingQuestionsSlugUkeyUpdate = {
  patch: updateQuestionOnQuestionForQuestionsUpdatedByFkeyPatch;
  slug: string;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyPatch;
  slug: string;
};
export type TopicOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedQuestionPatch;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyUsingGeneratedQuestionsPkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyPatch;
  rowId: any;
};
export type updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyUsingGeneratedQuestionsSlugUkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyPatch;
  slug: string;
};
export type QuestionOnQuestionForQuestionsTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicOnQuestionForQuestionsTopicIdFkeyUsingTopicsPkeyUpdate = {
  patch: updateTopicOnQuestionForQuestionsTopicIdFkeyPatch;
  rowId: any;
};
export type updateTopicOnQuestionForQuestionsTopicIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnQuestionForQuestionsTopicIdFkeyUsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnQuestionForQuestionsTopicIdFkeyPatch;
  slug: string;
};
export type UserOnQuestionForQuestionsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionPatch;
};
export type QuestionOnQuestionForQuestionsCreatedByFkeyUsingQuestionsPkeyUpdate = {
  patch: updateQuestionOnQuestionForQuestionsCreatedByFkeyPatch;
  rowId: any;
};
export type updateQuestionOnQuestionForQuestionsCreatedByFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionOnQuestionForQuestionsCreatedByFkeyUsingQuestionsSlugUkeyUpdate = {
  patch: updateQuestionOnQuestionForQuestionsCreatedByFkeyPatch;
  slug: string;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserStudySessionQuestionForUserStudySessionQuestionsCreatedByFkeyPatch;
  slug: string;
};
export type QuestionAnswerOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserStudySessionQuestionPatch;
};
export type UserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyUsingUserStudySessionQuestionsPkeyUpdate = {
  patch: updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyPatch;
  rowId: any;
};
export type updateUserStudySessionQuestionOnUserStudySessionQuestionForUserStudySessionQuestionsQuestionAnswerIdFkeyPatch = {
  completedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UserStudySessionQuestionsCreatedByFkeyInput | null | undefined;
  question?: UserStudySessionQuestionsQuestionIdFkeyInput | null | undefined;
  questionAnswer?: UserStudySessionQuestionsQuestionAnswerIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  startedAt?: any | null | undefined;
  subtopicId?: any | null | undefined;
  topic?: UserStudySessionQuestionsSubtopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: UserStudySessionQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UserStudySessionQuestionsUpdatedByFkeyInput | null | undefined;
  userStudySession?: UserStudySessionQuestionsUserStudySessionIdFkeyInput | null | undefined;
  userStudySessionId?: any | null | undefined;
};
export type UserOnQuestionAnswerForQuestionAnswersUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionAnswerPatch;
};
export type QuestionAnswerOnQuestionAnswerForQuestionAnswersUpdatedByFkeyUsingQuestionAnswersPkeyUpdate = {
  patch: updateQuestionAnswerOnQuestionAnswerForQuestionAnswersUpdatedByFkeyPatch;
  rowId: any;
};
export type updateQuestionAnswerOnQuestionAnswerForQuestionAnswersUpdatedByFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionAnswersCreatedByFkeyInput | null | undefined;
  examType?: QuestionAnswersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  isActive?: boolean | null | undefined;
  isCorrect?: boolean | null | undefined;
  question?: QuestionAnswersQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: QuestionAnswersUpdatedByFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionAnswerIdFkeyInverseInput | null | undefined;
};
export type QuestionAnswerOnQuestionAnswerForQuestionAnswersUpdatedByFkeyUsingQuestionAnswersSlugUkeyUpdate = {
  patch: updateQuestionAnswerOnQuestionAnswerForQuestionAnswersUpdatedByFkeyPatch;
  slug: string;
};
export type QuestionAnswerOnQuestionAnswerForQuestionAnswersUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnQuestionAnswerForQuestionAnswersUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnQuestionAnswerForQuestionAnswersUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnQuestionAnswerForQuestionAnswersUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnQuestionAnswerForQuestionAnswersUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnQuestionAnswerForQuestionAnswersUpdatedByFkeyPatch;
  slug: string;
};
export type QuestionOnQuestionAnswerForQuestionAnswersQuestionIdFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionAnswerPatch;
};
export type QuestionAnswerOnQuestionAnswerForQuestionAnswersQuestionIdFkeyUsingQuestionAnswersPkeyUpdate = {
  patch: updateQuestionAnswerOnQuestionAnswerForQuestionAnswersQuestionIdFkeyPatch;
  rowId: any;
};
export type updateQuestionAnswerOnQuestionAnswerForQuestionAnswersQuestionIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionAnswersCreatedByFkeyInput | null | undefined;
  examType?: QuestionAnswersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  isActive?: boolean | null | undefined;
  isCorrect?: boolean | null | undefined;
  question?: QuestionAnswersQuestionIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionAnswersUpdatedByFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionAnswerIdFkeyInverseInput | null | undefined;
};
export type QuestionAnswerOnQuestionAnswerForQuestionAnswersQuestionIdFkeyUsingQuestionAnswersSlugUkeyUpdate = {
  patch: updateQuestionAnswerOnQuestionAnswerForQuestionAnswersQuestionIdFkeyPatch;
  slug: string;
};
export type QuestionAnswerOnQuestionAnswerForQuestionAnswersQuestionIdFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionPatch;
};
export type QuestionOnQuestionAnswerForQuestionAnswersQuestionIdFkeyUsingQuestionsPkeyUpdate = {
  patch: updateQuestionOnQuestionAnswerForQuestionAnswersQuestionIdFkeyPatch;
  rowId: any;
};
export type updateQuestionOnQuestionAnswerForQuestionAnswersQuestionIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionOnQuestionAnswerForQuestionAnswersQuestionIdFkeyUsingQuestionsSlugUkeyUpdate = {
  patch: updateQuestionOnQuestionAnswerForQuestionAnswersQuestionIdFkeyPatch;
  slug: string;
};
export type UserOnQuestionAnswerForQuestionAnswersCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionAnswerPatch;
};
export type QuestionAnswerOnQuestionAnswerForQuestionAnswersCreatedByFkeyUsingQuestionAnswersPkeyUpdate = {
  patch: updateQuestionAnswerOnQuestionAnswerForQuestionAnswersCreatedByFkeyPatch;
  rowId: any;
};
export type updateQuestionAnswerOnQuestionAnswerForQuestionAnswersCreatedByFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdByUser?: QuestionAnswersCreatedByFkeyInput | null | undefined;
  examType?: QuestionAnswersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  isActive?: boolean | null | undefined;
  isCorrect?: boolean | null | undefined;
  question?: QuestionAnswersQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionAnswersUpdatedByFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionAnswerIdFkeyInverseInput | null | undefined;
};
export type QuestionAnswerOnQuestionAnswerForQuestionAnswersCreatedByFkeyUsingQuestionAnswersSlugUkeyUpdate = {
  patch: updateQuestionAnswerOnQuestionAnswerForQuestionAnswersCreatedByFkeyPatch;
  slug: string;
};
export type GenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnGenerativeAiResponseForGenerativeAiResponsesUpdatedByFkeyPatch;
  slug: string;
};
export type UserOnGenerativeAiResponseForGenerativeAiResponsesCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: GenerativeAiResponsePatch;
};
export type GenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesCreatedByFkeyUsingGenerativeAiResponsesPkeyUpdate = {
  patch: updateGenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesCreatedByFkeyPatch;
  rowId: any;
};
export type updateGenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesCreatedByFkeyPatch = {
  context?: any | null | undefined;
  createdAt?: any | null | undefined;
  genType?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  model?: string | null | undefined;
  prompt?: string | null | undefined;
  result?: string | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GenerativeAiResponsesUpdatedByFkeyInput | null | undefined;
};
export type GenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesCreatedByFkeyUsingGenerativeAiResponsesSlugUkeyUpdate = {
  patch: updateGenerativeAiResponseOnGenerativeAiResponseForGenerativeAiResponsesCreatedByFkeyPatch;
  slug: string;
};
export type ExamTypeOnExamTypeForExamTypesUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnExamTypeForExamTypesUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnExamTypeForExamTypesUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnExamTypeForExamTypesUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnExamTypeForExamTypesUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnExamTypeForExamTypesUpdatedByFkeyPatch;
  slug: string;
};
export type TopicOnTopicForTopicsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnTopicForTopicsExamTypeIdFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnTopicForTopicsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnTopicForTopicsExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnTopicForTopicsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnTopicForTopicsExamTypeIdFkeyPatch;
  slug: string;
};
export type ExamTypeOnTopicForTopicsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicOnTopicForTopicsExamTypeIdFkeyUsingTopicsPkeyUpdate = {
  patch: updateTopicOnTopicForTopicsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateTopicOnTopicForTopicsExamTypeIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnTopicForTopicsExamTypeIdFkeyUsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnTopicForTopicsExamTypeIdFkeyPatch;
  slug: string;
};
export type TopicObjectiveOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyPatch;
  slug: string;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicObjectivePatch;
};
export type TopicObjectiveOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyUsingTopicObjectivesPkeyUpdate = {
  patch: updateTopicObjectiveOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyPatch;
  rowId: any;
};
export type updateTopicObjectiveOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectiveOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyUsingTopicObjectivesSlugUkeyUpdate = {
  patch: updateTopicObjectiveOnGeneratedQuestionForGeneratedQuestionsTopicObjectiveIdFkeyPatch;
  slug: string;
};
export type UserOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedQuestionPatch;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyUsingGeneratedQuestionsPkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyUsingGeneratedQuestionsSlugUkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsUpdatedByFkeyPatch;
  slug: string;
};
export type TopicOnTopicForTopicsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnTopicForTopicsCreatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnTopicForTopicsCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnTopicForTopicsCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnTopicForTopicsCreatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnTopicForTopicsCreatedByFkeyPatch;
  slug: string;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicPatch;
};
export type TopicOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyUsingTopicsPkeyUpdate = {
  patch: updateTopicOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyPatch;
  rowId: any;
};
export type updateTopicOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicsCreatedByFkeyInput | null | undefined;
  examType?: TopicsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicIdFkeyInverseInput | null | undefined;
  guidebook?: string | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  parentTopicId?: any | null | undefined;
  questions?: QuestionsTopicIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicsParentTopicIdFkeyInput | null | undefined;
  topicObjectives?: TopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicsUpdatedByFkeyInput | null | undefined;
  userLessonsToSubtopicIdUsingRowId?: UserLessonsSubtopicIdFkeyInverseInput | null | undefined;
  userLessonsToTopicIdUsingRowId?: UserLessonsTopicIdFkeyInverseInput | null | undefined;
  userQuestionAnswersToSubtopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey2InverseInput | null | undefined;
  userQuestionAnswersToTopicIdUsingRowId?: FakeAppPublicUserQuestionAnswersForeignKey3InverseInput | null | undefined;
  userStudySessionQuestionsToSubtopicIdUsingRowId?: UserStudySessionQuestionsSubtopicIdFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToTopicIdUsingRowId?: UserStudySessionQuestionsTopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToSubtopicIdUsingRowId?: UserSubtopicsSubtopicIdFkeyInverseInput | null | undefined;
  userSubtopicsToTopicIdUsingRowId?: UserSubtopicsTopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToSubtopicIdUsingRowId?: UserTopicObjectivesSubtopicIdFkeyInverseInput | null | undefined;
  userTopicObjectivesToTopicIdUsingRowId?: UserTopicObjectivesTopicIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsTopicIdFkeyInverseInput | null | undefined;
};
export type TopicOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyUsingTopicsSlugUkeyUpdate = {
  patch: updateTopicOnGeneratedQuestionForGeneratedQuestionsTopicIdFkeyPatch;
  slug: string;
};
export type QuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedQuestionPatch;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyUsingGeneratedQuestionsPkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyPatch;
  rowId: any;
};
export type updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyUsingGeneratedQuestionsSlugUkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyPatch;
  slug: string;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionPatch;
};
export type QuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyUsingQuestionsPkeyUpdate = {
  patch: updateQuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyPatch;
  rowId: any;
};
export type updateQuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyUsingQuestionsSlugUkeyUpdate = {
  patch: updateQuestionOnGeneratedQuestionForGeneratedQuestionsQuestionIdFkeyPatch;
  slug: string;
};
export type GenerativeAiResponseOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedQuestionPatch;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyUsingGeneratedQuestionsPkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyPatch;
  rowId: any;
};
export type updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyUsingGeneratedQuestionsSlugUkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyPatch;
  slug: string;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyNodeIdUpdate = {
  id: string;
  patch: GenerativeAiResponsePatch;
};
export type GenerativeAiResponseOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyUsingGenerativeAiResponsesPkeyUpdate = {
  patch: updateGenerativeAiResponseOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyPatch;
  rowId: any;
};
export type updateGenerativeAiResponseOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyPatch = {
  context?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  genType?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  model?: string | null | undefined;
  prompt?: string | null | undefined;
  result?: string | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GenerativeAiResponsesUpdatedByFkeyInput | null | undefined;
};
export type GenerativeAiResponseOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyUsingGenerativeAiResponsesSlugUkeyUpdate = {
  patch: updateGenerativeAiResponseOnGeneratedQuestionForGeneratedQuestionsGenerativeAiResponseIdFkeyPatch;
  slug: string;
};
export type UserOnGeneratedQuestionForGeneratedQuestionsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedQuestionPatch;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsCreatedByFkeyUsingGeneratedQuestionsPkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsCreatedByFkeyPatch;
  rowId: any;
};
export type updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsCreatedByFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsCreatedByFkeyUsingGeneratedQuestionsSlugUkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsCreatedByFkeyPatch;
  slug: string;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyPatch;
  slug: string;
};
export type UserOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedExplanationPatch;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyUsingGeneratedExplanationsPkeyUpdate = {
  patch: updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyPatch;
  rowId: any;
};
export type updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedExplanationsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generativeAiResponse?: GeneratedExplanationsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  question?: GeneratedExplanationsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  user?: GeneratedExplanationsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyUsingGeneratedExplanationsSlugUkeyUpdate = {
  patch: updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsUpdatedByFkeyPatch;
  slug: string;
};
export type TopicObjectiveOnTopicObjectiveForTopicObjectivesCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnTopicObjectiveForTopicObjectivesCreatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnTopicObjectiveForTopicObjectivesCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnTopicObjectiveForTopicObjectivesCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnTopicObjectiveForTopicObjectivesCreatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnTopicObjectiveForTopicObjectivesCreatedByFkeyPatch;
  slug: string;
};
export type ExamTypeOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: TopicObjectivePatch;
};
export type TopicObjectiveOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyUsingTopicObjectivesPkeyUpdate = {
  patch: updateTopicObjectiveOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateTopicObjectiveOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: TopicObjectivesCreatedByFkeyInput | null | undefined;
  examType?: TopicObjectivesExamTypeIdFkeyInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questions?: QuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  summary?: string | null | undefined;
  title?: string | null | undefined;
  topic?: TopicObjectivesTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: TopicObjectivesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey4InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsTopicObjectiveIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesTopicObjectiveIdFkeyInverseInput | null | undefined;
};
export type TopicObjectiveOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyUsingTopicObjectivesSlugUkeyUpdate = {
  patch: updateTopicObjectiveOnTopicObjectiveForTopicObjectivesExamTypeIdFkeyPatch;
  slug: string;
};
export type QuestionOnQuestionForQuestionsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnQuestionForQuestionsExamTypeIdFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnQuestionForQuestionsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnQuestionForQuestionsExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnQuestionForQuestionsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnQuestionForQuestionsExamTypeIdFkeyPatch;
  slug: string;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionPatch;
};
export type QuestionOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyUsingQuestionsPkeyUpdate = {
  patch: updateQuestionOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyPatch;
  rowId: any;
};
export type updateQuestionOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyUsingQuestionsSlugUkeyUpdate = {
  patch: updateQuestionOnGeneratedExplanationForGeneratedExplanationsQuestionIdFkeyPatch;
  slug: string;
};
export type GenerativeAiResponseOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedExplanationPatch;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyUsingGeneratedExplanationsPkeyUpdate = {
  patch: updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyPatch;
  rowId: any;
};
export type updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedExplanationsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generativeAiResponse?: GeneratedExplanationsGenerativeAiResponseIdFkeyInput | null | undefined;
  question?: GeneratedExplanationsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedExplanationsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyUsingGeneratedExplanationsSlugUkeyUpdate = {
  patch: updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyPatch;
  slug: string;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyNodeIdUpdate = {
  id: string;
  patch: GenerativeAiResponsePatch;
};
export type GenerativeAiResponseOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyUsingGenerativeAiResponsesPkeyUpdate = {
  patch: updateGenerativeAiResponseOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyPatch;
  rowId: any;
};
export type updateGenerativeAiResponseOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyPatch = {
  context?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  genType?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsGenerativeAiResponseIdFkeyInverseInput | null | undefined;
  model?: string | null | undefined;
  prompt?: string | null | undefined;
  result?: string | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GenerativeAiResponsesUpdatedByFkeyInput | null | undefined;
};
export type GenerativeAiResponseOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyUsingGenerativeAiResponsesSlugUkeyUpdate = {
  patch: updateGenerativeAiResponseOnGeneratedExplanationForGeneratedExplanationsGenerativeAiResponseIdFkeyPatch;
  slug: string;
};
export type UserOnGeneratedExplanationForGeneratedExplanationsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedExplanationPatch;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsCreatedByFkeyUsingGeneratedExplanationsPkeyUpdate = {
  patch: updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsCreatedByFkeyPatch;
  rowId: any;
};
export type updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsCreatedByFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  examType?: GeneratedExplanationsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  generativeAiResponse?: GeneratedExplanationsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  question?: GeneratedExplanationsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedExplanationsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsCreatedByFkeyUsingGeneratedExplanationsSlugUkeyUpdate = {
  patch: updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsCreatedByFkeyPatch;
  slug: string;
};
export type GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnGeneratedAnswerForGeneratedAnswersUpdatedByFkeyPatch;
  slug: string;
};
export type GeneratedQuestionOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedAnswerPatch;
};
export type GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyUsingGeneratedAnswersPkeyUpdate = {
  patch: updateGeneratedAnswerOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyPatch;
  rowId: any;
};
export type updateGeneratedAnswerOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  generatedQuestion?: GeneratedAnswersGeneratedQuestionIdFkeyInput | null | undefined;
  isCorrect?: boolean | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedAnswersUpdatedByFkeyInput | null | undefined;
};
export type GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyUsingGeneratedAnswersSlugUkeyUpdate = {
  patch: updateGeneratedAnswerOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyPatch;
  slug: string;
};
export type GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedQuestionPatch;
};
export type GeneratedQuestionOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyUsingGeneratedQuestionsPkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyPatch;
  rowId: any;
};
export type updateGeneratedQuestionOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  explanation?: string | null | undefined;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyUsingGeneratedQuestionsSlugUkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedAnswerForGeneratedAnswersGeneratedQuestionIdFkeyPatch;
  slug: string;
};
export type UserOnGeneratedAnswerForGeneratedAnswersCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedAnswerPatch;
};
export type GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersCreatedByFkeyUsingGeneratedAnswersPkeyUpdate = {
  patch: updateGeneratedAnswerOnGeneratedAnswerForGeneratedAnswersCreatedByFkeyPatch;
  rowId: any;
};
export type updateGeneratedAnswerOnGeneratedAnswerForGeneratedAnswersCreatedByFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  generatedQuestion?: GeneratedAnswersGeneratedQuestionIdFkeyInput | null | undefined;
  generatedQuestionId?: any | null | undefined;
  isCorrect?: boolean | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedAnswersUpdatedByFkeyInput | null | undefined;
};
export type GeneratedAnswerOnGeneratedAnswerForGeneratedAnswersCreatedByFkeyUsingGeneratedAnswersSlugUkeyUpdate = {
  patch: updateGeneratedAnswerOnGeneratedAnswerForGeneratedAnswersCreatedByFkeyPatch;
  slug: string;
};
export type ExamTypesUserOnExamTypesUserForExamTypesUsersUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnExamTypesUserForExamTypesUsersUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnExamTypesUserForExamTypesUsersUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnExamTypesUserForExamTypesUsersUserIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnExamTypesUserForExamTypesUsersUserIdFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnExamTypesUserForExamTypesUsersUserIdFkeyPatch;
  slug: string;
};
export type ExamTypesUserNodeIdDelete = {
  id: string;
};
export type ExamTypesUserExamTypesUsersPkeyDelete = {
  rowId: any;
};
export type ExamTypesUserExamTypesUsersSlugUkeyDelete = {
  slug: string;
};
export type UserOnExamTypesUserForExamTypesUsersUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypesUserPatch;
};
export type ExamTypesUserPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesUsersCreatedByFkeyInput | null | undefined;
  examDateAt?: any | null | undefined;
  examType?: ExamTypesUsersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUsersUpdatedByFkeyInput | null | undefined;
  user?: ExamTypesUsersUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type ExamTypesUserOnExamTypesUserForExamTypesUsersUserIdFkeyUsingExamTypesUsersPkeyUpdate = {
  patch: updateExamTypesUserOnExamTypesUserForExamTypesUsersUserIdFkeyPatch;
  rowId: any;
};
export type updateExamTypesUserOnExamTypesUserForExamTypesUsersUserIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesUsersCreatedByFkeyInput | null | undefined;
  examDateAt?: any | null | undefined;
  examType?: ExamTypesUsersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUsersUpdatedByFkeyInput | null | undefined;
  user?: ExamTypesUsersUserIdFkeyInput | null | undefined;
};
export type ExamTypesUserOnExamTypesUserForExamTypesUsersUserIdFkeyUsingExamTypesUsersSlugUkeyUpdate = {
  patch: updateExamTypesUserOnExamTypesUserForExamTypesUsersUserIdFkeyPatch;
  slug: string;
};
export type ExamTypesUserOnExamTypesUserForExamTypesUsersUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnExamTypesUserForExamTypesUsersUpdatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnExamTypesUserForExamTypesUsersUpdatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnExamTypesUserForExamTypesUsersUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnExamTypesUserForExamTypesUsersUpdatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnExamTypesUserForExamTypesUsersUpdatedByFkeyPatch;
  slug: string;
};
export type UserOnExamTypesUserForExamTypesUsersUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypesUserPatch;
};
export type ExamTypesUserOnExamTypesUserForExamTypesUsersUpdatedByFkeyUsingExamTypesUsersPkeyUpdate = {
  patch: updateExamTypesUserOnExamTypesUserForExamTypesUsersUpdatedByFkeyPatch;
  rowId: any;
};
export type updateExamTypesUserOnExamTypesUserForExamTypesUsersUpdatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesUsersCreatedByFkeyInput | null | undefined;
  examDateAt?: any | null | undefined;
  examType?: ExamTypesUsersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: ExamTypesUsersUpdatedByFkeyInput | null | undefined;
  user?: ExamTypesUsersUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type ExamTypesUserOnExamTypesUserForExamTypesUsersUpdatedByFkeyUsingExamTypesUsersSlugUkeyUpdate = {
  patch: updateExamTypesUserOnExamTypesUserForExamTypesUsersUpdatedByFkeyPatch;
  slug: string;
};
export type QuestionOnQuestionForQuestionsCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnQuestionForQuestionsCreatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnQuestionForQuestionsCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnQuestionForQuestionsCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnQuestionForQuestionsCreatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnQuestionForQuestionsCreatedByFkeyPatch;
  slug: string;
};
export type ExamTypeOnQuestionForQuestionsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionPatch;
};
export type QuestionOnQuestionForQuestionsExamTypeIdFkeyUsingQuestionsPkeyUpdate = {
  patch: updateQuestionOnQuestionForQuestionsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateQuestionOnQuestionForQuestionsExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionsCreatedByFkeyInput | null | undefined;
  examType?: QuestionsExamTypeIdFkeyInput | null | undefined;
  explanation?: string | null | undefined;
  extendedExplaination?: string | null | undefined;
  generatedExplanations?: GeneratedExplanationsQuestionIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsQuestionIdFkeyInverseInput | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  isActive?: boolean | null | undefined;
  isLesson?: boolean | null | undefined;
  isStudySession?: boolean | null | undefined;
  markedForReview?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersQuestionIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: QuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: QuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionsUpdatedByFkeyInput | null | undefined;
  userLessonQuestions?: UserLessonQuestionsQuestionIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey1InverseInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionIdFkeyInverseInput | null | undefined;
};
export type QuestionOnQuestionForQuestionsExamTypeIdFkeyUsingQuestionsSlugUkeyUpdate = {
  patch: updateQuestionOnQuestionForQuestionsExamTypeIdFkeyPatch;
  slug: string;
};
export type QuestionAnswerOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyPatch;
  slug: string;
};
export type ExamTypeOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: QuestionAnswerPatch;
};
export type QuestionAnswerOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyUsingQuestionAnswersPkeyUpdate = {
  patch: updateQuestionAnswerOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateQuestionAnswerOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: QuestionAnswersCreatedByFkeyInput | null | undefined;
  examType?: QuestionAnswersExamTypeIdFkeyInput | null | undefined;
  isActive?: boolean | null | undefined;
  isCorrect?: boolean | null | undefined;
  question?: QuestionAnswersQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: QuestionAnswersUpdatedByFkeyInput | null | undefined;
  userStudySessionQuestions?: UserStudySessionQuestionsQuestionAnswerIdFkeyInverseInput | null | undefined;
};
export type QuestionAnswerOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyUsingQuestionAnswersSlugUkeyUpdate = {
  patch: updateQuestionAnswerOnQuestionAnswerForQuestionAnswersExamTypeIdFkeyPatch;
  slug: string;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyPatch;
  slug: string;
};
export type ExamTypeOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedQuestionPatch;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyUsingGeneratedQuestionsPkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedQuestionsExamTypeIdFkeyInput | null | undefined;
  explanation?: string | null | undefined;
  generatedAnswers?: GeneratedAnswersGeneratedQuestionIdFkeyInverseInput | null | undefined;
  generativeAiResponse?: GeneratedQuestionsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  is2024Lesson?: boolean | null | undefined;
  question?: GeneratedQuestionsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  sequence?: number | null | undefined;
  slug?: string | null | undefined;
  topic?: GeneratedQuestionsTopicIdFkeyInput | null | undefined;
  topicId?: any | null | undefined;
  topicObjective?: GeneratedQuestionsTopicObjectiveIdFkeyInput | null | undefined;
  topicObjectiveId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedQuestionsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyUsingGeneratedQuestionsSlugUkeyUpdate = {
  patch: updateGeneratedQuestionOnGeneratedQuestionForGeneratedQuestionsExamTypeIdFkeyPatch;
  slug: string;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyPatch;
  slug: string;
};
export type ExamTypeOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: GeneratedExplanationPatch;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyUsingGeneratedExplanationsPkeyUpdate = {
  patch: updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  examType?: GeneratedExplanationsExamTypeIdFkeyInput | null | undefined;
  generativeAiResponse?: GeneratedExplanationsGenerativeAiResponseIdFkeyInput | null | undefined;
  generativeAiResponseId?: any | null | undefined;
  question?: GeneratedExplanationsQuestionIdFkeyInput | null | undefined;
  questionId?: any | null | undefined;
  reviewStatus?: GeneratedContentReviewStatus | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  user?: GeneratedExplanationsUpdatedByFkeyInput | null | undefined;
};
export type GeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyUsingGeneratedExplanationsSlugUkeyUpdate = {
  patch: updateGeneratedExplanationOnGeneratedExplanationForGeneratedExplanationsExamTypeIdFkeyPatch;
  slug: string;
};
export type ExamTypesUserOnExamTypesUserForExamTypesUsersExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnExamTypesUserForExamTypesUsersExamTypeIdFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnExamTypesUserForExamTypesUsersExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnExamTypesUserForExamTypesUsersExamTypeIdFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnExamTypesUserForExamTypesUsersExamTypeIdFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnExamTypesUserForExamTypesUsersExamTypeIdFkeyPatch;
  slug: string;
};
export type UserOnExamTypesUserForExamTypesUsersCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypesUserPatch;
};
export type ExamTypesUserOnExamTypesUserForExamTypesUsersCreatedByFkeyUsingExamTypesUsersPkeyUpdate = {
  patch: updateExamTypesUserOnExamTypesUserForExamTypesUsersCreatedByFkeyPatch;
  rowId: any;
};
export type updateExamTypesUserOnExamTypesUserForExamTypesUsersCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: ExamTypesUsersCreatedByFkeyInput | null | undefined;
  examDateAt?: any | null | undefined;
  examType?: ExamTypesUsersExamTypeIdFkeyInput | null | undefined;
  examTypeId?: any | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUsersUpdatedByFkeyInput | null | undefined;
  user?: ExamTypesUsersUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type ExamTypesUserOnExamTypesUserForExamTypesUsersCreatedByFkeyUsingExamTypesUsersSlugUkeyUpdate = {
  patch: updateExamTypesUserOnExamTypesUserForExamTypesUsersCreatedByFkeyPatch;
  slug: string;
};
export type ExamTypesUserOnExamTypesUserForExamTypesUsersCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnExamTypesUserForExamTypesUsersCreatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnExamTypesUserForExamTypesUsersCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnExamTypesUserForExamTypesUsersCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnExamTypesUserForExamTypesUsersCreatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnExamTypesUserForExamTypesUsersCreatedByFkeyPatch;
  slug: string;
};
export type ExamTypeOnExamTypesUserForExamTypesUsersExamTypeIdFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypesUserPatch;
};
export type ExamTypesUserOnExamTypesUserForExamTypesUsersExamTypeIdFkeyUsingExamTypesUsersPkeyUpdate = {
  patch: updateExamTypesUserOnExamTypesUserForExamTypesUsersExamTypeIdFkeyPatch;
  rowId: any;
};
export type updateExamTypesUserOnExamTypesUserForExamTypesUsersExamTypeIdFkeyPatch = {
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesUsersCreatedByFkeyInput | null | undefined;
  examDateAt?: any | null | undefined;
  examType?: ExamTypesUsersExamTypeIdFkeyInput | null | undefined;
  rowId?: any | null | undefined;
  score?: number | null | undefined;
  slug?: string | null | undefined;
  totalTimeSpent?: IntervalInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUsersUpdatedByFkeyInput | null | undefined;
  user?: ExamTypesUsersUserIdFkeyInput | null | undefined;
  userId?: any | null | undefined;
};
export type ExamTypesUserOnExamTypesUserForExamTypesUsersExamTypeIdFkeyUsingExamTypesUsersSlugUkeyUpdate = {
  patch: updateExamTypesUserOnExamTypesUserForExamTypesUsersExamTypeIdFkeyPatch;
  slug: string;
};
export type UserOnExamTypeForExamTypesUpdatedByFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnExamTypeForExamTypesUpdatedByFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnExamTypeForExamTypesUpdatedByFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnExamTypeForExamTypesUpdatedByFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdBy?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnExamTypeForExamTypesUpdatedByFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnExamTypeForExamTypesUpdatedByFkeyPatch;
  slug: string;
};
export type ExamTypeOnExamTypeForExamTypesCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnExamTypeForExamTypesCreatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnExamTypeForExamTypesCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnExamTypeForExamTypesCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnExamTypeForExamTypesCreatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnExamTypeForExamTypesCreatedByFkeyPatch;
  slug: string;
};
export type UserOnExamTypeForExamTypesCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: ExamTypePatch;
};
export type ExamTypeOnExamTypeForExamTypesCreatedByFkeyUsingExamTypesPkeyUpdate = {
  patch: updateExamTypeOnExamTypeForExamTypesCreatedByFkeyPatch;
  rowId: any;
};
export type updateExamTypeOnExamTypeForExamTypesCreatedByFkeyPatch = {
  body?: string | null | undefined;
  createdAt?: any | null | undefined;
  createdByUser?: ExamTypesCreatedByFkeyInput | null | undefined;
  examTypesUsers?: ExamTypesUsersExamTypeIdFkeyInverseInput | null | undefined;
  generatedExplanations?: GeneratedExplanationsExamTypeIdFkeyInverseInput | null | undefined;
  generatedQuestions?: GeneratedQuestionsExamTypeIdFkeyInverseInput | null | undefined;
  isActive?: boolean | null | undefined;
  questionAnswers?: QuestionAnswersExamTypeIdFkeyInverseInput | null | undefined;
  questions?: QuestionsExamTypeIdFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  title?: string | null | undefined;
  topicObjectives?: TopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  topics?: TopicsExamTypeIdFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: ExamTypesUpdatedByFkeyInput | null | undefined;
  userLessons?: UserLessonsExamTypeIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey0InverseInput | null | undefined;
  userStudySessions?: UserStudySessionsExamTypeIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsExamTypeIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesExamTypeIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsExamTypeIdFkeyInverseInput | null | undefined;
};
export type ExamTypeOnExamTypeForExamTypesCreatedByFkeyUsingExamTypesSlugUkeyUpdate = {
  patch: updateExamTypeOnExamTypeForExamTypesCreatedByFkeyPatch;
  slug: string;
};
export type UserOnUserForUsersCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserForUsersCreatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserForUsersCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnUserForUsersCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnUserForUsersCreatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnUserForUsersCreatedByFkeyPatch;
  slug: string;
};
export type QuestionAnswerOnQuestionAnswerForQuestionAnswersCreatedByFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnQuestionAnswerForQuestionAnswersCreatedByFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnQuestionAnswerForQuestionAnswersCreatedByFkeyPatch;
  rowId: any;
};
export type updateUserOnQuestionAnswerForQuestionAnswersCreatedByFkeyPatch = {
  createdAt?: any | null | undefined;
  createdByUser?: UsersCreatedByFkeyInput | null | undefined;
  email?: string | null | undefined;
  examTypesToCreatedByUsingRowId?: ExamTypesCreatedByFkeyInverseInput | null | undefined;
  examTypesToUpdatedByUsingRowId?: ExamTypesUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToCreatedByUsingRowId?: ExamTypesUsersCreatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUpdatedByUsingRowId?: ExamTypesUsersUpdatedByFkeyInverseInput | null | undefined;
  examTypesUsersToUserIdUsingRowId?: ExamTypesUsersUserIdFkeyInverseInput | null | undefined;
  generatedAnswersToCreatedByUsingRowId?: GeneratedAnswersCreatedByFkeyInverseInput | null | undefined;
  generatedAnswersToUpdatedByUsingRowId?: GeneratedAnswersUpdatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToCreatedByUsingRowId?: GeneratedExplanationsCreatedByFkeyInverseInput | null | undefined;
  generatedExplanationsToUpdatedByUsingRowId?: GeneratedExplanationsUpdatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToCreatedByUsingRowId?: GeneratedQuestionsCreatedByFkeyInverseInput | null | undefined;
  generatedQuestionsToUpdatedByUsingRowId?: GeneratedQuestionsUpdatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToCreatedByUsingRowId?: GenerativeAiResponsesCreatedByFkeyInverseInput | null | undefined;
  generativeAiResponsesToUpdatedByUsingRowId?: GenerativeAiResponsesUpdatedByFkeyInverseInput | null | undefined;
  image?: string | null | undefined;
  isActive?: boolean | null | undefined;
  isAdmin?: boolean | null | undefined;
  lastActivityAt?: any | null | undefined;
  name?: string | null | undefined;
  questionAnswersToCreatedByUsingRowId?: QuestionAnswersCreatedByFkeyInverseInput | null | undefined;
  questionAnswersToUpdatedByUsingRowId?: QuestionAnswersUpdatedByFkeyInverseInput | null | undefined;
  questionsToCreatedByUsingRowId?: QuestionsCreatedByFkeyInverseInput | null | undefined;
  questionsToUpdatedByUsingRowId?: QuestionsUpdatedByFkeyInverseInput | null | undefined;
  rowId?: any | null | undefined;
  slug?: string | null | undefined;
  topicObjectivesToCreatedByUsingRowId?: TopicObjectivesCreatedByFkeyInverseInput | null | undefined;
  topicObjectivesToUpdatedByUsingRowId?: TopicObjectivesUpdatedByFkeyInverseInput | null | undefined;
  topicsToCreatedByUsingRowId?: TopicsCreatedByFkeyInverseInput | null | undefined;
  topicsToUpdatedByUsingRowId?: TopicsUpdatedByFkeyInverseInput | null | undefined;
  updatedAt?: any | null | undefined;
  updatedBy?: any | null | undefined;
  updatedByUser?: UsersUpdatedByFkeyInput | null | undefined;
  userLessonQuestionsToCreatedByUsingRowId?: UserLessonQuestionsCreatedByFkeyInverseInput | null | undefined;
  userLessonQuestionsToUpdatedByUsingRowId?: UserLessonQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToCreatedByUsingRowId?: UserLessonsCreatedByFkeyInverseInput | null | undefined;
  userLessonsToUpdatedByUsingRowId?: UserLessonsUpdatedByFkeyInverseInput | null | undefined;
  userLessonsToUserIdUsingRowId?: UserLessonsUserIdFkeyInverseInput | null | undefined;
  userQuestionAnswers?: FakeAppPublicUserQuestionAnswersForeignKey5InverseInput | null | undefined;
  userStudySessionQuestionsToCreatedByUsingRowId?: UserStudySessionQuestionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionQuestionsToUpdatedByUsingRowId?: UserStudySessionQuestionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToCreatedByUsingRowId?: UserStudySessionsCreatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUpdatedByUsingRowId?: UserStudySessionsUpdatedByFkeyInverseInput | null | undefined;
  userStudySessionsToUserIdUsingRowId?: UserStudySessionsUserIdFkeyInverseInput | null | undefined;
  userSubtopics?: UserSubtopicsUserIdFkeyInverseInput | null | undefined;
  userTopicObjectives?: UserTopicObjectivesUserIdFkeyInverseInput | null | undefined;
  userTopics?: UserTopicsUserIdFkeyInverseInput | null | undefined;
  verifiedAt?: any | null | undefined;
};
export type UserOnQuestionAnswerForQuestionAnswersCreatedByFkeyUsingUniqueSlugUpdate = {
  patch: updateUserOnQuestionAnswerForQuestionAnswersCreatedByFkeyPatch;
  slug: string;
};
export type QuestionAnswersTableUpdateQuestionAnswerMutation$variables = {
  input: UpdateQuestionAnswerInput;
};
export type QuestionAnswersTableUpdateQuestionAnswerMutation$data = {
  readonly updateQuestionAnswer: {
    readonly question: {
      readonly " $fragmentSpreads": FragmentRefs<"QuestionAnswersTable_question">;
    } | null | undefined;
  } | null | undefined;
};
export type QuestionAnswersTableUpdateQuestionAnswerMutation = {
  response: QuestionAnswersTableUpdateQuestionAnswerMutation$data;
  variables: QuestionAnswersTableUpdateQuestionAnswerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      "SEQUENCE_ASC"
    ]
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionAnswersTableUpdateQuestionAnswerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateQuestionAnswerPayload",
        "kind": "LinkedField",
        "name": "updateQuestionAnswer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Question",
            "kind": "LinkedField",
            "name": "question",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "QuestionAnswersTable_question"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuestionAnswersTableUpdateQuestionAnswerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateQuestionAnswerPayload",
        "kind": "LinkedField",
        "name": "updateQuestionAnswer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Question",
            "kind": "LinkedField",
            "name": "question",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "QuestionAnswersConnection",
                "kind": "LinkedField",
                "name": "questionAnswers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuestionAnswersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "QuestionAnswer",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "body",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isActive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCorrect",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rowId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sequence",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "questionAnswers(first:10,orderBy:[\"SEQUENCE_ASC\"])"
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": [
                  "filter",
                  "orderBy"
                ],
                "handle": "connection",
                "key": "QuestionAnswersTable_questionAnswers",
                "kind": "LinkedHandle",
                "name": "questionAnswers"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "acdd8b786d419e6bf46c6d7005d4af6a",
    "id": null,
    "metadata": {},
    "name": "QuestionAnswersTableUpdateQuestionAnswerMutation",
    "operationKind": "mutation",
    "text": "mutation QuestionAnswersTableUpdateQuestionAnswerMutation(\n  $input: UpdateQuestionAnswerInput!\n) {\n  updateQuestionAnswer(input: $input) {\n    question {\n      ...QuestionAnswersTable_question\n      id\n    }\n  }\n}\n\nfragment QuestionAnswerForm_questionAnswer on QuestionAnswer {\n  body\n  isActive\n  isCorrect\n}\n\nfragment QuestionAnswersTable_question on Question {\n  questionAnswers(first: 10, orderBy: [SEQUENCE_ASC]) {\n    edges {\n      node {\n        body\n        id\n        isActive\n        isCorrect\n        rowId\n        sequence\n        ...QuestionAnswerForm_questionAnswer\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "908b6fb195124fa1249bc8d580db3fa1";

export default node;
