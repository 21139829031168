import { type ObjectiveLessonWizardModal_userLesson$key } from '@app/__generated__/ObjectiveLessonWizardModal_userLesson.graphql'
import { ObjectiveLessonWizardModalCreateUserLessonMutation } from '@app/__generated__/ObjectiveLessonWizardModalCreateUserLessonMutation.graphql'
import { type ObjectiveLessonWizardModalDeleteUserLessonMutation } from '@app/__generated__/ObjectiveLessonWizardModalDeleteUserLessonMutation.graphql'
import { BorderButton, ConfirmModal, GuidebookButton, UserLessonQuestion, WizardWrapper } from '@app/components'
import greenBalloonImage from '@app/components/StudySession/greenBalloon.svg'
import yellowBalloonImage from '@app/components/StudySession/yellowBalloon.svg'
import {
  BackgroundImage,
  Container,
  createStyles,
  Grid,
  Group,
  Modal,
  ModalProps,
  rem,
  Stack,
  Text,
  Title
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconChevronRight } from '@tabler/icons-react'
import { find, map } from 'lodash'
import Image from 'next/image'
import { type FC, useCallback, useMemo, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const updateUserLessonQuestionMutation = graphql`
  mutation ObjectiveLessonWizardModal_updateUserLessonQuestionMutation($input: UpdateUserLessonQuestionInput!) {
    updateUserLessonQuestion(input: $input) {
      userLesson {
        user {
          currentExamTypesUser {
            examType {
              userTopicObjectives {
                nodes {
                  score
                }
              }
            }
            ...LessonsCarousel_examTypesUser
            ...PerformanceScoreBox_examTypesUser
            ...QuestionsPerformanceBox_examTypesUser
          }
        }
        topic {
          ...TopicInfoBox_topic
        }
        topicObjective {
          ...ObjectiveCard_topicObjective
        }
      }
      question {
        topic {
          ...TopicsPageHeader_topic
        }
      }
      userLessonQuestion {
        ...UserLessonQuestion_userLessonQuestion
      }
    }
  }
`

const userLessonFragment = graphql`
  fragment ObjectiveLessonWizardModal_userLesson on UserLesson {
    examTypeId
    id
    userLessonQuestions(orderBy: [SEQUENCE_ASC]) {
      nodes {
        rowId
        ...UserLessonQuestion_userLessonQuestion
      }
      totalCount
    }
    subtopic {
      topicObjectives(
        condition: { meetsLessonViabilityCriteria: true }
        filter: {
          isActive: { equalTo: true }
          topic: { isActive: { equalTo: true }, parentTopic: { isActive: { equalTo: true } } }
        }
      ) {
        nodes {
          rowId
          sequence
        }
      }
      rowId
      totalObjectivesCount
      ...GuidebookButton_topic
    }
    topic {
      guidebook
      rowId
    }
    topicObjective {
      title
      sequence
    }
    topicObjectiveId
    userId
  }
`

const createUserLessonMutation = graphql`
  mutation ObjectiveLessonWizardModalCreateUserLessonMutation($input: CreateUserLessonInput!) {
    createUserLesson(input: $input) {
      userLesson {
        ...ObjectiveLessonWizardModal_userLesson
      }
    }
  }
`

const deleteUserLessonMutation = graphql`
  mutation ObjectiveLessonWizardModalDeleteUserLessonMutation($input: DeleteUserLessonByIdInput!) {
    deleteUserLessonById(input: $input) {
      clientMutationId
    }
  }
`

const useStyles = createStyles((theme) => ({
  finishContainer: {
    position: 'relative'
  },
  fullHeight: {
    height: '100%',
    position: 'relative'
  },
  leftBalloon: {
    position: 'absolute',
    top: 10,
    left: -20,
    [theme.fn.smallerThan('sm')]: {
      left: -140
    }
  },
  modalHeader: {
    backgroundColor: theme.colors.gray[3],
    padding: 15,
    boxSizing: 'border-box',
    height: 70,
    marginBottom: 20
  },
  modalTitle: {
    fontWeight: 'bold',
    width: '100%'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  modalBody: {
    backgroundColor: theme.colors.studiousGray[2],
    flexGrow: 1,
    padding: '2rem'
  },
  rightBalloon: {
    position: 'absolute',
    top: 10,
    right: -20,
    [theme.fn.smallerThan('sm')]: {
      top: 70
    }
  }
}))

export interface ObjectiveLessonWizardModalProps extends ModalProps {
  backgroundImage?: string
  onClose: () => void
  opened: boolean
  userLesson: ObjectiveLessonWizardModal_userLesson$key | null
  setUserLesson: (userLesson: ObjectiveLessonWizardModal_userLesson$key | null) => void
}
export const ObjectiveLessonWizardModal: FC<ObjectiveLessonWizardModalProps> = ({
  classNames,
  onClose,
  opened,
  setUserLesson,
  userLesson,
  ...props
}) => {
  const { classes, theme, cx } = useStyles()
  const [isSessionComplete, setIsSessionComplete] = useState(false)
  const [deleting, confirmDeleteSessionModalHandler] = useDisclosure(false)
  const [createUserLesson] = useMutation<ObjectiveLessonWizardModalCreateUserLessonMutation>(createUserLessonMutation)
  const [deleteUserLesson] = useMutation<ObjectiveLessonWizardModalDeleteUserLessonMutation>(deleteUserLessonMutation)
  const [updateUserLessonQuestion] = useMutation(updateUserLessonQuestionMutation)
  const userLessonData = useFragment(userLessonFragment, userLesson)
  const nextLessonObjective = useMemo(
    () =>
      find(
        userLessonData?.subtopic?.topicObjectives?.nodes,
        (objective) => objective.sequence === (userLessonData?.topicObjective?.sequence || 0) + 1
      ),
    [userLessonData?.subtopic?.topicObjectives?.nodes, userLessonData?.topicObjective?.sequence]
  )
  const handleClose = useCallback(() => {
    setIsSessionComplete(false)
    onClose()
  }, [onClose])

  return (
    <Modal
      classNames={{
        header: classes.modalHeader,
        title: classes.modalTitle,
        content: classes.modalContent,
        body: classes.modalBody,
        ...classNames
      }}
      onClose={useCallback(() => {
        if (isSessionComplete) {
          handleClose()
        } else {
          confirmDeleteSessionModalHandler.open()
        }
      }, [confirmDeleteSessionModalHandler, handleClose, isSessionComplete])}
      opened={opened && !!userLessonData?.userLessonQuestions}
      radius={16}
      title={
        !isSessionComplete && (
          <Grid>
            <Grid.Col span='auto'>
              <div>{userLessonData?.topicObjective?.title}</div>
            </Grid.Col>
            <Grid.Col span='content'>
              {userLessonData?.topic?.guidebook ? <GuidebookButton topic={userLessonData?.subtopic} /> : null}
            </Grid.Col>
          </Grid>
        )
      }
      transitionProps={{
        transition: 'fade',
        duration: 200
      }}
      size='xl'
      withCloseButton={false}
      {...props}
    >
      <Container
        size='md'
        className={cx(classes.fullHeight)}
      >
        <Stack
          justify='center'
          className={classes.fullHeight}
        >
          <WizardWrapper stepCount={userLessonData?.userLessonQuestions?.totalCount || 0}>
            {useCallback(
              ({ active, onNext, onReset }) => [
                ...map(userLessonData?.userLessonQuestions.nodes, (userLessonQuestion, index: number) => (
                  <div
                    hidden={active !== index}
                    key={`step${index}`}
                  >
                    {active === index && (
                      <UserLessonQuestion
                        onSubmit={(answerData) => {
                          updateUserLessonQuestion({
                            variables: {
                              input: {
                                ...answerData
                              }
                            }
                          })
                        }}
                        onNext={onNext}
                        setIsSessionComplete={setIsSessionComplete}
                        userLessonQuestion={userLessonQuestion}
                        questionsCount={userLessonData?.userLessonQuestions?.totalCount}
                      />
                    )}
                  </div>
                )),
                <div
                  key='background'
                  hidden={active !== userLessonData?.userLessonQuestions?.totalCount}
                >
                  {active === userLessonData?.userLessonQuestions?.totalCount && active > 0 && (
                    <div className={classes.finishContainer}>
                      <BackgroundImage
                        src='/backgrounds/confetti.svg'
                        sx={{
                          height: '400px',
                          textAlign: 'center'
                        }}
                      >
                        <Title
                          sx={{
                            paddingTop: '20px'
                          }}
                          order={2}
                        >
                          Congratulations!
                        </Title>
                      </BackgroundImage>
                      <Image
                        width={200}
                        height={200}
                        src={yellowBalloonImage}
                        className={classes.leftBalloon}
                        alt='Balloon'
                      />
                      <Image
                        width={200}
                        height={200}
                        className={classes.rightBalloon}
                        src={greenBalloonImage}
                        alt='Balloon'
                      />
                      <Group
                        position='center'
                        spacing='xs'
                        sx={{
                          width: '100%',
                          padding: '0 60px',
                          position: 'absolute',
                          bottom: 20
                        }}
                      >
                        {nextLessonObjective ? (
                          <>
                            <BorderButton
                              color={theme.colors.studiousGray[4]}
                              size='lg'
                              onClick={onClose}
                            >
                              Take a Break
                            </BorderButton>
                            <BorderButton
                              color={theme.colors.paperYellow[4]}
                              rightIcon={<IconChevronRight />}
                              size='lg'
                              onClick={() => {
                                onReset()
                                setIsSessionComplete(false)
                                return createUserLesson({
                                  variables: {
                                    input: {
                                      userLesson: {
                                        examTypeId: userLessonData?.examTypeId,
                                        topicId: userLessonData?.topic?.rowId,
                                        subtopicId: userLessonData?.subtopic?.rowId,
                                        topicObjectiveId: nextLessonObjective.rowId,
                                        userId: userLessonData?.userId,
                                        startedAt: new Date()
                                      }
                                    }
                                  },
                                  onCompleted: (response) => {
                                    setUserLesson(response?.createUserLesson?.userLesson || null)
                                  }
                                })
                              }}
                            >
                              Go To Next Lesson
                            </BorderButton>
                          </>
                        ) : (
                          <BorderButton
                            onClick={handleClose}
                            color={theme.colors.jelloGreen[4]}
                            sx={(theme) => ({
                              [theme.fn.smallerThan('sm')]: {
                                fontSize: rem(12)
                              }
                            })}
                          >
                            <Text>{`You've completed the last objective of this subtopic!`}</Text>
                          </BorderButton>
                        )}
                      </Group>
                    </div>
                  )}
                </div>
              ],
              [
                classes.finishContainer,
                classes.leftBalloon,
                classes.rightBalloon,
                createUserLesson,
                handleClose,
                nextLessonObjective,
                onClose,
                setUserLesson,
                theme.colors.jelloGreen,
                theme.colors.paperYellow,
                theme.colors.studiousGray,
                updateUserLessonQuestion,
                userLessonData?.examTypeId,
                userLessonData?.subtopic?.rowId,
                userLessonData?.topic?.rowId,
                userLessonData?.userId,
                userLessonData?.userLessonQuestions.nodes,
                userLessonData?.userLessonQuestions?.totalCount
              ]
            )}
          </WizardWrapper>
        </Stack>
      </Container>
      {!!userLessonData?.id && (
        <ConfirmModal
          onClose={confirmDeleteSessionModalHandler.close}
          onConfirm={() =>
            deleteUserLesson({
              variables: {
                input: {
                  id: userLessonData.id
                }
              },
              onCompleted: () => {
                onClose()
                confirmDeleteSessionModalHandler.close()
              }
            })
          }
          opened={deleting}
          title='Are you sure you want to cancel this lesson? Canceling will delete all progress.'
          zIndex={100001}
        />
      )}
    </Modal>
  )
}
