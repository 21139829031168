/**
 * @generated SignedSource<<db3591d7694b2238002a223d6b0fc849>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySessionReviewQuestion_userStudySessionQuestion$data = {
  readonly completedAt: any | null | undefined;
  readonly question: {
    readonly correctAnswer: {
      readonly nodes: ReadonlyArray<{
        readonly rowId: any;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"QuestionTitle_question">;
  } | null | undefined;
  readonly questionAnswerId: any | null | undefined;
  readonly rowId: any;
  readonly sequence: number;
  readonly startedAt: any | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionAnswers_userStudySessionQuestion">;
  readonly " $fragmentType": "StudySessionReviewQuestion_userStudySessionQuestion";
};
export type StudySessionReviewQuestion_userStudySessionQuestion$key = {
  readonly " $data"?: StudySessionReviewQuestion_userStudySessionQuestion$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySessionReviewQuestion_userStudySessionQuestion">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudySessionReviewQuestion_userStudySessionQuestion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "questionAnswerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Question",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": [
        {
          "alias": "correctAnswer",
          "args": [
            {
              "kind": "Literal",
              "name": "condition",
              "value": {
                "isActive": true,
                "isCorrect": true
              }
            }
          ],
          "concreteType": "QuestionAnswersConnection",
          "kind": "LinkedField",
          "name": "questionAnswers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuestionAnswer",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "questionAnswers(condition:{\"isActive\":true,\"isCorrect\":true})"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuestionTitle_question"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuestionAnswers_userStudySessionQuestion"
    }
  ],
  "type": "UserStudySessionQuestion",
  "abstractKey": null
};
})();

(node as any).hash = "50cd0b8e449050630cb99f77715968db";

export default node;
