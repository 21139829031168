import { type GuidebookButton_topic$key } from '@app/__generated__/GuidebookButton_topic.graphql'
import { MarkdownBox, Modal } from '@app/components'
import { Button, ButtonProps, Group, rem, ScrollArea, Title, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconBook } from '@tabler/icons-react'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

interface GuidebookButtonProps extends ButtonProps {
  topic: GuidebookButton_topic$key | null
}

const topicFragment = graphql`
  fragment GuidebookButton_topic on Topic {
    guidebook
    parentTopic {
      title
    }
    title
  }
`

export const GuidebookButton: FC<GuidebookButtonProps> = ({ topic, ...props }) => {
  const theme = useMantineTheme()
  const topicData = useFragment(topicFragment, topic)
  const [showGuidebook, guidebookModalHandler] = useDisclosure(false)

  return (
    <>
      <Button
        onClick={topicData?.guidebook ? guidebookModalHandler.open : undefined}
        size='sm'
        mb='lg'
        variant='subtle'
        px={0}
        {...props}
      >
        <Group
          w={rem(25)}
          h={rem(25)}
          mx='xs'
          sx={(theme) => ({
            border: `1px solid ${theme.colors.spaceCadetPurple[4]}`,
            boxShadow: `2px 2px ${theme.colors.spaceCadetPurple[4]}`,
            backgroundColor: theme.colors.stickyNoteBlue[4],
            borderRadius: rem(50),
            justifyContent: 'center'
          })}
        >
          <IconBook
            color={theme.colors.spaceCadetPurple[4]}
            width={rem(18)}
          />
        </Group>
        <Title
          order={4}
          td='underline'
          color='spaceCadetPurple.4'
          sx={(theme) => ({
            [theme.fn.smallerThan('sm')]: {
              display: 'none'
            }
          })}
        >
          Guidebook
        </Title>
      </Button>
      <Modal
        onClose={guidebookModalHandler.close}
        opened={showGuidebook}
        scrollAreaComponent={ScrollArea.Autosize}
        size='xl'
        title={`${
          topicData?.parentTopic?.title ? topicData?.parentTopic?.title + ' / ' : ''
        } ${topicData?.title} / Guidebook`}
        withinPortal
      >
        <MarkdownBox
          p='lg'
          pt='xs'
        >
          {topicData?.guidebook}
        </MarkdownBox>
      </Modal>
    </>
  )
}
