/**
 * @generated SignedSource<<21d74ce53249c410817ea67f65db529c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PerformanceScoreBox_examTypesUser$data = {
  readonly score: number;
  readonly totalTimeSpent: {
    readonly days: number | null | undefined;
    readonly hours: number | null | undefined;
    readonly minutes: number | null | undefined;
    readonly seconds: number | null | undefined;
  };
  readonly " $fragmentType": "PerformanceScoreBox_examTypesUser";
};
export type PerformanceScoreBox_examTypesUser$key = {
  readonly " $data"?: PerformanceScoreBox_examTypesUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"PerformanceScoreBox_examTypesUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PerformanceScoreBox_examTypesUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "score",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Interval",
      "kind": "LinkedField",
      "name": "totalTimeSpent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "days",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seconds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExamTypesUser",
  "abstractKey": null
};

(node as any).hash = "bd721aa36d43ff6061767851af165133";

export default node;
