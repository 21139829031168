import {
  type TopicObjectivesTable_topic$data,
  type TopicObjectivesTable_topic$key
} from '@app/__generated__/TopicObjectivesTable_topic.graphql'
import { type TopicObjectivesTablePaginationQuery } from '@app/__generated__/TopicObjectivesTablePaginationQuery.graphql'
import { type TopicObjectivesTableReorderTopicObjectiveMutation } from '@app/__generated__/TopicObjectivesTableReorderTopicObjectiveMutation.graphql'
import { ActionColumnIcon, IconBoolean, PaginationTable } from '@app/components'
import { type TopicObjectiveFilter, type TopicObjectivesOrderBy } from '@app/lib'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, useMutation, usePaginationFragment } from 'react-relay'
import { Get, IterableElement } from 'type-fest'

type TopicObjectiveEdge = IterableElement<Get<Get<TopicObjectivesTable_topic$data, 'topicObjectives'>, 'edges'>>

const reorderMutation = graphql`
  mutation TopicObjectivesTableReorderTopicObjectiveMutation($input: UpdateTopicObjectiveByIdInput!) {
    updateTopicObjectiveById(input: $input) {
      topicObjective {
        sequence
      }
      topic {
        ...TopicObjectivesTable_topic
      }
    }
  }
`

// noinspection GraphQLUnresolvedReference
const topicFragment = graphql`
  fragment TopicObjectivesTable_topic on Topic
  @argumentDefinitions(
    filter: { type: "TopicObjectiveFilter", defaultValue: null }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "Cursor" }
    orderBy: { type: "[TopicObjectivesOrderBy!]", defaultValue: [TITLE_ASC] }
  )
  @refetchable(queryName: "TopicObjectivesTablePaginationQuery") {
    examType {
      slug
    }
    parentTopic {
      slug
    }
    slug
    topicObjectives(filter: $filter, first: $first, after: $after, orderBy: $orderBy)
      @connection(key: "TopicObjectivesTable_topic_topicObjectives", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          id
          isActive
          sequence
          slug
          title
        }
      }
      totalCount
    }
  }
`

export interface TopicObjectivesTableProps {
  topic: TopicObjectivesTable_topic$key | null
}

export const TopicObjectivesTable: FC<TopicObjectivesTableProps> = ({ topic }) => {
  const {
    data: topicData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<TopicObjectivesTablePaginationQuery, TopicObjectivesTable_topic$key>(topicFragment, topic)
  const [reorderTopicObjective, isReorderingTopicObjective] =
    useMutation<TopicObjectivesTableReorderTopicObjectiveMutation>(reorderMutation)

  return (
    <PaginationTable<
      TopicObjectiveEdge,
      TopicObjectivesOrderBy,
      TopicObjectivesTablePaginationQuery,
      TopicObjectiveFilter
    >
      columns={useMemo<ColumnDef<TopicObjectiveEdge>[]>(
        () => [
          {
            id: 'actions',
            size: 50,
            enableMultiSort: false,
            enableSorting: false,
            cell: (props) => {
              const node = props.row.original.node

              return (
                <ActionColumnIcon
                  href={`/admin/examTypes/${topicData?.examType?.slug}/topics/${topicData?.parentTopic?.slug}/subtopics/${topicData?.slug}/objectives/${node.slug}`}
                />
              )
            }
          },
          {
            accessorKey: 'node.title',
            header: 'Title'
          },
          {
            accessorKey: 'node.isActive',
            header: 'Active',
            size: 75,
            cell: (props) => {
              const node = props.row.original.node

              return (
                <IconBoolean
                  size={18}
                  value={node.isActive}
                />
              )
            }
          }
        ],
        [topicData]
      )}
      data={topicData?.topicObjectives?.edges || []}
      filterPlaceholder='Search Objectives'
      getFilterFromSearch={useCallback(
        (search: string) =>
          !isEmpty(search)
            ? {
                or: [
                  {
                    title: {
                      includesInsensitive: search
                    }
                  },
                  {
                    summary: {
                      includesInsensitive: search
                    }
                  }
                ]
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={[
        {
          id: 'sequence',
          desc: false
        }
      ]}
      isFilterable
      isLoadingNext={isLoadingNext}
      isReorderable
      loadNext={loadNext}
      onReorder={useCallback<(sourceIndex: number, destinationIndex: number) => void>(
        (sourceIndex, destinationIndex) => {
          if (!isReorderingTopicObjective) {
            const topicObjective = topicData?.topicObjectives?.edges?.[sourceIndex]?.node
            const nextSequence = destinationIndex + 1

            if (topicObjective && topicObjective.sequence !== nextSequence) {
              reorderTopicObjective({
                variables: {
                  input: {
                    id: topicObjective.id,
                    patch: {
                      sequence: nextSequence
                    }
                  }
                }
              })
            }
          }
        },
        [isReorderingTopicObjective, topicData?.topicObjectives?.edges, reorderTopicObjective]
      )}
      refetch={refetch}
      totalCount={topicData?.topicObjectives?.totalCount || 0}
    />
  )
}
