import { DateInput as BaseDateInput, type DateInputProps as BaseDateInputProps } from '@mantine/dates'
import { IconCalendar } from '@tabler/icons-react'
import { type FC } from 'react'

export interface DateInputProps extends BaseDateInputProps {}

export const DateInput: FC<DateInputProps> = (props) => (
  <BaseDateInput
    icon={<IconCalendar size={18} />}
    mb='xs'
    radius='md'
    {...props}
  />
)
