import { MarkdownEditor, markdownPreviewProps } from '@app/components'
import { Input, type InputWrapperProps, useMantineTheme } from '@mantine/core'
import { type MDEditorProps } from '@uiw/react-md-editor'
import { type FC } from 'react'

export interface MarkdownEditorInputProps
  extends Omit<InputWrapperProps, 'children' | 'onChange'>,
    Pick<MDEditorProps, 'value' | 'onChange'> {
  editorProps?: Omit<MDEditorProps, 'value' | 'onChange'>
}

export const MarkdownEditorInput: FC<MarkdownEditorInputProps> = ({
  editorProps,
  value,
  onChange,
  style,
  ...props
}) => {
  const theme = useMantineTheme()

  return (
    <Input.Wrapper
      mb='xs'
      {...props}
    >
      <MarkdownEditor
        commandsFilter={(command, isExtra) =>
          isExtra && (command.keyCommand === 'divider' || command.name === 'fullscreen') ? false : command
        }
        onChange={onChange}
        // this stupid library sets 'overflow: hidden' on the body tag, permanently, unless you set this to false 🤦🏼
        overflow={false}
        previewOptions={{
          ...markdownPreviewProps,
          style: {
            ...markdownPreviewProps.style,
            fontSize: theme.fontSizes.sm
          }
        }}
        style={{
          fontSize: theme.fontSizes.sm,
          ...style
        }}
        value={value}
        {...editorProps}
      />
    </Input.Wrapper>
  )
}
