import { Checkbox, type CheckboxProps } from '@mantine/core'
import { type FC } from 'react'

export interface CheckboxInputProps extends Omit<CheckboxProps, 'onChange'> {
  onChange?: (checked: boolean) => void
}

export const CheckboxInput: FC<CheckboxInputProps> = ({ onChange, ...props }) => (
  <Checkbox
    mb='xl'
    onChange={(e) => onChange?.(e.currentTarget.checked as any)}
    styles={{
      label: {
        fontWeight: 500
      }
    }}
    {...props}
  />
)
