import { ActionColumnIcon } from '@app/components'
import { Menu, type MenuProps } from '@mantine/core'
import { type FC } from 'react'

export interface DataTableActionMenuProps extends MenuProps {
  title?: string
}

export const ActionColumnMenu: FC<DataTableActionMenuProps> = ({ children, title, ...props }) => {
  return (
    <Menu
      offset={0}
      position='bottom-start'
      withArrow
      withinPortal
      {...props}
    >
      <Menu.Target>
        <ActionColumnIcon title={title} />
      </Menu.Target>
      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  )
}
