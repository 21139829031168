/**
 * @generated SignedSource<<454305bccee5a458ad605b2346c72fac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestionForm_question$data = {
  readonly body: string;
  readonly explanation: string;
  readonly isActive: boolean | null | undefined;
  readonly isLesson: boolean | null | undefined;
  readonly isStudySession: boolean | null | undefined;
  readonly rowId: any;
  readonly topic: {
    readonly parentTopicId: any | null | undefined;
    readonly rowId: any;
  } | null | undefined;
  readonly topicObjectiveId: any | null | undefined;
  readonly " $fragmentType": "QuestionForm_question";
};
export type QuestionForm_question$key = {
  readonly " $data"?: QuestionForm_question$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionForm_question">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionForm_question",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "explanation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLesson",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isStudySession",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentTopicId",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topicObjectiveId",
      "storageKey": null
    }
  ],
  "type": "Question",
  "abstractKey": null
};
})();

(node as any).hash = "dcd5339bbeaf57870b5cd05c6e731a9b";

export default node;
