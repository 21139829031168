/**
 * @generated SignedSource<<eb750b8d6c574549701216354ecc6d49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicsPageHeader_user$data = {
  readonly userSubtopics: {
    readonly nodes: ReadonlyArray<{
      readonly rid: any;
      readonly score: number;
    }>;
  };
  readonly userTopics: {
    readonly nodes: ReadonlyArray<{
      readonly rid: any;
      readonly score: number;
    }>;
  };
  readonly " $fragmentType": "TopicsPageHeader_user";
};
export type TopicsPageHeader_user$key = {
  readonly " $data"?: TopicsPageHeader_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicsPageHeader_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicsPageHeader_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserTopicsConnection",
      "kind": "LinkedField",
      "name": "userTopics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserTopic",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": "rid",
              "args": null,
              "kind": "ScalarField",
              "name": "topicId",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSubtopicsConnection",
      "kind": "LinkedField",
      "name": "userSubtopics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserSubtopic",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": "rid",
              "args": null,
              "kind": "ScalarField",
              "name": "subtopicId",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "0437c950a2205fbc3b76ff40b0b0e5f3";

export default node;
