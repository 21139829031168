import { Box, type BoxProps } from '@mantine/core'
import Image from 'next/image'
import { type FC } from 'react'

import bellImage from './bell.svg'
import boltImage from './bolt.svg'
import bookImage from './book.svg'
import clockImage from './clock.svg'
import easelImage from './easel.svg'
import exciteImage from './excite.svg'
import snowflakeImage from './flake.svg'
import flameImage from './flame.svg'
import negativeImage from './negative.svg'
import phoneImage from './phone.svg'
import targetImage from './target.svg'

export interface CustomIconProps extends BoxProps {
  size?: number
  type: 'bell' | 'bolt' | 'book' | 'clock' | 'easel' | 'excite' | 'flake' | 'flame' | 'negative' | 'phone' | 'target'
  alt?: string
}

const imageMap = {
  bell: bellImage,
  bolt: boltImage,
  book: bookImage,
  clock: clockImage,
  easel: easelImage,
  excite: exciteImage,
  flake: snowflakeImage,
  flame: flameImage,
  negative: negativeImage,
  phone: phoneImage,
  target: targetImage
}

export const CustomIcon: FC<CustomIconProps> = ({ type, alt, size = 20, ...props }) => {
  return (
    <Box
      style={{ display: 'inline-block', position: 'relative', width: size, height: size }}
      {...props}
    >
      <Image
        src={imageMap[type]}
        alt={alt || type}
        fill
      />
    </Box>
  )
}
