import { type UpdateStudentModal_user$key } from '@app/__generated__/UpdateStudentModal_user.graphql'
import { UpdateStudentModalChangePasswordMutation } from '@app/__generated__/UpdateStudentModalChangePasswordMutation.graphql'
import { type UpdateStudentModalMutation } from '@app/__generated__/UpdateStudentModalMutation.graphql'
import { UserPatch } from '@app/__generated__/UserSlugPageUpdateUserMutation.graphql'
import { UpdateUserPasswordInput } from '@app/__generated__/UserSlugPageUpdateUserPasswordMutation.graphql'
import { Modal, type ModalProps, UpdateStudentForm } from '@app/components'
import { createStyles } from '@mantine/core'
import { type FC } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const userFragment = graphql`
  fragment UpdateStudentModal_user on User {
    email
    id
    image
    name
    ...UpdateStudentForm_user
  }
`

const changePasswordMutation = graphql`
  mutation UpdateStudentModalChangePasswordMutation($input: CurrentUserChangePasswordInput!) {
    currentUserChangePassword(input: $input) {
      # this mutation doesn't return any payload, to prevent accidentally letting the requester know
      # if there is a user with the given email. we are using the default field clientMutationId as the payload
      # since *something* is required to be returned
      clientMutationId
    }
  }
`

const updateStudentMutation = graphql`
  mutation UpdateStudentModalMutation($input: UpdateUserByIdInput!) {
    updateUserById(input: $input) {
      user {
        id
        image
        name
        ...UpdateStudentForm_user
      }
    }
  }
`

export interface StudentPasswordResetModalProps extends ModalProps {
  onClose: () => void
  user: UpdateStudentModal_user$key | null
}

const useStyles = createStyles((theme) => ({
  modalTitle: {
    fontWeight: 'bold',
    fontSize: theme.fontSizes.lg
  }
}))

export const UpdateStudentModal: FC<StudentPasswordResetModalProps> = ({ classNames, onClose, user, ...props }) => {
  const { classes } = useStyles()
  const [updateStudent, isUpdatingStudent] = useMutation<UpdateStudentModalMutation>(updateStudentMutation)
  const [changePassword, isChangingPassword] =
    useMutation<UpdateStudentModalChangePasswordMutation>(changePasswordMutation)
  const userData = useFragment<UpdateStudentModal_user$key>(userFragment, user)

  return (
    <Modal
      title='Edit Account'
      classNames={{
        title: classes.modalTitle,
        ...classNames
      }}
      onClose={onClose}
      {...props}
    >
      <UpdateStudentForm
        isSaving={isUpdatingStudent || isChangingPassword}
        onSubmit={({ password, ...patch }: Partial<UserPatch & UpdateUserPasswordInput>) =>
          updateStudent({
            variables: {
              input: {
                id: userData?.id as string,
                patch
              }
            },
            onCompleted: () => {
              if (password) {
                changePassword({
                  variables: {
                    input: {
                      newPassword: password
                    }
                  },
                  onCompleted: onClose
                })
              } else {
                onClose()
              }
            }
          })
        }
        user={userData}
      />
    </Modal>
  )
}
