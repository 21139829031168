import { type AdminTopicObjectiveLayout_query$key } from '@app/__generated__/AdminTopicObjectiveLayout_query.graphql'
import { type AdminTopicObjectiveLayout_topicObjective$key } from '@app/__generated__/AdminTopicObjectiveLayout_topicObjective.graphql'
import { AdminNavbarLink } from '@app/components'
import { Navbar } from '@mantine/core'
import { IconMedal } from '@tabler/icons-react'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import { AdminLayout, AdminLayoutProps } from './AdminLayout'

const topicObjectiveFragment = graphql`
  fragment AdminTopicObjectiveLayout_topicObjective on TopicObjective {
    topic {
      parentTopic {
        examType {
          slug
        }
        slug
      }
      slug
    }
    title
    slug
  }
`

const queryFragment = graphql`
  fragment AdminTopicObjectiveLayout_query on Query {
    ...AdminLayout_query
  }
`

export interface AdminTopicObjectiveLayoutProps extends Omit<AdminLayoutProps, 'query'> {
  topicObjective: AdminTopicObjectiveLayout_topicObjective$key | null
  query: AdminTopicObjectiveLayout_query$key | null
}

export const AdminTopicObjectiveLayout: FC<AdminTopicObjectiveLayoutProps> = ({
  children,
  topicObjective,
  query,
  ...props
}) => {
  const topicObjectiveData = useFragment<AdminTopicObjectiveLayout_topicObjective$key>(
    topicObjectiveFragment,
    topicObjective
  )
  const queryData = useFragment<AdminTopicObjectiveLayout_query$key>(queryFragment, query)

  return (
    <AdminLayout
      navbar={
        <Navbar.Section>
          <AdminNavbarLink
            href={`/admin/examTypes/${topicObjectiveData?.topic?.parentTopic?.examType?.slug}/topics/${topicObjectiveData?.topic?.parentTopic?.slug}/subtopics/${topicObjectiveData?.topic?.slug}/objectives/${topicObjectiveData?.slug}`}
            IconComponent={IconMedal}
            label='Objective'
          />
        </Navbar.Section>
      }
      query={queryData}
      {...props}
    >
      {children}
    </AdminLayout>
  )
}
