/**
 * @generated SignedSource<<6e1b513be24c7bec52e958b5050a61fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamTypeUserCreateModal_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExamTypeUserForm_query">;
  readonly " $fragmentType": "ExamTypeUserCreateModal_query";
};
export type ExamTypeUserCreateModal_query$key = {
  readonly " $data"?: ExamTypeUserCreateModal_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamTypeUserCreateModal_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamTypeUserCreateModal_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamTypeUserForm_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0c0653dfc05bf5170e337b553795a5a5";

export default node;
