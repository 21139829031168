import { type StudySessionQuestionWizard_userStudySession$key } from '@app/__generated__/StudySessionQuestionWizard_userStudySession.graphql'
import { StudySessionQuestion, type StudySessionQuestionOnSubmitData, WizardWrapper } from '@app/components'
import { isEmpty, map } from 'lodash'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const userStudySessionFragment = graphql`
  fragment StudySessionQuestionWizard_userStudySession on UserStudySession {
    userStudySessionQuestions(orderBy: [SEQUENCE_ASC]) {
      nodes {
        rowId
        ...StudySessionQuestion_userStudySessionQuestion
      }
    }
  }
`
export interface StudySessionQuestionWizardProps {
  userStudySession: StudySessionQuestionWizard_userStudySession$key
  onClose: () => void
  sessionTimer: number
  questionOnSubmit: (answerData: StudySessionQuestionOnSubmitData) => void
}
export const StudySessionQuestionWizard: FC<StudySessionQuestionWizardProps> = ({
  userStudySession,
  onClose,
  sessionTimer,
  questionOnSubmit
}) => {
  const userStudySessionData = useFragment(userStudySessionFragment, userStudySession)

  return (
    <WizardWrapper
      stepCount={
        !isEmpty(userStudySessionData?.userStudySessionQuestions)
          ? userStudySessionData.userStudySessionQuestions.nodes.length
          : 0
      }
    >
      {({ active, onNext }) =>
        map(userStudySessionData?.userStudySessionQuestions.nodes, (userStudySessionQuestion, index) => (
          <div
            hidden={active !== index}
            key={`step${index}`}
          >
            {active === index && (
              <StudySessionQuestion
                questionsCount={userStudySessionData.userStudySessionQuestions.nodes.length}
                userStudySessionQuestion={userStudySessionQuestion}
                sessionTimeInSeconds={sessionTimer}
                onSubmit={(answerData) => {
                  questionOnSubmit(answerData)
                  onNext()
                }}
                onCancel={onClose}
              />
            )}
          </div>
        ))
      }
    </WizardWrapper>
  )
}
