/**
 * @generated SignedSource<<0d80f636119f57ea2bb0f25222ab1163>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicPropertyList_topic$data = {
  readonly createdAt: any;
  readonly guidebook: string | null | undefined;
  readonly image: string | null | undefined;
  readonly isActive: boolean | null | undefined;
  readonly parentTopicId: any | null | undefined;
  readonly summary: string | null | undefined;
  readonly title: string;
  readonly updatedAt: any;
  readonly " $fragmentType": "TopicPropertyList_topic";
};
export type TopicPropertyList_topic$key = {
  readonly " $data"?: TopicPropertyList_topic$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicPropertyList_topic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicPropertyList_topic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "guidebook",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentTopicId",
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "96632a31b48e66d41fb22d14ee8e493d";

export default node;
