/**
 * @generated SignedSource<<c5ab0143005b78f61cb2b7970316e704>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteQuestionAnswerByIdInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type QuestionAnswersTableDeleteQuestionAnswerMutation$variables = {
  input: DeleteQuestionAnswerByIdInput;
};
export type QuestionAnswersTableDeleteQuestionAnswerMutation$data = {
  readonly deleteQuestionAnswerById: {
    readonly deletedQuestionAnswerId: string | null | undefined;
    readonly question: {
      readonly " $fragmentSpreads": FragmentRefs<"QuestionAnswersTable_question">;
    } | null | undefined;
  } | null | undefined;
};
export type QuestionAnswersTableDeleteQuestionAnswerMutation = {
  response: QuestionAnswersTableDeleteQuestionAnswerMutation$data;
  variables: QuestionAnswersTableDeleteQuestionAnswerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedQuestionAnswerId",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      "SEQUENCE_ASC"
    ]
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionAnswersTableDeleteQuestionAnswerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteQuestionAnswerPayload",
        "kind": "LinkedField",
        "name": "deleteQuestionAnswerById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Question",
            "kind": "LinkedField",
            "name": "question",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "QuestionAnswersTable_question"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuestionAnswersTableDeleteQuestionAnswerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteQuestionAnswerPayload",
        "kind": "LinkedField",
        "name": "deleteQuestionAnswerById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Question",
            "kind": "LinkedField",
            "name": "question",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "QuestionAnswersConnection",
                "kind": "LinkedField",
                "name": "questionAnswers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuestionAnswersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "QuestionAnswer",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "body",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isActive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCorrect",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rowId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sequence",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "questionAnswers(first:10,orderBy:[\"SEQUENCE_ASC\"])"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": [
                  "filter",
                  "orderBy"
                ],
                "handle": "connection",
                "key": "QuestionAnswersTable_questionAnswers",
                "kind": "LinkedHandle",
                "name": "questionAnswers"
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d168e1d009e945ce250ae264fe004b7a",
    "id": null,
    "metadata": {},
    "name": "QuestionAnswersTableDeleteQuestionAnswerMutation",
    "operationKind": "mutation",
    "text": "mutation QuestionAnswersTableDeleteQuestionAnswerMutation(\n  $input: DeleteQuestionAnswerByIdInput!\n) {\n  deleteQuestionAnswerById(input: $input) {\n    deletedQuestionAnswerId\n    question {\n      ...QuestionAnswersTable_question\n      id\n    }\n  }\n}\n\nfragment QuestionAnswerForm_questionAnswer on QuestionAnswer {\n  body\n  isActive\n  isCorrect\n}\n\nfragment QuestionAnswersTable_question on Question {\n  questionAnswers(first: 10, orderBy: [SEQUENCE_ASC]) {\n    edges {\n      node {\n        body\n        id\n        isActive\n        isCorrect\n        rowId\n        sequence\n        ...QuestionAnswerForm_questionAnswer\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "3174a0cd20d8c36342b7245b2bd362e5";

export default node;
