import { type UserForm_user$key } from '@app/__generated__/UserForm_user.graphql'
import { CheckboxInput, Form, PasswordInput, TextInput } from '@app/components'
import { useForm, zodResolver } from '@mantine/form'
import { IconMail } from '@tabler/icons-react'
import { type FC, useCallback } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const formSchema = z
  .object({
    email: z.string().trim().email(),
    image: z.string().trim().url().or(z.literal('')),
    isAdmin: z.boolean(),
    isActive: z.boolean(),
    name: z
      .string({
        required_error: 'Enter a name'
      })
      .trim()
      .max(128, 'Must be 128 characters or less')
      .min(2, 'Must be at least 2 characters'),
    password: z.string().optional(),
    passwordConfirm: z.string().optional()
  })
  .refine(({ password, passwordConfirm }) => password === passwordConfirm, {
    path: ['passwordConfirm'],
    message: 'Both passwords must match'
  })
const userFragment = graphql`
  fragment UserForm_user on User {
    email
    hasPassword
    image
    isAdmin
    isActive
    name
  }
`

export interface UserFormData {
  email: string
  image: string | null
  name: string
  isAdmin: boolean
  isActive: boolean
  password: string | undefined
}

export type UserFormProps = {
  isSaving?: boolean
  onSubmit: (data: UserFormData) => void
  user: UserForm_user$key | null
}

export const UserForm: FC<UserFormProps> = ({ isSaving, onSubmit, user }) => {
  const userData = useFragment<UserForm_user$key>(userFragment, user)
  const form = useForm({
    initialValues: {
      email: userData?.email || '',
      image: userData?.image || '',
      name: userData?.name || '',
      isAdmin: userData?.isAdmin || false,
      isActive: userData?.isActive || true,
      password: '',
      passwordConfirm: ''
    },
    validate: zodResolver(formSchema),
    validateInputOnChange: true
  })

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit(
        useCallback(
          ({ image, password, passwordConfirm, ...values }) =>
            onSubmit({
              ...values,
              image: image || null,
              password: password && passwordConfirm ? password : undefined
            }),
          [onSubmit]
        )
      )}
    >
      <TextInput
        data-autofocus
        disabled={isSaving}
        label='Name'
        required
        {...form.getInputProps('name')}
      />
      <TextInput
        disabled={isSaving}
        label='Image'
        placeholder='http://'
        type='url'
        {...form.getInputProps('image')}
      />
      <TextInput
        disabled={isSaving}
        label='Email'
        required
        icon={<IconMail size={18} />}
        type='email'
        {...form.getInputProps('email')}
      />
      <PasswordInput
        disabled={isSaving}
        label={`${userData?.hasPassword ? 'Reset' : 'Set'} Password`}
        {...form.getInputProps('password')}
      />
      <PasswordInput
        disabled={isSaving}
        label='Confirm Password'
        {...form.getInputProps('passwordConfirm')}
      />
      <CheckboxInput
        disabled={isSaving}
        label='Is Admin'
        mt='xl'
        mb='xl'
        {...form.getInputProps('isAdmin', { type: 'checkbox' })}
      />
      <CheckboxInput
        disabled={isSaving}
        label='Is Active'
        mt='xl'
        mb='xl'
        {...form.getInputProps('isActive', { type: 'checkbox' })}
      />
    </Form>
  )
}
