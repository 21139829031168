import { type QuestionPropertyList_question$key } from '@app/__generated__/QuestionPropertyList_question.graphql'
import { IconBoolean, MarkdownBox, PropertyList, type PropertyListProps } from '@app/components'
import { longDate } from '@app/lib'
import { Anchor, Text } from '@mantine/core'
import Link from 'next/link'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const questionFragment = graphql`
  fragment QuestionPropertyList_question on Question {
    body
    createdAt
    explanation
    isActive
    topic {
      parentTopic {
        examType {
          slug
        }
        slug
      }
      slug
    }
    topicObjective {
      slug
      title
    }
    updatedAt
  }
`

export interface QuestionPropertyListProps extends Omit<PropertyListProps, 'items'> {
  question: QuestionPropertyList_question$key | null
}

export const QuestionPropertyList: FC<QuestionPropertyListProps> = ({ question }) => {
  const questionData = useFragment(questionFragment, question)
  return (
    <PropertyList
      p='xs'
      items={[
        {
          label: 'Body',
          value: (
            <MarkdownBox
              mb='xs'
              w='100%'
            >
              {questionData?.body}
            </MarkdownBox>
          )
        },
        {
          label: 'Explanation',
          value: (
            <MarkdownBox
              mb='xs'
              w='100%'
            >
              {questionData?.explanation}
            </MarkdownBox>
          )
        },
        {
          label: 'Objectives',
          value: questionData?.topicObjective ? (
            <Anchor
              component={Link}
              href={`/admin/examTypes/${questionData?.topic?.parentTopic?.examType?.slug}/topics/${questionData?.topic?.parentTopic?.slug}/subtopics/${questionData?.topic?.slug}/objectives/${questionData?.topicObjective?.slug}`}
            >
              {questionData?.topicObjective?.title}
            </Anchor>
          ) : (
            <Text color='red'>No objective selected</Text>
          )
        },
        {
          label: 'Active',
          value: (
            <IconBoolean
              size={18}
              value={questionData?.isActive ?? false}
            />
          )
        },
        {
          label: 'Created',
          value: longDate(questionData?.createdAt)
        },
        {
          label: 'Last Updated',
          value: longDate(questionData?.updatedAt)
        }
      ]}
    />
  )
}
