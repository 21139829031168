import {
  ActionIcon,
  Anchor,
  Breadcrumbs,
  createStyles,
  Group,
  Header,
  type HeaderProps,
  Menu,
  Text
} from '@mantine/core'
import { IconDots } from '@tabler/icons-react'
import { map, slice, truncate } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { type FC, type ReactNode, useCallback } from 'react'

import logoImg from '../logo.png'

const useStyles = createStyles((theme) => ({
  breadcrumbsAndLinkGroup: {
    height: 30
  },
  breadcrumbsSeparator: {
    marginLeft: 5,
    marginRight: 5
  },
  link: {
    color: theme.colors.stickyNoteBlue[4]
  }
}))
export interface AdminHeaderBreadcrumb {
  href?: string
  label?: string
}

export interface AdminHeaderProps extends Omit<HeaderProps, 'children' | 'height'> {
  breadcrumbs?: AdminHeaderBreadcrumb[]
  height?: number
  leftSection?: ReactNode
  links?: ReactNode
  rightSection?: ReactNode
}

export const AdminHeader: FC<AdminHeaderProps> = ({
  breadcrumbs,
  height: passedHeight = 50,
  leftSection,
  links,
  rightSection,
  ...props
}) => {
  const { classes } = useStyles()
  const renderBreadcrumbs = useCallback(() => {
    let links: Array<AdminHeaderBreadcrumb & { items?: AdminHeaderBreadcrumb[] }> = breadcrumbs || []

    if (breadcrumbs && breadcrumbs.length > 4) {
      const splitIndex = breadcrumbs.length - 3

      links = [{ items: slice(breadcrumbs, 0, splitIndex) }, ...slice(breadcrumbs, splitIndex)]
    }

    return map(links, ({ items, href, label: labelRaw }, linkIdx) => {
      const linkKey = `breadcrumb-${linkIdx}`
      const label = truncate(labelRaw, { length: 45 })

      if (items) {
        return (
          <Menu
            key={linkKey}
            offset={0}
            shadow='xl'
            withArrow
            withinPortal
          >
            <Menu.Target>
              <ActionIcon
                color='stickyNoteBlue'
                variant='transparent'
              >
                <IconDots
                  size={14}
                  strokeWidth={3}
                />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              {map(items, ({ href, label }, itemIdx) => (
                <Menu.Item
                  color='stickyNoteBlue'
                  component={Link}
                  href={href || '#'}
                  key={`${linkKey}-${itemIdx}`}
                >
                  <Text weight='bold'>{label}</Text>
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        )
      }

      if (href) {
        return (
          <Anchor
            className={classes.link}
            component={Link}
            href={href}
            key={linkKey}
            size='sm'
            underline={false}
            weight='bold'
          >
            {label}
          </Anchor>
        )
      }

      return (
        <Text
          color='stickyNoteBlue.4'
          key={linkKey}
          size='sm'
          weight='bold'
        >
          {label}
        </Text>
      )
    })
  }, [breadcrumbs, classes.link])

  return (
    <Header
      height={passedHeight + (breadcrumbs ? 40 : 0)}
      {...props}
    >
      <Group
        align='center'
        h={passedHeight}
        position='apart'
        px='xs'
        spacing='xs'
      >
        <Group
          position='left'
          spacing='xs'
        >
          <Anchor
            component={Link}
            href='/'
            title='Home'
          >
            <Image
              alt='Studious'
              width={156}
              height={31}
              src={logoImg}
              priority
            />
          </Anchor>
          {leftSection}
        </Group>
        {rightSection && (
          <Group
            position='right'
            spacing='xs'
          >
            {rightSection}
          </Group>
        )}
      </Group>
      {(breadcrumbs || links) && (
        <Group
          align='center'
          className={classes.breadcrumbsAndLinkGroup}
          noWrap
          position='apart'
          spacing='xs'
          px='xs'
        >
          {breadcrumbs && (
            <Breadcrumbs classNames={{ separator: classes.breadcrumbsSeparator }}>
              <Anchor
                className={classes.link}
                component={Link}
                href='/admin'
                size='sm'
                underline={false}
                weight='bold'
              >
                Admin
              </Anchor>
              {renderBreadcrumbs()}
            </Breadcrumbs>
          )}
          {links && (
            <Group
              position='right'
              spacing='xs'
            >
              {links}
            </Group>
          )}
        </Group>
      )}
    </Header>
  )
}
