/**
 * @generated SignedSource<<e6956d865792740b479d6aa6d4fb222f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestionAnswers_userStudySessionQuestion$data = {
  readonly question: {
    readonly questionAnswers: {
      readonly nodes: ReadonlyArray<{
        readonly body: string;
        readonly isCorrect: boolean | null | undefined;
        readonly rowId: any;
        readonly sequence: number;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"QuestionResult_question">;
  } | null | undefined;
  readonly questionAnswerId: any | null | undefined;
  readonly " $fragmentType": "QuestionAnswers_userStudySessionQuestion";
};
export type QuestionAnswers_userStudySessionQuestion$key = {
  readonly " $data"?: QuestionAnswers_userStudySessionQuestion$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionAnswers_userStudySessionQuestion">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionAnswers_userStudySessionQuestion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Question",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "condition",
              "value": {
                "isActive": true
              }
            }
          ],
          "concreteType": "QuestionAnswersConnection",
          "kind": "LinkedField",
          "name": "questionAnswers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuestionAnswer",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "body",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isCorrect",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rowId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sequence",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "questionAnswers(condition:{\"isActive\":true})"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuestionResult_question"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "questionAnswerId",
      "storageKey": null
    }
  ],
  "type": "UserStudySessionQuestion",
  "abstractKey": null
};

(node as any).hash = "e59507a30d233efeecec428e4f4c4606";

export default node;
