import { PasswordInput, PasswordInputProps } from '@app/components'
import { createStyles } from '@mantine/core'
import { FC } from 'react'

const useStyles = createStyles(() => ({
  inputLabel: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  inputWrapper: {
    padding: '30px 15px'
  },
  inputField: {
    fontWeight: 'bold',
    borderRadius: 5,
    padding: '30px 15px',
    fontSize: 18
  }
}))

export interface StudentPasswordInputProps extends PasswordInputProps {}

export const StudentPasswordInput: FC<StudentPasswordInputProps> = (passwordInputProps) => {
  const { classes } = useStyles()
  return (
    <PasswordInput
      popoverProps={{
        withinPortal: true,
        zIndex: 10001
      }}
      classNames={{
        input: classes.inputWrapper,
        innerInput: classes.inputField,
        label: classes.inputLabel
      }}
      mb='xl'
      {...passwordInputProps}
    />
  )
}
