import Script from 'next/script'
import pSingleton from 'p-singleton'
import pWaitFor from 'p-wait-for'
import { createContext, type FC, type ReactNode, useContext } from 'react'

const getElement = () => document.getElementById('cometchat__widget')
const init = async () => {
  await pWaitFor(() => !!window.CometChatWidget)

  if (!window.CometChatWidget.CometChat.isInitialized()) {
    await window.CometChatWidget.init({
      appID: process.env.NEXT_PUBLIC_APP_COMET_CHAT_APP_ID,
      appRegion: process.env.NEXT_PUBLIC_APP_COMET_CHAT_APP_REGION,
      authKey: process.env.NEXT_PUBLIC_APP_COMET_CHAT_AUTH_KEY
    })
  }
}

export interface CometChatContextValue {
  login(userData: { avatar?: string | null; name: string | null; uid: string }): Promise<void>
  logout(): Promise<void>
}

const contextValue: CometChatContextValue = {
  login: pSingleton(async (userData) => {
    await init()

    const maybeNewUser = new window.CometChatWidget.CometChat.User(userData.uid)

    maybeNewUser.setName(userData.name)

    if (userData.avatar) {
      maybeNewUser.setAvatar(userData.avatar)
    }

    const cometChatUser = await window.CometChatWidget.createOrUpdateUser(maybeNewUser)

    await window.CometChatWidget.login({ uid: cometChatUser.getUid() })

    if (!getElement()) {
      await window.CometChatWidget.launch({
        widgetID: process.env.NEXT_PUBLIC_APP_COMET_CHAT_WIDGET_ID,
        docked: 'true',
        alignment: 'right',
        roundedCorners: 'true',
        height: '600px',
        width: '400px',
        defaultType: 'user'
      })
    }
  }),
  logout: pSingleton(async () => {
    await init()

    const cometChatUser = await window.CometChatWidget.CometChat.getLoggedinUser()

    if (cometChatUser) {
      await window.CometChatWidget.logout()
    }

    const el = getElement()

    if (el) {
      el.remove()
    }
  })
}

export const CometChatContext = createContext<CometChatContextValue>(contextValue)

export interface CometChatProviderProps {
  children: ReactNode
}

export const useCometChatContext = () => useContext(CometChatContext)

// noinspection JSUnresolvedLibraryURL
export const CometChatProvider: FC<CometChatProviderProps> = ({ children }) => (
  <CometChatContext.Provider value={contextValue}>
    {children}
    <Script
      src='https://widget-js.cometchat.io/v3/cometchatwidget.js'
      strategy='lazyOnload'
    />
  </CometChatContext.Provider>
)
