import { type CurrentWeekStudySessionInfo_examType$key } from '@app/__generated__/CurrentWeekStudySessionInfo_examType.graphql'
import { BorderBox, CustomIcon } from '@app/components'
import { createStyles, List, Title } from '@mantine/core'
import dayjs from 'dayjs'
import { reduce } from 'lodash'
import { type FC, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

const examTypeFragment = graphql`
  fragment CurrentWeekStudySessionInfo_examType on ExamType {
    userStudySessionsData: userStudySessions(filter: { completedAt: { isNull: false } }, orderBy: [COMPLETED_AT_DESC]) {
      nodes {
        completedAt
        startedAt
      }
    }
  }
`

const useStyles = createStyles(() => ({
  iconListRoot: {
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center'
  },
  iconListItem: {
    padding: 0,
    margin: 0,
    '&:not(:first-of-type)': {
      margin: 0,
      marginLeft: 15
    }
  }
}))
export interface CurrentWeekStudySessionInfoProps {
  examType: CurrentWeekStudySessionInfo_examType$key | null
}
export const CurrentWeekStudySessionInfo: FC<CurrentWeekStudySessionInfoProps> = ({ examType }) => {
  const examTypeData = useFragment(examTypeFragment, examType)

  const { currentWeekLessonCount, currentWeekLessonDurationInMinutes } = useMemo(() => {
    return reduce(
      examTypeData?.userStudySessionsData.nodes,
      (acc, { completedAt, startedAt }) => {
        if (dayjs(startedAt) >= dayjs().startOf('week') && dayjs(completedAt) <= dayjs().endOf('week')) {
          acc.currentWeekLessonCount += 1
          acc.currentWeekLessonDurationInMinutes += dayjs(completedAt).diff(dayjs(startedAt), 'minutes')
        }
        return acc
      },
      { currentWeekLessonCount: 0, currentWeekLessonDurationInMinutes: 0 }
    )
  }, [examTypeData?.userStudySessionsData.nodes])

  const { classes, theme } = useStyles()

  return (
    <BorderBox
      backgroundColor={theme.colors.studiousGray[0]}
      mb='lg'
    >
      <Title
        order={5}
        mb={4}
      >
        Progress - per week
      </Title>
      <List
        spacing='xs'
        size='sm'
        center
        classNames={{
          root: classes.iconListRoot,
          item: classes.iconListItem
        }}
      >
        <List.Item icon={<CustomIcon type='book' />}>
          <strong>{currentWeekLessonCount}</strong> Study Session(s)
        </List.Item>
        <List.Item icon={<CustomIcon type='clock' />}>
          <strong>
            {currentWeekLessonDurationInMinutes > 60
              ? `${Math.floor(currentWeekLessonDurationInMinutes / 60)}h ${currentWeekLessonDurationInMinutes % 60}m`
              : `${currentWeekLessonDurationInMinutes}m`}
          </strong>{' '}
          Studied
        </List.Item>
      </List>
    </BorderBox>
  )
}
