/**
 * @generated SignedSource<<8aaf355e45bf33b321a48e6bc119e401>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicObjectiveForm_topicObjective$data = {
  readonly isActive: boolean | null | undefined;
  readonly rowId: any;
  readonly summary: string | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "TopicObjectiveForm_topicObjective";
};
export type TopicObjectiveForm_topicObjective$key = {
  readonly " $data"?: TopicObjectiveForm_topicObjective$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicObjectiveForm_topicObjective">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicObjectiveForm_topicObjective",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "TopicObjective",
  "abstractKey": null
};

(node as any).hash = "186507f76d5bcb4593c31500f5170138";

export default node;
