import { type QuestionResult_question$key } from '@app/__generated__/QuestionResult_question.graphql'
import { MarkdownBox } from '@app/components'
import { createStyles } from '@mantine/core'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const questionResultFragment = graphql`
  fragment QuestionResult_question on Question {
    explanation
  }
`

const useStyles = createStyles((theme) => ({
  correctBox: {
    border: `3px dashed ${theme.colors.jelloGreen[4]}`,
    borderRadius: 5
  },
  explanationBox: {
    border: `3px dashed ${theme.colors.jelloGreen[4]}`,
    borderRadius: 5
  }
}))

export interface QuestionResultProps {
  isCorrect: boolean
  question: QuestionResult_question$key
}

export const QuestionResult: FC<QuestionResultProps> = ({ isCorrect, question }) => {
  const { classes } = useStyles()
  const questionData = useFragment(questionResultFragment, question)

  if (isCorrect) {
    return (
      <MarkdownBox
        p='lg'
        className={classes.correctBox}
      >
        Correct, great job!
      </MarkdownBox>
    )
  }

  if (!isCorrect && questionData?.explanation) {
    return (
      <MarkdownBox
        p='lg'
        className={classes.explanationBox}
      >
        {questionData?.explanation}
      </MarkdownBox>
    )
  }

  return null
}
