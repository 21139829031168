/**
 * @generated SignedSource<<f611893dd7e87eaac96e7cea289613e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserExamTypeForm_query$data = {
  readonly examTypes: {
    readonly nodes: ReadonlyArray<{
      readonly rowId: any;
      readonly title: string;
    }>;
  } | null | undefined;
  readonly " $fragmentType": "UserExamTypeForm_query";
};
export type UserExamTypeForm_query$key = {
  readonly " $data"?: UserExamTypeForm_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserExamTypeForm_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserExamTypeForm_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "TITLE_ASC"
          ]
        }
      ],
      "concreteType": "ExamTypesConnection",
      "kind": "LinkedField",
      "name": "examTypes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamType",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rowId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "examTypes(orderBy:[\"TITLE_ASC\"])"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "c95fac69a32b7e9043256b611aaad6f0";

export default node;
