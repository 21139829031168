/**
 * @generated SignedSource<<84e6cc9d0330122da7d5946739439a5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserExamTypeCreateModal_user$data = {
  readonly rowId: any;
  readonly " $fragmentSpreads": FragmentRefs<"UserExamTypeForm_user">;
  readonly " $fragmentType": "UserExamTypeCreateModal_user";
};
export type UserExamTypeCreateModal_user$key = {
  readonly " $data"?: UserExamTypeCreateModal_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserExamTypeCreateModal_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserExamTypeCreateModal_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserExamTypeForm_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "33e5c718a577f96e4f7d6b2e3929ef72";

export default node;
