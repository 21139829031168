import { type TopicForm_examType$key } from '@app/__generated__/TopicForm_examType.graphql'
import { type TopicForm_topic$key } from '@app/__generated__/TopicForm_topic.graphql'
import { CheckboxInput, Form, MarkdownEditorInput, SelectInput, TextareaInput, TextInput } from '@app/components'
import { useForm, zodResolver } from '@mantine/form'
import { map } from 'lodash'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const examFragment = graphql`
  fragment TopicForm_examType on ExamType {
    allTopics: topics(condition: { parentTopicId: null }, orderBy: [SEQUENCE_ASC]) {
      nodes {
        rowId
        title
      }
    }
  }
`
const formSchema = z.object({
  parentTopicId: z.string().uuid().nullable(),
  guidebook: z.string({
    required_error: 'Enter guidebook content'
  }),
  isActive: z.boolean().default(true),
  image: z.string().trim().url().or(z.literal('')),
  summary: z
    .string({
      required_error: 'Enter a summary'
    })
    .trim()
    .max(128, 'Must be 128 characters or less'),
  title: z
    .string({
      required_error: 'Enter a title'
    })
    .trim()
    .min(1, 'Cannot be empty')
    .max(64, 'Must be 64 characters or less')
})

const topicFragment = graphql`
  fragment TopicForm_topic on Topic {
    guidebook
    image
    isActive
    parentTopicId
    rowId
    summary
    title
  }
`

export interface TopicFormData {
  guidebook: string
  image: string | null
  isActive: boolean
  parentTopicId: string | null
  summary: string | null
  title: string
}

export type TopicFormProps = {
  examType: TopicForm_examType$key | null
  topic: TopicForm_topic$key | null
  isSaving?: boolean
  onSubmit: (data: TopicFormData) => void
  withImage?: boolean
}

export const TopicForm: FC<TopicFormProps> = ({ examType, isSaving, onSubmit, topic, withImage = true }) => {
  const examTypeData = useFragment(examFragment, examType)
  const topicData = useFragment(topicFragment, topic)
  const form = useForm({
    initialValues: {
      guidebook: topicData?.guidebook || '',
      image: topicData?.image || '',
      isActive: topicData?.isActive ?? true,
      parentTopicId: topicData?.parentTopicId || null,
      summary: topicData?.summary || '',
      title: topicData?.title || ''
    },
    validate: zodResolver(formSchema),
    validateInputOnChange: true
  })

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit(({ image, summary, ...data }) =>
        onSubmit({
          ...data,
          image: image || null,
          summary: summary || null
        })
      )}
    >
      {topicData?.parentTopicId && (
        <SelectInput
          data={map(examTypeData?.allTopics?.nodes, ({ rowId, title }) => ({ label: title, value: rowId }))}
          label='Topic'
          {...form.getInputProps('parentTopicId')}
        />
      )}
      <TextInput
        autoCapitalize='words'
        data-autofocus
        disabled={isSaving}
        label='Title'
        required
        spellCheck
        {...form.getInputProps('title')}
      />
      <TextareaInput
        autoCapitalize='sentences'
        disabled={isSaving}
        label='Summary'
        spellCheck
        {...form.getInputProps('summary')}
      />
      <MarkdownEditorInput
        autoCapitalize='sentences'
        label='Guidebook'
        required
        spellCheck
        {...form.getInputProps('guidebook')}
      />
      {withImage ? (
        <TextInput
          disabled={isSaving}
          label='Image'
          placeholder='http://'
          type='url'
          {...form.getInputProps('image')}
        />
      ) : null}
      <CheckboxInput
        disabled={isSaving}
        label='Active'
        {...form.getInputProps('isActive', { type: 'checkbox' })}
      />
    </Form>
  )
}
