import { useId } from '@mantine/hooks'
import mermaid from 'mermaid'
import { type FC, useEffect, useRef } from 'react'

export interface MermaidCodeProps {
  children: string
  className?: string
}

export const MermaidCode: FC<MermaidCodeProps> = ({ children }) => {
  const id = useId()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      mermaid
        .render(id, children)
        .then(({ svg }) => {
          if (ref.current) {
            ref.current.innerHTML = svg
          }
        })
        .catch((e) => {
          if (ref.current) {
            ref.current.innerHTML = e
          }
        })
    }
  }, [id, children])

  return <code ref={ref} />
}
