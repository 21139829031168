import { type QuestionForm_examType$key } from '@app/__generated__/QuestionForm_examType.graphql'
import { type QuestionForm_question$key } from '@app/__generated__/QuestionForm_question.graphql'
import { CheckboxInput, Form, MarkdownEditorInput, SelectInput } from '@app/components'
import { useForm, zodResolver } from '@mantine/form'
import { find, map } from 'lodash'
import { type FC, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const examTypeFragment = graphql`
  fragment QuestionForm_examType on ExamType {
    allTopics: topics(filter: { parentTopicId: { isNull: true } }, orderBy: [SEQUENCE_ASC]) {
      nodes {
        rowId
        title
        parentTopicId
        allSubtopics: topicsByParentTopicId(orderBy: [SEQUENCE_ASC]) {
          nodes {
            rowId
            title
            allTopicObjectives: topicObjectives(orderBy: [SEQUENCE_ASC]) {
              nodes {
                rowId
                title
              }
            }
          }
        }
      }
    }
  }
`
const formSchema = z.object({
  body: z
    .string({
      required_error: 'Enter body text'
    })
    .nonempty(),
  explanation: z
    .string({
      required_error: 'Enter explanation text'
    })
    .nonempty(),
  isActive: z.boolean(),
  isLesson: z.boolean(),
  isStudySession: z.boolean(),
  topicObjectiveId: z
    .string({
      required_error: 'Select an objective'
    })
    .uuid()
})

const questionFragment = graphql`
  fragment QuestionForm_question on Question {
    body
    explanation
    isActive
    isLesson
    isStudySession
    rowId
    topic {
      parentTopicId
      rowId
    }
    topicObjectiveId
  }
`
export interface QuestionFormData {
  body: string
  explanation: string
  isActive: boolean
  isLesson: boolean
  isStudySession: boolean
  topicObjectiveId: string
}

export type QuestionFormProps = {
  examType: QuestionForm_examType$key | null
  question: QuestionForm_question$key | null
  isSaving?: boolean
  onSubmit: (data: QuestionFormData) => void
}

export const QuestionForm: FC<QuestionFormProps> = ({ examType, isSaving, onSubmit, question }) => {
  const examTypeData = useFragment(examTypeFragment, examType)
  const questionData = useFragment(questionFragment, question)
  const form = useForm({
    initialValues: {
      _parentTopicId: questionData?.topic?.parentTopicId || null,
      body: questionData?.body || '',
      explanation: questionData?.explanation || '',
      isActive: questionData?.isActive ?? true,
      isLesson: questionData?.isLesson ?? true,
      isStudySession: questionData?.isStudySession ?? true,
      topicId: questionData?.topic?.rowId || null,
      topicObjectiveId: questionData?.topicObjectiveId || null
    },
    validate: zodResolver(formSchema),
    validateInputOnChange: true
  })

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      // eslint-disable-next-line no-unused-vars
      onSubmit={form.onSubmit(({ _parentTopicId, ...values }) => onSubmit(values))}
    >
      {useMemo(() => {
        const topic = find(examTypeData?.allTopics?.nodes, { rowId: form.values._parentTopicId })
        const subtopic = find(topic?.allSubtopics.nodes, { rowId: form.values.topicId })
        return (
          <>
            <SelectInput
              data={map(examTypeData?.allTopics?.nodes, ({ rowId, title }) => ({ label: title, value: rowId }))}
              label='Topic'
              required
              {...form.getInputProps('_parentTopicId')}
              onChange={(value) => {
                form.setValues((prev) => ({
                  ...prev,
                  _parentTopicId: value,
                  topicId: null
                }))
              }}
            />
            <SelectInput
              data={map(topic?.allSubtopics.nodes, ({ rowId, title }) => ({ label: title, value: rowId }))}
              label='Subtopic'
              required
              {...form.getInputProps('topicId')}
              onChange={(value) => {
                form.setValues((prev) => ({
                  ...prev,
                  topicId: value,
                  topicObjectiveId: null
                }))
              }}
            />
            <SelectInput
              data={map(subtopic?.allTopicObjectives.nodes, ({ rowId, title }) => ({ label: title, value: rowId }))}
              label='Objective'
              required
              {...form.getInputProps('topicObjectiveId')}
            />
          </>
        )
      }, [examTypeData?.allTopics?.nodes, form])}
      <MarkdownEditorInput
        data-autofocus
        label='Body'
        required
        {...form.getInputProps('body')}
      />
      <MarkdownEditorInput
        label='Explanation'
        required
        {...form.getInputProps('explanation')}
      />
      <CheckboxInput
        disabled={isSaving}
        label='Active'
        mt='md'
        mb='xs'
        {...form.getInputProps('isActive', { type: 'checkbox' })}
      />
      <CheckboxInput
        disabled={isSaving}
        label='Enabled for Lessons'
        mb='xs'
        {...form.getInputProps('isLesson', { type: 'checkbox' })}
      />
      <CheckboxInput
        disabled={isSaving}
        label='Enabled for Study Sessions'
        mb='xs'
        {...form.getInputProps('isStudySession', { type: 'checkbox' })}
      />
    </Form>
  )
}
