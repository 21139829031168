/**
 * @generated SignedSource<<48b0f06649264ecdcbd3487de7194a48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Question_userStudySessionQuestion$data = {
  readonly question: {
    readonly " $fragmentSpreads": FragmentRefs<"QuestionTitle_question">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionAnswers_userStudySessionQuestion">;
  readonly " $fragmentType": "Question_userStudySessionQuestion";
};
export type Question_userStudySessionQuestion$key = {
  readonly " $data"?: Question_userStudySessionQuestion$data;
  readonly " $fragmentSpreads": FragmentRefs<"Question_userStudySessionQuestion">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Question_userStudySessionQuestion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Question",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuestionTitle_question"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuestionAnswers_userStudySessionQuestion"
    }
  ],
  "type": "UserStudySessionQuestion",
  "abstractKey": null
};

(node as any).hash = "c5ae4f5baf15783025d19f7160e757ab";

export default node;
