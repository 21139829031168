import { Button, type ButtonProps, createStyles, rem } from '@mantine/core'
import { type FC } from 'react'

const useStyles = createStyles((theme, { color }: BorderButtonProps) => ({
  button: {
    color: theme.colors.spaceCadetPurple[4],
    border: `1px solid ${theme.colors.spaceCadetPurple[4]}`,
    boxShadow: `3px 3px ${theme.colors.spaceCadetPurple[4]}`,
    paddingLeft: 50,
    paddingRight: 50,
    backgroundColor: color || theme.colors.stickyNoteBlue[4],
    '&:hover': theme.fn.hover({
      backgroundColor: theme.fn.darken(color || theme.colors.stickyNoteBlue[4], 0.05)
    }),
    [theme.fn.smallerThan('sm')]: { paddingLeft: rem(10), paddingRight: rem(10), size: 'sm' }
  }
}))

export interface BorderButtonProps extends ButtonProps {
  color?: string
  onClick?: () => void
}

export const BorderButton: FC<BorderButtonProps> = ({ color, children, ...props }) => {
  const { classes } = useStyles({ color })

  return (
    <Button
      size='xl'
      className={classes.button}
      {...props}
    >
      {children}
    </Button>
  )
}
