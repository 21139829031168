import { type ExamTypeUserCreateModal_examType$key } from '@app/__generated__/ExamTypeUserCreateModal_examType.graphql'
import { type ExamTypeUserCreateModal_query$key } from '@app/__generated__/ExamTypeUserCreateModal_query.graphql'
import { type ExamTypeUserCreateModalCreateExamTypesUserMutation } from '@app/__generated__/ExamTypeUserCreateModalCreateExamTypesUserMutation.graphql'
import { ExamTypeUserForm, UnsavedChangesModal, type UnsavedChangesModalProps } from '@app/components'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const examTypeFragment = graphql`
  fragment ExamTypeUserCreateModal_examType on ExamType {
    rowId
    ...ExamTypeUserForm_examType
  }
`
const queryFragment = graphql`
  fragment ExamTypeUserCreateModal_query on Query {
    ...ExamTypeUserForm_query
  }
`
const createMutation = graphql`
  mutation ExamTypeUserCreateModalCreateExamTypesUserMutation($connections: [ID!]!, $input: CreateExamTypesUserInput!) {
    createExamTypesUser(input: $input) {
      examType {
        ...ExamTypeUserForm_examType
      }
      examTypesUserEdge @appendEdge(connections: $connections) {
        node {
          examDateAt
          user {
            name
            slug
          }
          id
          ...ExamTypesUserDeleteModal_examTypesUser
          ...ExamTypeUserForm_examTypesUser
          ...ExamTypeUserUpdateModal_examTypesUser
        }
      }
    }
  }
`

export interface ExamTypeUserCreateModalProps extends UnsavedChangesModalProps {
  connectionId: string
  examType: ExamTypeUserCreateModal_examType$key | null
  query: ExamTypeUserCreateModal_query$key | null
}

export const ExamTypeUserCreateModal: FC<ExamTypeUserCreateModalProps> = ({
  connectionId,
  examType,
  onClose,
  query,
  ...props
}) => {
  const examTypeData = useFragment(examTypeFragment, examType)
  const queryData = useFragment(queryFragment, query)
  const [createExamTypesUser, isCreatingExamTypesUser] =
    useMutation<ExamTypeUserCreateModalCreateExamTypesUserMutation>(createMutation)

  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      isLoading={isCreatingExamTypesUser}
      onClose={onClose}
      title='Create User Exam'
      {...props}
    >
      <ExamTypeUserForm
        examType={examTypeData}
        examTypesUser={null}
        isCreating
        isSaving={isCreatingExamTypesUser}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (examTypesUser) =>
            createExamTypesUser({
              onCompleted: onClose,
              variables: {
                connections: [connectionId],
                input: {
                  examTypesUser: {
                    ...examTypesUser,
                    examTypeId: examTypeData?.rowId
                  }
                }
              }
            }),
          [connectionId, examTypeData, onClose, createExamTypesUser]
        )}
        query={queryData}
      />
    </UnsavedChangesModal>
  )
}
