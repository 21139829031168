/**
 * @generated SignedSource<<1311befc214077635902c6343d404c60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ObjectiveLessonWizardModal_userLesson$data = {
  readonly examTypeId: any;
  readonly id: string;
  readonly subtopic: {
    readonly rowId: any;
    readonly topicObjectives: {
      readonly nodes: ReadonlyArray<{
        readonly rowId: any;
        readonly sequence: number;
      }>;
    };
    readonly totalObjectivesCount: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"GuidebookButton_topic">;
  } | null | undefined;
  readonly topic: {
    readonly guidebook: string | null | undefined;
    readonly rowId: any;
  } | null | undefined;
  readonly topicObjective: {
    readonly sequence: number;
    readonly title: string;
  } | null | undefined;
  readonly topicObjectiveId: any;
  readonly userId: any;
  readonly userLessonQuestions: {
    readonly nodes: ReadonlyArray<{
      readonly rowId: any;
      readonly " $fragmentSpreads": FragmentRefs<"UserLessonQuestion_userLessonQuestion">;
    }>;
    readonly totalCount: number;
  };
  readonly " $fragmentType": "ObjectiveLessonWizardModal_userLesson";
};
export type ObjectiveLessonWizardModal_userLesson$key = {
  readonly " $data"?: ObjectiveLessonWizardModal_userLesson$data;
  readonly " $fragmentSpreads": FragmentRefs<"ObjectiveLessonWizardModal_userLesson">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v1 = {
  "equalTo": true
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sequence",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ObjectiveLessonWizardModal_userLesson",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examTypeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "SEQUENCE_ASC"
          ]
        }
      ],
      "concreteType": "UserLessonQuestionsConnection",
      "kind": "LinkedField",
      "name": "userLessonQuestions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserLessonQuestion",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UserLessonQuestion_userLessonQuestion"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "userLessonQuestions(orderBy:[\"SEQUENCE_ASC\"])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "subtopic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "condition",
              "value": {
                "meetsLessonViabilityCriteria": true
              }
            },
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "isActive": (v1/*: any*/),
                "topic": {
                  "isActive": (v1/*: any*/),
                  "parentTopic": {
                    "isActive": (v1/*: any*/)
                  }
                }
              }
            }
          ],
          "concreteType": "TopicObjectivesConnection",
          "kind": "LinkedField",
          "name": "topicObjectives",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TopicObjective",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "topicObjectives(condition:{\"meetsLessonViabilityCriteria\":true},filter:{\"isActive\":{\"equalTo\":true},\"topic\":{\"isActive\":{\"equalTo\":true},\"parentTopic\":{\"isActive\":{\"equalTo\":true}}}})"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalObjectivesCount",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GuidebookButton_topic"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "guidebook",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TopicObjective",
      "kind": "LinkedField",
      "name": "topicObjective",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topicObjectiveId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    }
  ],
  "type": "UserLesson",
  "abstractKey": null
};
})();

(node as any).hash = "ba39ea80db410423d970fec9c1128845";

export default node;
