import { Center, createStyles } from '@mantine/core'
import { IconGripVertical } from '@tabler/icons-react'
import { flexRender, Row } from '@tanstack/react-table'
import { map } from 'lodash'
import { useDrag, useDrop } from 'react-dnd'

const useStyles = createStyles((theme, { isDragging }: { isDragging: boolean }) => ({
  dragCell: {
    width: 35
  },
  dragHandle: {
    cursor: isDragging ? 'grabbing' : 'grab'
  },
  draggableRow: {
    opacity: isDragging ? 0.5 : 1
  }
}))

interface DraggableRowProps<TEdge> {
  row: Row<TEdge>
  onReorder: (sourceIndex: number, destinationIndex: number) => void
}

export const DraggableRow = <TEdge,>({ row, onReorder }: DraggableRowProps<TEdge>) => {
  const [, dropRef] = useDrop({
    accept: 'row',
    drop: (draggedRow: Row<TEdge>) => onReorder(draggedRow.index, row.index)
  })

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    item: () => row,
    type: 'row'
  })
  const { classes, theme } = useStyles({ isDragging })

  return (
    <tr
      className={classes.draggableRow}
      ref={previewRef}
    >
      {map(row.getVisibleCells(), (cell) => (
        <td
          key={cell.id}
          style={{
            width: cell.column.getSize()
          }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
      <td
        className={classes.dragCell}
        ref={dropRef}
      >
        <Center
          className={classes.dragHandle}
          component='div'
          ref={dragRef}
        >
          <IconGripVertical
            color={theme.colors.stickyNoteBlue[4]}
            size={14}
          />
        </Center>
      </td>
    </tr>
  )
}
