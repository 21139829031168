import { MarkdownPreview, markdownPreviewProps } from '@app/components'
import { Box, type BoxProps, useMantineTheme } from '@mantine/core'
import { type MarkdownPreviewProps } from '@uiw/react-markdown-preview'
import { type FC } from 'react'

export interface MarkdownBoxProps extends Omit<BoxProps, 'children'> {
  children?: string | null
  markdownProps?: Omit<MarkdownPreviewProps, 'source'>
}

export const MarkdownBox: FC<MarkdownBoxProps> = ({ children, markdownProps, ...props }) => {
  const theme = useMantineTheme()

  return (
    <Box {...props}>
      {children && (
        <MarkdownPreview
          source={children}
          {...markdownPreviewProps}
          {...markdownProps}
          style={{
            backgroundColor: 'transparent',
            fontSize: theme.fontSizes.sm,
            ...markdownProps?.style
          }}
        />
      )}
    </Box>
  )
}
