/**
 * @generated SignedSource<<6711b70f4bbd08274b8d9bf8dd049d7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySessionCompletePositive_userStudySession$data = {
  readonly averageTimePerCorrectQuestion: {
    readonly minutes: number | null | undefined;
    readonly seconds: number | null | undefined;
  } | null | undefined;
  readonly completedAt: any | null | undefined;
  readonly correctQuestionsCount: number;
  readonly startedAt: any | null | undefined;
  readonly totalQuestionsCount: number;
  readonly " $fragmentType": "StudySessionCompletePositive_userStudySession";
};
export type StudySessionCompletePositive_userStudySession$key = {
  readonly " $data"?: StudySessionCompletePositive_userStudySession$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySessionCompletePositive_userStudySession">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudySessionCompletePositive_userStudySession",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Interval",
      "kind": "LinkedField",
      "name": "averageTimePerCorrectQuestion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seconds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minutes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "correctQuestionsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalQuestionsCount",
      "storageKey": null
    }
  ],
  "type": "UserStudySession",
  "abstractKey": null
};

(node as any).hash = "5bb63f1d2c4e8442b5886336c10248b5";

export default node;
