import 'katex/dist/katex.css'

import katex from 'katex'
import { type FC } from 'react'

export interface KatexCodeProps {
  children: string
  className?: string
}

export const KatexCode: FC<KatexCodeProps> = ({ children }) => (
  <code
    dangerouslySetInnerHTML={{
      __html: katex.renderToString(children, {
        throwOnError: false
      })
    }}
  />
)
