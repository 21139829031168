/**
 * @generated SignedSource<<29cf79c6b95db226483a23349d723d5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicsPageHeader_topic$data = {
  readonly completedObjectivesCount: number | null | undefined;
  readonly guidebook: string | null | undefined;
  readonly parentTopicId: any | null | undefined;
  readonly rowId: any;
  readonly summary: string | null | undefined;
  readonly title: string;
  readonly totalObjectivesCount: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"GuidebookButton_topic">;
  readonly " $fragmentType": "TopicsPageHeader_topic";
};
export type TopicsPageHeader_topic$key = {
  readonly " $data"?: TopicsPageHeader_topic$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicsPageHeader_topic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicsPageHeader_topic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedObjectivesCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentTopicId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalObjectivesCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "guidebook",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GuidebookButton_topic"
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "a39b2e317e24b0c83605cc45425986ab";

export default node;
