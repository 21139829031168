import { type UpdateStudentForm_user$key } from '@app/__generated__/UpdateStudentForm_user.graphql'
import { Form, StudentPasswordInput, StudentTextInput } from '@app/components'
import { useForm, zodResolver } from '@mantine/form'
import { type FC, useCallback } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const formSchema = z
  .object({
    image: z.string().trim().url().or(z.literal('')),
    name: z
      .string({
        required_error: 'Enter a name'
      })
      .trim()
      .max(128, 'Must be 128 characters or less')
      .min(2, 'Must be at least 2 characters'),
    password: z.string().optional(),
    passwordConfirm: z.string().optional()
  })
  .refine(({ password, passwordConfirm }) => password === passwordConfirm, {
    path: ['passwordConfirm'],
    message: 'Both passwords must match'
  })

const userFragment = graphql`
  fragment UpdateStudentForm_user on User {
    image
    name
  }
`

export interface UpdateStudentData {
  image: string | null
  name: string
  password: string | undefined
}

export type UpdateStudentProps = {
  isSaving?: boolean
  onSubmit: (data: UpdateStudentData) => void
  user: UpdateStudentForm_user$key | null
}

export const UpdateStudentForm: FC<UpdateStudentProps> = ({ isSaving, onSubmit, user }) => {
  const userData = useFragment<UpdateStudentForm_user$key>(userFragment, user)
  const form = useForm({
    initialValues: {
      image: userData?.image || '',
      name: userData?.name || '',
      password: '',
      passwordConfirm: ''
    },
    validate: zodResolver(formSchema),
    validateInputOnChange: true
  })

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit(
        useCallback(
          // eslint-disable-next-line no-unused-vars
          ({ password, passwordConfirm, image, ...values }) =>
            onSubmit({
              ...values,
              image: image || null,
              password: password || undefined
            }),
          [onSubmit]
        )
      )}
    >
      <StudentTextInput
        data-autofocus
        mb='lg'
        withAsterisk
        disabled={isSaving}
        label='Name'
        required
        {...form.getInputProps('name')}
      />
      <StudentTextInput
        mb='lg'
        withAsterisk
        disabled={isSaving}
        label='Image'
        placeholder='http://'
        type='url'
        {...form.getInputProps('image')}
      />
      <StudentPasswordInput
        disabled={isSaving}
        label='New password'
        icon={null}
        {...form.getInputProps('password')}
      />
      <StudentPasswordInput
        disabled={isSaving}
        label='Confirm password'
        icon={null}
        {...form.getInputProps('passwordConfirm')}
      />
    </Form>
  )
}
