/**
 * @generated SignedSource<<dbffcf18ba287f993ef84afe42b0325e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateStudentModal_user$data = {
  readonly email: string;
  readonly id: string;
  readonly image: string | null | undefined;
  readonly name: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateStudentForm_user">;
  readonly " $fragmentType": "UpdateStudentModal_user";
};
export type UpdateStudentModal_user$key = {
  readonly " $data"?: UpdateStudentModal_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateStudentModal_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateStudentModal_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateStudentForm_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "85eefc58d2c5c2a39dd8a60e2b472d60";

export default node;
