import { Button, Container, createStyles, Group, Text, Title } from '@mantine/core'
import { type FC } from 'react'

const useStyles = createStyles((theme) => ({
  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],

    [theme.fn.smallerThan('sm')]: {
      fontSize: 120
    }
  },

  title: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32
    }
  },

  description: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl
  }
}))

export interface ErrorPageProps {
  buttonText?: string
  description: string
  onButtonClick: () => void
  statusCode?: number
  title: string
}

export const ErrorPage: FC<ErrorPageProps> = ({ buttonText, description, onButtonClick, statusCode, title }) => {
  const { classes } = useStyles()

  return (
    <Container fluid>
      {statusCode ? <div className={classes.label}>{statusCode}</div> : null}
      <Title className={classes.title}>{title}</Title>
      <Text
        color='dimmed'
        size='lg'
        align='center'
        className={classes.description}
      >
        {description}
      </Text>
      <Group position='center'>
        <Button
          onClick={onButtonClick}
          size='md'
          variant='subtle'
        >
          {buttonText}
        </Button>
      </Group>
    </Container>
  )
}
