/**
 * @generated SignedSource<<5bf55755111c61ab8efb0bf465a31f16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicInfoBox_topic$data = {
  readonly completedObjectivesCount: number | null | undefined;
  readonly guidebook: string | null | undefined;
  readonly id: string;
  readonly image: string | null | undefined;
  readonly parentTopicId: any | null | undefined;
  readonly rowId: any;
  readonly slug: string | null | undefined;
  readonly summary: string | null | undefined;
  readonly title: string;
  readonly totalObjectivesCount: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"GuidebookButton_topic">;
  readonly " $fragmentType": "TopicInfoBox_topic";
};
export type TopicInfoBox_topic$key = {
  readonly " $data"?: TopicInfoBox_topic$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicInfoBox_topic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicInfoBox_topic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedObjectivesCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "guidebook",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentTopicId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalObjectivesCount",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GuidebookButton_topic"
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "f5a529b9529ec75aae1a2bbb0d1b7fb6";

export default node;
