import { Select, type SelectProps } from '@mantine/core'
import { IconSelect } from '@tabler/icons-react'
import { type FC } from 'react'

export interface SelectInputProps extends SelectProps {}

export const SelectInput: FC<SelectInputProps> = (props) => (
  <Select
    mb='xs'
    icon={<IconSelect size={18} />}
    {...props}
  />
)
