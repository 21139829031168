import { type AdminLayout_query$key } from '@app/__generated__/AdminLayout_query.graphql'
import {
  AdminHeader,
  type AdminHeaderBreadcrumb,
  AdminNavbarLink,
  BareLayout,
  type BareLayoutProps,
  Menu
} from '@app/components'
import { initials } from '@app/lib'
import { Avatar, createStyles, Navbar, Text } from '@mantine/core'
import { useModals } from '@mantine/modals'
import { IconCertificate, IconLogout, IconUser, IconUsers, IconWorld } from '@tabler/icons-react'
import { join, map } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { signOut } from 'next-auth/react'
import { type FC, type ReactNode, useCallback, useMemo, useState } from 'react'
import { graphql, useFragment } from 'react-relay'

const queryFragment = graphql`
  fragment AdminLayout_query on Query {
    currentUser {
      image
      name
      rowId
      slug
    }
  }
`
const useStyles = createStyles((theme) => ({
  avatar: { cursor: 'pointer' },
  main: {
    backgroundColor: theme.white
  }
}))

export interface AdminLayoutProps extends BareLayoutProps {
  baseUrl?: string
  breadcrumbs?: AdminHeaderBreadcrumb[]
  links?: ReactNode
  query: AdminLayout_query$key | null
}

export const AdminLayout: FC<AdminLayoutProps> = ({
  breadcrumbs,
  children,
  classNames,
  padding = 'xs',
  query,
  links,
  navbar,
  title: passedTitle,
  ...props
}) => {
  const modals = useModals()
  const router = useRouter()
  const { classes } = useStyles()
  const queryData = useFragment(queryFragment, query)

  const [isSigningOut, setIsSigningOut] = useState(false)

  const onSignOutClick = useCallback(() => {
    modals.openConfirmModal({
      centered: true,
      size: 'xs',
      title: 'Confirm Sign Out',
      children: (
        <Text
          size='sm'
          pb='xs'
        >
          Are you sure you want to sign out?
        </Text>
      ),
      labels: {
        confirm: 'Sign Out',
        cancel: 'Cancel'
      },
      cancelProps: {
        variant: 'subtle'
      },
      confirmProps: {
        color: 'red',
        leftIcon: <IconLogout size={16} />,
        loading: isSigningOut
      },
      onConfirm: async () => {
        setIsSigningOut(true)
        await signOut({ redirect: false })
        await router.push('/')
      }
    })
  }, [modals, isSigningOut, router])
  const title = useMemo(() => passedTitle || join(map(breadcrumbs, 'label'), ' / '), [breadcrumbs, passedTitle])

  return (
    <BareLayout
      classNames={{
        main: classes.main,
        ...classNames
      }}
      header={
        <AdminHeader
          breadcrumbs={breadcrumbs}
          links={links}
          rightSection={
            <Menu offset={0}>
              <Menu.Target>
                <Avatar
                  className={classes.avatar}
                  color='stickyNoteBlue'
                  src={queryData?.currentUser?.image}
                  radius='xl'
                  title={queryData?.currentUser?.name || undefined}
                  variant='filled'
                >
                  {queryData?.currentUser?.name && initials(queryData.currentUser.name)}
                </Avatar>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  component={Link}
                  href={`/admin/users/${queryData?.currentUser?.slug}`}
                  icon={<IconUser size={14} />}
                >
                  My Profile
                </Menu.Item>
                <Menu.Item
                  component={Link}
                  href='/dashboard'
                  icon={<IconWorld size={14} />}
                >
                  Public Site
                </Menu.Item>
                <Menu.Item
                  color='red'
                  icon={<IconLogout size={14} />}
                  onClick={onSignOutClick}
                >
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          }
        />
      }
      navbar={
        <Navbar width={{ base: 180 }}>
          {navbar || (
            <Navbar.Section>
              <AdminNavbarLink
                href='/admin/examTypes'
                IconComponent={IconCertificate}
                label='Exam Types'
              />
              <AdminNavbarLink
                href='/admin/users'
                IconComponent={IconUsers}
                label='Users'
              />
            </Navbar.Section>
          )}
        </Navbar>
      }
      padding={padding}
      title={`Admin${title ? ` / ${title}` : ''}`}
      {...props}
    >
      {children}
    </BareLayout>
  )
}
