/**
 * @generated SignedSource<<9bedda50850230624e8cae2006c522b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminSubtopicLayout_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AdminLayout_query">;
  readonly " $fragmentType": "AdminSubtopicLayout_query";
};
export type AdminSubtopicLayout_query$key = {
  readonly " $data"?: AdminSubtopicLayout_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminSubtopicLayout_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminSubtopicLayout_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminLayout_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8400c2a44ff4249916e42c3472f738c1";

export default node;
