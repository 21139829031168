/**
 * @generated SignedSource<<a88bd742dd616241bc733087bfbe32ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamTypeUserStudySessionsList_examType$data = {
  readonly id: string;
  readonly userStudySessions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly completedAt: any | null | undefined;
        readonly correctQuestionsCount: number;
        readonly id: string;
        readonly startedAt: any | null | undefined;
        readonly totalQuestionsCount: number;
        readonly userStudySessionQuestions: {
          readonly nodes: ReadonlyArray<{
            readonly completedAt: any | null | undefined;
            readonly id: string;
            readonly question: {
              readonly " $fragmentSpreads": FragmentRefs<"QuestionTitle_question">;
            } | null | undefined;
            readonly startedAt: any | null | undefined;
            readonly " $fragmentSpreads": FragmentRefs<"QuestionAnswers_userStudySessionQuestion">;
          }>;
        };
      };
    }>;
  };
  readonly " $fragmentType": "ExamTypeUserStudySessionsList_examType";
};
export type ExamTypeUserStudySessionsList_examType$key = {
  readonly " $data"?: ExamTypeUserStudySessionsList_examType$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamTypeUserStudySessionsList_examType">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "userStudySessions"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": [
        "COMPLETED_AT_DESC"
      ],
      "kind": "LocalArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ExamTypeUserStudySessionsListQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "ExamTypeUserStudySessionsList_examType",
  "selections": [
    {
      "alias": "userStudySessions",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "completedAt": {
              "isNull": false
            }
          }
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        }
      ],
      "concreteType": "UserStudySessionsConnection",
      "kind": "LinkedField",
      "name": "__ExamTypeUserStudySessionsList_examType_userStudySessions_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserStudySessionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserStudySession",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalQuestionsCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "correctQuestionsCount",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "orderBy",
                      "value": [
                        "SEQUENCE_ASC"
                      ]
                    }
                  ],
                  "concreteType": "UserStudySessionQuestionsConnection",
                  "kind": "LinkedField",
                  "name": "userStudySessionQuestions",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserStudySessionQuestion",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Question",
                          "kind": "LinkedField",
                          "name": "question",
                          "plural": false,
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "QuestionTitle_question"
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "QuestionAnswers_userStudySessionQuestion"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "userStudySessionQuestions(orderBy:[\"SEQUENCE_ASC\"])"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "ExamType",
  "abstractKey": null
};
})();

(node as any).hash = "a8238e74f37065d86634ede550aa5b73";

export default node;
