import { type AdminSubtopicLayout_query$key } from '@app/__generated__/AdminSubtopicLayout_query.graphql'
import { type AdminSubtopicLayout_topic$key } from '@app/__generated__/AdminSubtopicLayout_topic.graphql'
import { AdminNavbarLink } from '@app/components'
import { Navbar } from '@mantine/core'
import { IconBinaryTree, IconHelpCircle, IconMedal } from '@tabler/icons-react'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import { AdminLayout, AdminLayoutProps } from './AdminLayout'

const topicFragment = graphql`
  fragment AdminSubtopicLayout_topic on Topic {
    parentTopic {
      examType {
        slug
      }
      slug
    }
    slug
  }
`

const queryFragment = graphql`
  fragment AdminSubtopicLayout_query on Query {
    ...AdminLayout_query
  }
`

export type AdminSubtopicLayoutProps = Omit<AdminLayoutProps, 'query'> & {
  topic?: AdminSubtopicLayout_topic$key | null
  query: AdminSubtopicLayout_query$key | null
}

export const AdminSubtopicLayout: FC<AdminSubtopicLayoutProps> = ({ children, topic, query, ...props }) => {
  const topicData = useFragment<AdminSubtopicLayout_topic$key>(topicFragment, topic || null)
  const queryData = useFragment<AdminSubtopicLayout_query$key>(queryFragment, query)

  return (
    <AdminLayout
      navbar={
        <Navbar.Section>
          <AdminNavbarLink
            href={`/admin/examTypes/${topicData?.parentTopic?.examType?.slug}/topics/${topicData?.parentTopic?.slug}/subtopics/${topicData?.slug}`}
            IconComponent={IconBinaryTree}
            label='Subtopic'
          />
          <AdminNavbarLink
            href={`/admin/examTypes/${topicData?.parentTopic?.examType?.slug}/topics/${topicData?.parentTopic?.slug}/subtopics/${topicData?.slug}/objectives`}
            IconComponent={IconMedal}
            label='Objectives'
          />
          <AdminNavbarLink
            href={`/admin/examTypes/${topicData?.parentTopic?.examType?.slug}/topics/${topicData?.parentTopic?.slug}/subtopics/${topicData?.slug}/questions`}
            IconComponent={IconHelpCircle}
            label='Questions'
          />
        </Navbar.Section>
      }
      query={queryData}
      {...props}
    >
      {children}
    </AdminLayout>
  )
}
