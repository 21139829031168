import { type AdminTopicLayout_query$key } from '@app/__generated__/AdminTopicLayout_query.graphql'
import { type AdminTopicLayout_topic$key } from '@app/__generated__/AdminTopicLayout_topic.graphql'
import { AdminNavbarLink } from '@app/components'
import { Navbar } from '@mantine/core'
import { IconBinaryTree, IconBinaryTree2 } from '@tabler/icons-react'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import { AdminLayout, AdminLayoutProps } from './AdminLayout'

const topicFragment = graphql`
  fragment AdminTopicLayout_topic on Topic {
    examType {
      slug
    }
    slug
  }
`

const queryFragment = graphql`
  fragment AdminTopicLayout_query on Query {
    ...AdminLayout_query
  }
`

export interface AdminTopicLayoutProps extends Omit<AdminLayoutProps, 'query'> {
  topic: AdminTopicLayout_topic$key | null
  query: AdminTopicLayout_query$key | null
}

export const AdminTopicLayout: FC<AdminTopicLayoutProps> = ({ children, topic, query, ...props }) => {
  const topicData = useFragment<AdminTopicLayout_topic$key>(topicFragment, topic)
  const queryData = useFragment<AdminTopicLayout_query$key>(queryFragment, query)

  return (
    <AdminLayout
      navbar={
        <Navbar.Section>
          <AdminNavbarLink
            href={`/admin/examTypes/${topicData?.examType?.slug}/topics/${topicData?.slug}`}
            IconComponent={IconBinaryTree2}
            label='Topic'
          />
          <AdminNavbarLink
            href={`/admin/examTypes/${topicData?.examType?.slug}/topics/${topicData?.slug}/subtopics`}
            IconComponent={IconBinaryTree}
            label='Subtopics'
          />
        </Navbar.Section>
      }
      query={queryData}
      {...props}
    >
      {children}
    </AdminLayout>
  )
}
