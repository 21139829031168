/**
 * @generated SignedSource<<a6d07e507cc3893029d99f4dd1fe1b4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamTypeUsersTable_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExamTypeUserCreateModal_query" | "ExamTypeUserUpdateModal_query">;
  readonly " $fragmentType": "ExamTypeUsersTable_query";
};
export type ExamTypeUsersTable_query$key = {
  readonly " $data"?: ExamTypeUsersTable_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamTypeUsersTable_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamTypeUsersTable_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamTypeUserCreateModal_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExamTypeUserUpdateModal_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "851465e19ff710097fabdf5d9fbf197c";

export default node;
