import { type QuestionTitle_question$key } from '@app/__generated__/QuestionTitle_question.graphql'
import { MarkdownBox } from '@app/components'
import { Text } from '@mantine/core'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const questionTitleFragment = graphql`
  fragment QuestionTitle_question on Question {
    body
  }
`
export interface QuestionTitleProps {
  number?: number
  questionsCount?: number
  question: QuestionTitle_question$key | null
}

export const QuestionTitle: FC<QuestionTitleProps> = ({ number, questionsCount = 12, question }) => {
  const questionData = useFragment(questionTitleFragment, question)

  return (
    <>
      {number ? (
        <Text
          size={16}
          mb='md'
        >
          Question {number}/{questionsCount}
        </Text>
      ) : null}
      <MarkdownBox mb='lg'>{questionData?.body}</MarkdownBox>
    </>
  )
}
