import { Anchor, Container, Group, Header, HeaderProps, useMantineTheme } from '@mantine/core'
import Image from 'next/image'
import Link from 'next/link'
import { type FC, type ReactNode } from 'react'

import logoImg from './logo.png'

export interface AppHeaderBaseProps extends Omit<HeaderProps, 'children' | 'height'> {
  children?: ReactNode
  height?: number
}

export const AppHeaderBase: FC<AppHeaderBaseProps> = ({ children, height = 63, ...props }) => {
  const theme = useMantineTheme()

  return (
    <Header
      height={height}
      sx={{
        borderColor: theme.colors.spaceCadetPurple[4]
      }}
      {...props}
    >
      <Container size='xl'>
        <Group
          h={height}
          position='apart'
          align='center'
        >
          <Anchor
            component={Link}
            href='/'
            title='Home'
          >
            <Image
              alt='Studious'
              width={156}
              height={31}
              src={logoImg}
              priority
            />
          </Anchor>
          {children}
        </Group>
      </Container>
    </Header>
  )
}
