/**
 * @generated SignedSource<<7b7004af2a16f472d9148f80d36dee30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GuidebookButton_topic$data = {
  readonly guidebook: string | null | undefined;
  readonly parentTopic: {
    readonly title: string;
  } | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "GuidebookButton_topic";
};
export type GuidebookButton_topic$key = {
  readonly " $data"?: GuidebookButton_topic$data;
  readonly " $fragmentSpreads": FragmentRefs<"GuidebookButton_topic">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GuidebookButton_topic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "guidebook",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "parentTopic",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Topic",
  "abstractKey": null
};
})();

(node as any).hash = "b7e8828a8ee794c8c4fce8d5bd1e8a49";

export default node;
