import { Avatar, AvatarProps, useMantineTheme } from '@mantine/core'
import { type FC } from 'react'

interface TopicIconProps extends AvatarProps {}

export const TopicIcon: FC<TopicIconProps> = ({ ...props }) => {
  const theme = useMantineTheme()

  return (
    <Avatar
      variant='outline'
      radius='xl'
      size='md'
      m={4}
      styles={{
        root: {
          border: `2px solid`
        },
        image: {
          objectFit: 'contain',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: 20
        },
        placeholder: {
          fontSize: theme.fontSizes.sm,
          color: theme.colors.dark[4]
        }
      }}
      {...props}
    />
  )
}
