import {
  type SubtopicsTable_topic$data,
  type SubtopicsTable_topic$key
} from '@app/__generated__/SubtopicsTable_topic.graphql'
import { type SubtopicsTablePaginationQuery } from '@app/__generated__/SubtopicsTablePaginationQuery.graphql'
import { type SubtopicsTableReorderSubopicMutation } from '@app/__generated__/SubtopicsTableReorderSubopicMutation.graphql'
import { ActionColumnIcon, IconBoolean, PaginationTable } from '@app/components'
import { type TopicFilter, type TopicsOrderBy } from '@app/lib'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, useMutation, usePaginationFragment } from 'react-relay'
import { type Get, type IterableElement } from 'type-fest'

type TopicEdge = IterableElement<Get<Get<SubtopicsTable_topic$data, 'subtopics'>, 'edges'>>

const reorderMutation = graphql`
  mutation SubtopicsTableReorderSubopicMutation($input: UpdateTopicByIdInput!) {
    updateTopicById(input: $input) {
      topic {
        sequence
      }
      parentTopic {
        ...SubtopicsTable_topic
      }
    }
  }
`

// noinspection GraphQLUnresolvedReference
const topicFragment = graphql`
  fragment SubtopicsTable_topic on Topic
  @argumentDefinitions(
    filter: { type: "TopicFilter", defaultValue: null }
    first: { type: "Int", defaultValue: 100 }
    after: { type: "Cursor" }
    orderBy: { type: "[TopicsOrderBy!]", defaultValue: [SEQUENCE_ASC] }
  )
  @refetchable(queryName: "SubtopicsTablePaginationQuery") {
    examType {
      slug
    }
    slug
    subtopics: topicsByParentTopicId(filter: $filter, first: $first, after: $after, orderBy: $orderBy)
      @connection(key: "SubtopicsTable_topic_subtopics", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          id
          isActive
          sequence
          slug
          title
        }
      }
      totalCount
    }
    title
  }
`

export interface SubtopicsTableProps {
  topic: SubtopicsTable_topic$key | null
}

export const SubtopicsTable: FC<SubtopicsTableProps> = ({ topic }) => {
  const {
    data: topicData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<SubtopicsTablePaginationQuery, SubtopicsTable_topic$key>(topicFragment, topic)
  const [reorderTopic, isReorderingTopic] = useMutation<SubtopicsTableReorderSubopicMutation>(reorderMutation)

  return (
    <PaginationTable<TopicEdge, TopicsOrderBy, SubtopicsTablePaginationQuery, TopicFilter>
      columns={useMemo<ColumnDef<TopicEdge>[]>(
        () => [
          {
            id: 'actions',
            size: 50,
            enableMultiSort: false,
            enableSorting: false,
            cell: (props) => {
              const node = props.row.original.node

              return (
                <ActionColumnIcon
                  href={`/admin/examTypes/${topicData?.examType?.slug}/topics/${topicData?.slug}/subtopics/${node.slug}`}
                />
              )
            }
          },
          {
            accessorKey: 'node.isActive',
            header: 'Active',
            size: 50,
            enableMultiSort: false,
            enableSorting: false,
            cell: (props) => {
              const node = props.row.original.node

              return (
                <IconBoolean
                  size={18}
                  value={node.isActive}
                />
              )
            }
          },
          {
            accessorKey: 'node.title',
            header: 'Title',
            enableMultiSort: false,
            enableSorting: false
          }
        ],
        [topicData]
      )}
      data={topicData?.subtopics?.edges || []}
      filterPlaceholder='Search Subtopics'
      getFilterFromSearch={useCallback(
        (search: string) =>
          !isEmpty(search)
            ? {
                or: [
                  {
                    title: {
                      includesInsensitive: search
                    }
                  },
                  {
                    summary: {
                      includesInsensitive: search
                    }
                  }
                ]
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={[
        {
          id: 'sequence',
          desc: false
        }
      ]}
      isFilterable
      isLoadingNext={isLoadingNext}
      isReorderable
      loadNext={loadNext}
      onReorder={useCallback<(sourceIndex: number, destinationIndex: number) => void>(
        (sourceIndex, destinationIndex) => {
          if (!isReorderingTopic) {
            const subtopic = topicData?.subtopics?.edges?.[sourceIndex]?.node
            const nextSequence = destinationIndex + 1

            if (subtopic && subtopic.sequence !== nextSequence) {
              reorderTopic({
                variables: {
                  input: {
                    id: subtopic.id,
                    patch: {
                      sequence: nextSequence
                    }
                  }
                }
              })
            }
          }
        },
        [topicData?.subtopics?.edges, isReorderingTopic, reorderTopic]
      )}
      refetch={refetch}
      totalCount={topicData?.subtopics?.totalCount || 0}
    />
  )
}
