import { NavLink, type NavLinkProps } from '@mantine/core'
import { type Icon } from '@tabler/icons-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { type FC } from 'react'

export interface NavbarLinkProps extends Omit<NavLinkProps, 'active' | 'icon' | 'variant'> {
  href?: string
  IconComponent: Icon
}

export const AdminNavbarLink: FC<NavbarLinkProps> = ({ href, IconComponent, ...props }) => {
  const { asPath } = useRouter()

  return (
    <NavLink
      active={asPath === href}
      component={Link}
      href={href || '#'}
      icon={<IconComponent size={18} />}
      variant='filled'
      {...props}
    />
  )
}
