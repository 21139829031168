import { type Question_userStudySessionQuestion$key } from '@app/__generated__/Question_userStudySessionQuestion.graphql'
import { QuestionAnswers, QuestionTitle } from '@app/components'
import { Box } from '@mantine/core'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const questionFragment = graphql`
  fragment Question_userStudySessionQuestion on UserStudySessionQuestion {
    question {
      ...QuestionTitle_question
    }
    ...QuestionAnswers_userStudySessionQuestion
  }
`

export interface QuestionProps {
  userStudySessionQuestion: Question_userStudySessionQuestion$key
  number?: number
  questionsCount?: number
  showResult?: boolean
  disabled?: boolean
  selectedAnswerId?: string
  onSelectAnswer?: (answer: any) => void
}

export const Question: FC<QuestionProps> = ({
  userStudySessionQuestion,
  number,
  questionsCount = 12,
  showResult = false,
  selectedAnswerId,
  disabled = false,
  onSelectAnswer
}) => {
  const userStudySessionQuestionData = useFragment(questionFragment, userStudySessionQuestion)

  return (
    <Box>
      {userStudySessionQuestionData && (
        <>
          <QuestionTitle
            number={number}
            questionsCount={questionsCount}
            question={userStudySessionQuestionData.question}
          />
          <QuestionAnswers
            showResult={showResult}
            disabled={disabled}
            onSelectAnswer={onSelectAnswer}
            selectedAnswerId={selectedAnswerId}
            userStudySessionQuestion={userStudySessionQuestionData}
          />
        </>
      )}
    </Box>
  )
}
