import { type AdminUserLayout_query$key } from '@app/__generated__/AdminUserLayout_query.graphql'
import { type AdminUserLayout_user$key } from '@app/__generated__/AdminUserLayout_user.graphql'
import { AdminNavbarLink } from '@app/components'
import { Navbar } from '@mantine/core'
import { IconCertificate, IconSchool, IconUser, IconVocabulary } from '@tabler/icons-react'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import { AdminLayout, AdminLayoutProps } from './AdminLayout'

const userFragment = graphql`
  fragment AdminUserLayout_user on User {
    slug
  }
`

const queryFragment = graphql`
  fragment AdminUserLayout_query on Query {
    ...AdminLayout_query
  }
`

export type UserLayoutProps = Omit<AdminLayoutProps, 'query'> & {
  user: AdminUserLayout_user$key | null
  query: AdminUserLayout_query$key | null
}

export const AdminUserLayout: FC<UserLayoutProps> = ({ children, user, query, ...props }) => {
  const userData = useFragment<AdminUserLayout_user$key>(userFragment, user)
  const queryData = useFragment<AdminUserLayout_query$key>(queryFragment, query)

  return (
    <AdminLayout
      navbar={
        <Navbar.Section>
          <AdminNavbarLink
            href={`/admin/users/${userData?.slug}`}
            IconComponent={IconUser}
            label='User'
          />
          <AdminNavbarLink
            href={`/admin/users/${userData?.slug}/exams`}
            IconComponent={IconCertificate}
            label='Exams'
          />
          <AdminNavbarLink
            href={`/admin/users/${userData?.slug}/studySessions`}
            IconComponent={IconSchool}
            label='Study Sessions'
          />
          <AdminNavbarLink
            href={`/admin/users/${userData?.slug}/lessons`}
            IconComponent={IconVocabulary}
            label='Lessons'
          />
        </Navbar.Section>
      }
      query={queryData}
      {...props}
    >
      {children}
    </AdminLayout>
  )
}
