import { type UserLessonQuestion_userLessonQuestion$key } from '@app/__generated__/UserLessonQuestion_userLessonQuestion.graphql'
import { BorderButton, MarkdownBox, QuestionTitle, UserLessonQuestionAnswers } from '@app/components'
import { type UpdateUserLessonQuestionInput } from '@app/lib'
import { Box, createStyles, Group } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { includes } from 'lodash'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment } from 'react-relay'

const userLessonQuestionFragment = graphql`
  fragment UserLessonQuestion_userLessonQuestion on UserLessonQuestion {
    attempts
    rowId
    sequence
    startedAt
    question {
      correctAnswer: questionAnswers(condition: { isActive: true, isCorrect: true }) {
        nodes {
          rowId
        }
      }
      explanation
      ...QuestionTitle_question
    }
    ...UserLessonQuestionAnswers_userLessonQuestion
  }
`

export interface UserLessonQuestionProps {
  defaultSelectedAnswerId?: string
  onNext: () => void
  onSubmit: (answerData: UpdateUserLessonQuestionInput) => void
  questionsCount?: number
  setIsSessionComplete: (isSessionComplete: boolean) => void
  userLessonQuestion: UserLessonQuestion_userLessonQuestion$key
}

const useStyles = createStyles((theme) => ({
  borderBox: {
    position: 'relative',
    [theme.fn.smallerThan('sm')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  },
  explanationBoxIncorrect: {
    border: `3px dashed ${theme.colors.softRed[4]}`,
    borderRadius: 5
  },
  explanationBoxCorrect: {
    border: `3px dashed ${theme.colors.jelloGreen[4]}`,
    borderRadius: 5
  },
  groupContainer: {
    position: 'relative',
    flexWrap: 'nowrap',
    [theme.fn.smallerThan('sm')]: {
      flexWrap: 'wrap'
    }
  }
}))

export const UserLessonQuestion: FC<UserLessonQuestionProps> = ({
  defaultSelectedAnswerId,
  onNext,
  onSubmit,
  questionsCount,
  setIsSessionComplete,
  userLessonQuestion
}) => {
  const { classes, theme } = useStyles()
  const [selectedAnswerId, setSelectedAnswerId] = useState<string | undefined>(defaultSelectedAnswerId)
  const [previouslySubmittedAnswerIds, setPreviouslySubmittedAnswerIds] = useState<string[]>([])
  const userLessonQuestionData = useFragment(userLessonQuestionFragment, userLessonQuestion)
  const questionStartTime = new Date() // timer starts when question is rendered
  const questionAnsweredCorrectly =
    userLessonQuestionData?.question?.correctAnswer?.nodes[0]?.rowId === previouslySubmittedAnswerIds[0]

  return (
    <>
      <Group
        align='flex-start'
        mb='xl'
        className={classes.groupContainer}
      >
        <Group
          position='left'
          mb='xl'
        >
          <Box>
            <QuestionTitle
              number={userLessonQuestionData.sequence}
              questionsCount={questionsCount}
              question={userLessonQuestionData.question as any}
            />
            <UserLessonQuestionAnswers
              onSelectAnswer={useCallback(
                (answerId) => {
                  if (!includes(previouslySubmittedAnswerIds, answerId)) {
                    setSelectedAnswerId(answerId)
                  }
                },
                [previouslySubmittedAnswerIds]
              )}
              previouslySubmittedAnswerIds={previouslySubmittedAnswerIds}
              selectedAnswerId={selectedAnswerId}
              userLessonQuestion={userLessonQuestionData}
            />
          </Box>
        </Group>
      </Group>
      {userLessonQuestionData?.attempts > 0 && !questionAnsweredCorrectly && (
        <MarkdownBox
          p='lg'
          className={classes.explanationBoxIncorrect}
        >
          {userLessonQuestionData?.question?.explanation || 'Sorry, Try again!'}
        </MarkdownBox>
      )}
      {userLessonQuestionData?.attempts > 0 && questionAnsweredCorrectly && (
        <MarkdownBox
          p='lg'
          className={classes.explanationBoxCorrect}
        >
          {
            //last question
            userLessonQuestionData?.sequence === questionsCount
              ? 'Correct, great job!'
              : 'Correct, great job! Keep going...'
          }
        </MarkdownBox>
      )}
      <Group
        position='center'
        p='xl'
      >
        {(userLessonQuestionData?.attempts === 0 ||
          previouslySubmittedAnswerIds[0] !== userLessonQuestionData?.question?.correctAnswer?.nodes[0]?.rowId) && (
          <BorderButton
            rightIcon={<IconChevronRight />}
            disabled={!selectedAnswerId || previouslySubmittedAnswerIds[0] === selectedAnswerId}
            color={selectedAnswerId ? theme.colors.paperYellow[4] : theme.colors.studiousGray[1]}
            onClick={() => {
              if (selectedAnswerId) {
                setPreviouslySubmittedAnswerIds([selectedAnswerId, ...previouslySubmittedAnswerIds])

                onSubmit?.({
                  rowId: userLessonQuestionData.rowId,
                  patch: {
                    completedAt:
                      userLessonQuestionData?.question?.correctAnswer?.nodes[0]?.rowId === selectedAnswerId
                        ? new Date()
                        : undefined,
                    startedAt:
                      userLessonQuestionData.attempts === 0 ? questionStartTime : userLessonQuestionData.startedAt,
                    attempts: userLessonQuestionData.attempts + 1
                  }
                })
              }
            }}
          >
            Next
          </BorderButton>
        )}
        {userLessonQuestionData?.attempts > 0 &&
          previouslySubmittedAnswerIds[0] === userLessonQuestionData?.question?.correctAnswer?.nodes[0]?.rowId &&
          (userLessonQuestionData?.sequence === questionsCount ? (
            <BorderButton
              rightIcon={<IconChevronRight />}
              color={theme.colors.jelloGreen[4]}
              onClick={() => {
                setIsSessionComplete(true)
                onNext()
              }}
            >
              Done
            </BorderButton>
          ) : (
            <BorderButton
              color={selectedAnswerId ? theme.colors.paperYellow[4] : theme.colors.studiousGray[1]}
              rightIcon={<IconChevronRight />}
              onClick={onNext}
            >
              Next
            </BorderButton>
          ))}
      </Group>
    </>
  )
}
