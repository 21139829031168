/**
 * @generated SignedSource<<207f9838d7703d2d045a1ba7ca6c1329>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminExamTypeLayout_examType$data = {
  readonly slug: string | null | undefined;
  readonly " $fragmentType": "AdminExamTypeLayout_examType";
};
export type AdminExamTypeLayout_examType$key = {
  readonly " $data"?: AdminExamTypeLayout_examType$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminExamTypeLayout_examType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminExamTypeLayout_examType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "ExamType",
  "abstractKey": null
};

(node as any).hash = "c29166c81786b41e77bc480ad1068b40";

export default node;
