import { useRouter } from 'next/router'
import { Session } from 'next-auth'
import { FC, ReactNode, useEffect } from 'react'

export interface AccessProviderProps {
  children: ReactNode
  session: Session
}

//todo: maybe convert to middleware
export const AccessProvider: FC<AccessProviderProps> = ({ children, session }) => {
  const router = useRouter()
  useEffect(() => {
    if (router?.isReady && session?.user && session?.user?.isActive === false) {
      router.push('/subscribe')
    }
  }, [router, session?.user])

  return children
}

export default AccessProvider
