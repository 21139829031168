import { type ObjectiveCard_query$key } from '@app/__generated__/ObjectiveCard_query.graphql'
import { type ObjectiveCard_topicObjective$key } from '@app/__generated__/ObjectiveCard_topicObjective.graphql'
import { type ObjectiveCardCreateUserLessonMutation } from '@app/__generated__/ObjectiveCardCreateUserLessonMutation.graphql'
import { type ObjectiveLessonWizardModal_userLesson$key } from '@app/__generated__/ObjectiveLessonWizardModal_userLesson.graphql'
import { BorderBox, BorderBoxProps, BorderButton, TopicIcon } from '@app/components'
import { zeroIfEmpty } from '@app/lib'
import { Box, Group, rem, Text, Title, Tooltip, useMantineTheme } from '@mantine/core'
import { IconCheck, IconChevronUp, IconExclamationMark } from '@tabler/icons-react'
import { find, truncate } from 'lodash'
import { type FC, useMemo } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const createUserLessonMutation = graphql`
  mutation ObjectiveCardCreateUserLessonMutation($input: CreateUserLessonInput!) {
    createUserLesson(input: $input) {
      userLesson {
        ...ObjectiveLessonWizardModal_userLesson
      }
    }
  }
`

const topicObjectiveFragment = graphql`
  fragment ObjectiveCard_topicObjective on TopicObjective {
    examTypeId
    rowId
    sequence
    subtopicId: topicId
    summary
    topic {
      topicId: parentTopicId
    }
    title
    currentUserHasCompletedLesson
  }
`

const queryFragment = graphql`
  fragment ObjectiveCard_query on Query {
    currentUser {
      rowId
      userTopicObjectives {
        nodes {
          score
          topicObjectiveId
        }
      }
    }
  }
`

export interface ObjectiveCardProps extends BorderBoxProps {
  setUserLesson: (userLesson: ObjectiveLessonWizardModal_userLesson$key | null) => void
  topicObjective: ObjectiveCard_topicObjective$key
  query: ObjectiveCard_query$key
  maxTitleLength?: number
  maxSummaryLength?: number
}

const PASSING_SCORE = 70
export const ObjectiveCard: FC<ObjectiveCardProps> = ({
  setUserLesson,
  topicObjective,
  query,
  maxTitleLength,
  maxSummaryLength,
  ...props
}) => {
  const theme = useMantineTheme()
  const topicObjectiveData = useFragment(topicObjectiveFragment, topicObjective)
  const queryData = useFragment(queryFragment, query)
  const [createUserLesson] = useMutation<ObjectiveCardCreateUserLessonMutation>(createUserLessonMutation)

  const learningObjectiveScore = useMemo(
    () =>
      zeroIfEmpty(
        find(
          queryData?.currentUser?.userTopicObjectives?.nodes,
          (userTopicObjective) => userTopicObjective.topicObjectiveId === topicObjectiveData?.rowId
        )?.score
      ) * 100,
    [queryData?.currentUser?.userTopicObjectives?.nodes, topicObjectiveData?.rowId]
  )

  return (
    <BorderBox
      p='sm'
      variant='roundedBottom'
      mb={15}
      h={rem(240)}
      {...props}
    >
      <Group
        align='center'
        mb='xs'
        noWrap
        spacing='xs'
      >
        <Tooltip label={`Proficiency score: ${learningObjectiveScore}%`}>
          <Box>
            <TopicIcon
              bg={
                topicObjectiveData?.currentUserHasCompletedLesson
                  ? learningObjectiveScore > PASSING_SCORE
                    ? theme.colors.jelloGreen[3]
                    : theme.colors.yellow[3]
                  : theme.colors.gray[3]
              }
            >
              {topicObjectiveData?.currentUserHasCompletedLesson ? (
                learningObjectiveScore > PASSING_SCORE ? (
                  <IconCheck />
                ) : (
                  <IconExclamationMark />
                )
              ) : (
                <IconChevronUp />
              )}
            </TopicIcon>
          </Box>
        </Tooltip>
        <Tooltip
          multiline
          suppressHydrationWarning
          label={topicObjectiveData.title}
        >
          <Title order={5}>
            {truncate(topicObjectiveData.title, {
              length: maxTitleLength ?? 50
            })}
          </Title>
        </Tooltip>
      </Group>
      <Tooltip
        multiline
        label={topicObjectiveData?.summary}
      >
        <Text
          color='gray.6'
          fw={400}
          sx={(theme) => ({
            [theme.fn.smallerThan('sm')]: { fontSize: rem(12) }
          })}
        >
          {truncate(topicObjectiveData?.summary ?? undefined, {
            length: maxSummaryLength ?? 100
          })}
        </Text>
      </Tooltip>
      <Group
        mt='xs'
        sx={{ position: 'absolute', bottom: rem(20), right: rem(20) }}
      >
        <BorderButton
          px='xl'
          size='sm'
          compact
          sx={(theme) => ({
            boxShadow: '1px 2px',
            marginLeft: 'auto',
            [theme.fn.smallerThan('sm')]: { paddingLeft: 5, paddingRight: 5, size: 'sm' }
          })}
          color={
            topicObjectiveData?.currentUserHasCompletedLesson
              ? theme.colors.jelloGreen[4]
              : theme.colors.stickyNoteBlue[4]
          }
          onClick={() =>
            createUserLesson({
              variables: {
                input: {
                  userLesson: {
                    examTypeId: topicObjectiveData.examTypeId,
                    topicId: topicObjectiveData.topic?.topicId,
                    subtopicId: topicObjectiveData.subtopicId,
                    startedAt: new Date(),
                    topicObjectiveId: topicObjectiveData.rowId,
                    userId: queryData?.currentUser?.rowId
                  }
                }
              },
              onCompleted: (response) => {
                setUserLesson(response?.createUserLesson?.userLesson || null)
              }
            })
          }
        >
          <Text
            size='sm'
            sx={(theme) => ({
              [theme.fn.smallerThan('sm')]: { fontSize: rem(12) }
            })}
          >
            {topicObjectiveData?.currentUserHasCompletedLesson ? 'Retake' : 'Start'}
          </Text>
        </BorderButton>
      </Group>
    </BorderBox>
  )
}
