import { type ExamTypeForm_examType$key } from '@app/__generated__/ExamTypeForm_examType.graphql'
import { CheckboxInput, Form, MarkdownEditorInput, TextInput } from '@app/components'
import { useForm, zodResolver } from '@mantine/form'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const formSchema = z.object({
  body: z.string(),
  isActive: z.boolean().default(true),
  title: z
    .string({
      required_error: 'Enter a title'
    })
    .trim()
    .max(64, 'Must be 64 characters or less')
})
const examTypeFragment = graphql`
  fragment ExamTypeForm_examType on ExamType {
    body
    isActive
    title
  }
`

export interface ExamTypeFormData {
  body: string
  isActive: boolean
  title: string
}

export type ExamTypeFormProps = {
  examType: ExamTypeForm_examType$key | null
  isSaving?: boolean
  onSubmit: (data: ExamTypeFormData) => void
}

export const ExamTypeForm: FC<ExamTypeFormProps> = ({ isSaving, onSubmit, examType }) => {
  const examTypeData = useFragment(examTypeFragment, examType)
  const form = useForm({
    initialValues: {
      body: examTypeData?.body || '',
      isActive: examTypeData?.isActive ?? true,
      title: examTypeData?.title || ''
    },
    validate: zodResolver(formSchema),
    validateInputOnChange: true
  })

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit(onSubmit)}
    >
      <TextInput
        data-autofocus
        disabled={isSaving}
        label='Title'
        required
        {...form.getInputProps('title')}
      />
      <MarkdownEditorInput
        label='Body'
        required
        {...form.getInputProps('body')}
      />
      <CheckboxInput
        disabled={isSaving}
        label='Active'
        mt='xl'
        mb='xl'
        {...form.getInputProps('isActive', { type: 'checkbox' })}
      />
    </Form>
  )
}
