import { TopicsPageHeader_topic$key } from '@app/__generated__/TopicsPageHeader_topic.graphql'
import { TopicsPageHeader_user$key } from '@app/__generated__/TopicsPageHeader_user.graphql'
import { BorderBox, BorderBoxProps, GuidebookButton, MarkdownBox, ProgressBar } from '@app/components'
import { Grid, Title, useMantineTheme } from '@mantine/core'
import { find, union } from 'lodash'
import { type FC, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

interface TopicsPageHeaderProps extends BorderBoxProps {
  topic: TopicsPageHeader_topic$key | null
  user: TopicsPageHeader_user$key | null
}

const fragment = graphql`
  fragment TopicsPageHeader_topic on Topic {
    rowId
    completedObjectivesCount
    parentTopicId
    summary
    title
    totalObjectivesCount
    guidebook
    ...GuidebookButton_topic
  }
`

const userFragment = graphql`
  fragment TopicsPageHeader_user on User {
    userTopics {
      nodes {
        rid: topicId
        score
      }
    }
    userSubtopics {
      nodes {
        rid: subtopicId
        score
      }
    }
  }
`

export const TopicsPageHeader: FC<TopicsPageHeaderProps> = ({ user, topic, ...props }) => {
  const theme = useMantineTheme()
  const topicData = useFragment(fragment, topic)
  const userData = useFragment(userFragment, user)

  const topicScore = useMemo(
    () =>
      (
        (find(
          union(userData?.userTopics?.nodes, userData?.userSubtopics?.nodes),
          (userTopic) => userTopic.rid === topicData?.rowId
        )?.score || 0) * 100
      ).toFixed(0),
    [userData?.userSubtopics?.nodes, userData?.userTopics?.nodes, topicData?.rowId]
  )

  return (
    <BorderBox
      variant='roundedBottom'
      backgroundColor={theme.colors.studiousGray[2]}
      {...props}
    >
      <Grid>
        <Grid.Col span='auto'>
          <Title
            order={2}
            mb='md'
          >
            {topicData?.title}
          </Title>
        </Grid.Col>
        <Grid.Col span='content'>{topicData?.guidebook && <GuidebookButton topic={topicData} />}</Grid.Col>
      </Grid>
      <MarkdownBox
        markdownProps={{
          style: {
            fontSize: theme.fontSizes.md
          }
        }}
        mb='md'
      >
        {topicData?.summary}
      </MarkdownBox>
      <Grid>
        <Grid.Col span='auto'>
          <ProgressBar
            color={parseInt(topicScore) < 70 ? theme.colors.yellow[3] : theme.colors.jelloGreen[3]}
            labelSize='sm'
            size='md'
            mx={0}
            my='xs'
            w='75%'
            groupProps={{
              style: {
                justifyContent: 'center'
              }
            }}
            value={parseInt(topicScore)}
          />
        </Grid.Col>
      </Grid>
    </BorderBox>
  )
}
