import { DaysUntilExam_examTypesUser$key } from '@app/__generated__/DaysUntilExam_examTypesUser.graphql'
import { BorderBox, BorderBoxProps } from '@app/components'
import { ColorSwatch, createStyles, Group, Text } from '@mantine/core'
import dayjs from 'dayjs'
import { type FC, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

const examTypesUserFragment = graphql`
  fragment DaysUntilExam_examTypesUser on ExamTypesUser {
    examDateAt
  }
`
const useStyles = createStyles((theme) => ({
  daysLeftSwatch: {
    border: `1px solid ${theme.colors.spaceCadetPurple[4]}`,
    boxShadow: `3px 3px ${theme.colors.spaceCadetPurple[4]}`
  }
}))

export interface DaysUntilExamProps extends BorderBoxProps {
  examTypesUser: DaysUntilExam_examTypesUser$key | null
}

export const DaysUntilExam: FC<DaysUntilExamProps> = ({ examTypesUser, ...props }) => {
  const { classes, theme } = useStyles()
  const examTypesUserData = useFragment<DaysUntilExam_examTypesUser$key>(examTypesUserFragment, examTypesUser)
  const today = useMemo(() => new Date(), [])

  if (!examTypesUserData?.examDateAt || dayjs(examTypesUserData?.examDateAt).isBefore(today, 'day')) {
    return null
  }

  const examDateAt = dayjs(examTypesUserData?.examDateAt)
  const daysLeft = examDateAt.diff(today, 'day')

  return (
    <BorderBox {...props}>
      <Group>
        {
          // TODO: maybe add some icons
          !examDateAt ? (
            <Text>You have no exam scheduled yet.</Text>
          ) : examDateAt.isSame(today, 'day') ? (
            <Text>
              Your exam is <strong>today</strong>, good luck!
            </Text>
          ) : examDateAt.isSame(dayjs(today).add(1, 'day'), 'day') ? (
            <Text>
              Your exam is <strong>tomorrow</strong>!
            </Text>
          ) : (
            <>
              <ColorSwatch
                size={30}
                className={classes.daysLeftSwatch}
                color={theme.colors.yellow[5]}
              >
                {daysLeft}
              </ColorSwatch>
              <Text>
                <strong>{`${daysLeft === 1 ? 'Day' : 'Days'} left`}</strong> until the exam
              </Text>
            </>
          )
        }
      </Group>
    </BorderBox>
  )
}
