import { type ExamTypesUserDeleteModal_examTypesUser$key } from '@app/__generated__/ExamTypesUserDeleteModal_examTypesUser.graphql'
import { type ExamTypesUserDeleteModalDeleteExamTypesUserMutation } from '@app/__generated__/ExamTypesUserDeleteModalDeleteExamTypesUserMutation.graphql'
import { ConfirmModal, type ConfirmModalProps } from '@app/components'
import { type FC, useCallback } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const examTypesUserFragment = graphql`
  fragment ExamTypesUserDeleteModal_examTypesUser on ExamTypesUser {
    id
  }
`
const deleteMutation = graphql`
  mutation ExamTypesUserDeleteModalDeleteExamTypesUserMutation(
    $connections: [ID!]!
    $input: DeleteExamTypesUserByIdInput!
  ) {
    deleteExamTypesUserById(input: $input) {
      deletedExamTypesUserId @deleteEdge(connections: $connections)
      examType {
        ...ExamTypeUserForm_examType
      }
      user {
        ...UserExamTypeForm_user
      }
    }
  }
`

export interface ExamTypesUserDeleteModalProps extends ConfirmModalProps {
  connectionId: string
  examTypesUser: ExamTypesUserDeleteModal_examTypesUser$key | null
}

export const ExamTypesUserDeleteModal: FC<ExamTypesUserDeleteModalProps> = ({
  children = 'Are you absolutely sure you want to delete this user exam? This cannot be undone!',
  connectionId,
  examTypesUser,
  onClose,
  ...props
}) => {
  const examTypesUserData = useFragment(examTypesUserFragment, examTypesUser)
  const [deleteExamTypesUser, isDeletingExamTypesUser] =
    useMutation<ExamTypesUserDeleteModalDeleteExamTypesUserMutation>(deleteMutation)

  return (
    <ConfirmModal
      isLoading={isDeletingExamTypesUser}
      onClose={onClose}
      onConfirm={useCallback(
        () =>
          examTypesUserData?.id &&
          deleteExamTypesUser({
            onCompleted: onClose,
            variables: {
              connections: [connectionId],
              input: {
                id: examTypesUserData.id
              }
            }
          }),
        [deleteExamTypesUser, onClose, connectionId, examTypesUserData?.id]
      )}
      title='Delete User Exam'
      {...props}
    >
      {children}
    </ConfirmModal>
  )
}
