import {
  createStyles,
  Group,
  type GroupProps,
  type MantineSize,
  Progress,
  type ProgressProps,
  Text
} from '@mantine/core'
import { type FC } from 'react'

const useStyles = createStyles((theme) => ({
  progressBar: {
    minWidth: 100,
    border: `1px solid ${theme.colors.spaceCadetPurple[4]}`
  }
}))

export interface ProgressBarProps extends ProgressProps {
  groupProps?: GroupProps
  labelSize?: MantineSize
}

export const ProgressBar: FC<ProgressBarProps> = ({ className, groupProps, value, labelSize = 'sm', ...props }) => {
  const { classes, cx, theme } = useStyles()

  return (
    <Group
      noWrap
      spacing='sm'
      {...groupProps}
    >
      <Progress
        value={value}
        className={cx(classes.progressBar, className)}
        color={theme.colors.jelloGreen[4]}
        {...props}
      />
      <Text
        size={labelSize}
        align='center'
      >
        {value}%
      </Text>
    </Group>
  )
}
