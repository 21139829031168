/**
 * @generated SignedSource<<1488b559f154bc82b8b9dbb5ca421693>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserExamTypeUpdateModal_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserExamTypeForm_query">;
  readonly " $fragmentType": "UserExamTypeUpdateModal_query";
};
export type UserExamTypeUpdateModal_query$key = {
  readonly " $data"?: UserExamTypeUpdateModal_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserExamTypeUpdateModal_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserExamTypeUpdateModal_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserExamTypeForm_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "47f7036f3a3d8e15a23adb4127c60b94";

export default node;
