import { type ExamTypeUserForm_examType$key } from '@app/__generated__/ExamTypeUserForm_examType.graphql'
import { type ExamTypeUserForm_examTypesUser$key } from '@app/__generated__/ExamTypeUserForm_examTypesUser.graphql'
import { type ExamTypeUserForm_query$key } from '@app/__generated__/ExamTypeUserForm_query.graphql'
import { type ExamTypeUserFormRefetchQuery } from '@app/__generated__/ExamTypeUserFormRefetchQuery.graphql'
import { DateInput, Form, type FormProps, SelectInput } from '@app/components'
import { useForm, zodResolver } from '@mantine/form'
import { useShallowEffect } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { debounce, includes, map } from 'lodash'
import { type FC, useMemo, useState } from 'react'
import { graphql, useFragment, useRefetchableFragment } from 'react-relay'
import { z } from 'zod'

const examTypeFragment = graphql`
  fragment ExamTypeUserForm_examType on ExamType {
    existingExamTypesUsers: examTypesUsers {
      nodes {
        userId
      }
    }
  }
`
const examTypesUserFragment = graphql`
  fragment ExamTypeUserForm_examTypesUser on ExamTypesUser {
    examDateAt
    userId
  }
`
const queryFragment = graphql`
  fragment ExamTypeUserForm_query on Query
  @argumentDefinitions(
    filter: { type: "UserFilter", defaultValue: null }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "Cursor" }
    orderBy: { type: "[UsersOrderBy!]", defaultValue: [NAME_ASC] }
  )
  @refetchable(queryName: "ExamTypeUserFormRefetchQuery") {
    users(filter: $filter, first: $first, after: $after, orderBy: $orderBy) {
      nodes {
        name
        rowId
      }
    }
  }
`
const formSchema = z.object({
  examDateAt: z.date().nullable(),
  userId: z.string().uuid()
})

export interface ExamTypeUserFormData {
  examDateAt: Date | null
  userId: string
}

export interface ExamTypeUserFormProps extends Omit<FormProps, 'onSubmit'> {
  examType: ExamTypeUserForm_examType$key | null
  examTypesUser: ExamTypeUserForm_examTypesUser$key | null
  isCreating?: boolean
  isSaving?: boolean
  onChange?(isDirty: boolean): void
  onSubmit: (data: ExamTypeUserFormData) => void
  query: ExamTypeUserForm_query$key | null
}

export const ExamTypeUserForm: FC<ExamTypeUserFormProps> = ({
  examType,
  examTypesUser,
  isCreating,
  isSaving,
  onChange,
  onSubmit,
  query,
  ...props
}) => {
  const examTypeData = useFragment(examTypeFragment, examType)
  const examTypesUserData = useFragment(examTypesUserFragment, examTypesUser)
  const [queryData, refetch] = useRefetchableFragment<ExamTypeUserFormRefetchQuery, ExamTypeUserForm_query$key>(
    queryFragment,
    query
  )
  const form = useForm({
    initialValues: {
      examDateAt: examTypesUserData?.examDateAt ? dayjs(examTypesUserData?.examDateAt).toDate() : null,
      userId: examTypesUserData?.userId || null
    },
    validate: zodResolver(formSchema),
    validateInputOnChange: true
  })
  const [searchValue, setSearchValue] = useState<string>()
  const existingUserIds = useMemo(
    () => map(examTypeData?.existingExamTypesUsers?.nodes, 'userId'),
    [examTypeData?.existingExamTypesUsers?.nodes]
  )
  const refetchDebounced = useMemo(
    () =>
      debounce(
        (value: string) =>
          refetch({
            filter: value
              ? {
                  name: {
                    startsWithInsensitive: value
                  }
                }
              : null
          }),
        250
      ),
    [refetch]
  )

  useShallowEffect(() => {
    if (onChange) {
      onChange(form.isDirty())
    }
  }, [form.values])

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit(onSubmit)}
      {...props}
    >
      {isCreating && (
        <SelectInput
          data={map(queryData?.users?.nodes, ({ rowId, name }) => ({
            disabled: includes(existingUserIds, rowId),
            label: name || '',
            value: rowId
          }))}
          data-autofocus
          disabled={isSaving}
          icon={<IconSearch size={18} />}
          label='User'
          onSearchChange={(value: string) => {
            setSearchValue(value)
            refetchDebounced(value)
          }}
          required
          searchable
          searchValue={searchValue}
          withinPortal
          zIndex={10001}
          {...form.getInputProps('userId')}
        />
      )}
      <DateInput
        clearable
        disabled={isSaving}
        label='Exam Date'
        popoverProps={{
          withinPortal: true,
          zIndex: 10001
        }}
        {...form.getInputProps('examDateAt')}
      />
    </Form>
  )
}
