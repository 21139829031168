import { TextInput as MantineTextInput, type TextInputProps as MantineTextInputProps } from '@mantine/core'
import { IconAlphabetLatin } from '@tabler/icons-react'
import { type FC } from 'react'

export interface TextInputProps extends MantineTextInputProps {}

export const TextInput: FC<TextInputProps> = (props) => (
  <MantineTextInput
    mb='xs'
    icon={<IconAlphabetLatin size={18} />}
    {...props}
  />
)
