import { Box, type BoxProps, createStyles } from '@mantine/core'
import { type FC, type ReactNode } from 'react'

const useStyles = createStyles((theme) => ({
  box: {
    border: '1px solid #392F5A',
    position: 'relative'
  },
  rounded: {
    borderRadius: 12
  },
  radiusBottom: {
    borderRadius: '0px 0px 12px 12px'
  },
  boxShadow: {
    border: `1px solid ${theme.colors.spaceCadetPurple[4]}`,
    boxShadow: `3px 3px ${theme.colors.spaceCadetPurple[4]}`
  }
}))

export interface BorderBoxProps extends BoxProps {
  backgroundColor?: string
  children?: ReactNode
  withShadow?: boolean
  variant?: 'default' | 'square' | 'roundedBottom'
}

export const BorderBox: FC<BorderBoxProps> = ({
  p = 'lg',
  pt,
  pb,
  pl,
  pr,
  backgroundColor,
  style = {},
  children,
  withShadow = false,
  variant = 'default',
  className,
  ...props
}) => {
  const { classes, theme, cx } = useStyles()

  return (
    <Box
      pt={pt ?? p}
      pb={pb ?? p}
      pl={pl ?? p}
      pr={pr ?? p}
      className={cx(
        classes.box,
        {
          [classes.rounded]: variant === 'default',
          [classes.boxShadow]: withShadow,
          [classes.radiusBottom]: variant === 'roundedBottom'
        },
        className
      )}
      style={{
        backgroundColor: backgroundColor || theme.white,
        ...style
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
