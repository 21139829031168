import { type UserExamTypesTable_query$key } from '@app/__generated__/UserExamTypesTable_query.graphql'
import {
  type UserExamTypesTable_user$data,
  type UserExamTypesTable_user$key
} from '@app/__generated__/UserExamTypesTable_user.graphql'
import { type UserExamTypesTablePaginationQuery } from '@app/__generated__/UserExamTypesTablePaginationQuery.graphql'
import {
  ActionColumnMenu,
  ExamTypesUserDeleteModal,
  PaginationTable,
  UserExamTypeCreateModal,
  UserExamTypeUpdateModal
} from '@app/components'
import { type ExamTypesUserFilter, ExamTypesUsersOrderBy, friendlyDate } from '@app/lib'
import { Anchor, Menu } from '@mantine/core'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { graphql, useFragment, usePaginationFragment } from 'react-relay'
import { type Get, type IterableElement } from 'type-fest'

type ExamTypesUserEdge = IterableElement<Get<Get<UserExamTypesTable_user$data, 'examTypesUsers'>, 'edges'>>

const queryFragment = graphql`
  fragment UserExamTypesTable_query on Query {
    ...UserExamTypeCreateModal_query
    ...UserExamTypeUpdateModal_query
  }
`
// noinspection GraphQLUnresolvedReference
const userFragment = graphql`
  fragment UserExamTypesTable_user on User
  @argumentDefinitions(
    filter: { type: "ExamTypesUserFilter", defaultValue: null }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "Cursor" }
    orderBy: { type: "[ExamTypesUsersOrderBy!]", defaultValue: [EXAM_DATE_AT_DESC] }
  )
  @refetchable(queryName: "UserExamTypesTablePaginationQuery") {
    examTypesUsers(filter: $filter, first: $first, after: $after, orderBy: $orderBy)
      @connection(key: "UserExamTypesTable_user_examTypesUsers", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          examDateAt
          examType {
            slug
            title
          }
          id
          ...ExamTypesUserDeleteModal_examTypesUser
          ...UserExamTypeUpdateModal_examTypesUser
        }
      }
      totalCount
    }
    ...UserExamTypeCreateModal_user
    ...UserExamTypeUpdateModal_user
  }
`

type ExamTypesUserNode = IterableElement<UserExamTypesTable_user$data['examTypesUsers']['edges']>['node']

export interface UserExamTypesTableHandle {
  showAddModal(): void
}

export interface UserExamTypesTableProps {
  query: UserExamTypesTable_query$key | null
  user: UserExamTypesTable_user$key | null
}

export const UserExamTypesTable = forwardRef<UserExamTypesTableHandle, UserExamTypesTableProps>(
  ({ query, user }, ref) => {
    const queryData = useFragment(queryFragment, query)
    const {
      data: userData,
      hasNext,
      loadNext,
      isLoadingNext,
      refetch
    } = usePaginationFragment<UserExamTypesTablePaginationQuery, UserExamTypesTable_user$key>(userFragment, user)
    const [adding, setAdding] = useState(false)
    const [deleting, setDeleting] = useState<ExamTypesUserNode | null>(null)
    const [updating, setUpdating] = useState<ExamTypesUserNode | null>(null)

    useImperativeHandle(ref, () => ({
      showAddModal: () => setAdding(true)
    }))

    // noinspection DuplicatedCode
    return (
      <>
        <PaginationTable<
          ExamTypesUserEdge,
          ExamTypesUsersOrderBy,
          UserExamTypesTablePaginationQuery,
          ExamTypesUserFilter
        >
          columns={useMemo<ColumnDef<ExamTypesUserEdge>[]>(
            () => [
              {
                id: 'actions',
                enableMultiSort: false,
                enableSorting: false,
                size: 50,
                cell: (props) => {
                  const node = props.row.original.node

                  return (
                    <ActionColumnMenu>
                      <Menu.Label>{node?.examType?.title}</Menu.Label>
                      <Menu.Item
                        icon={<IconEdit size={14} />}
                        onClick={() => setUpdating(node)}
                      >
                        Modify User Exam
                      </Menu.Item>
                      <Menu.Item
                        color='red'
                        icon={<IconTrash size={14} />}
                        onClick={() => setDeleting(node)}
                      >
                        Delete User Exam
                      </Menu.Item>
                    </ActionColumnMenu>
                  )
                }
              },
              {
                accessorKey: 'node.examType',
                id: 'examType',
                header: 'Exam Type',
                enableSorting: true,
                enableMultiSort: false,
                cell: (props) => {
                  const node = props.row.original.node

                  return (
                    <Anchor
                      component={Link}
                      href={`/admin/examTypes/${node?.examType?.slug}`}
                    >
                      {node?.examType?.title}
                    </Anchor>
                  )
                }
              },
              {
                accessorKey: 'node.examDateAt',
                header: 'Exam Date',
                id: 'examDateAt',
                enableSorting: true,
                enableMultiSort: false,
                cell: (props) => {
                  const node = props.row.original.node

                  return node.examDateAt ? friendlyDate(node.examDateAt) : '-'
                }
              }
            ],
            []
          )}
          data={userData?.examTypesUsers?.edges || []}
          filterPlaceholder='Search User Exams'
          getFilterFromSearch={useCallback((search: string) => {
            if (!isEmpty(search)) {
              const maybeDate = dayjs(search)
              const filter: ExamTypesUserFilter = {
                or: [
                  {
                    examType: {
                      title: {
                        includesInsensitive: search
                      }
                    }
                  }
                ]
              }

              if (maybeDate.isValid()) {
                filter.or?.push({
                  examDateAt: {
                    equalTo: maybeDate.format('YYYY-MM-DD')
                  }
                })
              }

              return filter
            }

            return null
          }, [])}
          hasNext={hasNext}
          initialSorting={[
            {
              id: 'examDateAt',
              desc: true
            }
          ]}
          isFilterable
          isLoadingNext={isLoadingNext}
          loadNext={loadNext}
          refetch={refetch}
          sortOptions={{
            examType: {
              asc: ExamTypesUsersOrderBy.ExamTypeByExamTypeIdTitleAsc,
              desc: ExamTypesUsersOrderBy.ExamTypeByExamTypeIdTitleDesc
            },
            examDateAt: {
              asc: ExamTypesUsersOrderBy.ExamDateAtAsc,
              desc: ExamTypesUsersOrderBy.ExamDateAtDesc
            }
          }}
          totalCount={userData?.examTypesUsers?.totalCount || 0}
        />
        <UserExamTypeCreateModal
          connectionId={userData?.examTypesUsers?.__id || ''}
          onClose={useCallback(() => setAdding(false), [])}
          opened={adding}
          query={queryData}
          user={userData}
        />
        <ExamTypesUserDeleteModal
          connectionId={userData?.examTypesUsers?.__id || ''}
          examTypesUser={deleting}
          opened={!!deleting}
          onClose={useCallback(() => setDeleting(null), [])}
        />
        <UserExamTypeUpdateModal
          examTypesUser={updating}
          opened={!!updating}
          onClose={useCallback(() => setUpdating(null), [])}
          query={queryData}
          user={userData}
        />
      </>
    )
  }
)
