/**
 * @generated SignedSource<<cda33b999d9713f4942900c30a400459>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StudySessionWizardModal_examTypesUser$data = {
  readonly examType: {
    readonly subtopics: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly parentTopicId: any | null | undefined;
        readonly sequence: number;
        readonly topicObjectives: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly questions: {
              readonly nodes: ReadonlyArray<{
                readonly rowId: any;
                readonly topicId: any;
                readonly topicObjectiveId: any | null | undefined;
              }>;
            };
            readonly topicId: any;
          }>;
        };
      }>;
    };
    readonly title: string;
    readonly topics: {
      readonly nodes: ReadonlyArray<{
        readonly rowId: any;
      }>;
    };
    readonly userStudySessions: {
      readonly totalCount: number;
    };
    readonly userSubtopics: {
      readonly nodes: ReadonlyArray<{
        readonly score: number;
        readonly subtopic: {
          readonly topicObjectives: {
            readonly nodes: ReadonlyArray<{
              readonly questions: {
                readonly nodes: ReadonlyArray<{
                  readonly rowId: any;
                  readonly topicId: any;
                  readonly topicObjectiveId: any | null | undefined;
                }>;
              };
            }>;
          };
        } | null | undefined;
        readonly subtopicId: any;
        readonly topicId: any;
      }>;
    };
    readonly userTopics: {
      readonly nodes: ReadonlyArray<{
        readonly score: number;
        readonly topicId: any;
      }>;
    };
  } | null | undefined;
  readonly examTypeId: any;
  readonly score: number;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionsPerformanceBox_examTypesUser" | "StreakBox_examTypesUser">;
  readonly " $fragmentType": "StudySessionWizardModal_examTypesUser";
};
export type StudySessionWizardModal_examTypesUser$key = {
  readonly " $data"?: StudySessionWizardModal_examTypesUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"StudySessionWizardModal_examTypesUser">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "condition",
  "value": {
    "meetsTopicViabilityCriteria": true
  }
},
v2 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": [
    "SEQUENCE_ASC"
  ]
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "condition",
    "value": {
      "meetsLessonViabilityCriteria": true
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "topicId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "condition",
      "value": {
        "isActive": true,
        "isStudySession": true
      }
    }
  ],
  "concreteType": "QuestionsConnection",
  "kind": "LinkedField",
  "name": "questions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Question",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "topicObjectiveId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "questions(condition:{\"isActive\":true,\"isStudySession\":true})"
},
v8 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      "SCORE_ASC",
      "TOPIC_BY_TOPIC_ID__SEQUENCE_ASC"
    ]
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudySessionWizardModal_examTypesUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examTypeId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamType",
      "kind": "LinkedField",
      "name": "examType",
      "plural": false,
      "selections": [
        {
          "alias": "subtopics",
          "args": [
            (v1/*: any*/),
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "parentTopicExists": true
              }
            },
            (v2/*: any*/)
          ],
          "concreteType": "TopicsConnection",
          "kind": "LinkedField",
          "name": "topics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sequence",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parentTopicId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v4/*: any*/),
                  "concreteType": "TopicObjectivesConnection",
                  "kind": "LinkedField",
                  "name": "topicObjectives",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TopicObjective",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": [
                        (v3/*: any*/),
                        (v5/*: any*/),
                        (v7/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "topicObjectives(condition:{\"meetsLessonViabilityCriteria\":true})"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "topics(condition:{\"meetsTopicViabilityCriteria\":true},filter:{\"parentTopicExists\":true},orderBy:[\"SEQUENCE_ASC\"])"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v1/*: any*/),
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "parentTopicExists": false
              }
            },
            (v2/*: any*/)
          ],
          "concreteType": "TopicsConnection",
          "kind": "LinkedField",
          "name": "topics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "topics(condition:{\"meetsTopicViabilityCriteria\":true},filter:{\"parentTopicExists\":false},orderBy:[\"SEQUENCE_ASC\"])"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserStudySessionsConnection",
          "kind": "LinkedField",
          "name": "userStudySessions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v8/*: any*/),
          "concreteType": "UserSubtopicsConnection",
          "kind": "LinkedField",
          "name": "userSubtopics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserSubtopic",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subtopicId",
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Topic",
                  "kind": "LinkedField",
                  "name": "subtopic",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": (v4/*: any*/),
                      "concreteType": "TopicObjectivesConnection",
                      "kind": "LinkedField",
                      "name": "topicObjectives",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TopicObjective",
                          "kind": "LinkedField",
                          "name": "nodes",
                          "plural": true,
                          "selections": [
                            (v7/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "topicObjectives(condition:{\"meetsLessonViabilityCriteria\":true})"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "userSubtopics(orderBy:[\"SCORE_ASC\",\"TOPIC_BY_TOPIC_ID__SEQUENCE_ASC\"])"
        },
        {
          "alias": null,
          "args": (v8/*: any*/),
          "concreteType": "UserTopicsConnection",
          "kind": "LinkedField",
          "name": "userTopics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserTopic",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "userTopics(orderBy:[\"SCORE_ASC\",\"TOPIC_BY_TOPIC_ID__SEQUENCE_ASC\"])"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuestionsPerformanceBox_examTypesUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StreakBox_examTypesUser"
    }
  ],
  "type": "ExamTypesUser",
  "abstractKey": null
};
})();

(node as any).hash = "5afa97d2093a0414b79d46d8a0b4f28a";

export default node;
