/**
 * @generated SignedSource<<f6893153ef2256c074c3325b393430f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DaysUntilExam_examTypesUser$data = {
  readonly examDateAt: any | null | undefined;
  readonly " $fragmentType": "DaysUntilExam_examTypesUser";
};
export type DaysUntilExam_examTypesUser$key = {
  readonly " $data"?: DaysUntilExam_examTypesUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"DaysUntilExam_examTypesUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DaysUntilExam_examTypesUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examDateAt",
      "storageKey": null
    }
  ],
  "type": "ExamTypesUser",
  "abstractKey": null
};

(node as any).hash = "014e6e788c111bc9897c6e5ad356eb65";

export default node;
