import { type UserExamTypeForm_examTypesUser$key } from '@app/__generated__/UserExamTypeForm_examTypesUser.graphql'
import { type UserExamTypeForm_query$key } from '@app/__generated__/UserExamTypeForm_query.graphql'
import { type UserExamTypeForm_user$key } from '@app/__generated__/UserExamTypeForm_user.graphql'
import { DateInput, Form, type FormProps, SelectInput } from '@app/components'
import { useForm, zodResolver } from '@mantine/form'
import { useShallowEffect } from '@mantine/hooks'
import dayjs from 'dayjs'
import { includes, map } from 'lodash'
import { type FC, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const formSchema = z.object({
  examDateAt: z.date().nullable(),
  examTypeId: z.string().uuid()
})
const examTypesUserFragment = graphql`
  fragment UserExamTypeForm_examTypesUser on ExamTypesUser {
    examDateAt
    examTypeId
  }
`
const queryFragment = graphql`
  fragment UserExamTypeForm_query on Query {
    examTypes(orderBy: [TITLE_ASC]) {
      nodes {
        rowId
        title
      }
    }
  }
`
const userFragment = graphql`
  fragment UserExamTypeForm_user on User {
    existingExamTypesUsers: examTypesUsers {
      nodes {
        examTypeId
      }
    }
  }
`

export interface UserExamTypeFormData {
  examDateAt: Date | null
  examTypeId: string
}

export interface UserExamTypeFormProps extends Omit<FormProps, 'onSubmit'> {
  examTypesUser: UserExamTypeForm_examTypesUser$key | null
  isCreating?: boolean
  isSaving?: boolean
  onChange?(isDirty: boolean): void
  onSubmit: (data: UserExamTypeFormData) => void
  query: UserExamTypeForm_query$key | null
  user: UserExamTypeForm_user$key | null
}

export const UserExamTypeForm: FC<UserExamTypeFormProps> = ({
  examTypesUser,
  isCreating,
  isSaving,
  onChange,
  onSubmit,
  query,
  user,
  ...props
}) => {
  const examTypesUserData = useFragment(examTypesUserFragment, examTypesUser)
  const queryData = useFragment(queryFragment, query)
  const userData = useFragment(userFragment, user)
  const form = useForm({
    initialValues: {
      examDateAt: examTypesUserData?.examDateAt ? dayjs(examTypesUserData?.examDateAt).toDate() : null,
      examTypeId: examTypesUserData?.examTypeId || null
    },
    validate: zodResolver(formSchema),
    validateInputOnChange: true
  })
  const existingExamTypeIds = useMemo(
    () => map(userData?.existingExamTypesUsers?.nodes, 'examTypeId'),
    [userData?.existingExamTypesUsers?.nodes]
  )

  useShallowEffect(() => {
    if (onChange) {
      onChange(form.isDirty())
    }
  }, [form.values])

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit(onSubmit)}
      {...props}
    >
      {isCreating && (
        <SelectInput
          data={map(queryData?.examTypes?.nodes, ({ rowId, title }) => ({
            disabled: includes(existingExamTypeIds, rowId),
            label: title || '',
            value: rowId
          }))}
          data-autofocus
          disabled={isSaving}
          label='Exam Type'
          required
          searchable
          {...form.getInputProps('examTypeId')}
        />
      )}
      <DateInput
        clearable
        disabled={isSaving}
        label='Exam Date'
        popoverProps={{
          withinPortal: true,
          zIndex: 10001
        }}
        {...form.getInputProps('examDateAt')}
      />
    </Form>
  )
}
