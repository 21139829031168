import { type ExamTypeUserUpdateModal_examType$key } from '@app/__generated__/ExamTypeUserUpdateModal_examType.graphql'
import { type ExamTypeUserUpdateModal_examTypesUser$key } from '@app/__generated__/ExamTypeUserUpdateModal_examTypesUser.graphql'
import { type ExamTypeUserUpdateModal_query$key } from '@app/__generated__/ExamTypeUserUpdateModal_query.graphql'
import { type ExamTypeUserUpdateModalUpdateExamTypesUserMutation } from '@app/__generated__/ExamTypeUserUpdateModalUpdateExamTypesUserMutation.graphql'
import { ExamTypeUserForm, UnsavedChangesModal, type UnsavedChangesModalProps } from '@app/components'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const examTypeFragment = graphql`
  fragment ExamTypeUserUpdateModal_examType on ExamType {
    ...ExamTypeUserForm_examType
  }
`
const examTypesUserFragment = graphql`
  fragment ExamTypeUserUpdateModal_examTypesUser on ExamTypesUser {
    id
    user {
      name
    }
    ...ExamTypeUserForm_examTypesUser
  }
`
const queryFragment = graphql`
  fragment ExamTypeUserUpdateModal_query on Query {
    ...ExamTypeUserForm_query
  }
`
const updateMutation = graphql`
  mutation ExamTypeUserUpdateModalUpdateExamTypesUserMutation($input: UpdateExamTypesUserByIdInput!) {
    updateExamTypesUserById(input: $input) {
      examTypesUser {
        ...ExamTypeUserForm_examTypesUser
      }
    }
  }
`

export interface ExamTypeUserUpdateModalProps extends UnsavedChangesModalProps {
  examType: ExamTypeUserUpdateModal_examType$key | null
  examTypesUser: ExamTypeUserUpdateModal_examTypesUser$key | null
  query: ExamTypeUserUpdateModal_query$key | null
}

export const ExamTypeUserUpdateModal: FC<ExamTypeUserUpdateModalProps> = ({
  examType,
  examTypesUser,
  onClose,
  query,
  ...props
}) => {
  const examTypeData = useFragment(examTypeFragment, examType)
  const examTypesUserData = useFragment(examTypesUserFragment, examTypesUser)
  const queryData = useFragment(queryFragment, query)
  const [updateExamTypesUser, isUpdatingExamTypesUser] =
    useMutation<ExamTypeUserUpdateModalUpdateExamTypesUserMutation>(updateMutation)

  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      title={`Modify User Exam for ${examTypesUserData?.user?.name}`}
      {...props}
    >
      <ExamTypeUserForm
        examType={examTypeData}
        examTypesUser={examTypesUserData}
        isCreating={false}
        isSaving={isUpdatingExamTypesUser}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (patch) =>
            examTypesUserData?.id &&
            updateExamTypesUser({
              onCompleted: onClose,
              variables: {
                input: {
                  id: examTypesUserData.id,
                  patch
                }
              }
            }),
          [examTypesUserData?.id, onClose, updateExamTypesUser]
        )}
        query={queryData}
      />
    </UnsavedChangesModal>
  )
}
