/**
 * @generated SignedSource<<ff9f7e6dc632fac9a89225dfbc62e47b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminUserLayout_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AdminLayout_query">;
  readonly " $fragmentType": "AdminUserLayout_query";
};
export type AdminUserLayout_query$key = {
  readonly " $data"?: AdminUserLayout_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminUserLayout_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminUserLayout_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminLayout_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a82292e0a512f53093e92b0b9d79a330";

export default node;
