import { type UserExamTypeCreateModal_query$key } from '@app/__generated__/UserExamTypeCreateModal_query.graphql'
import { type UserExamTypeCreateModal_user$key } from '@app/__generated__/UserExamTypeCreateModal_user.graphql'
import { type UserExamTypeCreateModalCreateExamTypesUserMutation } from '@app/__generated__/UserExamTypeCreateModalCreateExamTypesUserMutation.graphql'
import { UnsavedChangesModal, type UnsavedChangesModalProps, UserExamTypeForm } from '@app/components'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const userFragment = graphql`
  fragment UserExamTypeCreateModal_user on User {
    rowId
    ...UserExamTypeForm_user
  }
`
const queryFragment = graphql`
  fragment UserExamTypeCreateModal_query on Query {
    ...UserExamTypeForm_query
  }
`
const createMutation = graphql`
  mutation UserExamTypeCreateModalCreateExamTypesUserMutation($connections: [ID!]!, $input: CreateExamTypesUserInput!) {
    createExamTypesUser(input: $input) {
      examType {
        ...ExamTypeUserForm_examType
      }
      examTypesUserEdge @appendEdge(connections: $connections) {
        node {
          examDateAt
          examType {
            title
          }
          id
          ...UserExamTypeUpdateModal_examTypesUser
        }
      }
      user {
        ...UserExamTypeForm_user
      }
    }
  }
`

export interface UserExamTypeCreateModalProps extends UnsavedChangesModalProps {
  connectionId: string
  user: UserExamTypeCreateModal_user$key | null
  query: UserExamTypeCreateModal_query$key | null
}

export const UserExamTypeCreateModal: FC<UserExamTypeCreateModalProps> = ({
  connectionId,
  onClose,
  query,
  user,
  ...props
}) => {
  const userData = useFragment(userFragment, user)
  const queryData = useFragment(queryFragment, query)
  const [createExamTypesUser, isCreatingExamTypesUser] =
    useMutation<UserExamTypeCreateModalCreateExamTypesUserMutation>(createMutation)

  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      isLoading={isCreatingExamTypesUser}
      onClose={onClose}
      title='Create User Exam'
      {...props}
    >
      <UserExamTypeForm
        examTypesUser={null}
        isCreating
        isSaving={isCreatingExamTypesUser}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (examTypesUser) =>
            createExamTypesUser({
              onCompleted: onClose,
              variables: {
                connections: [connectionId],
                input: {
                  examTypesUser: {
                    ...examTypesUser,
                    userId: userData?.rowId
                  }
                }
              }
            }),
          [connectionId, userData, onClose, createExamTypesUser]
        )}
        query={queryData}
        user={userData}
      />
    </UnsavedChangesModal>
  )
}
