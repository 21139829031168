import { type UserExamTypeTopicsList_examType$key } from '@app/__generated__/UserExamTypeTopicsList_examType.graphql'
import { BorderBox, ProgressBar } from '@app/components'
import { Stack, Text, Title } from '@mantine/core'
import { map } from 'lodash'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const examTypeFragment = graphql`
  fragment UserExamTypeTopicsList_examType on ExamType {
    topics(orderBy: [SEQUENCE_ASC], condition: { meetsTopicViabilityCriteria: true, parentTopicId: null }) {
      nodes {
        id
        title
        totalObjectivesCount
        completedObjectivesCount
      }
    }
  }
`
export interface UserExamTypeTopicsListProps {
  examType: UserExamTypeTopicsList_examType$key | null
}

export const UserExamTypeTopicsList: FC<UserExamTypeTopicsListProps> = ({ examType }) => {
  const examTypeData = useFragment(examTypeFragment, examType)
  return (
    <BorderBox
      key='UserExamTypeTopicsList'
      mb='lg'
      sx={{ maxHeight: 300, overflowY: 'auto' }}
    >
      <Title
        order={5}
        pb={8}
      >
        Topics
      </Title>
      {map(examTypeData?.topics?.nodes, ({ id, completedObjectivesCount, totalObjectivesCount, title }, index) => (
        <BorderBox
          key={id}
          mb='lg'
        >
          <Stack>
            <Text>{`${index + 1}. ${title}`}</Text>
            <ProgressBar
              sx={{ width: '100%' }}
              value={Math.round(((completedObjectivesCount || 0) / (totalObjectivesCount || 0)) * 100)}
            />
          </Stack>
        </BorderBox>
      ))}
    </BorderBox>
  )
}
