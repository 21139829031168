import { Box, type BoxProps, createStyles } from '@mantine/core'
import { type FC, useCallback } from 'react'

const useStyles = createStyles((theme) => ({
  timer: {
    borderRadius: 5,
    padding: '0.25rem 1rem',
    border: `1px solid ${theme.colors.spaceCadetPurple[4]}`,
    boxShadow: `3px 3px ${theme.colors.jelloGreen[4]}`
  }
}))

export interface TimerBoxProps extends BoxProps {
  sessionTimeInSeconds?: number
}

export const TimerBox: FC<TimerBoxProps> = ({ className, style = {}, sessionTimeInSeconds = 1200, ...props }) => {
  const { classes, theme, cx } = useStyles()

  const sessionTimeRemaining = useCallback(() => {
    return `${Math.floor(sessionTimeInSeconds / 60)}:${sessionTimeInSeconds % 60 < 10 ? '0' : ''}${
      sessionTimeInSeconds % 60
    }`
  }, [sessionTimeInSeconds])

  return (
    <Box
      className={cx(classes.timer, className)}
      style={{
        backgroundColor: theme.colors.studiousGray[4],
        ...style
      }}
      {...props}
    >
      {sessionTimeRemaining()}
    </Box>
  )
}
