/**
 * @generated SignedSource<<23b481d3c917ab1c5ae27a33039f0382>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamTypeUserForm_examTypesUser$data = {
  readonly examDateAt: any | null | undefined;
  readonly userId: any;
  readonly " $fragmentType": "ExamTypeUserForm_examTypesUser";
};
export type ExamTypeUserForm_examTypesUser$key = {
  readonly " $data"?: ExamTypeUserForm_examTypesUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamTypeUserForm_examTypesUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamTypeUserForm_examTypesUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examDateAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    }
  ],
  "type": "ExamTypesUser",
  "abstractKey": null
};

(node as any).hash = "201c29d09fd2924c59635905f955cb26";

export default node;
