/**
 * @generated SignedSource<<65d907ce5e1e30f4e7161c63e7405fba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestionPropertyList_question$data = {
  readonly body: string;
  readonly createdAt: any;
  readonly explanation: string;
  readonly isActive: boolean | null | undefined;
  readonly topic: {
    readonly parentTopic: {
      readonly examType: {
        readonly slug: string | null | undefined;
      } | null | undefined;
      readonly slug: string | null | undefined;
    } | null | undefined;
    readonly slug: string | null | undefined;
  } | null | undefined;
  readonly topicObjective: {
    readonly slug: string | null | undefined;
    readonly title: string;
  } | null | undefined;
  readonly updatedAt: any;
  readonly " $fragmentType": "QuestionPropertyList_question";
};
export type QuestionPropertyList_question$key = {
  readonly " $data"?: QuestionPropertyList_question$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionPropertyList_question">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionPropertyList_question",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "explanation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "parentTopic",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExamType",
              "kind": "LinkedField",
              "name": "examType",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TopicObjective",
      "kind": "LinkedField",
      "name": "topicObjective",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "Question",
  "abstractKey": null
};
})();

(node as any).hash = "96d9ef59ad09da35499fa5f94c8c0229";

export default node;
