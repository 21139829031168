import {
  type ExamTypeUsersTable_examType$data,
  type ExamTypeUsersTable_examType$key
} from '@app/__generated__/ExamTypeUsersTable_examType.graphql'
import { type ExamTypeUsersTable_query$key } from '@app/__generated__/ExamTypeUsersTable_query.graphql'
import { type ExamTypeUsersTablePaginationQuery } from '@app/__generated__/ExamTypeUsersTablePaginationQuery.graphql'
import {
  ActionColumnMenu,
  ExamTypesUserDeleteModal,
  ExamTypeUserCreateModal,
  ExamTypeUserUpdateModal,
  PaginationTable
} from '@app/components'
import { type ExamTypesUserFilter, ExamTypesUsersOrderBy, friendlyDate } from '@app/lib'
import { Anchor, Menu } from '@mantine/core'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { graphql, useFragment, usePaginationFragment } from 'react-relay'
import { Get, type IterableElement } from 'type-fest'

type ExamTypesUserEdge = IterableElement<Get<Get<ExamTypeUsersTable_examType$data, 'examTypesUsers'>, 'edges'>>

// noinspection GraphQLUnresolvedReference
const examTypeFragment = graphql`
  fragment ExamTypeUsersTable_examType on ExamType
  @argumentDefinitions(
    filter: { type: "ExamTypesUserFilter", defaultValue: null }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "Cursor" }
    orderBy: { type: "[ExamTypesUsersOrderBy!]", defaultValue: [EXAM_DATE_AT_DESC] }
  )
  @refetchable(queryName: "ExamTypeUsersTablePaginationQuery") {
    examTypesUsers(filter: $filter, first: $first, after: $after, orderBy: $orderBy)
      @connection(key: "ExamTypeUsersTable_examType_examTypesUsers", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          examDateAt
          id
          user {
            name
            slug
          }
          ...ExamTypesUserDeleteModal_examTypesUser
          ...ExamTypeUserUpdateModal_examTypesUser
        }
      }
      totalCount
    }
    ...ExamTypeUserCreateModal_examType
    ...ExamTypeUserUpdateModal_examType
  }
`
const queryFragment = graphql`
  fragment ExamTypeUsersTable_query on Query {
    ...ExamTypeUserCreateModal_query
    ...ExamTypeUserUpdateModal_query
  }
`

type ExamTypesUserNode = IterableElement<ExamTypeUsersTable_examType$data['examTypesUsers']['edges']>['node']

export interface ExamTypeUsersTableHandle {
  showAddModal(): void
}

export interface ExamTypeUsersTableProps {
  examType: ExamTypeUsersTable_examType$key | null
  query: ExamTypeUsersTable_query$key | null
}

export const ExamTypeUsersTable = forwardRef<ExamTypeUsersTableHandle, ExamTypeUsersTableProps>(
  ({ examType, query }, ref) => {
    const queryData = useFragment(queryFragment, query)
    const {
      data: examTypeData,
      hasNext,
      loadNext,
      isLoadingNext,
      refetch
    } = usePaginationFragment<ExamTypeUsersTablePaginationQuery, ExamTypeUsersTable_examType$key>(
      examTypeFragment,
      examType
    )
    const [adding, setAdding] = useState(false)
    const [deleting, setDeleting] = useState<ExamTypesUserNode | null>(null)
    const [updating, setUpdating] = useState<ExamTypesUserNode | null>(null)

    useImperativeHandle(ref, () => ({
      showAddModal: () => setAdding(true)
    }))

    return (
      <>
        <PaginationTable<
          ExamTypesUserEdge,
          ExamTypesUsersOrderBy,
          ExamTypeUsersTablePaginationQuery,
          ExamTypesUserFilter
        >
          columns={useMemo<ColumnDef<ExamTypesUserEdge>[]>(
            () => [
              {
                id: 'actions',
                size: 50,
                enableSorting: false,
                enableMultiSort: false,
                cell: (props) => {
                  const node = props.row.original.node

                  return (
                    <ActionColumnMenu>
                      <Menu.Label>{node?.user?.name}</Menu.Label>
                      <Menu.Item
                        icon={<IconEdit size={14} />}
                        onClick={() => setUpdating(node)}
                      >
                        Modify User Exam
                      </Menu.Item>
                      <Menu.Item
                        color='red'
                        icon={<IconTrash size={14} />}
                        onClick={() => setDeleting(node)}
                      >
                        Delete User Exam
                      </Menu.Item>
                    </ActionColumnMenu>
                  )
                }
              },
              {
                accessorKey: 'node.user.name',
                header: 'User',
                id: 'user',
                enableSorting: true,
                enableMultiSort: false,
                cell: (props) => {
                  const node = props.row.original.node
                  return (
                    <Anchor
                      component={Link}
                      href={`/admin/users/${node?.user?.slug}`}
                    >
                      {node?.user?.name}
                    </Anchor>
                  )
                }
              },
              {
                accessorKey: 'node.examDateAt',
                id: 'examDateAt',
                header: 'Exam Date',
                enableSorting: true,
                enableMultiSort: false,
                cell: (props) => {
                  const node = props.row.original.node

                  return node.examDateAt ? friendlyDate(node.examDateAt) : '-'
                }
              }
            ],
            []
          )}
          data={examTypeData?.examTypesUsers?.edges || []}
          filterPlaceholder='Search Exam Users'
          getFilterFromSearch={useCallback((search: string) => {
            if (!isEmpty(search)) {
              const maybeDate = dayjs(search)
              const filter: ExamTypesUserFilter = {
                or: [
                  {
                    user: {
                      name: {
                        includesInsensitive: search
                      }
                    }
                  }
                ]
              }

              if (maybeDate.isValid()) {
                filter.or?.push({
                  examDateAt: {
                    equalTo: maybeDate.format('YYYY-MM-DD')
                  }
                })
              }

              return filter
            }

            return null
          }, [])}
          hasNext={hasNext}
          initialSorting={[
            {
              id: 'examDateAt',
              desc: true
            }
          ]}
          isFilterable
          isLoadingNext={isLoadingNext}
          loadNext={loadNext}
          refetch={refetch}
          sortOptions={{
            user: {
              asc: ExamTypesUsersOrderBy.UserByUserIdNameAsc,
              desc: ExamTypesUsersOrderBy.UserByUserIdNameDesc
            },
            examDateAt: {
              asc: ExamTypesUsersOrderBy.ExamDateAtAsc,
              desc: ExamTypesUsersOrderBy.ExamDateAtDesc
            }
          }}
          totalCount={examTypeData?.examTypesUsers?.totalCount || 0}
        />
        <ExamTypeUserCreateModal
          connectionId={examTypeData?.examTypesUsers?.__id || ''}
          examType={examTypeData}
          onClose={useCallback(() => setAdding(false), [])}
          opened={adding}
          query={queryData}
        />
        <ExamTypesUserDeleteModal
          connectionId={examTypeData?.examTypesUsers?.__id || ''}
          examTypesUser={deleting}
          opened={!!deleting}
          onClose={useCallback(() => setDeleting(null), [])}
        />
        <ExamTypeUserUpdateModal
          examType={examTypeData}
          examTypesUser={updating}
          opened={!!updating}
          onClose={useCallback(() => setUpdating(null), [])}
          query={queryData}
        />
      </>
    )
  }
)
