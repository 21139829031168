import { Container } from '@mantine/core'
import { type FC } from 'react'

import { AppHeaderBase, BareLayout, type BareLayoutProps } from '.'

export interface AuthLayoutProps extends BareLayoutProps {}

export const AuthLayout: FC<AuthLayoutProps> = ({ children, ...props }) => (
  <BareLayout
    header={<AppHeaderBase height={63} />}
    {...props}
  >
    <Container size='xl'>{children}</Container>
  </BareLayout>
)
