import { type StudySessionQuestion_userStudySessionQuestion$key } from '@app/__generated__/StudySessionQuestion_userStudySessionQuestion.graphql'
import { BorderButton, QuestionAnswers, QuestionTitle, TimerBox } from '@app/components'
import { Box, createStyles, Group, RingProgress, Text } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { type FC, useCallback, useEffect, useMemo, useState } from 'react'
import { graphql, useFragment } from 'react-relay'

const userStudySessionQuestionFragment = graphql`
  fragment StudySessionQuestion_userStudySessionQuestion on UserStudySessionQuestion {
    sequence
    rowId
    question {
      body
      topicObjective {
        title
        userTopicObjectives {
          nodes {
            score
          }
        }
      }
      subtopic: topic {
        title
        userSubtopicsBySubtopicId {
          nodes {
            score
          }
        }
        topic: parentTopic {
          title
          userTopics {
            nodes {
              score
            }
          }
        }
      }
      ...QuestionTitle_question
    }
    ...QuestionAnswers_userStudySessionQuestion
  }
`

export interface StudySessionQuestionOnSubmitData {
  rowId: string
  patch: {
    completedAt: Date
    sequence: number
    startedAt: Date
    questionAnswerId: string
  }
}

export interface StudySessionQuestionProps {
  questionsCount?: number
  showResult?: boolean
  disabled?: boolean
  defaultSelectedAnswerId?: string
  userStudySessionQuestion: StudySessionQuestion_userStudySessionQuestion$key
  sessionTimeInSeconds?: number
  onCancel?: () => void

  onNext?: () => void

  onPrev?: () => void
  onSubmit?: (answerData: StudySessionQuestionOnSubmitData) => void
}

const useStyles = createStyles((theme) => ({
  groupContainer: {
    position: 'relative',
    flexWrap: 'nowrap',
    [theme.fn.smallerThan('sm')]: {
      flexWrap: 'wrap'
    }
  },
  borderBox: {
    position: 'relative',
    [theme.fn.smallerThan('sm')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  },
  timerBox: {
    [theme.fn.smallerThan('sm')]: {
      position: 'absolute',
      top: 0,
      right: 0
    }
  }
}))

export const StudySessionQuestion: FC<StudySessionQuestionProps> = ({
  userStudySessionQuestion,
  showResult = false,
  defaultSelectedAnswerId,
  disabled = false,
  questionsCount,
  sessionTimeInSeconds,
  onCancel,
  onNext,
  onPrev,
  onSubmit
}) => {
  const { classes, theme } = useStyles()
  const [selectedAnswerId, setSelectedAnswer] = useState<string | undefined>(defaultSelectedAnswerId)
  const userStudySessionQuestionData = useFragment(userStudySessionQuestionFragment, userStudySessionQuestion)
  const selectAnswerHandler = useCallback((answerId: string) => {
    setSelectedAnswer(answerId)
  }, [])

  // get question start time
  const questionStartTime = useMemo(() => new Date(), [])

  const [questionTimer, setQuestionTimer] = useState(100)

  useEffect(() => {
    if (questionTimer > 0) {
      setTimeout(() => setQuestionTimer(questionTimer - 1), 1000)
    }
  }, [questionTimer])

  return (
    <>
      <Group
        align='flex-start'
        mb='xl'
        className={classes.groupContainer}
      >
        {!showResult && (
          <RingProgress
            size={60}
            thickness={4}
            roundCaps
            rootColor={questionTimer === 0 ? theme.colors.red[4] : undefined}
            sections={[
              {
                value: questionTimer,
                color:
                  questionTimer < 16
                    ? theme.colors.red[4]
                    : questionTimer < 31
                      ? theme.colors.yellow[4]
                      : theme.colors.jelloGreen[4]
              }
            ]}
            label={
              <Text
                align='center'
                size='sm'
              >
                {questionTimer}
              </Text>
            }
          />
        )}
        <Group
          position='left'
          mb='xl'
        >
          <Box>
            <QuestionTitle
              number={userStudySessionQuestionData.sequence}
              questionsCount={questionsCount}
              question={userStudySessionQuestionData.question as any}
            />
            <QuestionAnswers
              showResult={showResult}
              disabled={disabled}
              onSelectAnswer={selectAnswerHandler}
              selectedAnswerId={selectedAnswerId}
              userStudySessionQuestion={userStudySessionQuestionData}
            />
          </Box>
        </Group>
        {!showResult && (
          <TimerBox
            className={classes.timerBox}
            ml='auto'
            sessionTimeInSeconds={sessionTimeInSeconds || 0}
          />
        )}
      </Group>
      <Group
        position='center'
        p='xl'
      >
        {showResult && (
          <>
            {onPrev && (
              <BorderButton
                onClick={onPrev}
                color={theme.colors.studiousGray[0]}
              >
                Previous
              </BorderButton>
            )}

            <BorderButton
              onClick={onNext}
              rightIcon={<IconChevronRight />}
            >
              Next
            </BorderButton>
          </>
        )}
        {!showResult && (
          <>
            <BorderButton
              color={theme.colors.studiousGray[0]}
              onClick={onCancel}
            >
              Cancel
            </BorderButton>
            <BorderButton
              rightIcon={<IconChevronRight />}
              disabled={!selectedAnswerId}
              color={selectedAnswerId ? theme.colors.paperYellow[4] : theme.colors.studiousGray[1]}
              onClick={() =>
                onSubmit?.({
                  rowId: userStudySessionQuestionData.rowId,
                  patch: {
                    completedAt: new Date(),
                    sequence: userStudySessionQuestionData.sequence,
                    startedAt: questionStartTime,
                    questionAnswerId: selectedAnswerId as string
                  }
                })
              }
            >
              Submit
            </BorderButton>
          </>
        )}
      </Group>
    </>
  )
}
