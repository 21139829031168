import { Button, type ButtonProps, createStyles } from '@mantine/core'
import { type FC } from 'react'

const useStyles = createStyles((theme) => ({
  button: {
    color: theme.colors.spaceCadetPurple[4],
    border: `1px solid ${theme.colors.spaceCadetPurple[4]}`,
    borderRadius: 4,
    padding: '4px 10px',
    textTransform: 'uppercase',
    boxShadow: `3px 3px ${theme.colors.spaceCadetPurple[4]}`
  },
  variantCorrect: {
    '&:hover': theme.fn.hover({
      backgroundColor: theme.colors.jelloGreen[4]
    })
  },
  variantIncorrect: {
    '&:hover': theme.fn.hover({
      backgroundColor: theme.colors.softRed[4]
    })
  },
  variantDefault: {
    '&:hover': theme.fn.hover({
      backgroundColor: theme.colors.stickyNoteBlue[4]
    })
  }
}))

export interface AnswerButtonProps extends ButtonProps {
  isSelected?: boolean | null
  variant?: 'default' | 'correct' | 'incorrect'
  onClick?: () => void
}

export const AnswerButton: FC<AnswerButtonProps> = ({
  isSelected = false,
  variant = 'default',
  children,
  ...props
}) => {
  const { classes, cx } = useStyles()

  return (
    <Button
      size='md'
      variant={isSelected ? 'filled' : 'white'}
      color={variant === 'correct' ? 'jelloGreen' : variant === 'incorrect' ? 'softRed' : 'stickyNoteBlue'}
      classNames={{
        root: cx(classes.button, {
          [classes.variantCorrect]: variant === 'correct',
          [classes.variantIncorrect]: variant === 'incorrect',
          [classes.variantDefault]: variant === 'default'
        })
      }}
      {...props}
    >
      {children}
    </Button>
  )
}
