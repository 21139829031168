import { type AppLayout_query$key } from '@app/__generated__/AppLayout_query.graphql'
import { Container } from '@mantine/core'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import { AppHeader, BareLayout, type BareLayoutProps } from '.'

const queryFragment = graphql`
  fragment AppLayout_query on Query {
    ...AppHeader_query
  }
`

export interface AppLayoutProps extends BareLayoutProps {
  query: AppLayout_query$key | null
}

export const AppLayout: FC<AppLayoutProps> = ({ children, query, ...props }) => {
  const queryData = useFragment(queryFragment, query)

  return (
    <BareLayout
      header={<AppHeader query={queryData} />}
      {...props}
    >
      <Container size='xl'>{children}</Container>
    </BareLayout>
  )
}
