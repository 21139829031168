/**
 * @generated SignedSource<<092ad6b3a0bf3cc4c4dfb26fae908cd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteUserLessonByIdInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type ObjectiveLessonWizardModalDeleteUserLessonMutation$variables = {
  input: DeleteUserLessonByIdInput;
};
export type ObjectiveLessonWizardModalDeleteUserLessonMutation$data = {
  readonly deleteUserLessonById: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type ObjectiveLessonWizardModalDeleteUserLessonMutation = {
  response: ObjectiveLessonWizardModalDeleteUserLessonMutation$data;
  variables: ObjectiveLessonWizardModalDeleteUserLessonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteUserLessonPayload",
    "kind": "LinkedField",
    "name": "deleteUserLessonById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ObjectiveLessonWizardModalDeleteUserLessonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ObjectiveLessonWizardModalDeleteUserLessonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "15286e306646ef2e0ba609ea9e80b269",
    "id": null,
    "metadata": {},
    "name": "ObjectiveLessonWizardModalDeleteUserLessonMutation",
    "operationKind": "mutation",
    "text": "mutation ObjectiveLessonWizardModalDeleteUserLessonMutation(\n  $input: DeleteUserLessonByIdInput!\n) {\n  deleteUserLessonById(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd3d7f00b998b76b7769d21fad2b7b09";

export default node;
