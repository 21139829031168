/**
 * @generated SignedSource<<6e58e905271347579527da7193d10546>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestionsPerformanceBox_examTypesUser$data = {
  readonly averageCorrectQuestionTime: {
    readonly minutes: number | null | undefined;
    readonly seconds: number | null | undefined;
  } | null | undefined;
  readonly correctQuestionCount: number | null | undefined;
  readonly " $fragmentType": "QuestionsPerformanceBox_examTypesUser";
};
export type QuestionsPerformanceBox_examTypesUser$key = {
  readonly " $data"?: QuestionsPerformanceBox_examTypesUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionsPerformanceBox_examTypesUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionsPerformanceBox_examTypesUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Interval",
      "kind": "LinkedField",
      "name": "averageCorrectQuestionTime",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seconds",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "correctQuestionCount",
      "storageKey": null
    }
  ],
  "type": "ExamTypesUser",
  "abstractKey": null
};

(node as any).hash = "ffccc18295cf6c1ab499333079ed78ce";

export default node;
