/**
 * @generated SignedSource<<0ac91aa22656e9e331b8738dc6e587e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminQuestionLayout_question$data = {
  readonly body: string;
  readonly explanation: string;
  readonly slug: string | null | undefined;
  readonly topic: {
    readonly examType: {
      readonly slug: string | null | undefined;
    } | null | undefined;
    readonly parentTopic: {
      readonly slug: string | null | undefined;
    } | null | undefined;
    readonly slug: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "AdminQuestionLayout_question";
};
export type AdminQuestionLayout_question$key = {
  readonly " $data"?: AdminQuestionLayout_question$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminQuestionLayout_question">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminQuestionLayout_question",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "explanation",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExamType",
          "kind": "LinkedField",
          "name": "examType",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "parentTopic",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Question",
  "abstractKey": null
};
})();

(node as any).hash = "f05d079798f8ec1dab520ab4f4537a10";

export default node;
