/**
 * @generated SignedSource<<99e95697d20977401cfba4e662676261>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestionAnswerForm_questionAnswer$data = {
  readonly body: string;
  readonly isActive: boolean | null | undefined;
  readonly isCorrect: boolean | null | undefined;
  readonly " $fragmentType": "QuestionAnswerForm_questionAnswer";
};
export type QuestionAnswerForm_questionAnswer$key = {
  readonly " $data"?: QuestionAnswerForm_questionAnswer$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionAnswerForm_questionAnswer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionAnswerForm_questionAnswer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCorrect",
      "storageKey": null
    }
  ],
  "type": "QuestionAnswer",
  "abstractKey": null
};

(node as any).hash = "b5d4403dc9c8b8261125b97d41aeade3";

export default node;
