import { AppShell, type AppShellProps } from '@mantine/core'
import Head from 'next/head'
import { type FC } from 'react'

export interface BareLayoutProps extends AppShellProps {
  title?: string
}

export const BareLayout: FC<BareLayoutProps> = ({ children, title, ...props }) => {
  return (
    <AppShell
      padding='xl'
      sx={(theme) => ({
        main: { [theme.fn.smallerThan('sm')]: { paddingLeft: 0, paddingRight: 0 } }
      })}
      {...props}
    >
      <Head>
        <title>{`Studious${title ? ` / ${title}` : ''}`}</title>
      </Head>
      {children}
    </AppShell>
  )
}
