import { type AppHeader_query$key } from '@app/__generated__/AppHeader_query.graphql'
import {
  Anchor,
  Avatar,
  Burger,
  Button,
  ColorSwatch,
  createStyles,
  Divider,
  Drawer,
  Group,
  Menu,
  rem,
  ScrollArea,
  Text
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconChevronDown, IconLogout, IconSettings, IconUser } from '@tabler/icons-react'
import { map } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { signOut } from 'next-auth/react'
import { event as trackEvent } from 'nextjs-google-analytics'
import { type FC, useCallback } from 'react'
import { graphql, useFragment } from 'react-relay'

import { AppHeaderBase, type AppHeaderBaseProps } from '.'

const queryFragment = graphql`
  fragment AppHeader_query on Query {
    currentUser {
      image
      isAdmin
      isActive
      name
      rowId
    }
  }
`
const useStyles = createStyles((theme) => ({
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing.md,
    height: 60,
    textDecoration: 'none',
    color: theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.md,
    fontFamily: 'var(--font-poppins)',
    position: 'relative',

    '&:hover': {
      textDecoration: 'none'
    },

    [theme.fn.smallerThan('sm')]: {
      height: rem(42),
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },

    ...theme.fn.hover({
      backgroundColor: theme.colors.gray[0]
    })
  },

  linkIndicator: {
    position: 'absolute',
    bottom: 12
  },

  subLink: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor: theme.colors.gray[0]
    }),

    '&:active': theme.activeStyles
  },

  dropdownFooter: {
    backgroundColor: theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${theme.colors.gray[1]}`
  },

  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none'
    }
  },

  hiddenDesktop: {
    [theme.fn.largerThan('sm')]: {
      display: 'none'
    }
  },

  user: {
    color: theme.black,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',
    height: 60,
    '&:hover': {
      backgroundColor: theme.colors.gray[0]
    },

    [theme.fn.smallerThan('xs')]: {
      display: 'none'
    }
  },

  userActive: {
    backgroundColor: theme.colors.gray[0]
  }
}))

const mainLinks = [
  {
    title: 'Home',
    href: '/dashboard'
  },
  {
    title: 'Topics',
    href: '/topics'
  },
  {
    title: 'Review',
    href: '/review'
  }
]

export interface AppHeaderProps extends Omit<AppHeaderBaseProps, 'children'> {
  query: AppHeader_query$key | null
}

export const AppHeader: FC<AppHeaderProps> = ({ query }) => {
  const [drawerOpened, drawerHandler] = useDisclosure(false)
  const [userMenuOpened, userMenuHandler] = useDisclosure(false)
  const queryData = useFragment(queryFragment, query)
  const router = useRouter()
  const onSignOutClick = useCallback(async () => {
    await signOut({ redirect: false })
    trackEvent('logout')
    await router.push('/login')
  }, [router])

  const { classes, theme, cx } = useStyles()

  return (
    <AppHeaderBase>
      <Group
        align='center'
        className={classes.hiddenMobile}
        spacing='xs'
      >
        {map(mainLinks, (link) => (
          <Anchor
            key={link.href}
            href={link.href}
            component={Link}
            className={classes.link}
          >
            {link.title}
            {router.pathname === link.href && (
              <ColorSwatch
                className={classes.linkIndicator}
                color={theme.colors.stickyNoteBlue[4]}
                size={5}
              />
            )}
          </Anchor>
        ))}
      </Group>

      <Group className={classes.hiddenMobile}>
        <Menu
          radius={0}
          width={200}
          offset={0}
          position='bottom-end'
          transitionProps={{ transition: 'pop-top-right' }}
          onClose={userMenuHandler.close}
          onOpen={userMenuHandler.open}
        >
          <Menu.Target>
            <Anchor className={cx(classes.user, { [classes.userActive]: userMenuOpened })}>
              <Group spacing={7}>
                <Avatar
                  style={{
                    border: '1px solid #0A0908'
                  }}
                  alt='User Image'
                  radius='xl'
                  src={queryData?.currentUser?.image}
                  size={42}
                />
                <Text
                  weight={500}
                  size='sm'
                  sx={{ lineHeight: 1 }}
                  mr={3}
                >
                  {queryData?.currentUser?.name}
                </Text>
                <IconChevronDown
                  size={rem(12)}
                  stroke={1.5}
                />
              </Group>
            </Anchor>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              component={Link}
              href='/account'
              icon={
                <IconUser
                  size='0.9rem'
                  stroke={1.5}
                />
              }
            >
              My Profile
            </Menu.Item>
            {queryData?.currentUser?.isAdmin && (
              <Menu.Item
                component={Link}
                href='/admin'
                icon={
                  <IconSettings
                    size='0.9rem'
                    stroke={1.5}
                  />
                }
              >
                Admin
              </Menu.Item>
            )}
            <Menu.Item
              color='red'
              onClick={onSignOutClick}
              icon={
                <IconLogout
                  size='0.9rem'
                  stroke={1.5}
                />
              }
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>

      <Burger
        opened={drawerOpened}
        onClick={drawerHandler.toggle}
        className={classes.hiddenDesktop}
      />

      <Drawer
        opened={drawerOpened}
        onClose={drawerHandler.close}
        size='100%'
        padding='md'
        position='right'
        title={false}
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea
          h={`calc(100vh - ${rem(60)})`}
          mx='-md'
        >
          <Divider
            my='sm'
            color={'gray.1'}
          />

          <Link
            href='/dashboard'
            className={classes.link}
          >
            Home
          </Link>
          <Link
            href='/topics'
            className={classes.link}
          >
            Topics
          </Link>
          <Link
            href='/review'
            className={classes.link}
          >
            Review
          </Link>

          <Divider
            my='sm'
            color={'gray.1'}
          />

          <Link
            href='/account'
            className={classes.link}
          >
            Account
          </Link>

          <a
            href='#'
            onClick={onSignOutClick}
            className={classes.link}
          >
            Logout
          </a>

          <Divider
            my='sm'
            color={'gray.1'}
          />

          {!queryData?.currentUser ? (
            <Group
              position='center'
              grow
              pb='xl'
              px='md'
              mt='xl'
            >
              <Button
                component={Link}
                href='/login'
                variant='default'
              >
                Log in
              </Button>
              <Button
                component={Link}
                href='/register'
              >
                Sign up
              </Button>
            </Group>
          ) : null}
        </ScrollArea>
      </Drawer>
    </AppHeaderBase>
  )
}
