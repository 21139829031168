import {
  type ExamTypesTable_query$data,
  type ExamTypesTable_query$key
} from '@app/__generated__/ExamTypesTable_query.graphql'
import { type ExamTypesTablePaginationQuery } from '@app/__generated__/ExamTypesTablePaginationQuery.graphql'
import { ActionColumnIcon, IconBoolean, PaginationTable } from '@app/components'
import { type ExamTypeFilter, ExamTypesOrderBy } from '@app/lib'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'
import { type Get, type IterableElement } from 'type-fest'

// noinspection GraphQLUnresolvedReference
const queryFragment = graphql`
  fragment ExamTypesTable_query on Query
  @argumentDefinitions(
    filter: { type: "ExamTypeFilter", defaultValue: null }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "Cursor" }
    orderBy: { type: "[ExamTypesOrderBy!]", defaultValue: [TITLE_ASC] }
  )
  @refetchable(queryName: "ExamTypesTablePaginationQuery") {
    examTypes(filter: $filter, first: $first, after: $after, orderBy: $orderBy)
      @connection(key: "ExamTypesTable_query_examTypes", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          isActive
          slug
          title
        }
      }
      totalCount
    }
  }
`

type ExamTypesEdge = IterableElement<Get<Get<ExamTypesTable_query$data, 'examTypes'>, 'edges'>>

export interface ExamTypesTableProps {
  query: ExamTypesTable_query$key
}

export const ExamTypesTable: FC<ExamTypesTableProps> = ({ query }) => {
  const { data, hasNext, loadNext, isLoadingNext, refetch } = usePaginationFragment<
    ExamTypesTablePaginationQuery,
    ExamTypesTable_query$key
  >(queryFragment, query)

  return (
    <PaginationTable<ExamTypesEdge, ExamTypesOrderBy, ExamTypesTablePaginationQuery, ExamTypeFilter>
      columns={useMemo<ColumnDef<ExamTypesEdge>[]>(
        () => [
          {
            id: 'actions',
            size: 50,
            enableSorting: false,
            enableMultiSort: false,
            cell: (props) => {
              const node = props.row.original.node

              return <ActionColumnIcon href={`/admin/examTypes/${node.slug}`} />
            }
          },
          {
            accessorKey: 'node.isActive',
            id: 'isActive',
            enableSorting: true,
            enableMultiSort: false,
            header: 'Active',
            size: 50,
            cell: (props) => {
              const node = props.row.original.node

              return (
                <IconBoolean
                  size={18}
                  value={node.isActive}
                />
              )
            }
          },
          {
            accessorKey: 'node.title',
            id: 'title',
            enableSorting: true,
            enableMultiSort: false,
            header: 'Title'
          }
        ],
        []
      )}
      data={data?.examTypes?.edges || []}
      filterPlaceholder='Search Exam Types'
      getFilterFromSearch={useCallback(
        (search: string) =>
          !isEmpty(search)
            ? {
                or: [
                  {
                    title: {
                      includesInsensitive: search
                    }
                  },
                  {
                    body: {
                      includesInsensitive: search
                    }
                  }
                ]
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={[
        {
          id: 'title',
          desc: false
        }
      ]}
      isFilterable
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      refetch={refetch}
      sortOptions={{
        isActive: {
          asc: ExamTypesOrderBy.IsActiveAsc,
          desc: ExamTypesOrderBy.IsActiveDesc
        },
        title: {
          asc: ExamTypesOrderBy.TitleAsc,
          desc: ExamTypesOrderBy.TitleDesc
        }
      }}
      totalCount={data?.examTypes?.totalCount || 0}
    />
  )
}
