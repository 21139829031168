/**
 * @generated SignedSource<<f1b82bdddcc56b25352feea24b71d77c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicObjectiveForm_topic$data = {
  readonly parentTopicId: any | null | undefined;
  readonly rowId: any;
  readonly " $fragmentType": "TopicObjectiveForm_topic";
};
export type TopicObjectiveForm_topic$key = {
  readonly " $data"?: TopicObjectiveForm_topic$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicObjectiveForm_topic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicObjectiveForm_topic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentTopicId",
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "531d11caef4bf048222a5f62ccf6eec0";

export default node;
