import {
  type LessonsCarousel_examTypesUser$data,
  type LessonsCarousel_examTypesUser$key
} from '@app/__generated__/LessonsCarousel_examTypesUser.graphql'
import { type LessonsCarousel_query$key } from '@app/__generated__/LessonsCarousel_query.graphql'
import { type ObjectiveLessonWizardModal_userLesson$key } from '@app/__generated__/ObjectiveLessonWizardModal_userLesson.graphql'
import { ObjectiveCard, ObjectiveLessonWizardModal } from '@app/components'
import { BorderBox } from '@app/components/boxes'
import { Carousel } from '@mantine/carousel'
import { createStyles, getStylesRef, Text, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { concat, every, find, first, flatMap, map, omit, orderBy, partition, shuffle, some, sortBy, take } from 'lodash'
import { type FC, useCallback, useMemo, useState } from 'react'
import { graphql, useFragment } from 'react-relay'

const examTypesUserFragment = graphql`
  fragment LessonsCarousel_examTypesUser on ExamTypesUser {
    score
    examType {
      userTopics(orderBy: [SCORE_ASC, TOPIC_BY_TOPIC_ID__SEQUENCE_ASC]) {
        nodes {
          topicId
          score
        }
      }
      topics(
        condition: { meetsTopicViabilityCriteria: true }
        filter: { parentTopicExists: false }
        orderBy: [SEQUENCE_ASC]
      ) {
        nodes {
          title
          rowId
          subtopics: topicsByParentTopicId(
            condition: { meetsTopicViabilityCriteria: true }
            filter: { parentTopicExists: true }
            orderBy: [SEQUENCE_ASC]
          ) {
            nodes {
              title
              rowId
              sequence
              topicObjectives(orderBy: [SEQUENCE_ASC], condition: { meetsLessonViabilityCriteria: true }) {
                nodes {
                  title
                  rowId
                  sequence
                  userLessons {
                    totalCount
                  }
                  ...ObjectiveCard_topicObjective
                }
              }
              userTopicObjectives: userTopicObjectivesBySubtopicId(
                orderBy: [SCORE_ASC, TOPIC_BY_TOPIC_ID__SEQUENCE_ASC]
              ) {
                nodes {
                  topicObjectiveId
                  score
                }
              }
            }
          }
          userSubtopics(orderBy: [SCORE_ASC, TOPIC_BY_TOPIC_ID__SEQUENCE_ASC]) {
            nodes {
              subtopicId
              score
            }
          }
        }
      }
      ongoingTopics: topics(
        condition: { meetsTopicViabilityCriteria: true }
        filter: { userLessonsExist: true, userTopics: { some: { score: { lessThan: 0.7 } } } }
      ) {
        nodes {
          userLessons(orderBy: [COMPLETED_AT_ASC], first: 1) {
            nodes {
              completedAt
            }
          }
          rowId
        }
      }
    }
  }
`

const queryFragment = graphql`
  fragment LessonsCarousel_query on Query {
    ...ObjectiveCard_query
  }
`

const PASSING_SCORE = 0.7
const NUM_OF_RECOMMENDED_LESSONS = 3

export const filterRecommendedLessons = (examTypesUserData: LessonsCarousel_examTypesUser$data | null) => {
  //get user score merged with each topic/subtopic/objective data
  const topics = map(examTypesUserData?.examType?.topics.nodes, (topic) => ({
    ...omit(topic, 'userSubtopics'), //passed down to subtopics as score
    subtopics: map(topic.subtopics.nodes, (subtopic) => ({
      ...omit(subtopic, 'userTopicObjectives'), //passed down to topic objectives as score
      score: find(topic.userSubtopics.nodes, (userSubtopic) => userSubtopic.subtopicId === subtopic.rowId)?.score || 0,
      topicObjectives: map(subtopic.topicObjectives.nodes, (topicObjective) => ({
        ...omit(topicObjective, 'userLessons'), //changed to hasUserDoneLesson
        hasUserDoneLesson: topicObjective.userLessons.totalCount > 0,
        score:
          find(
            subtopic.userTopicObjectives.nodes,
            (userTopicObjective) => userTopicObjective.topicObjectiveId === topicObjective.rowId
          )?.score || 0
      }))
    })),
    score:
      find(examTypesUserData?.examType?.userTopics.nodes, (userTopic) => userTopic.topicId === topic.rowId)?.score || 0
  }))

  // get the most recently touched (lesson done) ongoing topics (score > 0 and < 0.7)
  const recentTopicIds = take(
    map(
      orderBy(
        examTypesUserData?.examType?.ongoingTopics.nodes,
        [(ongoingTopic) => ongoingTopic.userLessons.nodes[0].completedAt],
        ['desc']
      ),
      ({ rowId }) => rowId
    ),
    3
  )

  // take recent topics and fill remaining slots with random topics
  const [preselectedTopics, remainingTopics] = partition(topics, (topic) => recentTopicIds.includes(topic.rowId))
  const [belowPassingScoreTopics, abovePassingScoreTopics] = partition(
    remainingTopics,
    (topic) => topic.score < PASSING_SCORE
  )

  //For users below the exam competency threshold
  if ((examTypesUserData?.score || 0) < PASSING_SCORE) {
    // Randomly choose different topics that have a topic-level score of less than threshold
    const selectedTopics = take(
      // prioritize recent topics, then below passing score topics, then above passing score topics
      concat(
        preselectedTopics,
        shuffle(belowPassingScoreTopics),
        sortBy(abovePassingScoreTopics, ['score', 'sequence'])
      ),
      NUM_OF_RECOMMENDED_LESSONS
    )

    const selectedSubtopics = flatMap(
      selectedTopics,
      (topic) =>
        first(
          orderBy(
            topic?.subtopics,
            [
              // For each selected topic, find the first subtopic that has a score of less than threshold
              (subtopic) => subtopic.score < PASSING_SCORE,
              // If available, choose the first ordered objective that has not been completed
              (subtopic) => some(subtopic.topicObjectives, (topicObjective) => !topicObjective.hasUserDoneLesson),
              'sequence'
            ],
            ['desc', 'desc', 'asc']
          )
        ) ?? []
    )

    const selectedObjectives = flatMap(
      selectedSubtopics,
      (subtopic) =>
        first(
          every(subtopic.topicObjectives, 'hasUserDoneLesson') && subtopic.score < PASSING_SCORE
            ? // If all objectives are marked as complete but the subtopic score is still below threshold, choose the objective/lesson with the lowest score
              sortBy(subtopic.topicObjectives, ['score', 'sequence'])
            : // If available, choose the first ordered objective that has not been completed
              orderBy(
                subtopic.topicObjectives,
                [(topicObjective) => !topicObjective.hasUserDoneLesson, 'sequence'],
                ['desc', 'asc']
              )
        ) ?? []
    )

    return {
      selectedTopics,
      selectedSubtopics,
      selectedObjectives
    }
  }

  //For users above the exam competency threshold (> threshold)

  // If all Topics are above competency threshold, chose the topics with lowest score
  // If user has topics that are below competency threshold, prioritize topics that are closest to, but still under, a passing score.
  // If there are less than 3 topics with a score below threshold, fill the remaining slots with the lowest scoring topics that are above threshold
  const selectedTopics = take(
    concat(
      // as preselectedTopics are also below passing score, they are concatenated to belowPassingScoreTopics
      orderBy(concat(preselectedTopics, belowPassingScoreTopics), ['score'], ['desc']),
      orderBy(abovePassingScoreTopics, ['score'], ['asc'])
    ),
    NUM_OF_RECOMMENDED_LESSONS
  )

  const selectedSubtopics = flatMap(
    selectedTopics,
    (topic) =>
      first(
        orderBy(
          topic?.subtopics,
          [
            // check if there are any child objectives that have not been completed or has a null score
            // If all objectives have non-null scores, choose the subtopic with the lowest score
            // and present the objective with the lowest score to the user.
            (subtopic) => some(subtopic.topicObjectives, (topicObjective) => !topicObjective.hasUserDoneLesson),
            'score',
            'sequence'
          ],
          ['desc', 'asc', 'asc']
        )
      ) ?? []
  )

  const selectedObjectives = flatMap(
    selectedSubtopics,
    (subtopic) =>
      first(
        orderBy(
          subtopic?.topicObjectives,
          [
            // If all objectives have non-null scores, choose the subtopic with the lowest score
            // and present the objective with the lowest score to the user.
            (topicObjective) => !topicObjective.hasUserDoneLesson,
            'score',
            'sequence'
          ],
          ['desc', 'asc', 'asc']
        )
      ) ?? []
  )

  return {
    selectedTopics,
    selectedSubtopics,
    selectedObjectives
  }
}

const useStyles = createStyles((theme) => ({
  carouselControls: {
    ref: getStylesRef('controls'),
    transition: 'opacity 150ms ease',
    opacity: 0
  },
  carouselRoot: {
    [theme.fn.smallerThan('sm')]: {
      '&:hover': {
        [`& .${getStylesRef('controls')}`]: {
          opacity: 1
        }
      }
    }
  }
}))

export type LessonsCarouselProps = {
  examTypesUser: LessonsCarousel_examTypesUser$key | null
  query: LessonsCarousel_query$key
}

export const LessonsCarousel: FC<LessonsCarouselProps> = ({ examTypesUser, query }) => {
  const { classes, theme } = useStyles()
  const examTypesUserData = useFragment(examTypesUserFragment, examTypesUser)
  const queryData = useFragment(queryFragment, query)
  const [userLesson, setUserLesson] = useState<ObjectiveLessonWizardModal_userLesson$key | null>(null)

  return (
    <>
      <BorderBox
        backgroundColor={theme.white}
        mb='lg'
      >
        <Title order={3}>Recommended Lessons</Title>
        <Text
          color='gray.6'
          mb='lg'
        >
          Complete 1-3 lessons daily to reach your goal of exam readiness in 100 days.
        </Text>
        <Carousel
          align='start'
          classNames={{
            controls: classes.carouselControls,
            root: classes.carouselRoot
          }}
          controlsOffset={-40}
          height={240}
          mx='auto'
          nextControlIcon={<IconChevronRight />}
          previousControlIcon={<IconChevronLeft />}
          slideGap='md'
          slideSize={'33.333333%'}
          breakpoints={[
            { maxWidth: 'md', slideSize: '45%' },
            { maxWidth: 'sm', slideSize: '90%', slideGap: 'xs' }
          ]}
          styles={{
            control: {
              '&[data-inactive]': {
                opacity: 0,
                cursor: 'default'
              }
            }
          }}
          {...(useMediaQuery(`(min-width: ${theme.breakpoints.md})`)
            ? { slidesToScroll: 0, draggable: false }
            : { slidesToScroll: 1 })}
        >
          {map(
            useMemo(() => filterRecommendedLessons(examTypesUserData).selectedObjectives, [examTypesUserData]),
            (objective) =>
              objective ? (
                <Carousel.Slide key={`slide-${objective.rowId}`}>
                  <ObjectiveCard
                    key={`card-${objective.rowId}`}
                    setUserLesson={setUserLesson}
                    topicObjective={objective}
                    query={queryData}
                  />
                </Carousel.Slide>
              ) : null
          )}
        </Carousel>
      </BorderBox>
      <ObjectiveLessonWizardModal
        opened={!!userLesson}
        onClose={useCallback(() => setUserLesson(null), [setUserLesson])}
        setUserLesson={setUserLesson}
        userLesson={userLesson}
      />
    </>
  )
}
