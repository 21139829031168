import dayjs, { type Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import { entries, get, isNull, map, reduce, split, startCase, toLower, toUpper } from 'lodash'
import { remark } from 'remark'
import stripMD from 'strip-markdown'

dayjs.extend(advancedFormat)
dayjs.extend(localizedFormat)
dayjs.extend(timezone)

// this works by coercing input to number by marking it positive, which results in NaN for non-numeric things (except null)
export const isNumeric = (x: number | string | null) => !isNull(x) && +x === +x
export const connectionEdgesToKeyPairs = <TConnection extends { readonly edges: ReadonlyArray<{ node: any }> }>(
  connection: TConnection | null,
  valuePath: string = 'rowId',
  labelPath: string = 'name'
) =>
  map(connection?.edges, ({ node }) => ({
    value: get(node, valuePath, ''),
    label: get(node, labelPath, '')
  }))
export const connectionNodesToKeyPairs = <TConnection extends { readonly nodes: ReadonlyArray<any> }>(
  connection: TConnection | null | undefined,
  valuePath: string = 'rowId',
  labelPath: string = 'name'
) =>
  map(connection?.nodes, (node) => ({
    value: get(node, valuePath, ''),
    label: get(node, labelPath, '')
  }))
export const initials = (str: string) =>
  reduce(split(str, ' '), (acc, word) => (!isNumeric(word) ? `${acc}${toUpper(word.charAt(0))}` : `${acc}${word}`), '')
export const humanize = (str?: string): string => startCase(toLower(str))
export const shortDate = (date: string | Date | Dayjs): string => dayjs(date).format('MM/DD')
export const friendlyDate = (maybeDate: Date | Dayjs | string) => dayjs(maybeDate).format('LL')
export const longDate = (maybeDate: Date | Dayjs | string) => dayjs(maybeDate).format('MMMM DD YYYY hh:mm:ss A z')
export const enumTypeToKeyPairs = (type: object) =>
  map(entries(type), ([label, value]) => ({
    label: startCase(label),
    value: value
  }))
export const stripMarkdown = (markdown: string) => remark().use(stripMD).processSync(markdown).toString()
export const zeroIfEmpty = (value: number | null | undefined = null) => (!value ? 0 : value)
