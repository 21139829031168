/**
 * @generated SignedSource<<c4c4be5347a4a3c44514d3ac76eb89a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserLessonQuestion_userLessonQuestion$data = {
  readonly attempts: number;
  readonly question: {
    readonly correctAnswer: {
      readonly nodes: ReadonlyArray<{
        readonly rowId: any;
      }>;
    };
    readonly explanation: string;
    readonly " $fragmentSpreads": FragmentRefs<"QuestionTitle_question">;
  } | null | undefined;
  readonly rowId: any;
  readonly sequence: number;
  readonly startedAt: any | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserLessonQuestionAnswers_userLessonQuestion">;
  readonly " $fragmentType": "UserLessonQuestion_userLessonQuestion";
};
export type UserLessonQuestion_userLessonQuestion$key = {
  readonly " $data"?: UserLessonQuestion_userLessonQuestion$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserLessonQuestion_userLessonQuestion">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserLessonQuestion_userLessonQuestion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attempts",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Question",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": [
        {
          "alias": "correctAnswer",
          "args": [
            {
              "kind": "Literal",
              "name": "condition",
              "value": {
                "isActive": true,
                "isCorrect": true
              }
            }
          ],
          "concreteType": "QuestionAnswersConnection",
          "kind": "LinkedField",
          "name": "questionAnswers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuestionAnswer",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "questionAnswers(condition:{\"isActive\":true,\"isCorrect\":true})"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "explanation",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuestionTitle_question"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserLessonQuestionAnswers_userLessonQuestion"
    }
  ],
  "type": "UserLessonQuestion",
  "abstractKey": null
};
})();

(node as any).hash = "eb0535393325035355aa68d1fe17072a";

export default node;
