import { StudySessionCompletePositive_userStudySession$key } from '@app/__generated__/StudySessionCompletePositive_userStudySession.graphql'
import { BorderBox, BorderButton, CustomIcon } from '@app/components'
import { Box, createStyles, Group, rem, Text } from '@mantine/core'
import { IconSquareRoundedCheckFilled, IconSquareRoundedXFilled } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { round } from 'lodash'
import Image from 'next/image'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import greenBalloonImage from './greenBalloon.svg'
import yellowBalloonImage from './yellowBalloon.svg'

export interface StudySessionCompletePositiveProps {
  userStudySession: StudySessionCompletePositive_userStudySession$key
  onNewSession: () => void
  onStartReview: () => void
}

const studySessionCompletePositiveFragment = graphql`
  fragment StudySessionCompletePositive_userStudySession on UserStudySession {
    averageTimePerCorrectQuestion {
      seconds
      minutes
    }
    startedAt
    completedAt
    correctQuestionsCount
    totalQuestionsCount
  }
`

const useStyles = createStyles((theme) => ({
  boxContainer: {
    position: 'relative',
    [theme.fn.smallerThan('sm')]: {
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem'
    }
  },
  leftBalloon: {
    [theme.fn.smallerThan('sm')]: {
      position: 'absolute',
      top: 10,
      left: -100
    }
  },
  rightBalloon: {
    [theme.fn.smallerThan('sm')]: {
      position: 'absolute',
      top: 10,
      right: -100
    }
  },
  statisticsNumbersText: {
    size: 'md',
    weight: 600,
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(14)
    }
  },
  statisticsText: {
    size: 'md',
    weight: 600,
    color: theme.colors.studiousGray[5],
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(12)
    }
  }
}))

export const StudySessionCompletePositive: FC<StudySessionCompletePositiveProps> = ({
  userStudySession,
  onNewSession,
  onStartReview
}) => {
  const { classes, theme } = useStyles()
  const userStudySessionData = useFragment(studySessionCompletePositiveFragment, userStudySession)

  return (
    <>
      <Group
        noWrap
        position='center'
        align='center'
        py='sm'
      >
        <Image
          width={100}
          height={200}
          src={yellowBalloonImage}
          className={classes.leftBalloon}
          alt='Balloon'
        />
        <Box>
          <Text
            mb='lg'
            align='center'
            size='xl'
            weight='bold'
          >
            {/* The original message is "Your Performance Score went up: Congrats, keep at it!",
             * but we do not have the calculations for the performance score implemented yet. */}
            You completed a learning session: Congrats, keep at it!
          </Text>
          <Text
            mb='xl'
            align='center'
            weight='bold'
            size='xl'
          >
            You&apos;ve answered{' '}
            <CustomIcon
              type='bolt'
              size={18}
            />
            <strong style={{ color: theme.colors.stickyNoteBlue[4] }}>
              {userStudySessionData?.totalQuestionsCount ?? 12}{' '}
            </strong>
            questions!
          </Text>
          {/* <Text
              align='center'
              pb='lg'
              size='md'
            >
              You’ve completed 1 of your 7 recommended sessions for the week!
            </Text>
            */}
          <Text
            align='center'
            size='md'
            weight={600}
          >
            Studious is building your personal study profile every time you use the app! Review your answers now or
            start another study session.
          </Text>
        </Box>
        <Image
          width={100}
          height={200}
          className={classes.rightBalloon}
          src={greenBalloonImage}
          alt='Balloon'
        />
      </Group>
      <Group
        px='sm'
        position='center'
        align='center'
      >
        <BorderBox
          w='100%'
          backgroundColor={theme.colors.studiousGray[2]}
          variant='default'
          p='sm'
          mb='xl'
          className={classes.boxContainer}
        >
          <Box>
            <Text
              mb='md'
              align='center'
              size='lg'
              weight='bold'
            >
              Performance Summary
              <Text
                weight='normal'
                size='sm'
                color={theme.colors.studiousGray[5]}
                align='left'
              >
                You can see your performance summary here.
              </Text>
            </Text>
          </Box>
          <Group
            spacing={'sm'}
            position='center'
            align='center'
          >
            <Group>
              <CustomIcon type='easel' />
              <Text className={classes.statisticsNumbersText}>{userStudySessionData?.totalQuestionsCount}</Text>
              <Text className={classes.statisticsText}>Questions</Text>
            </Group>
            <Group>
              <CustomIcon type='clock' />
              <Text className={classes.statisticsNumbersText}>
                {dayjs
                  .duration({
                    minutes: userStudySessionData?.averageTimePerCorrectQuestion?.minutes || 0,
                    seconds: userStudySessionData?.averageTimePerCorrectQuestion?.seconds
                      ? round(userStudySessionData?.averageTimePerCorrectQuestion?.seconds)
                      : 0
                  })
                  .format('mm:ss')}
              </Text>
              <Text className={classes.statisticsText}>Per Question</Text>
            </Group>
            <Group>
              <IconSquareRoundedCheckFilled style={{ color: theme.colors.green[4] }} />
              <Text className={classes.statisticsNumbersText}>{userStudySessionData?.correctQuestionsCount}</Text>
              <Text className={classes.statisticsText}>Correct</Text>
            </Group>
            <Group>
              <IconSquareRoundedXFilled style={{ color: theme.colors.red[4] }} />
              <Text className={classes.statisticsNumbersText}>
                {(userStudySessionData?.totalQuestionsCount ?? 0) - (userStudySessionData?.correctQuestionsCount ?? 0)}
              </Text>
              <Text className={classes.statisticsText}>Incorrect</Text>
            </Group>
          </Group>
        </BorderBox>
      </Group>
      <Group position='center'>
        <BorderButton
          color={theme.colors.studiousGray[3]}
          onClick={onNewSession}
        >
          New Session
        </BorderButton>
        <BorderButton onClick={onStartReview}>Review</BorderButton>
      </Group>
    </>
  )
}
