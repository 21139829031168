import { useDidUpdate } from '@mantine/hooks'
import { FC, ReactNode, useCallback, useState } from 'react'

export interface WizardWrapperChildrenProps {
  active: number
  onNext: () => void
  onPrev: () => void
  onReset: () => void
}

export interface WizardWrapperProps {
  children: (props: WizardWrapperChildrenProps) => ReactNode
  onStepChange?: (step: number) => void
  stepCount: number
}

export const WizardWrapper: FC<WizardWrapperProps> = ({ children, onStepChange, stepCount }) => {
  const [active, setActive] = useState(0)

  const onNext = useCallback(() => setActive((value) => Math.min(stepCount, value + 1)), [stepCount])
  const onPrev = useCallback(() => setActive((value) => Math.max(0, value - 1)), [])
  const onReset = useCallback(() => setActive(0), [])

  useDidUpdate(() => {
    onStepChange?.(active)
  }, [active, onStepChange])

  return <div>{children({ active, onNext, onPrev, onReset })}</div>
}
