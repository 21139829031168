import { Box, type BoxProps, Group, type MantineNumberSize, Text } from '@mantine/core'
import { map } from 'lodash'
import { type FC, isValidElement } from 'react'

export interface PropertyListProps extends Omit<BoxProps, 'children'> {
  items: Array<{ label: string; value: any }>
  textSize?: MantineNumberSize
}

export const PropertyList: FC<PropertyListProps> = ({ items, textSize = 'sm', ...props }) => (
  <Box {...props}>
    {map(items, ({ label, value }, itemIdx) => (
      <Group
        align='flex-start'
        key={`item-${itemIdx}`}
        noWrap
        position='left'
        spacing='xs'
      >
        <Text
          align='start'
          size={textSize}
          sx={{
            whiteSpace: 'nowrap'
          }}
          weight='bold'
        >
          {label}:
        </Text>
        {isValidElement(value) ? (
          value
        ) : (
          <Text
            align='start'
            size={textSize}
          >
            {value || '-'}
          </Text>
        )}
      </Group>
    ))}
  </Box>
)
