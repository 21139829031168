/**
 * @generated SignedSource<<4bf671245bd4607043eed0a8ccad67da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminExamTypeLayout_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AdminLayout_query">;
  readonly " $fragmentType": "AdminExamTypeLayout_query";
};
export type AdminExamTypeLayout_query$key = {
  readonly " $data"?: AdminExamTypeLayout_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminExamTypeLayout_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminExamTypeLayout_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminLayout_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b65dcfde69bcc12ccd8f3ae07811896b";

export default node;
