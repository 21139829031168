import Image from 'next/image'
import { type CSSProperties, type FC } from 'react'

import iconImage from './flareTopRight.svg'

export interface FlareTopRightProps {
  size?: number
  style?: CSSProperties
}

export const FlareTopRight: FC<FlareTopRightProps> = ({ size = 20, style = {} }) => {
  return (
    <span
      style={{
        display: 'inline-block',
        width: size,
        height: size,
        ...style
      }}
    >
      <Image
        src={iconImage}
        alt='^^^'
      />
    </span>
  )
}
