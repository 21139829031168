import { RouterTransition } from '@app/components'
import { MantineProvider, RelayProvider } from '@app/providers'
import AccessProvider from '@app/providers/AccessProvider'
import { type AppProps } from 'next/app'
import Head from 'next/head'
import { type Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { type RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes'

interface PageProps {
  relayData: RecordMap | undefined
  session: Session | null
  token: string | null | undefined
}

const App = ({ Component, pageProps: { relayData, session, token, ...pageProps } }: AppProps<PageProps>) => (
  <SessionProvider session={session}>
    <Head>
      <title>Studious</title>
      <meta
        name='viewport'
        content='minimum-scale=1, initial-scale=1, width=device-width'
      />
    </Head>
    <RelayProvider
      relayData={relayData}
      token={token}
    >
      <AccessProvider session={session}>
        <MantineProvider>
          <RouterTransition />
          <GoogleAnalytics trackPageViews />
          <Component {...pageProps} />
        </MantineProvider>
      </AccessProvider>
    </RelayProvider>
  </SessionProvider>
)

// noinspection JSUnusedGlobalSymbols
export default App
