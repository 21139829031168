/**
 * @generated SignedSource<<47fa0740587a6f80f1573428603ab9d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserExamTypeTopicsList_examType$data = {
  readonly topics: {
    readonly nodes: ReadonlyArray<{
      readonly completedObjectivesCount: number | null | undefined;
      readonly id: string;
      readonly title: string;
      readonly totalObjectivesCount: number | null | undefined;
    }>;
  };
  readonly " $fragmentType": "UserExamTypeTopicsList_examType";
};
export type UserExamTypeTopicsList_examType$key = {
  readonly " $data"?: UserExamTypeTopicsList_examType$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserExamTypeTopicsList_examType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserExamTypeTopicsList_examType",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "meetsTopicViabilityCriteria": true,
            "parentTopicId": null
          }
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "SEQUENCE_ASC"
          ]
        }
      ],
      "concreteType": "TopicsConnection",
      "kind": "LinkedField",
      "name": "topics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalObjectivesCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completedObjectivesCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "topics(condition:{\"meetsTopicViabilityCriteria\":true,\"parentTopicId\":null},orderBy:[\"SEQUENCE_ASC\"])"
    }
  ],
  "type": "ExamType",
  "abstractKey": null
};

(node as any).hash = "6d78994edb2632e09ac2554cb21cc189";

export default node;
