import { type QuestionsPerformanceBox_examTypesUser$key } from '@app/__generated__/QuestionsPerformanceBox_examTypesUser.graphql'
import { BorderBox, type BorderBoxProps, CustomIcon } from '@app/components'
import { createStyles, List, Text, Title } from '@mantine/core'
import dayjs from 'dayjs'
import durationPlugin from 'dayjs/plugin/duration'
import { round } from 'lodash'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

dayjs.extend(durationPlugin)

const examTypesUserFragment = graphql`
  fragment QuestionsPerformanceBox_examTypesUser on ExamTypesUser {
    averageCorrectQuestionTime {
      minutes
      seconds
    }
    correctQuestionCount
  }
`

const useStyles = createStyles(() => ({
  iconListRoot: {
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center'
  },
  iconListItem: {
    padding: 0,
    margin: 0,
    '&:not(:first-of-type)': {
      margin: 0,
      marginLeft: 15
    }
  }
}))

export interface QuestionsPerformanceBoxProps extends BorderBoxProps {
  examTypesUser: QuestionsPerformanceBox_examTypesUser$key | null
}

export const QuestionsPerformanceBox: FC<QuestionsPerformanceBoxProps> = ({ examTypesUser, ...props }) => {
  const examTypesUserData = useFragment(examTypesUserFragment, examTypesUser)
  const { classes, theme } = useStyles()

  return (
    <BorderBox {...props}>
      <Title order={3}>Questions Performance</Title>
      <Text
        mb='lg'
        color={theme.colors.gray[6]}
      >
        You can see total questions and time per question.
      </Text>
      <List
        spacing='xs'
        size='sm'
        center
        classNames={{
          root: classes.iconListRoot,
          item: classes.iconListItem
        }}
      >
        <List.Item icon={<CustomIcon type='easel' />}>
          <strong>{examTypesUserData?.correctQuestionCount || 0}</strong> Questions
        </List.Item>
        <List.Item icon={<CustomIcon type='clock' />}>
          <strong>
            {examTypesUserData?.averageCorrectQuestionTime
              ? dayjs
                  .duration({
                    seconds: examTypesUserData.averageCorrectQuestionTime.seconds
                      ? round(examTypesUserData.averageCorrectQuestionTime.seconds)
                      : 0,
                    minutes: examTypesUserData.averageCorrectQuestionTime.minutes || 0
                  })
                  .format('mm:ss')
              : '-'}
          </strong>{' '}
          Per Question
        </List.Item>
      </List>
    </BorderBox>
  )
}
