import { type ExamTypeUserStudySessionsList_examType$key } from '@app/__generated__/ExamTypeUserStudySessionsList_examType.graphql'
import { type ExamTypeUserStudySessionsListQuery } from '@app/__generated__/ExamTypeUserStudySessionsListQuery.graphql'
import { BorderBox, CustomIcon, ProgressBar, QuestionAnswers, QuestionTitle } from '@app/components'
import { friendlyDate } from '@app/lib'
import { Accordion, createStyles, Grid, Group, Text, Tooltip } from '@mantine/core'
import dayjs from 'dayjs'
import { map } from 'lodash'
import { type FC } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

const examTypeFragment = graphql`
  fragment ExamTypeUserStudySessionsList_examType on ExamType
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 10 }
    after: { type: "Cursor" }
    orderBy: { type: "[UserStudySessionsOrderBy!]", defaultValue: [COMPLETED_AT_DESC] }
  )
  @refetchable(queryName: "ExamTypeUserStudySessionsListQuery") {
    userStudySessions(after: $after, filter: { completedAt: { isNull: false } }, first: $first, orderBy: $orderBy)
      @connection(key: "ExamTypeUserStudySessionsList_examType_userStudySessions", filters: ["filter", "orderBy"]) {
      edges {
        node {
          id
          totalQuestionsCount
          correctQuestionsCount
          startedAt
          completedAt
          userStudySessionQuestions(orderBy: [SEQUENCE_ASC]) {
            nodes {
              id
              startedAt
              completedAt
              question {
                ...QuestionTitle_question
              }
              ...QuestionAnswers_userStudySessionQuestion
            }
          }
        }
      }
    }
  }
`

const useStyles = createStyles(() => ({
  accordionContent: {
    padding: '0 10px'
  },
  accordionItem: {
    border: 'none'
  },
  accordionControl: {
    borderRadius: 5,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  accordionPanel: {
    padding: '0 10px 20px 10px'
  }
}))

export interface ExamTypeUserStudySessionsListProps {
  examType: ExamTypeUserStudySessionsList_examType$key | null
}

// @todo - this should be renamed this will likely need to be renamed and moved into component folders for userStudySessions and/or userLessons
export const ExamTypeUserStudySessionsList: FC<ExamTypeUserStudySessionsListProps> = ({ examType }) => {
  const {
    data: examTypeData
    // hasNext,
    // loadNext,
    // isLoadingNext,
    // refetch
  } = usePaginationFragment<ExamTypeUserStudySessionsListQuery, ExamTypeUserStudySessionsList_examType$key>(
    examTypeFragment,
    examType
  )
  const { classes } = useStyles()

  return (
    <>
      {map(
        examTypeData?.userStudySessions?.edges,
        ({
          node: { id, completedAt, userStudySessionQuestions, startedAt, correctQuestionsCount, totalQuestionsCount }
        }) => (
          <BorderBox
            key={id}
            variant='roundedBottom'
            mb='lg'
            p={0}
          >
            <Accordion
              bottom={0}
              classNames={{
                content: classes.accordionContent,
                item: classes.accordionItem,
                control: classes.accordionControl
              }}
            >
              <Accordion.Item
                value={`session-${id}`}
                key={`session-${id}`}
              >
                <Accordion.Control>
                  <Grid align='center'>
                    <Grid.Col span={2}>
                      <Text>{friendlyDate(completedAt)}</Text>
                    </Grid.Col>
                    <Grid.Col span='auto'>
                      <ProgressBar
                        w='100%'
                        value={Math.floor(((correctQuestionsCount ?? 0) / (totalQuestionsCount ?? 1)) * 100)}
                      />
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <Tooltip label='Total Time Spent'>
                        <Group
                          align='center'
                          spacing='xs'
                        >
                          <CustomIcon
                            w='10%'
                            type='clock'
                          />
                          <Text>
                            {dayjs(completedAt).diff(startedAt, 'minutes')
                              ? `${dayjs(completedAt).diff(startedAt, 'minutes').toString()} min`
                              : `${dayjs(completedAt).diff(startedAt, 'seconds').toString()} sec`}
                          </Text>
                        </Group>
                      </Tooltip>
                    </Grid.Col>
                  </Grid>
                </Accordion.Control>
                <Accordion.Panel>
                  <Accordion
                    bottom={0}
                    classNames={{
                      content: classes.accordionContent,
                      item: classes.accordionItem,
                      control: classes.accordionControl,
                      panel: classes.accordionPanel
                    }}
                  >
                    {map(userStudySessionQuestions?.nodes, (userStudySessionQuestion, idx) => (
                      <BorderBox
                        key={`question-${userStudySessionQuestion.id}`}
                        variant='roundedBottom'
                        p={0}
                        mb='md'
                      >
                        <Accordion.Item value={`question${idx}`}>
                          <Accordion.Control>
                            <QuestionTitle
                              number={idx + 1}
                              questionsCount={userStudySessionQuestions.nodes.length}
                              question={userStudySessionQuestion.question}
                            />
                          </Accordion.Control>
                          <Accordion.Panel>
                            <QuestionAnswers
                              showResult
                              userStudySessionQuestion={userStudySessionQuestion}
                            />
                          </Accordion.Panel>
                        </Accordion.Item>
                      </BorderBox>
                    ))}
                  </Accordion>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </BorderBox>
        )
      )}
    </>
  )
}
