import { type AdminExamTypeLayout_examType$key } from '@app/__generated__/AdminExamTypeLayout_examType.graphql'
import { type AdminExamTypeLayout_query$key } from '@app/__generated__/AdminExamTypeLayout_query.graphql'
import { AdminNavbarLink } from '@app/components'
import { Navbar } from '@mantine/core'
import { IconBinaryTree2, IconBookmarkQuestion, IconCertificate, IconUsers } from '@tabler/icons-react'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import { AdminLayout, AdminLayoutProps } from './AdminLayout'

const examTypeFragment = graphql`
  fragment AdminExamTypeLayout_examType on ExamType {
    slug
  }
`

const queryFragment = graphql`
  fragment AdminExamTypeLayout_query on Query {
    ...AdminLayout_query
  }
`

export interface ExamTypeLayoutProps extends Omit<AdminLayoutProps, 'query'> {
  examType: AdminExamTypeLayout_examType$key | null
  query: AdminExamTypeLayout_query$key | null
}

export const AdminExamTypeLayout: FC<ExamTypeLayoutProps> = ({ children, examType, query, ...props }) => {
  const examTypeData = useFragment<AdminExamTypeLayout_examType$key>(examTypeFragment, examType)
  const queryData = useFragment<AdminExamTypeLayout_query$key>(queryFragment, query)

  return (
    <AdminLayout
      navbar={
        <Navbar.Section>
          <AdminNavbarLink
            color='blue'
            href={`/admin/examTypes/${examTypeData?.slug}`}
            IconComponent={IconCertificate}
            label='Exam Type'
          />
          <AdminNavbarLink
            color='blue'
            href={`/admin/examTypes/${examTypeData?.slug}/topics`}
            IconComponent={IconBinaryTree2}
            label='Topics'
          />
          <AdminNavbarLink
            color='blue'
            href={`/admin/examTypes/${examTypeData?.slug}/users`}
            IconComponent={IconUsers}
            label='Users'
          />
          <AdminNavbarLink
            color='blue'
            href={`/admin/examTypes/${examTypeData?.slug}/generatedQuestions`}
            IconComponent={IconBookmarkQuestion}
            label='Review Generated Questions'
          />
          <AdminNavbarLink
            color='blue'
            href={`/admin/examTypes/${examTypeData?.slug}/generatedExplanations`}
            IconComponent={IconBookmarkQuestion}
            label='Review Generated Explanations'
          />
        </Navbar.Section>
      }
      query={queryData}
      {...props}
    >
      {children}
    </AdminLayout>
  )
}
