/**
 * @generated SignedSource<<029dde4d6739517562a2c9a08cd0f934>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ObjectiveCard_query$data = {
  readonly currentUser: {
    readonly rowId: any;
    readonly userTopicObjectives: {
      readonly nodes: ReadonlyArray<{
        readonly score: number;
        readonly topicObjectiveId: any;
      }>;
    };
  } | null | undefined;
  readonly " $fragmentType": "ObjectiveCard_query";
};
export type ObjectiveCard_query$key = {
  readonly " $data"?: ObjectiveCard_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ObjectiveCard_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ObjectiveCard_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserTopicObjectivesConnection",
          "kind": "LinkedField",
          "name": "userTopicObjectives",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserTopicObjective",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "score",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "topicObjectiveId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0c95af336a2d5d9adcd9b4d9f0b61ea3";

export default node;
