import { TopicInfoBox_topic$key } from '@app/__generated__/TopicInfoBox_topic.graphql'
import { TopicInfoBox_user$key } from '@app/__generated__/TopicInfoBox_user.graphql'
import { BorderBox, BorderButton, GuidebookButton, MarkdownBox, ProgressBar, TopicIcon } from '@app/components'
import { zeroIfEmpty } from '@app/lib'
import { Box, createStyles, Grid, Group, rem, Space, Text, Tooltip } from '@mantine/core'
import { IconBook2, IconCheck, IconExclamationMark } from '@tabler/icons-react'
import { find, union } from 'lodash'
import { useRouter } from 'next/router'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

interface TopicInfoBoxProps {
  topic: TopicInfoBox_topic$key
  user: TopicInfoBox_user$key | null
}

const topicFragment = graphql`
  fragment TopicInfoBox_topic on Topic {
    id
    completedObjectivesCount
    guidebook
    image
    parentTopicId
    rowId
    slug
    summary
    title
    totalObjectivesCount
    ...GuidebookButton_topic
  }
`

const userFragment = graphql`
  fragment TopicInfoBox_user on User {
    userTopics {
      nodes {
        rid: topicId
        score
      }
    }
    userSubtopics {
      nodes {
        rid: subtopicId
        score
      }
    }
  }
`

const useStyles = createStyles((theme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none'
    }
  },
  topicTitle: {
    flex: 1,
    fontWeight: 500,
    [theme.fn.smallerThan('sm')]: {
      fontSize: theme.fontSizes.md
    }
  }
}))

export const TopicInfoBox: FC<TopicInfoBoxProps> = ({ user, topic }) => {
  const { classes, theme } = useStyles()
  const topicData = useFragment(topicFragment, topic)
  const userData = useFragment(userFragment, user)
  const router = useRouter()

  const { isInProgress, isCompleted } = useMemo(() => {
    const totalObjectivesCount = topicData?.totalObjectivesCount || 0
    const completedObjectivesCount = topicData?.completedObjectivesCount || 0

    return {
      totalObjectivesCount,
      completedObjectivesCount,
      isCompleted: completedObjectivesCount === totalObjectivesCount,
      isInProgress: completedObjectivesCount < totalObjectivesCount
    }
  }, [topicData?.completedObjectivesCount, topicData?.totalObjectivesCount])

  const topicScore = useMemo(
    () =>
      (
        zeroIfEmpty(
          find(
            union(userData?.userTopics?.nodes, userData?.userSubtopics?.nodes),
            (userTopic) => userTopic.rid === topicData.rowId
          )?.score
        ) * 100
      ).toFixed(0),
    [userData?.userSubtopics?.nodes, userData?.userTopics?.nodes, topicData.rowId]
  )

  const progressColor = useMemo(
    () => (parseInt(topicScore) < 70 ? theme.colors.yellow[3] : theme.colors.jelloGreen[3]),
    [theme.colors.jelloGreen, theme.colors.yellow, topicScore]
  )

  return (
    <BorderBox
      my='xs'
      p='xs'
    >
      <Grid
        p='sm'
        justify='flex-end'
      >
        <Grid.Col
          style={{ alignSelf: 'flex-start' }}
          span='content'
          miw={rem(65)}
        >
          {topicData?.image ? (
            <TopicIcon
              bg={theme.colors.studiousGray[4]}
              src={topicData.image}
            />
          ) : topicData?.parentTopicId ? (
            parseInt(topicScore) > 70 ? (
              <Tooltip label={`Proficiency Score: ${topicScore}%`}>
                <Box>
                  <TopicIcon bg={theme.colors.jelloGreen[3]}>
                    <IconCheck />
                  </TopicIcon>
                </Box>
              </Tooltip>
            ) : (
              <Tooltip label={`Proficiency Score: ${topicScore}%`}>
                <Box>
                  <TopicIcon bg={theme.colors.yellow[3]}>
                    <IconExclamationMark />
                  </TopicIcon>
                </Box>
              </Tooltip>
            )
          ) : null}
        </Grid.Col>
        <Grid.Col span='auto'>
          <Text
            size='xl'
            className={classes.topicTitle}
            my='xs'
          >
            {topicData.title}
          </Text>
          <MarkdownBox
            className={classes.hiddenMobile}
            my='xs'
          >
            {topicData.summary}
          </MarkdownBox>
          <Grid align='flex-end'>
            <Grid.Col span='auto'>
              <ProgressBar
                color={progressColor}
                labelSize='sm'
                size='md'
                mx={0}
                my='xs'
                w='100%'
                value={parseInt(topicScore)}
              />
            </Grid.Col>
            <Grid.Col
              className={classes.hiddenMobile}
              span='content'
            >
              <Group
                noWrap
                spacing={2}
                align='center'
              >
                <Tooltip
                  label={`${topicData?.completedObjectivesCount}/${topicData?.totalObjectivesCount} Objectives Completed`}
                >
                  <IconBook2
                    size={30}
                    fill={theme.colors.paperYellow[4]}
                  />
                </Tooltip>
                <Text size='md'>{topicData?.completedObjectivesCount || 0}</Text>
              </Group>
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col
          span='content'
          sx={(theme) => ({
            alignSelf: 'flex-end',
            [theme.fn.largerThan('sm')]: {
              minWidth: 165
            }
          })}
        >
          <Group
            align='stretch'
            sx={{
              flexDirection: 'column-reverse'
            }}
          >
            <BorderButton
              px='xl'
              size='sm'
              compact
              sx={() => ({
                boxShadow: '1px 2px',
                marginLeft: 'auto'
              })}
              color={isCompleted ? theme.colors.jelloGreen[4] : theme.colors.stickyNoteBlue[4]}
              onClick={useCallback(() => router.push(`${router.asPath}/${topicData.slug}`), [router, topicData.slug])}
            >
              <Text
                size='sm'
                sx={(theme) => ({
                  [theme.fn.smallerThan('sm')]: { fontSize: rem(12) }
                })}
              >
                {isCompleted ? 'Review' : isInProgress ? 'Continue' : 'Get Started'}
              </Text>
            </BorderButton>
            <Space h='xs' />
            {topicData?.guidebook && <GuidebookButton topic={topicData} />}
          </Group>
        </Grid.Col>
      </Grid>
    </BorderBox>
  )
}
