import { type TopicObjectiveForm_examType$key } from '@app/__generated__/TopicObjectiveForm_examType.graphql'
import { type TopicObjectiveForm_topic$key } from '@app/__generated__/TopicObjectiveForm_topic.graphql'
import { type TopicObjectiveForm_topicObjective$key } from '@app/__generated__/TopicObjectiveForm_topicObjective.graphql'
import { CheckboxInput, Form, SelectInput, TextareaInput, TextInput } from '@app/components'
import { useForm, zodResolver } from '@mantine/form'
import { filter, map } from 'lodash'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const examTypeFragment = graphql`
  fragment TopicObjectiveForm_examType on ExamType {
    allTopics: topics(orderBy: [SEQUENCE_ASC]) {
      nodes {
        parentTopicId
        rowId
        title
      }
    }
  }
`
const formSchema = z.object({
  _parentTopicId: z.string().uuid(),
  isActive: z.boolean(),
  summary: z
    .string({
      required_error: 'Enter a summary'
    })
    .trim()
    .max(256, 'Must be 256 characters or less'),
  title: z
    .string({
      required_error: 'Enter a title'
    })
    .trim()
    .min(1, 'Cannot be empty')
    .max(128, 'Must be 128 characters or less'),
  topicId: z.string().uuid()
})
const topicObjectiveFragment = graphql`
  fragment TopicObjectiveForm_topicObjective on TopicObjective {
    isActive
    rowId
    summary
    title
  }
`
const topicFragment = graphql`
  fragment TopicObjectiveForm_topic on Topic {
    rowId
    parentTopicId
  }
`

export interface TopicObjectiveFormData {
  isActive: boolean
  summary: string | null
  title: string
  topicId: string | null
}

export type TopicObjectiveFormProps = {
  examType: TopicObjectiveForm_examType$key | null
  isSaving?: boolean
  onSubmit: (data: TopicObjectiveFormData) => void
  topic: TopicObjectiveForm_topic$key | null
  topicObjective: TopicObjectiveForm_topicObjective$key | null
}

export const TopicObjectiveForm: FC<TopicObjectiveFormProps> = ({
  examType,
  isSaving,
  onSubmit,
  topic,
  topicObjective
}) => {
  const examTypeData = useFragment(examTypeFragment, examType)
  const topicObjectiveData = useFragment(topicObjectiveFragment, topicObjective)
  const topicData = useFragment(topicFragment, topic)
  const form = useForm({
    initialValues: {
      _parentTopicId: topicData?.parentTopicId || null,
      isActive: topicObjectiveData?.isActive ?? true,
      summary: topicObjectiveData?.summary || '',
      title: topicObjectiveData?.title || '',
      topicId: topicData?.rowId || null
    },
    validate: zodResolver(formSchema),
    validateInputOnChange: true
  })

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit(
        // eslint-disable-next-line no-unused-vars
        ({ _parentTopicId, summary, ...values }) =>
          onSubmit({
            ...values,
            summary: summary || null
          })
      )}
    >
      {topicObjectiveData?.rowId && (
        <>
          <SelectInput
            data={map(filter(examTypeData?.allTopics?.nodes, { parentTopicId: null }), ({ rowId, title }) => ({
              label: title,
              value: rowId
            }))}
            label='Topic'
            {...form.getInputProps('_parentTopicId')}
            onChange={(value) => {
              form.setValues((prev) => ({
                ...prev,
                _parentTopicId: value,
                topicId: null
              }))
            }}
          />
          <SelectInput
            data={map(
              filter(examTypeData?.allTopics?.nodes, { parentTopicId: form.values._parentTopicId }),
              ({ rowId, title }) => ({
                label: title,
                value: rowId
              })
            )}
            label='Subtopic'
            {...form.getInputProps('topicId')}
          />
        </>
      )}
      <TextInput
        autoCapitalize='words'
        data-autofocus
        disabled={isSaving}
        label='Title'
        required
        spellCheck
        {...form.getInputProps('title')}
      />
      <TextareaInput
        autoCapitalize='sentences'
        disabled={isSaving}
        label='Summary'
        spellCheck
        {...form.getInputProps('summary')}
      />
      <CheckboxInput
        disabled={isSaving}
        label='Is Active'
        {...form.getInputProps('isActive', { type: 'checkbox' })}
      />
    </Form>
  )
}
