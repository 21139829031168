import { type StreakBox_examTypesUser$key } from '@app/__generated__/StreakBox_examTypesUser.graphql'
import { BorderBox, type BorderBoxProps, CustomIcon } from '@app/components'
import { ColorSwatch, SimpleGrid, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { IconSnowflake } from '@tabler/icons-react'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { filter, map, reduce, reverse } from 'lodash'
import { type FC, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const examTypesUserFragment = graphql`
  fragment StreakBox_examTypesUser on ExamTypesUser {
    streak {
      nodes {
        date
        isCompleted
      }
    }
  }
`

export interface StreakBoxProps extends BorderBoxProps {
  examTypesUser: StreakBox_examTypesUser$key | null
}

export const StreakBox: FC<StreakBoxProps> = ({ examTypesUser, ...props }) => {
  const examTypesUserData = useFragment(examTypesUserFragment, examTypesUser)
  const theme = useMantineTheme()
  const today = useMemo(() => new Date(), [])
  const streakCount = useMemo(
    () =>
      reduce(
        reverse(filter(examTypesUserData?.streak.nodes, ({ date }) => dayjs(date).isSameOrBefore(today, 'day'))),
        (acc, { isCompleted }) => (isCompleted ? acc + 1 : acc),
        0
      ),
    [examTypesUserData?.streak.nodes, today]
  )

  return (
    <BorderBox {...props}>
      <Title order={3}>Streaks</Title>
      <Text
        mb='lg'
        color={theme.colors.gray[6]}
      >
        You have a <strong>{streakCount}</strong> day streak. You&apos;re warming up!
      </Text>

      <SimpleGrid cols={7}>
        {map(examTypesUserData?.streak.nodes, ({ date, isCompleted }) => (
          <Stack
            align='center'
            justify='center'
            key={date}
          >
            {dayjs(date).format('dd')}
            {isCompleted ? (
              <CustomIcon
                type='flame'
                size={30}
              />
            ) : dayjs(date).isSameOrAfter(today, 'day') ? (
              <ColorSwatch
                size={30}
                color={theme.colors.gray[3]}
              />
            ) : (
              <IconSnowflake
                size={30}
                color={theme.colors.stickyNoteBlue[4]}
              />
            )}
          </Stack>
        ))}
      </SimpleGrid>
    </BorderBox>
  )
}
