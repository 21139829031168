import { createStyles } from '@mantine/core'
import { DateInput as BaseDateInput, type DateInputProps as BaseDateInputProps } from '@mantine/dates'
import { IconCalendar } from '@tabler/icons-react'
import { type FC } from 'react'

const useStyles = createStyles(() => ({
  input: {
    label: {
      fontSize: 16,
      fontWeight: 'bold'
    },
    input: {
      fontWeight: 'bold',
      borderRadius: 5,
      padding: '30px 15px',
      fontSize: 18
    }
  }
}))

export interface StudentDateInputProps extends BaseDateInputProps {}

export const StudentDateInput: FC<StudentDateInputProps> = (props) => {
  const { classes } = useStyles()

  return (
    <BaseDateInput
      className={classes.input}
      icon={<IconCalendar size={18} />}
      mb='xs'
      radius='md'
      {...props}
    />
  )
}
