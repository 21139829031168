import {
  type UserStudySessionsTable_user$data,
  type UserStudySessionsTable_user$key
} from '@app/__generated__/UserStudySessionsTable_user.graphql'
import { type UserStudySessionsTablePaginationQuery } from '@app/__generated__/UserStudySessionsTablePaginationQuery.graphql'
import { PaginationTable } from '@app/components'
import { isNumeric, type UserStudySessionFilter, UserStudySessionsOrderBy } from '@app/lib'
import { type ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import durationPlugin from 'dayjs/plugin/duration'
import { includes, isEmpty, replace, round } from 'lodash'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'
import { type Get, type IterableElement } from 'type-fest'

type UserStudySessionEdge = IterableElement<Get<Get<UserStudySessionsTable_user$data, 'userStudySessions'>, 'edges'>>

dayjs.extend(durationPlugin)

// noinspection GraphQLUnresolvedReference
const userFragment = graphql`
  fragment UserStudySessionsTable_user on User
  @argumentDefinitions(
    filter: { type: "UserStudySessionFilter", defaultValue: null }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "Cursor" }
    orderBy: { type: "[UserStudySessionsOrderBy!]", defaultValue: [COMPLETED_AT_DESC] }
  )
  @refetchable(queryName: "UserStudySessionsTablePaginationQuery") {
    userStudySessions(filter: $filter, first: $first, after: $after, orderBy: $orderBy)
      @connection(key: "UserStudySessionsTable_user_userStudySessions", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          examType {
            title
          }
          slug
          averageTimePerQuestion {
            minutes
            seconds
          }
          correctQuestionsCount
          totalQuestionsCount
          score
          startedAt
          completedAt
        }
      }
      totalCount
    }
  }
`

export interface UserStudySessionsTableProps {
  user: UserStudySessionsTable_user$key | null
}

export const UserStudySessionsTable: FC<UserStudySessionsTableProps> = ({ user }) => {
  const {
    data: userData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<UserStudySessionsTablePaginationQuery, UserStudySessionsTable_user$key>(userFragment, user)
  return (
    <PaginationTable<
      UserStudySessionEdge,
      UserStudySessionsOrderBy,
      UserStudySessionsTablePaginationQuery,
      UserStudySessionFilter
    >
      columns={useMemo<ColumnDef<UserStudySessionEdge>[]>(
        () => [
          {
            accessorKey: 'node.examType.title',
            id: 'examType',
            header: 'Exam Type',
            enableMultiSort: false,
            enableSorting: true
          },
          {
            accessorKey: 'node.startedAt',
            header: 'Start Date',
            id: 'startedAt',
            enableMultiSort: false,
            enableSorting: true,
            cell: (props) => {
              const node = props.row.original.node

              return node.startedAt ? dayjs(node.startedAt).format('LLL') : '-'
            }
          },
          {
            accessorKey: 'node.completedAt',
            header: 'Completion Date',
            id: 'completedAt',
            enableMultiSort: false,
            enableSorting: true,
            cell: (props) => {
              const node = props.row.original.node

              return node.completedAt ? dayjs(node.completedAt).format('LLL') : '-'
            }
          },
          {
            accessorKey: 'node.averageTimePerQuestion',
            header: 'Avg. Time Per Question',
            id: 'averageTimePerQuestion',
            enableMultiSort: false,
            enableSorting: true,
            cell: (props) => {
              const node = props.row.original.node

              return dayjs
                .duration({
                  minutes: node.averageTimePerQuestion?.minutes || 0,
                  seconds: node.averageTimePerQuestion?.seconds ? round(node.averageTimePerQuestion?.seconds) : 0
                })
                .format('mm:ss')
            }
          },
          {
            accessorKey: 'node.correctQuestionsCount',
            header: 'Correct Questions',
            id: 'correctQuestionsCount',
            enableMultiSort: false,
            enableSorting: true
          },
          {
            accessorKey: 'node.totalQuestionsCount',
            header: 'Total Questions',
            id: 'totalQuestionsCount',
            enableMultiSort: false,
            enableSorting: true
          },
          {
            accessorKey: 'node.score',
            header: 'Score',
            id: 'score',
            enableMultiSort: false,
            enableSorting: true,
            cell: (props) => {
              const node = props.row.original.node

              return `${node.score * 100}%`
            }
          }
        ],
        []
      )}
      data={userData?.userStudySessions?.edges || []}
      filterPlaceholder='Search User Study Sessions'
      getFilterFromSearch={useCallback((search: string) => {
        if (!isEmpty(search)) {
          const maybeDate = dayjs(search)
          const filter: UserStudySessionFilter = {
            or: [
              {
                examType: {
                  title: {
                    includesInsensitive: search
                  }
                }
              }
            ]
          }

          if (maybeDate.isValid()) {
            const stringDate = maybeDate.format('YYYY-MM-DD')

            filter.or?.push(
              {
                startedAt: {
                  greaterThanOrEqualTo: stringDate
                }
              },
              {
                completedAt: {
                  greaterThanOrEqualTo: stringDate
                }
              }
            )
          }

          if (isNumeric(search)) {
            const num = Number(search)

            filter.or?.push(
              {
                correctQuestionsCount: {
                  greaterThanOrEqualTo: num
                }
              },
              {
                totalQuestionsCount: {
                  greaterThanOrEqualTo: num
                }
              }
            )
          }

          if (includes(search, '%')) {
            filter.or?.push({
              score: {
                greaterThanOrEqualTo: Number(replace(search, '%', '')) / 100
              }
            })
          }

          return filter
        }

        return null
      }, [])}
      hasNext={hasNext}
      initialSorting={[
        {
          id: 'completedAt',
          desc: true
        }
      ]}
      isFilterable
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      refetch={refetch}
      sortOptions={{
        examType: {
          asc: UserStudySessionsOrderBy.ExamTypeByExamTypeIdTitleAsc,
          desc: UserStudySessionsOrderBy.ExamTypeByExamTypeIdTitleDesc
        },
        startedAt: {
          asc: UserStudySessionsOrderBy.StartedAtAsc,
          desc: UserStudySessionsOrderBy.StartedAtDesc
        },
        completedAt: {
          asc: UserStudySessionsOrderBy.CompletedAtAsc,
          desc: UserStudySessionsOrderBy.CompletedAtDesc
        },
        averageTimePerQuestion: {
          asc: UserStudySessionsOrderBy.AverageTimePerCorrectQuestionAsc,
          desc: UserStudySessionsOrderBy.AverageTimePerCorrectQuestionDesc
        },
        correctQuestionsCount: {
          asc: UserStudySessionsOrderBy.CorrectQuestionsCountAsc,
          desc: UserStudySessionsOrderBy.CorrectQuestionsCountDesc
        },
        totalQuestionsCount: {
          asc: UserStudySessionsOrderBy.TotalQuestionsCountAsc,
          desc: UserStudySessionsOrderBy.TotalQuestionsCountDesc
        },
        score: {
          asc: UserStudySessionsOrderBy.ScoreAsc,
          desc: UserStudySessionsOrderBy.ScoreDesc
        }
      }}
      totalCount={userData?.userStudySessions?.totalCount || 0}
    />
  )
}
