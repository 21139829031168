import {
  createStyles,
  LoadingOverlay,
  Modal as MantineModal,
  type ModalProps as MantineModalProps
} from '@mantine/core'
import { type FC } from 'react'

const useStyles = createStyles({
  body: {
    position: 'relative'
  },
  title: {
    fontWeight: 600
  }
})

export interface ModalProps extends MantineModalProps {
  isLoading?: boolean
}

export const Modal: FC<ModalProps> = ({ children, classNames, isLoading = false, ...props }) => {
  const { classes } = useStyles()

  return (
    <MantineModal
      centered
      classNames={{
        body: classes.body,
        title: classes.title,
        ...classNames
      }}
      closeOnClickOutside={false}
      closeOnEscape={false}
      transitionProps={{
        transition: 'fade',
        duration: 200
      }}
      zIndex={1001}
      {...props}
    >
      <LoadingOverlay visible={isLoading} />
      {children}
    </MantineModal>
  )
}
