/**
 * @generated SignedSource<<fd43888b0c583f61b28ee5e71fd82dc4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestionTitle_question$data = {
  readonly body: string;
  readonly " $fragmentType": "QuestionTitle_question";
};
export type QuestionTitle_question$key = {
  readonly " $data"?: QuestionTitle_question$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionTitle_question">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionTitle_question",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    }
  ],
  "type": "Question",
  "abstractKey": null
};

(node as any).hash = "e4b8d8a658c9b5bbaae186658fc1ad99";

export default node;
