import {
  SubtopicQuestionsTable_topic$data,
  type SubtopicQuestionsTable_topic$key
} from '@app/__generated__/SubtopicQuestionsTable_topic.graphql'
import { type SubtopicQuestionsTablePaginationQuery } from '@app/__generated__/SubtopicQuestionsTablePaginationQuery.graphql'
import { ActionColumnIcon, IconBoolean, PaginationTable } from '@app/components'
import { type QuestionFilter, QuestionsOrderBy, stripMarkdown } from '@app/lib'
import { Anchor, Text } from '@mantine/core'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'
import { Get, IterableElement } from 'type-fest'

// noinspection GraphQLUnresolvedReference
const subtopicFragment = graphql`
  fragment SubtopicQuestionsTable_topic on Topic
  @argumentDefinitions(
    filter: { type: "QuestionFilter", defaultValue: null }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "Cursor" }
    orderBy: { type: "[QuestionsOrderBy!]", defaultValue: [IS_ACTIVE_DESC] }
  )
  @refetchable(queryName: "SubtopicQuestionsTablePaginationQuery") {
    slug
    examType {
      slug
    }
    parentTopic {
      slug
    }
    questions(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "SubtopicQuestionsTable_topic_questions", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          body
          isActive
          slug
          topicObjective {
            slug
            title
          }
        }
      }
      totalCount
    }
  }
`

type SubtopicQuestionsEdge = IterableElement<Get<Get<SubtopicQuestionsTable_topic$data, 'questions'>, 'edges'>>

export interface SubtopicQuestionsTableProps {
  subtopic: SubtopicQuestionsTable_topic$key | null
}

export const SubtopicQuestionsTable: FC<SubtopicQuestionsTableProps> = ({ subtopic }) => {
  const { data, hasNext, loadNext, isLoadingNext, refetch } = usePaginationFragment<
    SubtopicQuestionsTablePaginationQuery,
    SubtopicQuestionsTable_topic$key
  >(subtopicFragment, subtopic)

  return (
    <PaginationTable<SubtopicQuestionsEdge, QuestionsOrderBy, SubtopicQuestionsTablePaginationQuery, QuestionFilter>
      columns={useMemo<ColumnDef<SubtopicQuestionsEdge>[]>(
        () => [
          {
            id: 'actions',
            enableMultiSort: false,
            enableSorting: false,
            size: 50,
            cell: (props) => {
              const node = props.row.original.node

              return (
                <ActionColumnIcon
                  href={`/admin/examTypes/${data?.examType?.slug}/topics/${data?.parentTopic?.slug}/subtopics/${data?.slug}/questions/${node.slug}`}
                />
              )
            }
          },
          {
            accessorKey: 'node.isActive',
            enableMultiSort: false,
            enableSorting: true,
            id: 'isActive',
            header: 'Active',
            size: 50,
            cell: (props) => {
              const node = props.row.original.node

              return (
                <IconBoolean
                  size={18}
                  value={node.isActive}
                />
              )
            }
          },
          {
            accessorKey: 'node.body',
            enableSorting: false,
            enableMultiSort: false,
            id: 'body',
            header: 'Body',
            cell: (props) => {
              const node = props.row.original.node

              return stripMarkdown(node.body)
            }
          },
          {
            accessorKey: 'node.topicObjective',
            enableSorting: false,
            enableMultiSort: false,
            id: 'objective',
            header: 'Objective',
            cell: (props) => {
              const node = props.row.original.node

              return node?.topicObjective ? (
                <Anchor
                  component={Link}
                  href={`/admin/examTypes/${data?.examType?.slug}/topics/${data?.parentTopic?.slug}/subtopics/${data?.slug}/objectives/${node.topicObjective?.slug}`}
                >
                  {node?.topicObjective?.title}
                </Anchor>
              ) : (
                <Text color='red'>No objective selected</Text>
              )
            }
          }
        ],
        [data?.examType?.slug, data?.parentTopic?.slug, data?.slug]
      )}
      data={data?.questions?.edges || []}
      filterPlaceholder='Search Questions'
      getFilterFromSearch={useCallback(
        (search: string) =>
          !isEmpty(search)
            ? {
                or: [
                  {
                    body: {
                      includesInsensitive: search
                    }
                  },
                  {
                    explanation: {
                      includesInsensitive: search
                    }
                  },
                  {
                    topicObjective: {
                      title: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    topicObjective: {
                      summary: {
                        includesInsensitive: search
                      }
                    }
                  }
                ]
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={[
        {
          id: 'isActive',
          desc: false
        }
      ]}
      isFilterable
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      refetch={refetch}
      sortOptions={{
        isActive: {
          asc: QuestionsOrderBy.IsActiveAsc,
          desc: QuestionsOrderBy.IsActiveDesc
        }
      }}
      totalCount={data?.questions?.totalCount || 0}
    />
  )
}
